import { LayoutDefault } from "components";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

const SupportLayout: React.FC = () => {
  return (
    <LayoutDefault>
      <Suspense>
        <Outlet />
      </Suspense>
    </LayoutDefault>
  );
};

export default SupportLayout;
