import { SystemLogSerializer } from "data/interfaces/info/SystemLogSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "info/system-log";

class SystemLogModel extends MainModule<
  SystemLogSerializer,
  SystemLogSerializer
> {}

export default new SystemLogModel(model_url);
