import { RouteInterface } from "utils/router";

import RetDashboardGeral from "../views/dashboard/geral/index";

const dashboardRoutes: RouteInterface[] = [
  {
    title: "Geral",
    path: "/retaguarda/dashboard/geral",
    element: <RetDashboardGeral />,
    perm: "DASHBOARD_GERAL",
  },
];

export default dashboardRoutes;
