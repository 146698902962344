import { FechamentoCegoSerializer } from "data/interfaces/pdv/FechamentoCegoSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "pdv-novo/fechamento-cego";

class FechamentoCegoModel extends MainModule<
  FechamentoCegoSerializer,
  FechamentoCegoSerializer
> {}

export default new FechamentoCegoModel(model_url);
