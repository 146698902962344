import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_DEPARTAMENTO_FILTERS,
  RETAGUARDA_DEPARTAMENTO_LIST,
  RETAGUARDA_DEPARTAMENTO_ORDERING,
} from "constants/retaguarda/cadastro/departamento";
import { DepartamentoModel } from "data/models";
import React from "react";

const RetDepartamentoList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={DepartamentoModel}
      columns={RETAGUARDA_DEPARTAMENTO_LIST}
      filters={RETAGUARDA_DEPARTAMENTO_FILTERS}
      ordering={RETAGUARDA_DEPARTAMENTO_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetDepartamentoList;
