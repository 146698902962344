import { RouteInterface } from "utils/router";

export const getCollapseStates = (
  routes: RouteInterface[]
): { [key: string]: boolean } => {
  let initialState: { [key: string]: boolean } = {};

  routes.forEach((route) => {
    if (route.state) {
      initialState[route.state] = getCollapseInitialState(route.children || []);
      initialState = {
        ...initialState,
        ...getCollapseStates(route.children || []),
      };
    }
  });

  return initialState;
};

const getCollapseInitialState = (routes: RouteInterface[]): boolean => {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].state && getCollapseInitialState(routes[i].children || [])) {
      return true;
    } else if (window.location.pathname.includes(routes[i].path)) {
      return true;
    }
  }
  return false;
};
