import { lazy } from "react";
import { RouteInterface } from "utils/router";

const SignIn = lazy(() => import("layouts/auth/views/sign_in"));
const Recovery = lazy(() => import("layouts/auth/views/recovery"));

const AuthRoutes: RouteInterface[] = [
  {
    path: "/auth",
    element: <SignIn />,
  },
  {
    path: "/auth/recovery",
    element: <Recovery />,
  },
];

export default AuthRoutes;
