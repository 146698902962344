export function getDataFromBalancaPattern(
  pattern: string,
  value: string,
  ignoreErrors = false
) {
  if (!pattern || !value) {
    if (ignoreErrors) return {};
    throw new Error("Padrão ou valor não informados.");
  }

  const data: {
    codigo?: string;
    preco?: number | string;
    peso?: number | string;
  } = {};
  const chars = pattern.split("");
  const values = value.split("");

  const codeString = chars.filter((char: string) => char === "C");
  const codeStart = chars.indexOf("C");
  const priceString = chars.filter((char: string) => char === "R");
  const priceStart = chars.indexOf("R");
  const weightString = chars.filter((char: string) => char === "P");
  const weightStart = chars.indexOf("P");
  const decimalString = chars.filter((char: string) => char === "D");
  const decimalStart = chars.indexOf("D");

  if (priceString.length && weightString.length) {
    if (ignoreErrors) return data;
    throw new Error(
      "Não é possível ter preço e peso na mesma balança. Remova um dos dois."
    );
  }

  if (codeString.length) {
    data.codigo = values
      .slice(codeStart, codeStart + codeString.length)
      .join("");
  }

  if (!data.codigo) {
    if (ignoreErrors) return data;
    throw new Error("Não foi possível encontrar o código do produto.");
  }

  let decimalValue: number | string = 0;

  if (decimalString.length) {
    decimalValue = values
      .slice(decimalStart, decimalStart + decimalString.length)
      .join("");
  }

  if (priceString.length) {
    data.preco = values
      .slice(priceStart, priceStart + priceString.length)
      .join("");
    data.preco = Number(data.preco) + Number(`0.${decimalValue}`);
  }

  if (weightString.length) {
    data.peso = values
      .slice(weightStart, weightStart + weightString.length)
      .join("");
    data.peso = Number(data.peso) + Number(`0.${decimalValue}`);
  }

  return data;
}
