import { Box, Button } from "@mui/material";
import React from "react";
import { CartItemsList } from "./CartItemsList";
import { DeliveryTypeSelect } from "./DeliveryTypeSelect";
import { OrderSummary } from "./OrderSummary";
import { CartState } from "layouts/catalogo/views/cart";

interface CartItemsProps {
  cartState: CartState;
  setCartState: (state: CartState) => void;
  onNext: () => void;
}

const CartItems: React.FC<CartItemsProps> = ({
  cartState,
  setCartState,
  onNext,
}) => {
  return (
    <Box>
      <CartItemsList />

      <DeliveryTypeSelect
        value={cartState.deliveryType}
        onChange={(type) => setCartState({ ...cartState, deliveryType: type })}
      />

      <OrderSummary deliveryType={cartState.deliveryType} />

      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        sx={{ mt: 4 }}
        onClick={onNext}
        disabled={!cartState.deliveryType}
      >
        Continuar
      </Button>
    </Box>
  );
};

export default CartItems;
