import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_USUARIOS_FILTERS,
  RETAGUARDA_USUARIOS_LIST,
  RETAGUARDA_USUARIOS_ORDERING,
} from "constants/retaguarda/cadastro/usuarios";
import { UserModel } from "data/models";
import React from "react";

const RetUserList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={UserModel}
      columns={RETAGUARDA_USUARIOS_LIST}
      filters={RETAGUARDA_USUARIOS_FILTERS}
      ordering={RETAGUARDA_USUARIOS_ORDERING}
      actions={{
        update: true,
        delete: true,
        create: true,
      }}
    />
  );
};

export default RetUserList;
