import { RouteInterface } from "utils/router";

import {
  AccountBalanceOutlined,
  AssessmentOutlined,
  CreateOutlined,
  DashboardOutlined,
  DesktopWindowsOutlined,
  HomeOutlined,
  MoneyOffOutlined,
  SettingsOutlined,
  StorageOutlined,
} from "@mui/icons-material";

import cadastroRoutes from "./routes/cadastro";
import fiscalRoutes from "./routes/fiscal";
import estoqueRoutes from "./routes/estoque";
import financeiroRoutes from "./routes/financeiro";
import relatoriosRoutes from "./routes/relatorios";
import pdvRoutes from "./routes/pdv";
import configuracoesRoutes from "./routes/configuracoes";
import dashboardRoutes from "./routes/dashboard";
import PageNotFound from "components/layout/error";

import WebHome from "./views/home";

const RetaguardaRoutes: RouteInterface[] = [
  {
    title: "Início",
    path: "/retaguarda/home",
    element: <WebHome />,
    icon: <HomeOutlined />,
  },
  {
    icon: <DashboardOutlined />,
    title: "Dashboard",
    path: "/retaguarda/dashboard",
    children: dashboardRoutes,
    state: "dashboardCollapse",
    perm: "DASHBOARD",
  },
  {
    icon: <CreateOutlined />,
    title: "Cadastro",
    path: "/retaguarda/cadastro",
    children: cadastroRoutes,
    state: "cadastroCollapse",
    perm: "CADASTRO",
  },
  {
    icon: <MoneyOffOutlined />,
    title: "Fiscal",
    path: "/retaguarda/fiscal",
    children: fiscalRoutes,
    state: "fiscalCollapse",
    perm: "FISCAL",
  },
  {
    icon: <StorageOutlined />,
    title: "Estoque",
    path: "/retaguarda/estoque",
    children: estoqueRoutes,
    state: "estoqueCollapse",
    perm: "ESTOQUE",
  },
  {
    icon: <AccountBalanceOutlined />,
    title: "Financeiro",
    path: "/retaguarda/financeiro",
    children: financeiroRoutes,
    state: "financeiroCollapse",
    perm: "FINANCEIRO",
  },
  {
    icon: <AssessmentOutlined />,
    title: "Relatórios",
    path: "/retaguarda/relatorios",
    children: relatoriosRoutes,
    state: "relatoriosCollapse",
    perm: "RELATORIOS",
  },
  {
    icon: <DesktopWindowsOutlined />,
    title: "PDV",
    path: "/retaguarda/pdv",
    children: pdvRoutes,
    state: "pdvCollapse",
    perm: "PDV",
  },
  {
    icon: <SettingsOutlined />,
    title: "Configurações",
    path: "/retaguarda/config",
    children: configuracoesRoutes,
    state: "configCollapse",
    perm: "CONFIGURACOES",
  },
  {
    norender: true,
    path: "/retaguarda/*",
    element: <PageNotFound />,
  },
];

export default RetaguardaRoutes;
