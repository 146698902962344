/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { Scheduler } from "@aldabil/react-scheduler";
import { ptBR } from "date-fns/locale/pt-BR";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import CustomEditor from "./CustomEditor";
import { AttachMoney, PersonRounded } from "@mui/icons-material";
import useQuery from "hooks/useQuery";
import { AgendamentoModel, ParceiroModel } from "data/models";
import NuvelGrid from "components/nuvel/Grid";

const translations = {
  event: {
    allDay: "O dia inteiro",
    end: "Final",
    start: "Início",
    title: "Título",
    subtitle: "Subtítulo",
  },
  form: {
    addTitle: "Adicionar Evento",
    cancel: "Cancelar",
    confirm: "Confirmar",
    delete: "Deletar",
    editTitle: "Editar Título",
  },
  loading: "Carregando...",
  moreEvents: "Mais agendamentos",
  navigation: {
    agenda: "Agendamentos",
    day: "Dia",
    month: "Mês",
    today: "Hoje",
    week: "Semana",
  },
  noDataToDisplay: "Sem nenhum agendamento para hoje",
  validation: {
    invalidEmail: "Email inválido",
    max: "Máximo",
    min: "Mínimo",
    onlyNumbers: "Apenas números",
    required: "Obrigatório",
  },
};

function ExtraView({ event, isFuncionario }: any) {
  return (
    <div>
      {!isFuncionario ? (
        <Typography
          style={{ display: "flex", alignItems: "center" }}
          color="textSecondary"
          variant="caption"
          noWrap
        >
          <PersonRounded /> {event.parceiro_funcionario_name}
        </Typography>
      ) : null}
      <Typography
        style={{ display: "flex", alignItems: "center" }}
        color="textSecondary"
        variant="caption"
        noWrap
      >
        <PersonRounded /> {event.parceiro_cliente_name}
      </Typography>
      <Typography
        style={{ display: "flex", alignItems: "center" }}
        color="textSecondary"
        variant="caption"
        noWrap
      >
        <AttachMoney /> {event.service_name}
      </Typography>
    </div>
  );
}
function Agenda() {
  const theme = useTheme();
  const [mode, setMode] = React.useState("tabs");
  const query_params = useQuery();

  const agenda_id = query_params.get("id");

  const { data: funcionarios } = ParceiroModel.useModel({
    funcionario_ativo: true,
    func: true,
  });

  const resourcesConstant =
    funcionarios?.rows?.map((func) => {
      if (func.contatos.length !== 0) {
        return {
          ...func,
          telefone: func.contatos[0].telefone,
          avatar: "https://picsum.photos/200/300",
          color: theme.palette.primary.main,
          parceiro_funcionario_id: func.id,
        };
      } else {
        return {
          ...func,
          telefone: "Sem telefone",
          avatar: "https://picsum.photos/200/300",
          color: theme.palette.primary.main,
          parceiro_funcionario_id: func.id,
        };
      }
    }) || [];

  const handleChange = (event: any) => {
    setMode(event.target.value);
  };

  const fetchRemote = (query: any) => {
    return AgendamentoModel.get(undefined, {
      ...query,
      agenda_id: agenda_id,
    }).then((res) => {
      return res.map((event: any) => ({
        ...event,
        parceiro_funcionario_id: event.parceiro_funcionario,
        event_id: event.id,
        start: new Date(event.start),
        end: new Date(event.end),
      }));
    });
  };

  const handleDelete = (deleted_id: any) => {
    return AgendamentoModel.delete(deleted_id).then(() => {
      return deleted_id;
    });
  };

  return (
    <>
      <Paper sx={{ mb: 1, p: 1 }}>
        <NuvelGrid container spacing={1}>
          <NuvelGrid xs={4}>
            <FormControl sx={{ flexDirection: "row", display: "flex" }}>
              <FormLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: 1,
                  color: "black",
                }}
                id="demo-controlled-radio-buttons-group"
              >
                Filtros:
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={mode}
                onChange={handleChange}
                sx={{ flexDirection: "row", display: "flex" }}
              >
                <FormControlLabel
                  value="default"
                  control={<Radio />}
                  label="Geral"
                />
                <FormControlLabel
                  value="tabs"
                  control={<Radio />}
                  label="Funcionarios"
                />
              </RadioGroup>
            </FormControl>
          </NuvelGrid>
        </NuvelGrid>
      </Paper>
      <Paper>
        {mode === "default" ? (
          <Scheduler
            key="scheduler-default"
            onDelete={handleDelete}
            getRemoteEvents={fetchRemote}
            view="month"
            customEditor={(scheduler) => <CustomEditor scheduler={scheduler} />}
            viewerExtraComponent={(_fields, event) => {
              return <ExtraView event={event} />;
            }}
            locale={ptBR as any}
            translations={translations}
          />
        ) : (
          <>
            {funcionarios && (
              <Scheduler
                key="scheduler-employee"
                onDelete={handleDelete}
                getRemoteEvents={fetchRemote}
                resourceViewMode="tabs"
                view="month"
                customEditor={(scheduler) => (
                  <CustomEditor scheduler={scheduler} />
                )}
                viewerExtraComponent={(_fields, event) => {
                  return <ExtraView event={event} isFuncionario={true} />;
                }}
                resources={mode === "tabs" ? resourcesConstant : undefined}
                resourceFields={{
                  idField: "parceiro_funcionario_id",
                  textField: "razao_social_nome",
                  subTextField: "telefone",
                  avatarField: "razao_social_nome",
                  colorField: "color",
                }}
                locale={ptBR as any}
                translations={translations}
              />
            )}
          </>
        )}
      </Paper>
    </>
  );
}

export default Agenda;
