import { FormaSerializer } from "data/interfaces/financeiro/FormaSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "forma";

class FormaDePagamentoModel extends MainModule<
  FormaSerializer,
  FormaSerializer
> {}

export default new FormaDePagamentoModel(model_url);
