import { LayoutDefault } from "components";
import React, { useState } from "react";
import { BottomNavigation } from "./components";
import { styled } from "@mui/material/styles";
import NuvelGrid from "components/nuvel/Grid";
import { Box } from "@mui/material";
import HideAppBar from "./components/layout/AppBar";
import { useResponsive } from "hooks";
import { CatalogProvider } from "./context";
import ViewsWrapper from "./views_wrapper";

const MainDesktop = styled("main")(() => ({
  flexGrow: 1,
  height: "calc(100vh - 56px)",
  overflow: "auto",
}));

const CatalogoLayout: React.FC = () => {
  const { isMobile } = useResponsive();
  const [navigationValue, setNavigationValue] = useState(0);

  return (
    <CatalogProvider>
      <LayoutDefault>
        <MainDesktop>
          <HideAppBar />
          <NuvelGrid container>
            <NuvelGrid size={{ xs: 0, sm: 2.5 }}></NuvelGrid>
            <NuvelGrid size={{ xs: 12, sm: 7 }}>
              <Box sx={{ p: isMobile ? 2 : 0 }}>
                <ViewsWrapper route={navigationValue} />
              </Box>
            </NuvelGrid>
            <NuvelGrid size={{ xs: 0, sm: 2.5 }}></NuvelGrid>
          </NuvelGrid>
        </MainDesktop>
        <BottomNavigation
          value={navigationValue}
          setValue={setNavigationValue}
        />
      </LayoutDefault>
    </CatalogProvider>
  );
};

export default CatalogoLayout;
