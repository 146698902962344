import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_SERVICOS_FILTERS,
  RETAGUARDA_SERVICOS_LIST,
  RETAGUARDA_SERVICOS_ORDERING,
} from "constants/retaguarda/financeiro/servicos";
import { ServicoModel } from "data/models/financeiro";
import React from "react";

const RetServicosList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={ServicoModel}
      columns={RETAGUARDA_SERVICOS_LIST}
      filters={RETAGUARDA_SERVICOS_FILTERS}
      ordering={RETAGUARDA_SERVICOS_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetServicosList;
