import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_CERTIFICADO_FILTERS,
  RETAGUARDA_CERTIFICADO_LIST,
  RETAGUARDA_CERTIFICADO_ORDERING,
} from "constants/retaguarda/fiscal/certificado";
import { CertificadoModel } from "data/models";
import React from "react";

const RetCertificadosList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={CertificadoModel}
      columns={RETAGUARDA_CERTIFICADO_LIST}
      filters={RETAGUARDA_CERTIFICADO_FILTERS}
      ordering={RETAGUARDA_CERTIFICADO_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetCertificadosList;
