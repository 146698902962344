import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelGrid from "components/nuvel/Grid";
import NuvelSelect from "components/nuvel/Select";
import { TransacaoFilters } from "constants/retaguarda/financeiro/contas_a_pagar";
import React from "react";
import { handleChange } from "utils/functions";

interface Filtros {
  state: TransacaoFilters;
  setState: React.Dispatch<React.SetStateAction<TransacaoFilters>>;
}

function ContasAPagarFiltro(props: Filtros) {
  const { state, setState } = props;

  return (
    <NuvelGrid container spacing={2}>
      <NuvelGrid size={{ xs: 12, md: 2 }}>
        <NuvelSelect
          onChange={(e) => handleChange(e, setState)}
          label="Status"
          name="status"
          value={state.status}
          options={[
            { label: "Todos", value: -1 },
            { label: "A Vencer", value: 0 },
            { label: "Pago", value: 1 },
            { label: "Vencido", value: 3 },
          ]}
        />
      </NuvelGrid>
      <NuvelGrid size={{ xs: 12, md: 3 }}>
        <NuvelAutocomplete
          label="Fornecedor"
          name="parceiro_de_negocios"
          value={Number(state.parceiro_de_negocios)}
          onChange={(e) => handleChange(e, setState)}
          reference="fornecedor"
        />
      </NuvelGrid>
      <NuvelGrid size={{ xs: 12, md: 3 }}>
        <NuvelAutocomplete
          label="Loja"
          name="loja"
          value={Number(state.loja)}
          onChange={(e) => handleChange(e, setState)}
          reference="loja"
        />
      </NuvelGrid>
      <NuvelGrid size={{ xs: 12, md: 2 }}>
        <NuvelDatePicker
          label="Data Inicial"
          onChange={(e) => handleChange(e, setState)}
          name="data_inicial"
          value={state.data_inicial}
        />
      </NuvelGrid>
      <NuvelGrid size={{ xs: 12, md: 2 }}>
        <NuvelDatePicker
          label="Data Final"
          onChange={(e) => handleChange(e, setState)}
          name="data_final"
          value={state.data_final}
        />
      </NuvelGrid>
    </NuvelGrid>
  );
}

export default ContasAPagarFiltro;
