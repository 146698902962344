import "assets/css/index.css";
import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { SWRConfig } from "swr";

import "utils/extensions";

import { AnimatePresence } from "framer-motion";
import { SnackbarProvider } from "notistack";
import AppConstantsProvider from "provider";
import MainRouter from "router";
import LoadingAnimation from "utils/design/loading_animation";
import ThemeConstructor from "utils/design/provider";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

// eslint-disable-next-line react-refresh/only-export-components
function Main() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [firstLoading, setLoading] = useState(true);

  const handleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  return (
    <ThemeConstructor theme={theme} setTheme={setTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <SWRConfig>
          <SnackbarProvider
            maxSnack={10}
            preventDuplicate
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <AppConstantsProvider handleTheme={handleTheme}>
              <AnimatePresence mode="wait">
                {firstLoading ? (
                  <LoadingAnimation setLoading={setLoading} />
                ) : (
                  <MainRouter />
                )}
              </AnimatePresence>
            </AppConstantsProvider>
          </SnackbarProvider>
        </SWRConfig>
      </LocalizationProvider>
    </ThemeConstructor>
  );
}

const rootElement = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);
