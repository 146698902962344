import { AutoFormProps } from "components/nuvel/forms/automatic/NuvelAutoForm";
import { TableColumn, TableFilter } from "components/nuvel/lists";
import { MetaDeVendaSerializer } from "data/interfaces/financeiro/MetaDeVendaSerializer";

export const RETAGUARDA_METADEVENDA_FORM: AutoFormProps<MetaDeVendaSerializer>[] =
  [
    {
      type: "grid",
      grid: { spacing: 2 },
      children: [
        {
          grid: { xs: 12, md: (12 / 7) * 1 },
          name: "ativado",
          label: "Ativado",
          type: "checkbox",
          defaultValue: true,
        },
        {
          grid: { xs: 12, md: (12 / 7) * 6 },
          name: "loja",
          label: "Loja",
          type: "autocomplete",
          reference: "loja",
        },
        {
          grid: { xs: 12, md: 12 / 7 },
          name: "valor_segunda_feira",
          label: "Segunda-feira",
          type: "money",
        },
        {
          grid: { xs: 12, md: 12 / 7 },
          name: "valor_terca_feira",
          label: "Terça-feira",
          type: "money",
        },
        {
          grid: { xs: 12, md: 12 / 7 },
          name: "valor_quarta_feira",
          label: "Quarta-feira",
          type: "money",
        },
        {
          grid: { xs: 12, md: 12 / 7 },
          name: "valor_quinta_feira",
          label: "Quinta-feira",
          type: "money",
        },
        {
          grid: { xs: 12, md: 12 / 7 },
          name: "valor_sexta_feira",
          label: "Sexta-feira",
          type: "money",
        },
        {
          grid: { xs: 12, md: 12 / 7 },
          name: "valor_sabado",
          label: "Sábado",
          type: "money",
        },
        {
          grid: { xs: 12, md: 12 / 7 },
          name: "valor_domingo",
          label: "Domingo",
          type: "money",
        },
      ],
    },
  ];

export const RETAGUARDA_METADEVENDA_LIST: TableColumn<MetaDeVendaSerializer>[] =
  [
    {
      label: "Loja",
      accessor: "loja_nome",
    },
    {
      label: "Ativado",
      accessor: (row) => `${row.ativado ? "Sim" : "Não"}`,
    },
  ];

export const RETAGUARDA_METADEVENDA_ORDERING: (keyof MetaDeVendaSerializer)[] =
  ["loja_nome"];

export const RETAGUARDA_METADEVENDA_FILTERS: TableFilter<MetaDeVendaSerializer>[] =
  [
    {
      label: "Loja",
      accessor: "loja_nome",
      support: ["icontains"],
    },
  ];
