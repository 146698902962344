// /* eslint-disable @typescript-eslint/no-explicit-any */

// import React from "react";
// import { Paper, useTheme } from "@mui/material";
// // import FileSaver from "file-saver";
// import { getPeriodo } from "utils/functions/getPeriodo";
// import AuthController from "data/controller/auth";
// // import DashVendas from "data/controller/dashVendas";
// import DashboardDataLayer from "components/retaguarda/dashboard/DataLayer";
// import PeriodoSelect from "components/retaguarda/dashboard/PeriodoSelect";
// import CardList from "components/retaguarda/dashboard/CardsList";
// import ChartVendasPeriodo from "components/retaguarda/dashboard/ChartVendasPeriodo";
// import ChartVendasPresentePassado from "components/retaguarda/dashboard/ChartVendasPresPas";
// import ChartFinalizadoras from "components/retaguarda/dashboard/ChartFinalizadoras";
// import ChartVendasPorLoja from "components/retaguarda/dashboard/ChatVendasPorLoja";
// import TotalEstados from "components/retaguarda/dashboard/TotalEstados";
// import MaisVendidos from "components/retaguarda/dashboard/MaisVendidos";

// import NuvelSelect from "components/nuvel/Select";
// import NuvelDatePicker from "components/nuvel/DatePicker";
// import dayjs from "dayjs";
// import NuvelGrid from "components/nuvel/Grid";
// import NuvelAutocomplete from "components/nuvel/Autocomplete";
// import { handleChange as handleChangeUtils } from "utils/functions";
// interface State {
//   loja: any[];
//   periodo: string;
//   periodoParams: {
//     start_date: string;
//     end_date: string;
//     previus_start_date: string;
//     previus_end_date: string;
//   };
//   status: number;
// }

// const DashVendasGeral: React.FC = () => {
//   const [state, setState] = React.useState<State>({
//     loja: [],
//     periodo: "hoje",
//     periodoParams: {
//       start_date: getPeriodo("hoje").start_date,
//       end_date: getPeriodo("hoje").end_date,
//       previus_start_date: getPeriodo("hoje").previus_start_date,
//       previus_end_date: getPeriodo("hoje").previus_end_date,
//     },
//     status: -1,
//   });

//   const { data: user_data } = AuthController.useCurrentUser();
//   const [qtdMaisVendidos, setQtdMaisVendidos] = React.useState<number>(10);

//   const theme = useTheme();
//   const color = theme.palette.primary.main;

//   const COLORS = {
//     dinheiro: color,
//     cartão_de_crédito: "#00C49F",
//     cheque: "#0088FE",
//     cartão_de_débito: "#FF8042",
//     crédito_loja: "#CC0000",
//     vale_alimentação: "#FFD700",
//     vale_refeição: "#6A5ACD",
//     vale_presente: "#FF1493",
//     vale_combustível: "#32CD32",
//     boleto_bancário: "#FF4500",
//     depósito_bancário: "#9370DB",
//     pagamento_instantâneo_pix_: "#20B2AA",
//     transferência_bancária_carteira_digital: "#FF69B4",
//     "Programa de fidelidade, Cashback, Crédito Virtual": "#3CB371",
//     outras: "#4B0082",
//   };

//   const handleChange = (e: any) => {
//     const { name, value } = e.target;
//     setState((prevState) => ({
//       ...prevState,
//       periodoParams: {
//         ...prevState.periodoParams,
//         [name]: value.format(),
//       },
//     }));
//   };

//   const isDayInterval =
//     dayjs(state.periodoParams.start_date).diff(
//       dayjs(state.periodoParams.end_date),
//       "days"
//     ) === 0;

//   // const download_xls = (tipo: string) => {
//   //   const { loja, periodoParams } = state;
//   //   const { start_date, end_date, previus_start_date, previus_end_date } =
//   //     periodoParams;

//   //   const downloadFunction =
//   //     tipo === "simplificado"
//   //       ? DashVendas.getDownloadSimplificado
//   //       : DashVendas.getDownloadDetalhado;

//   //   downloadFunction({
//   //     loja,
//   //     start_date,
//   //     end_date,
//   //     previus_start_date,
//   //     previus_end_date,
//   //   }).then((res) => {
//   //     const blob = new Blob([res.data], {
//   //       type: "application/vnd.ms-excel",
//   //     });
//   //     FileSaver.saveAs(
//   //       blob,
//   //       `relatorio_${tipo}_${start_date}_até_${end_date}.xlsx`
//   //     );
//   //   });
//   // };

//   const showVendasPorHora =
//     user_data?.username?.includes("suporte") ||
//     user_data?.user_lojas?.length >= 2;

//   return (
//     <DashboardDataLayer qtdMaisVendidos={qtdMaisVendidos} data={state}>
//       {({
//         totalPeriodo,
//         histogram_v_total,
//         totalFormaPagamento,
//         totalEstados,
//         produtosMaisVendidos,
//         histograma_presente_passado,
//       }) => (
//         <>
//           <NuvelGrid container spacing={1}>
//             <NuvelGrid xs={12}>
//               <Paper sx={{ borderRadius: 3, p: 2, pt: 1, pb: 1 }}>
//                 <NuvelGrid container spacing={2}>
//                   <NuvelGrid xs={12} sm={4} md={3}>
//                     <NuvelAutocomplete
//                       label="Loja"
//                       name="loja"
//                       reference="loja"
//                       value={state.loja}
//                       onChange={(e: any) => handleChangeUtils(e, setState)}
//                     />
//                   </NuvelGrid>
//                   <NuvelGrid xs={12} sm={6} md={3}>
//                     <NuvelSelect
//                       label="Status"
//                       onChange={(e: any) => handleChangeUtils(e, setState)}
//                       name="status"
//                       value={state.status}
//                       options={[
//                         { label: "Todos", value: -1 },
//                         { label: "Realizadas", value: 0 },
//                         { label: "Canceladas", value: 1 },
//                         { label: "Cancelado sem finalizar", value: 2 },
//                       ]}
//                     />
//                   </NuvelGrid>
//                   <NuvelGrid xs={12} sm={6} md={2}>
//                     <PeriodoSelect state={state} setState={setState} />
//                   </NuvelGrid>
//                   <NuvelGrid xs={12} sm={6} md={2}>
//                     <NuvelDatePicker
//                       label="Data Inicial"
//                       onChange={(e: any) =>
//                         handleChange({
//                           target: {
//                             name: e.target.name,
//                             value: dayjs(e.target.value).startOf("day"),
//                           },
//                         })
//                       }
//                       name="start_date"
//                       value={state.periodoParams.start_date}
//                     />
//                   </NuvelGrid>
//                   <NuvelGrid xs={12} sm={6} md={2}>
//                     <NuvelDatePicker
//                       label="Data Final"
//                       onChange={(e) =>
//                         handleChange({
//                           target: {
//                             name: e.target.name,
//                             value: dayjs(e.target.value).endOf("day"),
//                           },
//                         })
//                       }
//                       name="end_date"
//                       value={state.periodoParams.end_date}
//                     />
//                   </NuvelGrid>
//                 </NuvelGrid>
//               </Paper>
//             </NuvelGrid>
//             <CardList totalPeriodo={totalPeriodo} />
//             <NuvelGrid xs={12} sm={12} lg={6}>
//               <ChartVendasPeriodo
//                 data={histogram_v_total}
//                 isDayInterval={isDayInterval}
//               />
//             </NuvelGrid>
//             <NuvelGrid xs={12} sm={12} lg={6}>
//               <ChartVendasPresentePassado data={histograma_presente_passado} />
//             </NuvelGrid>
//             <NuvelGrid xs={12} sm={12} lg={4} xl={4}>
//               <ChartFinalizadoras colors={COLORS} data={totalFormaPagamento} />
//             </NuvelGrid>
//             {showVendasPorHora && (
//               <NuvelGrid xs={12} sm={12} lg={8} xl={8}>
//                 <ChartVendasPorLoja data={totalPeriodo?.vendas_por_loja} />
//               </NuvelGrid>
//             )}
//             <NuvelGrid xs={12} sm={6} lg={6}>
//               <TotalEstados rows={totalEstados} />
//             </NuvelGrid>
//             <NuvelGrid xs={12} sm={6} lg={6}>
//               <MaisVendidos
//                 rows={produtosMaisVendidos}
//                 loading={!produtosMaisVendidos}
//                 quantidade={qtdMaisVendidos}
//                 setQuantidade={setQtdMaisVendidos}
//               />
//             </NuvelGrid>
//           </NuvelGrid>
//         </>
//       )}
//     </DashboardDataLayer>
//   );
// };

// export default DashVendasGeral;

import DashVendasGeral from "components/legacy/dashboard";
import React from "react";

const Dashboard: React.FC = () => {
  return <DashVendasGeral />;
};

export default Dashboard;
