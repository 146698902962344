import { ArrowDropDown, ArrowDropUp, InfoRounded } from "@mui/icons-material";
import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import PaperWithLoad from "components/nuvel/PaperWithLoad";
import React from "react";

export default function Total({ ...props }) {
  const { title, valor, porcentagem, quantidade, percent, desc } = props;

  const formatCurrency = (value) => {
    return value.toLocaleString("pt-BR", {
      minimumFractionDigits: 0,
      style: "currency",
      currency: "BRL",
    });
  };

  let positivo = porcentagem > 0 ? true : false;
  const currentTheme = localStorage.getItem("theme");
  const color = currentTheme === "light" ? "#333" : "#fff";

  return (
    <>
      <PaperWithLoad loading={Number(valor) !== 0 && !valor}>
        {desc ? (
          <Tooltip
            style={{
              position: "absolute",
              top: "0",
              right: "0",
            }}
            title={desc}
          >
            <IconButton>
              <InfoRounded
                style={{
                  color: color,
                }}
              />
            </IconButton>
          </Tooltip>
        ) : null}
        <Box
          p={2}
          pb={1}
          style={{
            minHeight: "133.69px",
          }}
        >
          <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
            {title}
          </Typography>
          {valor ? (
            <>
              {quantidade ? (
                <Typography variant="h4">{valor}</Typography>
              ) : percent ? (
                <Typography variant="h4">{valor}%</Typography>
              ) : (
                <Typography variant="h4">{formatCurrency(valor)}</Typography>
              )}
            </>
          ) : (
            <Typography variant="h4">...</Typography>
          )}
          <Divider />
          <Box pt={1}>
            {porcentagem ? (
              porcentagem !== 0 ? (
                <Typography
                  variant="h6"
                  align="center"
                  sx={positivo ? { color: "green" } : { color: "red" }}
                >
                  {positivo ? <ArrowDropUp /> : <ArrowDropDown />}
                  {porcentagem}%
                </Typography>
              ) : (
                <Typography variant="h6" align="center">
                  {porcentagem}%
                </Typography>
              )
            ) : null}
          </Box>
        </Box>
      </PaperWithLoad>
    </>
  );
}