import { memo, ReactNode } from "react";
import NuvelGrid from "components/nuvel/Grid";
import { AutoFormProps, GridInternalProps } from "./NuvelAutoForm";
import { InternalModel } from "data/main";
import FieldComponent from "./FieldComponent";
import { SxProps } from "@mui/material";
import NuvelAccordion from "components/nuvel/Accordion";

interface GenerateFieldsProps<T extends InternalModel> {
  field: AutoFormProps<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any;
}

const ComponentGrid = ({
  children,
  gridProps,
  sx,
}: {
  children?: ReactNode;
  gridProps: GridInternalProps;
  sx?: SxProps;
}) => {
  return (
    <NuvelGrid {...gridProps} sx={sx}>
      {children}
    </NuvelGrid>
  );
};

const GenerateFields = <T extends InternalModel>({
  field,
  state,
}: GenerateFieldsProps<T>) => {
  if (Object.keys(field).length === 2 && field.grid && field.type === "grid") {
    return <ComponentGrid gridProps={field.grid} />;
  }
  if (!field.grid) {
    return <p>{`Error on Field: ${JSON.stringify(field)}`}</p>;
  }
  if (field.type === "accordion" && !field.familly && !field.children) {
    return (
      <p>{`Error rendering accordion, the accordion object must have a familly or a children`}</p>
    );
  }

  if (field.validation && !field.validation(state)) {
    return null;
    // return <ComponentGrid gridProps={field.grid} />;
  }

  if (field.type === "accordion" && !field.children && field.familly) {
    return (
      <ComponentGrid gridProps={field.grid}>
        {field.familly.map((familly) => {
          if (!familly.children) {
            return (
              <p>{`Error rendering accordion with familly, the familly object must have children`}</p>
            );
          }
          return (
            <NuvelAccordion title={familly.label as string}>
              <NuvelGrid container spacing={2} key={familly.label as string}>
                {familly.children.map((child) => (
                  <GenerateFields
                    key={child.name as string}
                    field={child}
                    state={state}
                  />
                ))}
              </NuvelGrid>
            </NuvelAccordion>
          );
        })}
      </ComponentGrid>
    );
  }

  if (field.children && field.children.length !== 0) {
    if (field.type === "accordion") {
      return (
        <ComponentGrid gridProps={field.grid}>
          <NuvelAccordion
            title={field.label as string}
            startExpanded={field.startExpanded}
          >
            <NuvelGrid container spacing={2} key={field.name as string}>
              {field.children.map((child) => (
                <GenerateFields
                  key={child.name as string}
                  field={child}
                  state={state}
                />
              ))}
            </NuvelGrid>
          </NuvelAccordion>
        </ComponentGrid>
      );
    }
    return (
      <NuvelGrid container spacing={2} key={field.name as string}>
        {field.children.map((child) => (
          <GenerateFields
            key={child.name as string}
            field={child}
            state={state}
          />
        ))}
      </NuvelGrid>
    );
  }

  return (
    <ComponentGrid
      key={field.name as string}
      gridProps={field.grid}
      sx={
        field.type === "checkbox"
          ? {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }
          : undefined
      }
    >
      <FieldComponent field={field} />
    </ComponentGrid>
  );
};

export default memo(GenerateFields) as <T extends InternalModel>(
  props: GenerateFieldsProps<T>
) => JSX.Element;
