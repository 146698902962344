import React from "react";
import { styled } from "@mui/material/styles";
import { DrawerHeader } from "./sharedFunctions";
import { Box } from "@mui/material";

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  height: "calc(100vh)",
  overflow: "auto",
}));

const MobileContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Main>
      <DrawerHeader />
      <Box sx={{ height: "calc(100% - 56px)" }}>{children}</Box>
    </Main>
  );
};

export default MobileContent;
