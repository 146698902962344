import { BalancaFormatter } from "./balancaFormatter";

export interface ToledoFormattedLine {
  department: number;
  labelType: number;
  priceType: number;
  code: string;
  price: number;
  validityDays: number;
  description1: string;
  description2: string;
  extraInfo: string;
}
export class ToledoFormatter implements BalancaFormatter {
  private static readonly DEPARTMENT_LENGTH = 2;
  private static readonly LABEL_TYPE_LENGTH = 2;
  private static readonly PRICE_TYPE_LENGTH = 1;
  private static readonly CODE_LENGTH = 6;
  private static readonly PRICE_LENGTH = 6;
  private static readonly VALIDITY_LENGTH = 3;
  private static readonly DESCRIPTION_LENGTH = 50;
  private static readonly EXTRA_INFO_LENGTH = 250;

  private padNumber(num: number, length: number): string {
    return num.toString().padStart(length, "0");
  }

  private padString(str: string, length: number): string {
    return str.substring(0, length).padEnd(length, " ");
  }

  private formatPrice(price: number): string {
    return price
      .toFixed(2)
      .replace(".", "")
      .padStart(ToledoFormatter.PRICE_LENGTH, "0");
  }

  formatLine({
    department = 1,
    labelType = 1,
    priceType = 0,
    code = "",
    price = 0,
    validityDays = 0,
    description1 = "",
    description2 = "",
    extraInfo = "",
  }): string {
    const formattedDepartment = this.padNumber(
      department,
      ToledoFormatter.DEPARTMENT_LENGTH
    );
    const formattedLabelType = this.padNumber(
      labelType,
      ToledoFormatter.LABEL_TYPE_LENGTH
    );
    const formattedPriceType = this.padNumber(
      priceType,
      ToledoFormatter.PRICE_TYPE_LENGTH
    );
    const formattedCode = this.padNumber(
      parseInt(code),
      ToledoFormatter.CODE_LENGTH
    );
    const formattedPrice = this.formatPrice(price);
    const formattedValidity = this.padNumber(
      validityDays,
      ToledoFormatter.VALIDITY_LENGTH
    );
    const formattedDesc1 = this.padString(
      description1,
      ToledoFormatter.DESCRIPTION_LENGTH
    );
    const formattedDesc2 = this.padString(
      description2,
      ToledoFormatter.DESCRIPTION_LENGTH
    );
    const formattedExtra = this.padString(
      extraInfo,
      ToledoFormatter.EXTRA_INFO_LENGTH
    );

    return `${formattedDepartment}${formattedLabelType}${formattedPriceType}${formattedCode}${formattedPrice}${formattedValidity}${formattedDesc1}${formattedDesc2}${formattedExtra}`;
  }

  parseLine(line: string): ToledoFormattedLine {
    let currentPosition = 0;

    const extractPart = (length: number): string => {
      const part = line.substr(currentPosition, length);
      currentPosition += length;
      return part;
    };

    const department = parseInt(extractPart(ToledoFormatter.DEPARTMENT_LENGTH));
    const labelType = parseInt(extractPart(ToledoFormatter.LABEL_TYPE_LENGTH));
    const priceType = parseInt(extractPart(ToledoFormatter.PRICE_TYPE_LENGTH));
    const code = extractPart(ToledoFormatter.CODE_LENGTH);
    const priceStr = extractPart(ToledoFormatter.PRICE_LENGTH);
    const validityDays = parseInt(extractPart(ToledoFormatter.VALIDITY_LENGTH));
    const description1 = extractPart(ToledoFormatter.DESCRIPTION_LENGTH).trim();
    const description2 = extractPart(ToledoFormatter.DESCRIPTION_LENGTH).trim();
    const extraInfo = extractPart(ToledoFormatter.EXTRA_INFO_LENGTH).trim();

    // Converte o preço de volta para decimal (divide por 100 para considerar os centavos)
    const price = parseInt(priceStr) / 100;

    return {
      department,
      labelType,
      priceType,
      code,
      price,
      validityDays,
      description1,
      description2,
      extraInfo,
    };
  }
}
