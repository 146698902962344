import dayjs from "dayjs";

export interface TransacaoFilters {
  loja?: undefined | number | string | number;
  parceiro_de_negocios?: undefined | number | string | number;
  nota_fiscal_entrada?: undefined | number | string | number;
  nota_fiscal_saida?: undefined | number | string | number;
  plano_de_contas?: undefined | number | string | number;
  emissao?: dayjs.Dayjs;
  vencimento?: dayjs.Dayjs;
  data_inicial?: dayjs.Dayjs;
  data_final?: dayjs.Dayjs;
  descricao?: string;
  status?: -1 | 0 | 1 | 2 | 3;
  tipo_conta?: 1 | 2;
  qnt_prestacoes?: number | string;
  valor?: number | string;
  statusList: number[];
}

export const defaultFiltros: TransacaoFilters = {
  parceiro_de_negocios: undefined,
  loja: undefined,
  data_inicial: dayjs().add(-6, "months"),
  data_final: dayjs().add(6, "months"),
  status: -1,
  statusList: [],
};

export const legends = [
  {
    icon: "info",
    text: "A pagar",
    color: "#03A9F4",
    status: 0,
  },
  {
    icon: "warning",
    text: "Vencendo Hoje",
    color: "#FFC107",
    status: 1,
  },
  {
    icon: "info",
    text: "Vencidas",
    color: "#F44336",
    status: 2,
  },
  {
    icon: "check_circle",
    text: "Pagas",
    color: "#8BC34A",
    status: 3,
  },
];
