import React from "react";
import Home from "./views/home";
// import Orders from "./views/orders";
import Cart from "./views/cart";

interface ViewsWrapperProps {
  route: number;
}
const ViewsWrapper: React.FC<ViewsWrapperProps> = ({ route }) => {
  return (
    <>
      {route === 0 && <Home />}
      {/* {route === 1 && <Orders />} */}
      {route === 1 && <Cart />}
    </>
  );
};

export default ViewsWrapper;
