export const rgbaToHSL = (
  rgbaColor: string
): [number, number, number, number] => {
  const [r, g, b, a] = rgbaColor
    .replace(/[^\d,.]/g, "")
    .split(",")
    .map((value) => parseFloat(value));

  const normalizedR = r / 255;
  const normalizedG = g / 255;
  const normalizedB = b / 255;

  const max = Math.max(normalizedR, normalizedG, normalizedB);
  const min = Math.min(normalizedR, normalizedG, normalizedB);
  const l = (max + min) / 2;

  let h = 0;
  let s = 0;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case normalizedR:
        h =
          ((normalizedG - normalizedB) / d +
            (normalizedG < normalizedB ? 6 : 0)) *
          60;
        break;
      case normalizedG:
        h = ((normalizedB - normalizedR) / d + 2) * 60;
        break;
      case normalizedB:
        h = ((normalizedR - normalizedG) / d + 4) * 60;
        break;
    }
  }

  return [h, s * 100, l * 100, a];
};

export const hslToRGBA = (
  h: number,
  s: number,
  l: number,
  a: number
): string => {
  h /= 360;
  s /= 100;
  l /= 100;

  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number): number => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  const toByte = (value: number): number => Math.round(value * 255);

  return `rgba(${toByte(r)}, ${toByte(g)}, ${toByte(b)}, ${a})`;
};

export const generateRandomColor = (principalColor: string): string => {
  const [baseHue, baseSaturation, baseLightness, baseAlpha] =
    rgbaToHSL(principalColor);

  // Increase the range of random shifts for more distinguishable colors
  const hue = Math.floor(Math.random() * 60) - 30; // Random hue shift between -30 and 30
  const saturation = Math.floor(Math.random() * 40) - 20; // Random saturation shift between -20 and 20
  const lightness = Math.floor(Math.random() * 40) - 20; // Random lightness shift between -20 and 20

  const finalHue = (baseHue + hue + 360) % 360;
  const finalSaturation = Math.max(
    0,
    Math.min(100, baseSaturation + saturation)
  );
  const finalLightness = Math.max(0, Math.min(100, baseLightness + lightness));

  return hslToRGBA(finalHue, finalSaturation, finalLightness, baseAlpha);
};
