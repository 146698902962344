/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTheme } from "@mui/material";
import NuvelGrid from "components/nuvel/Grid";
import NuvelModal from "components/nuvel/Modal";
import PlanoDeContaPagamentoSelect from "components/nuvel/plano_de_contas/pagamento";
import PlanoDeContaRecebimentoSelect from "components/nuvel/plano_de_contas/recebimento";
import NuvelTextField from "components/nuvel/TextField";
import React from "react";

export default function IncluirEditarDialog(props: any) {
  const { modal, handleModal, state, setState, handleSave } = props;
  const theme = useTheme();

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "pai" && value === null) return;
    setState((_v: any) => ({
      ..._v,
      [name]: value,
    }));
  };

  React.useEffect(() => {
    if (state.caixa_qtd_turnos !== 0) {
      const _temp: any = [];
      for (let i = 0; i < state.caixa_qtd_turnos; i++) {
        _temp.push({
          numero: i + 1,
          abertura: new Date(),
          fechamento: new Date(),
        });
      }
      if (_temp.length === 0) return;
      return setState((_v: any) => ({ ..._v, caixa_turnos: _temp }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.caixa_qtd_turnos]);

  return (
    <NuvelModal
      open={modal.open}
      btnCancel={handleModal}
      btnSave={handleSave}
      title={
        <h4 style={{ margin: 0 }}>
          Adicionando conta para{" "}
          <b style={{ color: theme.palette.primary.main }}>
            {state?._oldPayload?.name}
          </b>
        </h4>
      }
    >
      <NuvelGrid container spacing={1}>
        <NuvelGrid xs={12}>
          {state.tipo === 0 ? (
            <PlanoDeContaRecebimentoSelect
              name="pai"
              value={state.pai}
              onChange={(e: any) => handleChange(e)}
            />
          ) : (
            <PlanoDeContaPagamentoSelect
              name="pai"
              value={state.pai}
              onChange={(e: any) => handleChange(e)}
            />
          )}
        </NuvelGrid>
        <NuvelGrid xs={12}>
          <NuvelTextField
            label="Descrição"
            onChange={handleChange}
            name="descricao"
            value={state.descricao}
          />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelModal>
  );
}
