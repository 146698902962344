import {
  Brightness4,
  ExitToApp,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardHeaderProps,
  Collapse,
  Divider,
  IconButton,
  IconProps,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AuthControlle } from "data";
import { useAppContext } from "hooks";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface DesktopUserProps {
  open?: boolean;
}

interface ExpandMoreIconProps extends IconProps {
  expand: boolean;
}

const ExpandMoreIcon = styled((props: { expand: boolean }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})<ExpandMoreIconProps>(({ theme }) => ({
  transform: "rotate(180deg)",
  marginLeft: "auto",

  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),

  variants: [
    {
      props: ({ expand }) => !expand,

      style: {
        transform: "rotate(0deg)",
      },
    },
  ],
}));

interface CardHeaderStyledProps extends CardHeaderProps {
  expand?: boolean;
}

const CardHeaderStyled = styled(CardHeader, {
  shouldForwardProp: (prop) => prop !== "expand",
})<CardHeaderStyledProps>(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CardContentStyled = styled(CardContent)({
  padding: 0,
  "&:last-child": {
    paddingBottom: 0,
  },
});

const DesktopUser: React.FC<DesktopUserProps> = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { data: user } = AuthControlle.useCurrentUser();

  const {
    configuration: { changeTheme },
  } = useAppContext();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      elevation={3}
      sx={{ maxWidth: 345, mt: "auto", mb: 2, borderRadius: 3 }}
    >
      <CardHeaderStyled
        onClick={handleExpandClick}
        avatar={
          <Avatar
            sx={{
              bgcolor: "primary.main",
              width: 32,
              height: 32,
              borderRadius: 2,
              fontSize: 16,
            }}
          >
            {user
              ? user?.nome
                ? `${user?.nome?.charAt(0)}${user?.nome?.charAt(1)}`
                : `${user?.username?.split("_")[1]?.charAt(0)}${user?.username
                    ?.split("_")[1]
                    ?.charAt(1)}`
              : "AB"}
          </Avatar>
        }
        action={
          <ExpandMoreIcon
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </ExpandMoreIcon>
        }
        title={
          <Typography variant="h6" sx={{ fontSize: 14 }}>
            {user?.nome || user?.username?.split("_")[1] || "Usuário"}
          </Typography>
        }
        subheader={
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {user?.email || "Sem email cadastrado"}
          </Typography>
        }
      />
      <Divider />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContentStyled>
          <List disablePadding>
            <ListItemButton onClick={changeTheme} sx={{ p: 1 }}>
              <ListItemIcon sx={{ minWidth: 32, ml: 0.7, mr: 0.3 }}>
                <Brightness4 fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary="Trocar Tema"
                primaryTypographyProps={{ fontSize: 14 }}
              />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/auth/")} sx={{ p: 1 }}>
              <ListItemIcon sx={{ minWidth: 32, ml: 0.7, mr: 0.3 }}>
                <ExitToApp fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary="Sair"
                primaryTypographyProps={{ fontSize: 14 }}
              />
            </ListItemButton>
          </List>
        </CardContentStyled>
      </Collapse>
    </Card>
  );
};

export default DesktopUser;
