import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import React from "react";
import SwipeableViews from "react-swipeable-views";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ ...props }) {
  const {
    title,
    children,
    open,
    handleAdd,
    handleClose,
    disabledAdd,
    disabledClose,
    listType,
    buttonType,
  } = props;
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent
          dividers
          style={
            listType
              ? {
                  padding: 0,
                  borderBottom: 0,
                }
              : {}
          }
        >
          {children}
        </DialogContent>
        <SwipeableViews index={buttonType >= 0 ? buttonType : 1}>
          <div>
            <DialogActions>
              <Button
                onClick={handleClose}
                disabled={disabledClose}
                color="primary"
                variant="contained"
              >
                Fechar
              </Button>
            </DialogActions>
          </div>
          <div>
            <DialogActions>
              <Button
                onClick={handleClose}
                disabled={disabledClose}
                color="primary"
              >
                Cancelar
              </Button>
              <Button
                onClick={handleAdd}
                disabled={disabledAdd}
                color="primary"
                variant="contained"
              >
                Confirmar
              </Button>
            </DialogActions>
          </div>
          <div></div>
        </SwipeableViews>
      </Dialog>
    </div>
  );
}
