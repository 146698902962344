import { OrcamentoSerializer } from "data/interfaces/financeiro/OrcamentoSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "orcamento";

class OrcamentoModel extends MainModule<
  OrcamentoSerializer,
  OrcamentoSerializer
> {}

export default new OrcamentoModel(model_url);
