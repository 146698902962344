import { Pagination } from "@mui/material";
import { useCallback } from "react";

interface NuvelPaginationComponentProps {
  count: number;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
}

export function NuvelPaginationComponent({
  count,
  page,
  setPage,
  pageSize,
}: NuvelPaginationComponentProps) {
  const handleChangePage = useCallback(
    (_event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [setPage]
  );
  return (
    <Pagination
      count={Number(
        count < pageSize
          ? 0
          : ~~Number(count / pageSize) + Number(count % pageSize !== 0 ? 1 : 0)
      )}
      showFirstButton
      showLastButton
      page={Number(page)}
      onChange={handleChangePage}
    />
  );
}
