import { TableColumn, TableFilter } from "components/nuvel/lists";
import { LojaSerializerList } from "data/interfaces/core/LojaSerializerList";

export const RETAGUARDA_LOJA_FORM = [
  {
    grid: { xs: 12, md: 8 },
    child: [
      {
        label: "CNPJ",
        name: "cnpj",
        type: "mask",
        mask: "99.999.999/9999-99",
        normalize: (value: string) =>
          value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", ""),
        grid: { xs: 12, md: 4 },
      },
      {
        label: "IE",
        name: "ie",
        type: "text",
        grid: { xs: 12, md: 4 },
      },
      {
        label: "IM",
        name: "im",
        type: "text",
        grid: { xs: 12, md: 4 },
      },
      {
        label: "Razão Social",
        name: "razao_social",
        type: "text",
        grid: { xs: 12, md: 12 },
      },
      {
        label: "Nome Fantasia",
        name: "nome_fantasia",
        type: "text",
        grid: { xs: 12, md: 12 },
      },
      {
        label: "CRT",
        name: "crt",
        type: "select",
        options: [
          { label: "Simples Nacional", value: 1 },
          { label: "Lucro Presumido", value: 2 },
          { label: "Regime Nacional", value: 3 },
        ],
        grid: { xs: 12, md: 9 },
      },
      {
        label: "CNAE",
        name: "cnae",
        type: "text",
        grid: { xs: 12, md: 3 },
      },
      {
        label: "Endereço",
        name: "endereco",
        type: "expansible",
        grid: { xs: 12, md: 12 },
      },
      {
        label: "Contatos",
        name: "contatos",
        type: "expansible",
        grid: { xs: 12, md: 12 },
      },
    ],
  },
  {
    grid: { xs: 12, md: 4 },
    child: [
      {
        label: "Referência Nota",
        name: "referencia",
        type: "text",
        grid: { xs: 12, md: 12 },
      },
      {
        label: "Número Identificador",
        name: "num_id",
        type: "number",
        grid: { xs: 12, md: 12 },
      },
      {
        label: "Número Série NF-e",
        name: "nfe_serie",
        type: "number",
        grid: { xs: 12, md: 12 },
      },
      {
        label: "Sequência NF-e",
        name: "nfe_sequencia",
        type: "number",
        grid: { xs: 12, md: 12 },
      },
      {
        label: "Alíquota PIS",
        name: "aliq_pis",
        type: "number",
        percent: true,
        grid: { xs: 12, md: 12 },
      },
      {
        label: "Alíquota COFINS",
        name: "aliq_cofins",
        type: "number",
        percent: true,
        grid: { xs: 12, md: 12 },
      },
      {
        label: "Tags",
        name: "tags",
        type: "autocomplete",
        grid: { xs: 12, md: 12 },
      },
    ],
  },
  {
    label: "Homologação NF-e",
    name: "homologacao_nfe",
    type: "checkbox",
    grid: { xs: 12, md: 12 },
  },
];

export const RETAGUARDA_LOJA_LIST: TableColumn<LojaSerializerList>[] = [
  {
    label: "CNPJ",
    accessor: "cnpj",
    minWidth: 250,
  },
  {
    label: "Razão Social",
    accessor: "razao_social",
    minWidth: 250,
  },
  {
    label: "Nome Fantasia",
    accessor: "nome_fantasia",
    minWidth: 250,
  },
];

export const RETAGUARDA_LOJA_ORDERING: (keyof LojaSerializerList)[] = [
  "razao_social",
  "nome_fantasia",
];

export const RETAGUARDA_LOJA_FILTERS: TableFilter<LojaSerializerList>[] = [
  {
    label: "Nome Fantasia",
    accessor: "nome_fantasia",
    support: ["icontains", "istartswith"],
  },
  {
    label: "Razão Social",
    accessor: "razao_social",
    support: ["icontains", "istartswith"],
  },
  {
    label: "CNPJ",
    accessor: "cnpj",
    support: ["exact"],
  },
];
