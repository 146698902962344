/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import api from "data/api";
import useSWR from "swr";

class DashVendas {
  useNFES(props: any) {
    return useSWR<any, any>(`getNFES_${JSON.stringify(props)}`, async () => {
      const {
        currentPage,
        pageSize,
        sorting = [],
        filters = [],
        searchValue,
      } = props;
      const a_sorting: any[] = [];

      if (sorting.length > 0) {
        sorting.forEach((el: any) => {
          if (el.direction === "asc") {
            a_sorting.push(el.columnName);
          } else {
            a_sorting.push("-" + el.columnName);
          }
        });
      }

      const params: any = {
        currentPage: currentPage,
        pageSize: pageSize,
        sorting: a_sorting.join(","),
        search: searchValue,
      };

      filters.forEach((element: any) => {
        params[element.property] = element.value;
      });
      const res = await api().get(`/movimento/dashboard/cupom/`, {
        params: params,
      });
      return res.data;
    });
  }

  useTotalPeriodo(props: any) {
    return useSWR<any, any>(
      `getTotalPeriodo_${JSON.stringify(props)}`,
      async () => {
        const {
          loja,
          start_date,
          end_date,
          previus_start_date,
          previus_end_date,
          status,
        } = props;
        let emitente = null;
        if (loja.length > 0) {
          emitente = loja;
        }

        const params = {
          emitente: emitente,
          start_date: start_date,
          end_date: end_date,
          previus_start_date: previus_start_date,
          previus_end_date: previus_end_date,
          status: status.includes("-1") || status.includes(-1) ? [] : status,
        };

        const res = await api().get(
          `/movimento/dashboard/cupom/total_periodo/`,
          {
            params: { ...params },
          }
        );

        return res.data;
      }
    );
  }

  useHistogramHorarioComparativoVTotal(props: any) {
    return useSWR<any, any>(
      `getHistogramHorarioComparativo_${JSON.stringify(props)}`,
      async () => {
        const { loja, start_date, end_date, status } = props;
        let emitente = null;

        if (loja.length > 0) {
          emitente = loja;
        }

        const params = {
          emitente: emitente,
          start_date: start_date,
          end_date: end_date,
          status: status.includes("-1") || status.includes(-1) ? [] : status,
        };

        const res = await api().get(
          `/movimento/dashboard/cupom/histogram_horario_comparativo/`,
          {
            params: params,
          }
        );
        return res.data;
      }
    );
  }

  useHistogramVTotal(props: any) {
    return useSWR<any, any>(
      `getHistogramVTotal_${JSON.stringify(props)}`,
      async () => {
        const { loja, start_date, end_date, status } = props;
        let emitente = null;

        if (loja.length > 0) {
          emitente = loja;
        }

        const params = {
          emitente: emitente,
          start_date: start_date,
          end_date: end_date,
          status: status.includes("-1") || status.includes(-1) ? [] : status,
        };

        const res = await api().get(
          `/movimento/dashboard/cupom/histogram_v_total/`,
          {
            params: params,
          }
        );
        return res.data.rows;
      }
    );
  }

  useHistogramaPresentePassadoComparativo(props: any) {
    return useSWR<any, any>(
      `getHistogramPresentePassadoComparativo_${JSON.stringify(props)}`,
      async () => {
        const { loja, start_date, end_date, status } = props;
        let emitente = null;

        if (loja.length > 0) {
          emitente = loja;
        }

        const params = {
          emitente: emitente,
          start_date: start_date,
          end_date: end_date,
          status: status.includes("-1") || status.includes(-1) ? [] : status,
        };

        const res = await api().get(
          `/movimento/dashboard/cupom/histograma_presente_passado_comparativo/`,
          {
            params: params,
          }
        );
        return res.data;
      }
    );
  }

  getDownloadSimplificado = (props: any) => {
    let emitente = null;
    if (props.loja && props.loja !== "" && props.loja !== "todas") {
      emitente = parseInt(props.loja);
    }
    return api().request({
      url: `/movimento/dashboard/cupom/download_simplificado/`,
      params: {
        emitente: emitente,
        start_date: props.start_date,
        end_date: props.end_date,
        previus_start_date: props.previus_start_date,
        previus_end_date: props.previus_end_date,
      },
      responseType: "blob",
      method: "GET",
    });
  };

  getDownloadDetalhado = (props: any) => {
    let emitente = null;
    if (props.loja && props.loja !== "" && props.loja !== "todas") {
      emitente = parseInt(props.loja);
    }
    return api().request({
      url: `/movimento/dashboard/cupom/download_detalhado/`,
      params: {
        emitente: emitente,
        start_date: props.start_date,
        end_date: props.end_date,
        previus_start_date: props.previus_start_date,
        previus_end_date: props.previus_end_date,
      },
      responseType: "blob",
      method: "GET",
    });
  };

  useProdutosMaisVendidos(props: any) {
    return useSWR<any, any>(
      `produtosMaisVendidos_${JSON.stringify(props)}`,
      async () => {
        const { loja, start_date, end_date, limit, qt, status } = props;
        let emitente = null;

        if (loja.length > 0) {
          emitente = loja;
        }

        const params = {
          emitente: emitente,
          start_date: start_date,
          end_date: end_date,
          limit: limit,
          status: status.includes("-1") || status.includes(-1) ? [] : status,
          qt,
        };

        const res = await api().get(
          `/movimento/dashboard/cupom_items/items_mais_vendidos/`,
          {
            params: params,
          }
        );
        return res.data.rows;
      }
    );
  }

  useTotalFormaPagamento(props: any) {
    return useSWR<any, any>(
      `totalFormaPagamento_${JSON.stringify(props)}`,
      async () => {
        const { loja, start_date, end_date, sort, status } = props;
        let emitente = null;

        if (loja.length > 0) {
          emitente = loja;
        }

        const params = {
          emitente: emitente,
          start_date: start_date,
          end_date: end_date,
          sort: sort,
          status: status.includes("-1") || status.includes(-1) ? [] : status,
        };

        const res = await api().get(
          `/movimento/dashboard/cupom_pagamento/total_periodo/`,
          {
            params: params,
          }
        );
        return res.data.rows;
      }
    );
  }

  useTotalEstados(props: any) {
    return useSWR<any, any>(
      `totalEstados_${JSON.stringify(props)}`,
      async () => {
        const { loja, start_date, end_date, sort, status } = props;
        let emitente = null;

        if (loja.length > 0) {
          emitente = loja;
        }

        const params = {
          emitente: emitente,
          start_date: start_date,
          end_date: end_date,
          sort: sort,
          status: status.includes("-1") || status.includes(-1) ? [] : status,
        };

        const res = await api().get(
          `/movimento/dashboard/cupom/total_estado/`,
          {
            params: params,
          }
        );
        return res.data.rows;
      }
    );
  }
}

export default new DashVendas();
