import React from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { useCatalog } from "layouts/catalogo/context";
import DefaultSpacing from "../DefaultSpacing";
import DepartamentsTabBar from "./TabBar";

export default function HideAppBar() {
  const { state } = useCatalog();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar sx={{ backgroundColor: "#3D3D3D", color: "#fff" }}>
        <Toolbar>
          <Box width="100%">
            <DefaultSpacing>
              <Typography variant="h6" component="div">
                {state.store?.name}
              </Typography>
            </DefaultSpacing>
          </Box>
        </Toolbar>
        <Box width="100%" sx={{ bgcolor: "#fff" }}>
          <DefaultSpacing>
            <DepartamentsTabBar />
          </DefaultSpacing>
        </Box>
      </AppBar>
      <DepartamentsTabBar />
      <Toolbar />
    </React.Fragment>
  );
}
