import { HomeOutlined } from "@mui/icons-material";
import { lazy } from "react";
import { redirect } from "react-router-dom";
import { RouteInterface } from "utils/router";

const Home = lazy(() => import("layouts/admin/views/home"));

const AdminRoutes: RouteInterface[] = [
  {
    path: "/admin/*",
    loader: () => redirect("/admin/home"),
  },
  {
    title: "Home",
    path: "/admin/home",
    element: <Home />,
    icon: <HomeOutlined />,
    children: [
      {
        path: ":id",
        element: <Home />,
      },
    ],
    perm: "ADMIN_HOME",
  },
  {
    title: "Home 0",
    path: "/admin/home0",
    icon: <HomeOutlined />,
    children: [
      {
        title: "Home 1",
        path: "/admin/home0/home1",
        element: <Home />,
        icon: <HomeOutlined />,
      },
      {
        title: "Home 2",
        path: "/admin/home0/home2",
        element: <Home />,
      },
    ],
    state: "homeCollapse",
  },
  {
    title: "Home 3",
    path: "/admin/home3",
    icon: <HomeOutlined />,
    children: [
      {
        title: "Home 5",
        path: "/admin/home3/home5",
        element: <Home />,
        icon: <HomeOutlined />,
      },
      {
        title: "Home 4",
        path: "/admin/home3/home4",
        element: <Home />,
      },
    ],
    state: "home3Collapse",
  },
];

export default AdminRoutes;
