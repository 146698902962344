import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_TIPOINSUMO_FILTERS,
  RETAGUARDA_TIPOINSUMO_LIST,
  RETAGUARDA_TIPOINSUMO_ORDERING,
} from "constants/retaguarda/estoque/tipo_insumo";
import { TipoInsumoModel } from "data/models";
import React from "react";

const RetTipoInsumoList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={TipoInsumoModel}
      columns={RETAGUARDA_TIPOINSUMO_LIST}
      filters={RETAGUARDA_TIPOINSUMO_FILTERS}
      ordering={RETAGUARDA_TIPOINSUMO_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetTipoInsumoList;
