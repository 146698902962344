import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelGrid from "components/nuvel/Grid";
import NuvelSelect from "components/nuvel/Select";
import NuvelTextField from "components/nuvel/TextField";
import DefaultRelatorios from "components/retaguarda/relatorios";
import { TransacaoPrestacaoSerializer } from "data/interfaces/financeiro/TransacaoPrestacaoSerializer";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { handleChange } from "utils/functions";

interface TransacaoState {
  relatorio: number;
  data_inicio: dayjs.Dayjs;
  data_fim: dayjs.Dayjs;
  lojas: number[];
  tags: number[];
  parceiro_de_negocios: number[];
  nota_fiscal: string;
  descricao: string;
  status: number[];
  tipo_transacao: number;
  operacao: number;
}

const getTransacaoStatus = (status: number): string => {
  switch (status) {
    case 0:
      return "Não Pago";
    case 1:
      return "Faturado";
    case 2:
      return "Parcialmente Faturado";
    case 3:
      return "Atrasado";
    default:
      return "";
  }
};

const relatorios = (state: TransacaoState) => [
  {
    url: "transacoes/pagar",
    title: "Contas a pagar",
    columns: [
      { label: "Número", accessor: "numero", flex: 1 },
      { label: "Descrição", accessor: "descricao", flex: 1 },
      {
        label: "Status",
        accessor: (row: TransacaoPrestacaoSerializer) =>
          getTransacaoStatus(row.status ?? 0),
        flex: 1,
      },
      { label: "Valor", accessor: "valor", flex: 2, type: "money" },
      {
        label: "Vencimento",
        accessor: (row: TransacaoPrestacaoSerializer) =>
          dayjs(row.vencimento).format("DD/MM/YYYY HH:mm"),
        flex: 1,
      },
      { label: "Parceiro", accessor: "parceiro", flex: 1 },
    ],
    filtros: {
      data_inicio: dayjs(state.data_inicio).format(),
      data_fim: dayjs(state.data_fim).endOf("day").format(),
      lojas: state.lojas.join(","),
      tags: state.tags.join(","),
      parceiro_de_negocios: state.parceiro_de_negocios.join(","),
      nota_fiscal: state.nota_fiscal,
      descricao: state.descricao,
      status: state.status.join(","),
      operacao: 0, // OperacaoType.PAGAR
    },
  },
  {
    url: "transacoes/receber",
    title: "Contas a receber",
    columns: [
      { label: "Número", accessor: "numero", flex: 1 },
      { label: "Descrição", accessor: "descricao", flex: 1 },
      { label: "Valor", accessor: "valor", flex: 2, type: "money" },
      {
        label: "Status",
        accessor: (row: TransacaoPrestacaoSerializer) =>
          getTransacaoStatus(row.status ?? 0),
        flex: 1,
      },
      {
        label: "Vencimento",
        accessor: (row: TransacaoPrestacaoSerializer) =>
          dayjs(row.vencimento).format("DD/MM/YYYY HH:mm"),
        flex: 1,
      },
      { label: "Parceiro", accessor: "parceiro", flex: 1 },
    ],
    filtros: {
      data_inicio: dayjs(state.data_inicio).format(),
      data_fim: dayjs(state.data_fim).endOf("day").format(),
      lojas: state.lojas.join(","),
      tags: state.tags.join(","),
      parceiro_de_negocios: state.parceiro_de_negocios.join(","),
      nota_fiscal: state.nota_fiscal,
      descricao: state.descricao,
      status: state.status.join(","),
      operacao: 1, // OperacaoType.RECEBER
    },
  },
];

const RetReportTransacoesForm: React.FC = () => {
  const [state, setState] = React.useState<TransacaoState>({
    relatorio: 0,
    data_inicio: dayjs().startOf("day"),
    data_fim: dayjs().startOf("day"),
    lojas: [],
    tags: [],
    parceiro_de_negocios: [],
    nota_fiscal: "",
    descricao: "",
    status: [],
    tipo_transacao: 0,
    operacao: 0,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      status: [],
      operacao: prevState.relatorio,
    }));
  }, [state.relatorio]);

  return (
    <DefaultRelatorios
      title="Transações"
      data_inicio={state.data_inicio}
      data_fim={state.data_fim}
      relatorio={state.relatorio}
      relatorios={relatorios(state)}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={4}>
          <NuvelSelect
            label="Relatório"
            name="relatorio"
            onChange={(e) => handleChange(e, setState)}
            value={state.relatorio}
            options={relatorios(state).map((rel, index) => ({
              value: index,
              label: rel.title,
            }))}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelDatePicker
            label="Data Início"
            onChange={(e) => handleChange(e, setState)}
            name="data_inicio"
            value={state.data_inicio}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelDatePicker
            label="Data Fim"
            onChange={(e) => handleChange(e, setState)}
            name="data_fim"
            value={state.data_fim}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelAutocomplete
            name="loja"
            reference="loja"
            label="Lojas"
            value={state.lojas}
            onChange={(_, v) => setState({ ...state, lojas: v as number[] })}
            multiple
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelAutocomplete
            name="parceiro_de_negocios"
            reference={state.relatorio === 0 ? "fornecedor" : "cliente"}
            label={state.relatorio === 0 ? "Fornecedor" : "Cliente"}
            value={state.parceiro_de_negocios}
            onChange={(_, v) =>
              setState({ ...state, parceiro_de_negocios: v as number[] })
            }
            multiple
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelSelect
            label="Status"
            multiple={true}
            name="status"
            onChange={(e) => handleChange(e, setState)}
            value={state.status}
            options={[
              { value: 0, label: "Não Pago" },
              { value: 1, label: "Faturado" },
              { value: 2, label: "Parcialmente Faturado" },
              { value: 3, label: "Atrasado" },
            ]}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelTextField
            label="Nota Fiscal"
            name="nota_fiscal"
            value={state.nota_fiscal}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelTextField
            label="Descrição"
            name="descricao"
            value={state.descricao}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelAutocomplete
            name="tags"
            reference="tags"
            label="Tags"
            value={state.tags}
            onChange={(_, v) => setState({ ...state, tags: v as number[] })}
            multiple
          />
        </NuvelGrid>
      </NuvelGrid>
    </DefaultRelatorios>
  );
};

export default RetReportTransacoesForm;
