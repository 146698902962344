import { mutate } from "swr";

const showMessage = (
  mensagem: string,
  titulo = "Mensagem do Sistema",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func?: any
) => {
  mutate("dialog", {
    open: true,
    message: mensagem,
    title: titulo,
    type: 0,
    func: func,
  });
};

const showConfirm = (
  mensagem: string,
  titulo = "Mensagem do Sistema",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: any
) => {
  mutate("dialog", {
    open: true,
    message: mensagem,
    title: titulo,
    type: 1,
    func: func,
  });
};

const showConfirmSync = async (
  mensagem: string,
  titulo = "Mensagem do Sistema"
): Promise<boolean> => {
  return await new Promise((resolve) => {
    showConfirm(mensagem, titulo, (resposta: boolean) => {
      resolve(resposta);
    });
  });
};

const showInputDialog = (
  mensagem: string,
  titulo = "Insira o Valor",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _onCancel?: any
) => {
  mutate("dialog", {
    open: true,
    message: mensagem,
    title: titulo,
    type: 2,
    func: func,
    inputProps: input,
    onCancel: _onCancel,
  });
};

const showInputDialogSync = async (
  mensagem: string,
  titulo = "Insira o Valor",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input?: any
): Promise<string> => {
  return await new Promise((resolve) => {
    showInputDialog(
      mensagem,
      titulo,
      (resposta: string) => {
        resolve(resposta);
      },
      input
    );
  });
};
const closeMessage = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutate("dialog", (dg: any) => ({
    ...dg,
    open: false,
  }));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const showLayoutDialog = async (func: any) => {
  mutate("dialog", {
    open: true,
    message: "Selecione o layout desejado",
    title: "Usuário Master Identificado",
    type: 3,
    func: func,
  });
};

export {
  showMessage,
  showConfirm,
  showConfirmSync,
  showInputDialog,
  showInputDialogSync,
  showLayoutDialog,
  closeMessage,
};
