import {
  AutoFormHooks,
  AutoFormProps,
} from "components/nuvel/forms/automatic/NuvelAutoForm";
import { TableColumn, TableFilter } from "components/nuvel/lists";
import { ContaBancariaSerializer } from "data/interfaces/financeiro/ContaBancariaSerializer";

interface bankAPI {
  ispb: string;
  name: string;
  code: number;
  fullName: string;
}

export const RETAGUARDA_CONTABANCARIA_FORM: AutoFormProps<ContaBancariaSerializer>[] =
  [
    {
      type: "grid",
      grid: { xs: 12 },
      children: [
        {
          grid: { xs: 12, md: 6 },
          label: "Descrição",
          name: "descricao",
          type: "text",
        },
        {
          grid: { xs: 12, md: 6 },
          label: "Titular",
          name: "conta_nome",
          type: "text",
        },
        {
          grid: { xs: 12, md: 3 },
          label: "Banco",
          name: "banco",
          list_name: "banco_list",
          type: "muiAutocomplete",
        },
        {
          grid: { xs: 12, md: 3 },
          label: "Agência",
          name: "agencia",
          type: "text",
        },
        {
          grid: { xs: 12, md: 3 },
          label: "Agência Dígito",
          name: "agencia_digito",
          type: "text",
        },
        {
          grid: { xs: 12, md: 3 },
          label: "Nome da Agência",
          name: "agencia_nome",
          type: "text",
        },
        {
          grid: { xs: 12, md: 3 },
          label: "Conta",
          name: "conta",
          type: "text",
        },
        {
          grid: { xs: 12, md: 3 },
          label: "Conta Dígito",
          name: "conta_digito",
          type: "text",
        },
        {
          grid: { xs: 12, md: 3 },
          label: "Saldo Inicial",
          name: "saldo_inicial",
          type: "money",
          disabled: (state) => !!state.id,
        },
        {
          grid: { xs: 12, md: 3 },
          label: "Saldo Atual",
          name: "saldo_atual",
          type: "money",
          disabled: true,
        },
        {
          grid: { xs: 12, md: 3 },
          label: "Conta Cofre",
          name: "cofre",
          type: "checkbox",
        },
      ],
    },
  ];

export const RETAGUARDA_CONTABANCARIA_LIST: TableColumn<ContaBancariaSerializer>[] =
  [
    {
      label: "Descrição",
      accessor: "descricao",
      minWidth: 300,
    },
    {
      label: "Saldo Atual",
      accessor: (row) => row.saldo_atual.toBRL(),
      minWidth: 300,
    },
  ];

export const RETAGUARDA_CONTABANCARIA_ORDERING: (keyof ContaBancariaSerializer)[] =
  ["descricao"];

export const RETAGUARDA_CONTABANCARIA_FILTERS: TableFilter<ContaBancariaSerializer>[] =
  [
    {
      label: "Descrição",
      accessor: "descricao",
      support: ["icontains"],
    },
  ];

export const RETAGUARDA_CONTABANCARIA_HOOKS: AutoFormHooks[] = [
  {
    function: (_state, dispatch) => {
      fetch("https://brasilapi.com.br/api/banks/v1", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          dispatch({
            type: "UPDATE_FIELD",
            value: response
              .filter((bank: bankAPI) => bank.code)
              .map((bank: bankAPI) => ({
                label: `${bank.code} - ${bank.fullName}`,
                value: bank.code,
              })),
            name: "banco_list",
          });
        });
      return;
    },
    watch: [],
  },
];
