import { TableColumn, TableFilter } from "components/nuvel/lists";
import { UserSerializer } from "data/interfaces/core/UserSerializer";

export const RETAGUARDA_USUARIOS_LIST: TableColumn<UserSerializer>[] = [
  {
    label: "Usuário",
    accessor: "usuario",
    minWidth: 50,
  },
  {
    label: "Email",
    accessor: "email",
    minWidth: 250,
  },
];

export const RETAGUARDA_USUARIOS_ORDERING: (keyof UserSerializer)[] = [
  "usuario",
  "email",
];

export const RETAGUARDA_USUARIOS_FILTERS: TableFilter<UserSerializer>[] = [
  {
    label: "Usuário",
    accessor: "usuario",
    support: ["icontains"],
  },
  {
    label: "Email",
    accessor: "email",
    support: ["icontains"],
  },
];
