import { InternalModel, MainInterface } from "data/main";
import { useAppContext } from "hooks";
import useCurrentRoute from "hooks/useCurrentRoute";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BaseForm from "./shared/form";

interface NuvelDefaultFormProps<T extends InternalModel, L> {
  children: React.ReactNode;
  model: MainInterface<T, L>;
  state: T;
  setState: React.Dispatch<React.SetStateAction<T>>;
  onLoad?: (data: T) => void;
}

const NuvelDefaultForm = <T extends InternalModel, L extends InternalModel>({
  children,
  model,
  setState,
  state,
  onLoad,
}: NuvelDefaultFormProps<T, L>) => {
  const {
    dialog: { showMessage },
    showSnack,
  } = useAppContext();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const { id } = useParams<{ id: string }>();
  const route = useCurrentRoute(1);

  useEffect(() => {
    if (id && id !== "novo") {
      setLoading(true);
      model
        .get(Number(id))
        .then((data: T) => {
          setState(data);
          if (onLoad) {
            onLoad(data);
          }
          setLoading(false);
        })
        .catch(() => {
          showMessage(
            "Verifique sua conexão de internet, caso persista, favor consultar um administrador.",
            "Houve um erro ao processar solicitação."
          );
          if (window.history.length > 1) {
            navigate(-1);
          } else {
            const newUrl = window.location.pathname
              .split("/")
              .slice(0, -1)
              .join("/");
            navigate(newUrl);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    model
      .save(id !== "novo" ? { ...state, id: Number(id) } : state)
      .then((response) => {
        if (response) {
          showSnack("Salvo com sucesso!", 2, "success");
          if (window.history.length > 1) {
            navigate(-1);
          } else {
            const newUrl = window.location.pathname
              .split("/")
              .slice(0, -1)
              .join("/");
            navigate(newUrl);
          }
        } else {
          showSnack("Erro ao salvar!", 2, "error");
          setLoading(false);
        }
      });
  };

  return (
    <BaseForm
      handleSubmit={handleSubmit}
      loading={loading}
      route={route}
      navigate={navigate}
    >
      {children}
    </BaseForm>
  );
};

export default NuvelDefaultForm;
