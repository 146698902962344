import { AgendamentoSerializer } from "data/interfaces/core/AgendamentoSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "agendamentos";

class AgendamentoModel extends MainModule<
  AgendamentoSerializer,
  AgendamentoSerializer
> {}

export default new AgendamentoModel(model_url);
