import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_PARCEIRO_FILTERS,
  RETAGUARDA_PARCEIRO_LIST,
  RETAGUARDA_PARCEIRO_ORDERING,
} from "constants/retaguarda/cadastro/parceiro_de_negocios";
import { ParceiroModel } from "data/models";
import React from "react";

const RetParceiroList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={ParceiroModel}
      columns={RETAGUARDA_PARCEIRO_LIST}
      filters={RETAGUARDA_PARCEIRO_FILTERS}
      ordering={RETAGUARDA_PARCEIRO_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetParceiroList;
