/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import StyledTreeItem from "./TreeItem";

import ModalForm from "./dialog";
import { PlanoDeContasModel } from "data/models";
import { Box, Paper } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";

function Form_PlanoDeConta() {
  const defaultValues = {
    descricao: "",
    pai: "",
    tipo: "",
    filhos: [],
  };
  const [openned, setOpenned] = React.useState<any>([]);
  const [state, setState] = React.useState<any>({ ...defaultValues });
  const [modal, setModal] = React.useState<any>({
    open: false,
  });

  const [list, setList] = React.useState<any>(null);

  const { data: plano, mutate: refreshModel } = PlanoDeContasModel.useModel();

  const changeModalState = (payload: any, defaultPayload?: any) => {
    setState((oval: any) =>
      payload
        ? payload
        : {
            ...oval,
            ...defaultPayload,
            descricao: "",
          }
    );
  };

  const openModalAdd = (e: any, payload: any) => {
    e.stopPropagation();
    changeModalState(null, {
      pai: payload._origin.id,
      tipo: payload._origin.tipo,
      _oldPayload: payload,
    });
    setModal((_v: any) => ({ ..._v, open: true }));
  };

  const openModalEdit = (e: any) => {
    e.stopPropagation();
    setModal((_v: any) => ({ ..._v, open: true }));
  };

  const savePlano = () => {
    // setLoading(() => true);
    PlanoDeContasModel.save(state).then(() => {
      refreshModel();
      // setLoading(() => false);
    });

    changeModalState(defaultValues);
    setModal((_v: any) => ({ ..._v, open: false }));
  };

  const getAllIds = (array: any) => {
    const main = Array.isArray(array) ? array : [array];
    const obj: any = [];
    main.forEach((item) => {
      obj.push(String(item.id));
    });
    return obj;
  };

  React.useEffect(() => {
    if (plano) {
      const teste = getAllIds(plano);
      setOpenned(() => teste);
      setList(() => renderTree([...(plano as any)]));
      // setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plano]);

  const renderTree = (mainNode: any, index?: any, OldNumber?: any) => {
    const generateProps = (node: any, forceIndex: any) => {
      const innerIndex = !isNaN(forceIndex)
        ? Number(forceIndex ? forceIndex : 0 + index ? index : 0)
        : index;
      let IndexadorPDC = OldNumber ? `${OldNumber}.` : "";

      IndexadorPDC += `${
        node.id === 1 || node.id === 2 ? innerIndex + 1 : "0" + (innerIndex + 1)
      }`;

      const treeItemProps = {
        index: innerIndex,
        key: node.id,
        _origin: node,
        prefix: IndexadorPDC,
        handle_edit: openModalEdit,
        handle_add: openModalAdd,
      };

      return treeItemProps;
    };

    const mapNode = Array.isArray(mainNode) ? mainNode : [mainNode];
    return mapNode.map((nodes, index) => {
      const innerProps = generateProps(nodes, index);
      return (
        <StyledTreeItem {...innerProps}>
          {Array.isArray(nodes.filhos)
            ? nodes.filhos.map((node: any, index: any) =>
                renderTree(node, index, innerProps.prefix)
              )
            : null}
        </StyledTreeItem>
      );
    });
  };

  return (
    <Paper>
      <Box pt={2} pb={2} pr={1} pl={1}>
        {openned.length !== 0 ? (
          <SimpleTreeView style={{ flexGrow: 1 }}>{list}</SimpleTreeView>
        ) : null}

        <ModalForm
          modal={modal}
          state={state}
          setState={setState}
          handleSave={savePlano}
          handleModal={() => setModal((_v: any) => ({ ..._v, open: false }))}
        />
      </Box>
    </Paper>
  );
}

export default Form_PlanoDeConta;
