import { Box, Paper, Typography } from "@mui/material";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelGrid from "components/nuvel/Grid";
import NuvelModal from "components/nuvel/Modal";
import NuvelTextField from "components/nuvel/TextField";
import { ContaBancariaModel } from "data/models";
import { useAppContext } from "hooks";
import React, { useState } from "react";

interface ModalProps {
  modal: {
    id: number;
    open: boolean;
  };
  id: number;
  handleModal: (id: number) => void;
  refreshListFn: () => void;
}

const defaultState = {
  conta_bancaria_saida: undefined,
  conta_bancaria_entrada: undefined,
  valor: 0,
  saldo_disponivel_saida: 0,
  saldo_disponivel_entrada: 0,
  saldo_novo: 0,
};

interface InformationBoxProps {
  color: "success" | "error";
  value: string;
  label: string;
}

const InformationBox = (props: InformationBoxProps) => (
  <Paper
    elevation={0}
    sx={{
      padding: 2,
      backgroundColor: (theme) => theme.palette[props.color].dark,
    }}
  >
    <Box>
      <Box>
        <Typography color="white">{props.label}</Typography>
      </Box>
      <Box>
        <Typography color="white">{props.value}</Typography>
      </Box>
    </Box>
  </Paper>
);

const ContaBancariaTransferenciaModal: React.FC<ModalProps> = ({
  modal,
  id,
  handleModal,
  refreshListFn,
}) => {
  const { showSnack } = useAppContext();
  const [state, setState] = useState<{
    conta_bancaria_saida: undefined | number | string;
    conta_bancaria_entrada: undefined | number | string;
    valor: number;
    saldo_disponivel_saida: number;
    saldo_disponivel_entrada: number;
    saldo_novo: number;
  }>(defaultState);

  const resetState = () => {
    setState(defaultState);
  };

  const handleSave = () => {
    ContaBancariaModel.action("post", "transferencia_bancaria", {
      conta_bancaria_saida: state.conta_bancaria_saida,
      conta_bancaria_entrada: state.conta_bancaria_entrada,
      valor: state.valor,
    })
      .then(() => {
        showSnack("Operação realizada com sucesso", 2, "success");
        refreshListFn();
        handleModal(id);
        resetState();
      })
      .catch(() => {
        handleModal(id);
        resetState();
      });
  };

  return (
    <NuvelModal
      open={id === modal.id ? modal.open : false}
      title="Transferência Bancária"
      btnSave={handleSave}
      btnCancel={() => {
        handleModal(id);
        resetState();
      }}
      maxWidth="sm"
      fullWidth
    >
      <NuvelGrid container spacing={1}>
        <NuvelGrid size={{ xs: 12 }}>
          <NuvelAutocomplete
            label="Origem"
            name="conta_bancaria_saida"
            reference="conta_bancaria"
            onChange={(e, _, full) => {
              setState((_v) => ({
                ..._v,
                conta_bancaria_saida: e.target.value,
                saldo_disponivel_saida: full?.saldo_atual || 0,
              }));
            }}
            value={state.conta_bancaria_saida}
            helperText={
              state.saldo_disponivel_saida
                ? `Saldo na conta: ${state.saldo_disponivel_saida.toBRL()}`
                : undefined
            }
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <NuvelAutocomplete
            label="Destino"
            name="conta_bancaria_entrada"
            reference="conta_bancaria"
            onChange={(e, _, full) => {
              setState((_v) => ({
                ..._v,
                conta_bancaria_entrada: e.target.value,
                saldo_disponivel_entrada: full?.saldo_atual || 0,
              }));
            }}
            value={state.conta_bancaria_entrada}
            helperText={
              state.saldo_disponivel_entrada
                ? `Saldo na conta: ${state.saldo_disponivel_entrada.toBRL()}`
                : undefined
            }
            filter={{
              id__not_in: state.conta_bancaria_saida,
            }}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <NuvelTextField
            label="Valor"
            value={state.valor}
            onChange={({ target: { value } }) => {
              setState((_v) => ({
                ..._v,
                valor: Number(value),
              }));
            }}
            money
            max={state.saldo_disponivel_saida}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <Typography variant="h6" textAlign="center">
            Resultado da Operação
          </Typography>
        </NuvelGrid>
        <NuvelGrid size={{ xs: 6 }}>
          <InformationBox
            color="error"
            label="Conta Origem"
            value={(
              Number(state.saldo_disponivel_saida) - Number(state.valor)
            ).toBRL()}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 6 }}>
          <InformationBox
            color="success"
            label="Conta Destino"
            value={(
              Number(state.saldo_disponivel_entrada) + Number(state.valor)
            ).toBRL()}
          />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelModal>
  );
};

export default ContaBancariaTransferenciaModal;
