import { lazy } from "react";
import { redirect } from "react-router-dom";
import { RouteInterface } from "utils/router";

const Home = lazy(() => import("layouts/support/views/home"));

const SupportRoutes: RouteInterface[] = [
  {
    path: "/support/*",
    loader: () => redirect("/support/home"),
  },
  {
    path: "/support/home",
    element: <Home />,
  },
];

export default SupportRoutes;
