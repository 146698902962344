import { DepositoSerializer } from "data/interfaces/estoque/DepositoSerializer";
import { DepositoSerializerList } from "data/interfaces/estoque/DepositoSerializerList";
import MainModule from "data/main.ts";

export const model_url: string = "estoque/deposito";

class DepositoModel extends MainModule<
  DepositoSerializer,
  DepositoSerializerList
> {}

export default new DepositoModel(model_url);
