import React from "react";
import { RouterProvider } from "react-router-dom";
import { useGenerateRoutes } from "utils/router/useGenerateRoutes";

const MainRouter: React.FC = () => {
  const router = useGenerateRoutes();

  return <RouterProvider router={router} />;
};

export default MainRouter;
