import { CloudDownload } from "@mui/icons-material";
import { Button } from "@mui/material";
import { AutoFormProps } from "components/nuvel/forms/automatic/NuvelAutoForm";
import { TableColumn, TableFilter } from "components/nuvel/lists";
import { DownloadXMLSerializer } from "data/interfaces/core/DownloadXMLSerializer";
import dayjs from "dayjs";

const recorrencia = [
  { value: 0, label: "Sem Recorrência" },
  { value: 1, label: "Diário" },
  { value: 2, label: "Semanal" },
  { value: 3, label: "Mensal" },
];

export const RETAGUARDA_DOWNLOADXML_FORM: AutoFormProps<DownloadXMLSerializer>[] =
  [
    {
      type: "grid",
      grid: { xs: 12 },
      children: [
        {
          grid: { xs: 12 },
          label: "Loja",
          name: "loja",
          type: "autocomplete",
          reference: "loja",
        },
        {
          grid: { xs: 12, md: 6 },
          label: "Período Inicial",
          name: "periodo_inicial",
          type: "date",
          defaultValue: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
          props: {
            format: "YYYY-MM-DD",
          },
        },
        {
          grid: { xs: 12, md: 6 },
          label: "Período Final",
          name: "periodo_final",
          type: "date",
          defaultValue: dayjs().format("YYYY-MM-DD"),
          props: {
            format: "YYYY-MM-DD",
          },
        },
        {
          grid: { xs: 12, md: 6 },
          label: "Recorrência",
          name: "recorrencia",
          type: "select",
          options: recorrencia,
          defaultValue: 0,
        },
        {
          grid: { xs: 12, md: 6 },
          label: "E-mail de envio",
          name: "email_envio",
          type: "text",
        },
      ],
    },
  ];

function status_to_str(status: string) {
  switch (status) {
    case "A":
      return "Aguardando";
    case "P":
      return "Processando";
    case "C":
      return "Concluído";
    case "E":
      return "Erro";
  }
}

export const RETAGUARDA_DOWNLOADXML_LIST: TableColumn<DownloadXMLSerializer>[] =
  [
    {
      label: "Loja",
      accessor: "loja_nome",
      minWidth: 300,
    },
    {
      label: "Período Inicial",
      accessor: "periodo_inicial",
      type: "date",
    },
    {
      label: "Período Final",
      accessor: "periodo_final",
      type: "date",
    },
    {
      label: "Data de Solicitação",
      accessor: "data_solicitacao",
      type: "date",
    },
    {
      label: "Status",
      accessor: (row) => status_to_str(row?.status),
    },
    {
      label: "Quantidade de Notas",
      accessor: "quantidade_registros",
    },
    {
      label: "Quantidade de Notas Processadas",
      accessor: "quantidade_processada",
    },
    {
      label: "Data de Conclusão",
      accessor: (row) =>
        row?.data_processamento
          ? dayjs(row?.data_processamento).format("DD/MM/YYYY HH:mm:ss")
          : "Aguardando...",
    },
    {
      label: "Download",
      align: "center",
      accessor: (row) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            const url = String(row?.zip_download).replace(
              "http://",
              "https://"
            );
            window.open(url, "_blank");
          }}
          disabled={row.status !== "C"}
          color="secondary"
          size="small"
          variant="text"
          sx={{ mr: 1 }}
          startIcon={<CloudDownload color="primary" />}
        >
          Download
        </Button>
      ),
    },
  ];

export const RETAGUARDA_DOWNLOADXML_ORDERING: (keyof DownloadXMLSerializer)[] =
  ["loja_nome"];

export const RETAGUARDA_DOWNLOADXML_FILTERS: TableFilter<DownloadXMLSerializer>[] =
  [
    {
      label: "Loja",
      accessor: "loja_nome",
      support: ["icontains"],
    },
  ];
