import { NuvelAutoForm } from "components/nuvel/forms";
import { RETAGUARDA_SERVICOS_FORM } from "constants/retaguarda/financeiro/servicos";
import { ServicoModel } from "data/models/financeiro";
import React from "react";

const RetServicosForm: React.FC = () => {
  return (
    <NuvelAutoForm model={ServicoModel} fields={RETAGUARDA_SERVICOS_FORM} />
  );
};

export default RetServicosForm;
