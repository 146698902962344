import * as React from "react";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { Order, TableColumn } from ".";

interface DefaultTableProps<T> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: Order;
  orderBy: keyof T | null;
  columns: TableColumn<T>[];
  ordering?: (keyof T)[];
  actions?: boolean;
}

export default function DefaultTableHead<T>({
  order,
  orderBy,
  onRequestSort,
  columns,
  ordering,
  actions,
}: DefaultTableProps<T>) {
  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => {
          const hasOrdering =
            typeof column.accessor !== "function"
              ? ordering?.includes(column.accessor)
              : null;
          if (!hasOrdering) {
            return (
              <TableCell key={column.accessor as string} align="left">
                {column.label}
              </TableCell>
            );
          }
          return (
            <TableCell
              key={column.accessor as string}
              align="left"
              sortDirection={orderBy === column.accessor ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.accessor}
                direction={orderBy === column.accessor ? order : "asc"}
                onClick={
                  typeof column.accessor !== "function"
                    ? createSortHandler(column.accessor)
                    : () => {}
                }
              >
                {column.label}
                {orderBy === column.accessor ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
        {actions && <TableCell align="center">Ações</TableCell>}
      </TableRow>
    </TableHead>
  );
}
