import { CodBarraSerializer } from "data/interfaces/estoque/CodBarraSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "estoque/codbarra";

class CodBarraModel extends MainModule<
  CodBarraSerializer,
  CodBarraSerializer
> {}

export default new CodBarraModel(model_url);
