import * as React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import NuvelGrid from "components/nuvel/Grid";
import NuvelPaper from "components/nuvel/Paper";
import { useNavigate } from "react-router-dom";
import useReport from "hooks/useReport";
import dayjs from "dayjs";
import { SimpleTableColumn } from "components/nuvel/lists/simple";
import { SimpleList } from "components/nuvel/lists";
import { imprimirDoc } from "utils/functions/print";

import ReportPDF from "./report";

export interface Relatorio {
  url: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<SimpleTableColumn<any>>;
  totais?: string;
  filtros: {
    [key: string]: string | number | string[] | number[] | null | undefined;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  child?: Array<SimpleTableColumn<any>>;
  child_key?: string;
}

interface Props {
  title: string;
  data_inicio: dayjs.Dayjs;
  data_fim: dayjs.Dayjs;
  relatorio: number;
  relatorios: Relatorio[];
  children?: React.ReactNode;
}

const DefaultRelatorios: React.FC<Props> = ({
  children,
  title,
  relatorio,
  relatorios,
  data_fim,
  data_inicio,
}) => {
  const navigate = useNavigate();
  const { loading, report, handleSubmit } = useReport(relatorios, relatorio);

  return (
    <form noValidate={true} onSubmit={handleSubmit} style={{ height: "100%" }}>
      <NuvelGrid container spacing={2} sx={{ height: "100%" }}>
        <NuvelGrid xs={12} display="flex" flexDirection="column">
          <NuvelPaper sx={{ height: "100%" }}>
            <Box p={2}>
              <Typography variant="h4">Relatório de {title}</Typography>
            </Box>
            <Divider />
            <Box p={2}>
              <NuvelGrid container spacing={2}>
                <NuvelGrid xs={12}>{children}</NuvelGrid>
                <NuvelGrid xs={12}>
                  <Divider />
                </NuvelGrid>
                <NuvelGrid xs={12}>
                  <SimpleList
                    columns={relatorios[relatorio].columns || []}
                    data={report.data.rows || []}
                    total_pages={
                      Math.ceil(report.data.totalCount / 10) || undefined
                    }
                    page={report.page}
                    handleChangePage={(_, page) => {
                      handleSubmit(undefined, page);
                    }}
                    totals={report.data.totais || undefined}
                    child={report.config.child || undefined}
                    child_key={report.config.child_key || ""}
                  />
                </NuvelGrid>
              </NuvelGrid>
            </Box>
          </NuvelPaper>
          <Box display="flex" pt={2}>
            <Box flexGrow="1">
              <Button variant="contained" onClick={() => navigate(-1)}>
                Voltar
              </Button>
            </Box>
            <Box>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                sx={{ mr: 1 }}
              >
                Exportar XLS
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                sx={{ mr: 1 }}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  handleSubmit(undefined, 1, 99999999999, (report: any) => {
                    imprimirDoc(
                      <ReportPDF
                        inicio={data_inicio}
                        fim={data_fim}
                        title={report.config.title}
                        data={report.data.rows}
                        columns={report.config.columns}
                        totais={report.config.totais}
                        child={report.config.child}
                        child_key={report.config.child_key}
                      />
                    );
                  });
                }}
              >
                Imprimir
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                color="primary"
              >
                Consultar
              </Button>
            </Box>
          </Box>
        </NuvelGrid>
      </NuvelGrid>
    </form>
  );
};

export default DefaultRelatorios;
