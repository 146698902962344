import React from "react";
import {
  Box,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import { ElementoBase } from "./types";

interface Props {
  elemento: ElementoBase | null;
  onUpdate: (elemento: ElementoBase) => void;
}

const PainelPropriedades: React.FC<Props> = ({ elemento, onUpdate }) => {
  if (!elemento) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography color="text.secondary">
          Selecione um elemento para editar
        </Typography>
      </Box>
    );
  }

  const handleEstiloChange = (campo: string, valor: string | number) => {
    onUpdate({
      ...elemento,
      estilo: {
        ...elemento.estilo,
        [campo]: valor,
      },
    });
  };

  const isTextoOuPreco = elemento.tipo === "texto" || elemento.tipo === "preco";

  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      {/* Posição */}
      <Box>
        <Typography variant="subtitle2" gutterBottom>
          Posição
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <TextField
            label="X"
            type="number"
            size="small"
            value={elemento.posicao.x}
            onChange={(e) =>
              onUpdate({
                ...elemento,
                posicao: { ...elemento.posicao, x: Number(e.target.value) },
              })
            }
          />
          <TextField
            label="Y"
            type="number"
            size="small"
            value={elemento.posicao.y}
            onChange={(e) =>
              onUpdate({
                ...elemento,
                posicao: { ...elemento.posicao, y: Number(e.target.value) },
              })
            }
          />
        </Box>
      </Box>

      {/* Tamanho */}
      <Box>
        <Typography variant="subtitle2" gutterBottom>
          Tamanho
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <TextField
            label="Largura"
            type="number"
            size="small"
            value={elemento.tamanho?.width || 100}
            onChange={(e) =>
              onUpdate({
                ...elemento,
                tamanho: {
                  width: Number(e.target.value),
                  height: elemento.tamanho?.height || 30,
                },
              })
            }
          />
          <TextField
            label="Altura"
            type="number"
            size="small"
            value={elemento.tamanho?.height || 30}
            onChange={(e) =>
              onUpdate({
                ...elemento,
                tamanho: {
                  width: elemento.tamanho?.width || 100,
                  height: Number(e.target.value),
                },
              })
            }
          />
        </Box>
      </Box>

      {isTextoOuPreco && (
        <>
          <Divider />

          {/* Tamanho da fonte */}
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Tamanho da Fonte
            </Typography>
            <Slider
              value={elemento.estilo?.fontSize || 12}
              min={8}
              max={72}
              onChange={(_e, value) =>
                handleEstiloChange("fontSize", value as number)
              }
              valueLabelDisplay="auto"
              marks={[
                { value: 8, label: "8" },
                { value: 12, label: "12" },
                { value: 16, label: "16" },
                { value: 24, label: "24" },
                { value: 32, label: "32" },
                { value: 48, label: "48" },
                { value: 72, label: "72" },
              ]}
            />
          </Box>

          {/* Estilo da fonte */}
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Estilo do Texto
            </Typography>
            <ToggleButtonGroup
              size="small"
              value={[
                elemento.estilo?.fontWeight === "bold" && "bold",
                elemento.estilo?.fontStyle === "italic" && "italic",
                elemento.estilo?.textDecoration === "underline" && "underline",
              ].filter(Boolean)}
              onChange={(_e, newFormats) => {
                const newEstilo = { ...elemento.estilo };
                newEstilo.fontWeight = newFormats.includes("bold")
                  ? "bold"
                  : "normal";
                newEstilo.fontStyle = newFormats.includes("italic")
                  ? "italic"
                  : "normal";
                newEstilo.textDecoration = newFormats.includes("underline")
                  ? "underline"
                  : "none";
                onUpdate({ ...elemento, estilo: newEstilo });
              }}
              aria-label="formatação de texto"
            >
              <ToggleButton value="bold" aria-label="negrito">
                <FormatBoldIcon />
              </ToggleButton>
              <ToggleButton value="italic" aria-label="itálico">
                <FormatItalicIcon />
              </ToggleButton>
              <ToggleButton value="underline" aria-label="sublinhado">
                <FormatUnderlinedIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* Alinhamento do texto */}
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Alinhamento
            </Typography>
            <ToggleButtonGroup
              size="small"
              value={elemento.estilo?.textAlign || "left"}
              exclusive
              onChange={(_e, value) => {
                if (value) handleEstiloChange("textAlign", value);
              }}
              aria-label="alinhamento de texto"
            >
              <ToggleButton value="left" aria-label="alinhado à esquerda">
                <FormatAlignLeftIcon />
              </ToggleButton>
              <ToggleButton value="center" aria-label="centralizado">
                <FormatAlignCenterIcon />
              </ToggleButton>
              <ToggleButton value="right" aria-label="alinhado à direita">
                <FormatAlignRightIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* Família da fonte */}
          <FormControl size="small" fullWidth>
            <InputLabel>Fonte</InputLabel>
            <Select
              value={elemento.estilo?.fontFamily || "Arial"}
              onChange={(e) => handleEstiloChange("fontFamily", e.target.value)}
              label="Fonte"
            >
              <MenuItem value="Arial">Arial</MenuItem>
              <MenuItem value="Times New Roman">Times New Roman</MenuItem>
              <MenuItem value="Courier New">Courier New</MenuItem>
              <MenuItem value="Georgia">Georgia</MenuItem>
              <MenuItem value="Verdana">Verdana</MenuItem>
              <MenuItem value="Helvetica">Helvetica</MenuItem>
            </Select>
          </FormControl>
        </>
      )}
    </Box>
  );
};

export default PainelPropriedades;
