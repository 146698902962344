import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_TAG_FILTERS,
  RETAGUARDA_TAG_LIST,
  RETAGUARDA_TAG_ORDERING,
} from "constants/retaguarda/cadastro/tag";
import { TagModel } from "data/models";
import React from "react";

const RetTagList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={TagModel}
      columns={RETAGUARDA_TAG_LIST}
      filters={RETAGUARDA_TAG_FILTERS}
      ordering={RETAGUARDA_TAG_ORDERING}
      actions={{
        update: true,
        delete: true,
        create: true,
      }}
    />
  );
};

export default RetTagList;
