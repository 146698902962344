import { AppContext } from "provider";
import { useContext } from "react";

export interface Permission {
  acesso: string;
  value: string[];
}

interface Permissions {
  read: boolean;
  create: boolean;
  remove: boolean;
}

const defaultAllowed: Permissions = {
  read: true,
  create: true,
  remove: true,
};

const usePermissions = (access: string): Permissions => {
  const appConstants = useContext(AppContext);
  const permissions: Permission[] = appConstants.configuration.permissions;

  if (permissions && permissions.length === 0) {
    return defaultAllowed;
  }

  const checkPermission = (right: string): boolean => {
    const permission = permissions.find((perm) => perm.acesso === access);
    if (permission) {
      return permission.value.includes(right);
    }
    return false;
  };

  return {
    read: checkPermission("R"),
    create: checkPermission("W"),
    remove: checkPermission("D"),
  };
};

export default usePermissions;
