import { RouteInterface } from "utils/router";

import RetNotaSaidaForm from "../views/fiscal/nota_saida/form";
import RetImpostosList from "../views/fiscal/impostos/list";
import RetImpostosForm from "../views/fiscal/impostos/form";
import RetCertificadosList from "../views/fiscal/certificados/list";
import RetCertificadosForm from "../views/fiscal/certificados/form";
import RetDownloadXMLList from "../views/fiscal/download_xml/list";
import RetDownloadXMLForm from "../views/fiscal/download_xml/form";

const fiscalRoutes: RouteInterface[] = [
  {
    title: "Nota de Saída",
    path: "/retaguarda/fiscal/nota_saida",
    element: <RetNotaSaidaForm />,
    perm: "FISCAL_NOTA_DE_SAIDA",
  },
  {
    title: "Impostos",
    path: "/retaguarda/fiscal/impostos",
    element: <RetImpostosList />,
    form: <RetImpostosForm />,
    perm: "FISCAL_IMPOSTOS",
  },
  {
    title: "Certificados",
    path: "/retaguarda/fiscal/certificados",
    element: <RetCertificadosList />,
    form: <RetCertificadosForm />,
    perm: "FISCAL_CERTIFICADOS",
  },
  {
    title: "Download XML",
    path: "/retaguarda/fiscal/download_xml",
    element: <RetDownloadXMLList />,
    form: <RetDownloadXMLForm />,
    perm: "FISCAL_DOWNLOAD_XML",
  },
];

export default fiscalRoutes;
