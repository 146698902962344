import { Add, Delete } from "@mui/icons-material";
import { Button } from "@mui/material";
import NuvelAccordion from "components/nuvel/Accordion";
import NuvelGrid from "components/nuvel/Grid";
import NuvelTextField from "components/nuvel/TextField";
import { ContatoSerializer } from "data/interfaces/info/ContatoSerializer";

type AccordionContatoProps = {
  isMultiple?: boolean;
} & (
  | {
      isMultiple: true;
      contatos: ContatoSerializer[];
      setContatos: (contatos: ContatoSerializer[]) => void;
      contato?: never;
      setContato?: never;
    }
  | {
      isMultiple?: false;
      contato: ContatoSerializer;
      setContato: (contato: ContatoSerializer) => void;
      contatos?: never;
      setContatos?: never;
    }
);

interface ContatoRowProps {
  contato: ContatoSerializer;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  index: number;
  isMultiple?: boolean;
  handleRemove?: (index: number) => void;
}

function ContatoRow({
  contato,
  handleChange,
  handleRemove,
  index,
  isMultiple = false,
}: ContatoRowProps) {
  return (
    <NuvelGrid container spacing={2} key={index} mb={2}>
      {isMultiple ? (
        <NuvelGrid xs={4}>
          <NuvelTextField
            label="Descrição"
            onChange={(e) => handleChange(e, index)}
            name="nome"
            value={contato.nome}
          />
        </NuvelGrid>
      ) : null}
      <NuvelGrid xs={isMultiple ? 3 : 5}>
        <NuvelTextField
          label="Telefone"
          mask="(00) 00000-0000"
          normalize={(value) => value.replaceAll(/[-() ]/g, "")}
          onChange={(e) => handleChange(e, index)}
          name="telefone"
          value={contato.telefone}
        />
      </NuvelGrid>
      <NuvelGrid xs={isMultiple ? 4 : 7}>
        <NuvelTextField
          label="Email"
          onChange={(e) => handleChange(e, index)}
          name="email"
          value={contato.email}
        />
      </NuvelGrid>
      {isMultiple && (
        <NuvelGrid xs={1}>
          <Button onClick={() => handleRemove?.(index)} sx={{ height: "100%" }}>
            <Delete />
          </Button>
        </NuvelGrid>
      )}
    </NuvelGrid>
  );
}

function AccordionContato(props: AccordionContatoProps) {
  const {
    contatos,
    setContatos,
    setContato,
    isMultiple = false,
    contato,
  } = props;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (setContatos && contatos) {
      const newContatos = [...contatos];
      newContatos[index] = {
        ...newContatos[index],
        [e.target.name]: e.target.value,
      };
      setContatos(newContatos);
    }
  };

  const handleChangeSingle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (setContato && contato) {
      const newContato = { ...contato };
      // @ts-expect-error target.name is not in the type
      newContato[e.target.name] = e.target.value;
      setContato(newContato);
    }
  };

  const handleAdd = () => {
    if (setContatos && contatos) {
      setContatos([...contatos, { nome: "", email: "", telefone: "" }]);
    }
  };

  const handleRemove = (index: number) => {
    if (setContatos && contatos) {
      setContatos(contatos.filter((_, i) => i !== index));
    }
  };

  return (
    <NuvelAccordion title="Contatos">
      {isMultiple ? (
        <NuvelGrid container spacing={2} mb={2}>
          <NuvelGrid xs={12}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={handleAdd}
            >
              Adicionar
            </Button>
          </NuvelGrid>
        </NuvelGrid>
      ) : null}
      {isMultiple && contatos ? (
        contatos.map((contato, index) => (
          <ContatoRow
            contato={contato}
            handleChange={handleChange}
            index={index}
            handleRemove={handleRemove}
            isMultiple
          />
        ))
      ) : contato ? (
        <ContatoRow
          contato={contato}
          handleChange={handleChangeSingle}
          index={0}
          isMultiple={false}
        />
      ) : null}
    </NuvelAccordion>
  );
}

export default AccordionContato;
