import { RouteInterface } from "utils/router";

import RetPDVCaixaList from "../views/pdv/caixa/list";

const pdvRoutes: RouteInterface[] = [
  {
    title: "Caixa",
    path: "/retaguarda/pdv/caixa",
    element: <RetPDVCaixaList />,
    perm: "PDV_CAIXA",
  },
];

export default pdvRoutes;
