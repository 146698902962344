import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Product, useCatalog } from "../context";
import NuvelGrid from "components/nuvel/Grid";
import { useResponsive } from "hooks/useResponsive";

import ProductModalDesktop from "../components/home/ProductModalDesktop";
import ProductModalMobile from "../components/home/ProductModalMobile";
import ProductCard from "../components/home/ProductCard";

const Home: React.FC = () => {
  const { isMobile } = useResponsive();
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const { state } = useCatalog();

  console.log(state);

  useEffect(() => {
    if (selectedProduct) {
      setOpenModal(true);
    }
  }, [selectedProduct]);

  return (
    <Box>
      {state.departments?.map((dept) => {
        const products = state.products.filter((p) => p.department === dept.id);

        if (products.length === 0) return null;

        return (
          <Box
            key={dept.id}
            id={`section-${dept.id}`}
            sx={{ scrollMarginTop: 100 }}
            mt={2}
          >
            <Typography variant="h4">{dept.name}</Typography>
            <Divider />
            <NuvelGrid container spacing={2} sx={{ mt: 1 }}>
              {products
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((p) => (
                  <NuvelGrid xs={12} sm={6} key={p.id}>
                    <ProductCard
                      product={p}
                      setSelectedProduct={setSelectedProduct}
                    />
                  </NuvelGrid>
                ))}
            </NuvelGrid>
          </Box>
        );
      })}
      {selectedProduct &&
        (isMobile ? (
          <ProductModalMobile
            product={selectedProduct}
            open={openModal}
            setOpen={setOpenModal}
            setSelectedProduct={setSelectedProduct}
          />
        ) : (
          <ProductModalDesktop
            product={selectedProduct}
            open={openModal}
            setOpen={setOpenModal}
            setSelectedProduct={setSelectedProduct}
          />
        ))}
    </Box>
  );
};

export default Home;
