import { Typography } from "@mui/material";
import NuvelGrid from "components/nuvel/Grid";
import PaperWithLoad from "components/nuvel/PaperWithLoad";
import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";

export default function Finalizadoras({ ...props }) {
  const { data, colors } = props;

  let _data = [];

  const formatCurrency = (value) => {
    return value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    });
  };

  if (data && data.length > 0) {
    data.forEach((element) => {
      _data.push({
        value: element.valor_total,
        labels: element.f_pagamento,
        labels_flat: element.f_pagamento_flat,
        text: formatCurrency(element.valor_total),
      });
    });
  }

  const renderCustomLabel = (item) => {
    return (
      <text
        fill={item.fill}
        x={item.x}
        y={item.y}
        stroke="none"
        alignmentBaseline="middle"
        className="recharts-text recharts-pie-label-text"
        textAnchor="end"
      >
        <tspan x={item.x} textAnchor={item.textAnchor} dy="0em">
          {item.payload.text}
        </tspan>
      </text>
    );
  };
  return (
    <>
      <PaperWithLoad loading={!data} style={{ width: "100%", height: "24em" }}>
        <NuvelGrid container>
          <NuvelGrid
            style={{
              width: "100%",
              height: "3em",
              textAlign: "center",
            }}
            xs={12}
          >
            <Typography variant="h5" component="h2">
              Finalizadoras
            </Typography>
          </NuvelGrid>
          <NuvelGrid style={{ width: "100%", height: "21em" }} xs={12}>
            {_data.length > 0 && (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    isAnimationActive={true}
                    dataKey="value"
                    data={_data}
                    label={(item) => renderCustomLabel(item)}
                    cx={"50%"}
                    cy={"50%"}
                    outerRadius={120}
                  >
                    {_data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colors[entry.labels_flat]}
                      />
                    ))}
                  </Pie>
                  <Legend
                    formatter={(value, entry) =>
                      `${entry.payload.payload.labels}`
                    }
                  />
                  <Tooltip
                    labelFormatter={(value) => value}
                    formatter={(value, order, entry) => [
                      `${value.toBRL()}`,
                      entry?.payload?.payload?.labels,
                    ]}
                  />
                </PieChart>
              </ResponsiveContainer>
            )}
          </NuvelGrid>
        </NuvelGrid>
      </PaperWithLoad>
    </>
  );
}
