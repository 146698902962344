import { Box, Button, Container, styled, Typography } from "@mui/material";
import { Not_Found } from "assets/img";
import { SystemLogModel } from "data/models/info";
import useWindowSize from "hooks/useWindowSize";
import React, { ErrorInfo } from "react";
import StackTrace from "stacktrace-js";
interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

const CodeBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#0D0D0D",
  color: theme.palette.error.contrastText,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `2px solid #2F2F2F`,
  borderTop: `36px solid #2F2F2F`,
  fontFamily: "monospace",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  position: "relative",
}));

const CodeBoxTitle = styled(Typography)({
  position: "absolute",
  top: -26,
  left: 8,
  fontSize: "0.8rem",
  fontWeight: "bold",
  letterSpacing: "1px",
});

const sendErrorLog = async (logData: {
  location: string;
  error_message: string;
  component_stack: string;
  stack_trace: string;
  log_type: number;
  origin: number;
}) => {
  try {
    const response = await SystemLogModel.save_with_response(logData);

    if (!response) {
      throw new Error(`Failed to send log: ${response.statusText}`);
    }

    const data = await response.json();
    console.log("Log sent successfully:", data);
  } catch (error) {
    console.error("Error sending log to the server:", error);
  }
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
    this.setState({ errorInfo });

    // Use stacktrace-js to map the stack trace
    StackTrace.fromError(error)
      .then((stackframes) => {
        const mappedStack = stackframes
          .map((sf) => {
            return sf.toString();
          })
          .join("\n");

        const logData = {
          location: window.location.href,
          error_message: error.message,
          component_stack: errorInfo.componentStack || "",
          stack_trace: mappedStack,
          log_type: 1,
          origin: 2,
        };

        sendErrorLog(logData);
      })
      .catch((stackTraceError) => {
        console.error("Error mapping stack trace", stackTraceError);

        // Fallback to original stack trace
        const logData = {
          location: window.location.href,
          error_message: error.message,
          component_stack: errorInfo.componentStack || "",
          stack_trace: error.stack || "",
          log_type: 1,
          origin: 2,
        };

        sendErrorLog(logData);
      });
  }

  handleReload = () => {
    this.setState({ hasError: false, error: undefined, errorInfo: undefined });
    window.location.reload();
  };

  handleDownloadLog = () => {
    const { error, errorInfo } = this.state;
    if (error && errorInfo) {
      const log = JSON.stringify({
        location: window.location,
        error: error.toString(),
        component_stack: errorInfo.componentStack,
        stack: error.stack,
      });
      const blob = new Blob([log], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "error_log.txt";
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryContent
          onReload={this.handleReload}
          onDownloadLog={this.handleDownloadLog}
          error={this.state.error}
        />
      );
    }

    return this.props.children;
  }
}

const ErrorBoundaryContent: React.FC<{
  onReload: () => void;
  onDownloadLog: () => void;
  error?: Error;
}> = ({ onReload, onDownloadLog, error }) => {
  const [width] = useWindowSize();
  const isMobile = width < 1050;

  return (
    <Container sx={{ height: "100vh" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        textAlign="center"
      >
        {import.meta.env.DEV ? (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              {import.meta.env.MODE} - {import.meta.env.VITE_API_URL}
            </Typography>
            <Typography variant="h2" gutterBottom>
              Ocorreu um erro inesperado.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Nosso sistema já detectou este erro e nossa equipe técnica foi
              automaticamente notificada.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Caso não entremos em contato em até 15 minutos, por favor, procure
              nosso suporte técnico e encaminhe o arquivo <b>error_log.txt</b>{" "}
              gerado pelo botão abaixo.
            </Typography>
            <CodeBox>
              <CodeBoxTitle variant="body2">{error?.message}</CodeBoxTitle>
              <Typography variant="body2">{error?.stack}</Typography>
            </CodeBox>
          </Box>
        ) : (
          <Box sx={{ mb: `calc((1912px / 100) * -1.5)` }}>
            <Typography variant="h2" gutterBottom>
              Algo deu errado
            </Typography>
            <Typography variant="h5" gutterBottom>
              Ocorreu um erro inesperado. Por favor, tente recarregar a página.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Nosso sistema já detectou este erro e nossa equipe técnica foi
              automaticamente notificada.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Caso não entremos em contato em até 15 minutos, por favor, procure
              nosso suporte técnico e encaminhe o arquivo <b>error_log.txt</b>{" "}
              gerado pelo botão abaixo.
            </Typography>
          </Box>
        )}
        {import.meta.env.PROD && (
          <img
            src={Not_Found}
            alt="Erro"
            style={{
              width: isMobile ? "100%" : `calc(${width}px / 2.5)`,
              marginTop: "20px",
              marginBottom: "20px",
            }}
          />
        )}
        <Box>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={onReload}
            sx={{ mr: 2 }}
          >
            Recarregar Página
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={onDownloadLog}
          >
            Baixar Log
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ErrorBoundary;
