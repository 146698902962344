import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";

function DownloadXLS(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (tipo) => {
    props.handleSelect(tipo);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="primary"
      >
        Download XLS
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose("simplificado")}>
          Simplificado
        </MenuItem>
        <MenuItem onClick={() => handleClose("detalhado")}>Detalhado</MenuItem>
      </Menu>
    </div>
  );
}

export default DownloadXLS;
