import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelGrid from "components/nuvel/Grid";
import NuvelSwitch from "components/nuvel/Switch";
import NuvelTextField from "components/nuvel/TextField";
import { ParceiroDeNegocioSerializer } from "data/interfaces/core/ParceiroDeNegocioSerializer";
import React from "react";
import { handleChange } from "utils/functions";

interface TabFuncionarioProps {
  state: ParceiroDeNegocioSerializer;
  setState: React.Dispatch<React.SetStateAction<ParceiroDeNegocioSerializer>>;
}
const TabFuncionario: React.FC<TabFuncionarioProps> = ({ state, setState }) => {
  return (
    <NuvelGrid container spacing={1}>
      <NuvelGrid
        xs={12}
        md={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <NuvelSwitch
          checked={state.funcionario_ativo}
          name="funcionario_ativo"
          onChange={(e) => handleChange(e, setState)}
          label="Ativo"
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={2}>
        <NuvelDatePicker
          label="Data de Admissão"
          onChange={(e) => handleChange(e, setState)}
          name="funcionario_dataadmissao"
          value={state.funcionario_dataadmissao}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={3}>
        <NuvelTextField
          label="Salário"
          money
          onChange={(e) => handleChange(e, setState)}
          name="funcionario_salario"
          value={state.funcionario_salario}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={3}>
        <NuvelAutocomplete
          label="Cargo"
          name="funcionario_cargo"
          onChange={(e) => handleChange(e, setState)}
          reference="cargo"
          value={Number(state.funcionario_cargo)}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={3}>
        <NuvelTextField
          label="Login PDV"
          onChange={(e) => handleChange(e, setState)}
          name="funcionario_loginpdv"
          value={state.funcionario_loginpdv}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={3}>
        <NuvelTextField
          type="password"
          label="Senha PDV"
          onChange={(e) => handleChange(e, setState)}
          name="funcionario_senhapdv"
          value={state.funcionario_senhapdv}
        />
      </NuvelGrid>
      <NuvelGrid
        xs={12}
        md={3}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <NuvelSwitch
          checked={state.funcionario_supervisor}
          name="funcionario_supervisor"
          onChange={(e) => {
            setState({
              ...state,
              funcionario_visualiza_fechamento: !e.target.value
                ? false
                : state.funcionario_visualiza_fechamento,
              funcionario_supervisor: e.target.value,
            });
          }}
          label="Supervisor"
        />
      </NuvelGrid>
      <NuvelGrid
        xs={12}
        md={3}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <NuvelSwitch
          checked={state.funcionario_visualiza_fechamento}
          name="funcionario_visualiza_fechamento"
          onChange={(e) => handleChange(e, setState)}
          label={
            <span>
              Visualiza Fechamento
              <br />
              <span style={{ fontSize: 14 }}>
                Apenas com Fechamento Cego e supervisor ativo
              </span>
            </span>
          }
          disabled={!state.funcionario_supervisor}
        />
      </NuvelGrid>
    </NuvelGrid>
  );
};

export default TabFuncionario;
