import { PermissaoSerializer } from "data/interfaces/core/PermissaoSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "permissao";

class PermissaoModel extends MainModule<
  PermissaoSerializer,
  PermissaoSerializer
> {}

export default new PermissaoModel(model_url);
