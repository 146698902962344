/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { MenuItem, InputLabel, Select, FormControl } from "@mui/material";
import { styled } from "@mui/system";
import { LojaSerializerList } from "data/interfaces/core/LojaSerializerList";
import { LojaModel } from "data/models";
import { handleChange } from "utils/functions";

const FormControlStyled = styled(FormControl)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const OnlineStoreStyled = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

interface LojaSelectProps {
  state: { loja: string };
  setState: (value: any) => void;
  isOnlyOne?: boolean;
  disableAll?: boolean;
}

const LojaSelect: React.FC<LojaSelectProps> = (props) => {
  const { state, setState, isOnlyOne: only1 } = props;
  const { data: lojas } = LojaModel.useModel({
    pageSize: 9999,
  });

  const returnLojaNome = (loja: LojaSerializerList) =>
    loja.nome_fantasia || loja.razao_social || "Sem Nome";

  const isOnlyOne = only1 ? true : false;

  const sortByNumber = (a: any, b: any) => {
    const num_a = Number(returnLojaNome(a).split("-")[0]);
    const num_b = Number(returnLojaNome(b).split("-")[0]);
    if (isNaN(num_a)) {
      return 1;
    }
    if (isNaN(num_b)) {
      return -1;
    }
    return num_a > num_b ? 1 : num_b > num_a ? -1 : 0;
  };

  return (
    <FormControlStyled fullWidth variant="filled">
      <InputLabel htmlFor="loja-select">Loja</InputLabel>
      <Select
        value={state.loja}
        onChange={(e: any) => handleChange(e, setState)}
        name="loja"
        autoWidth
        inputProps={{
          id: "loja-select",
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 500,
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          onScroll: () => console.log("Oh No!"),
        }}
      >
        {!isOnlyOne && !props.disableAll ? (
          <MenuItem value={"todas"}>Todas</MenuItem>
        ) : null}

        {lojas &&
          lojas.rows.sort(sortByNumber).map((el, idx) => {
            return (
              <OnlineStoreStyled key={idx} value={el.id}>
                {returnLojaNome(el)}
              </OnlineStoreStyled>
            );
          })}
      </Select>
    </FormControlStyled>
  );
};

export default LojaSelect;
