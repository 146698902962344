import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import useCurrentRoute from "hooks/useCurrentRoute";
import NuvelTextField from "components/nuvel/TextField";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import { TableFilter } from ".";
import {
  ClickAwayListener,
  Divider,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  styled,
  useRadioGroup,
} from "@mui/material";
import { getFilterLabel } from "utils/functions/getFilterLabel";
import { RestartAlt } from "@mui/icons-material";

interface DefaltTableToolbarProps<L> {
  filters?: TableFilter<L>[];
  rawSearch: string;
  setRawSearch: React.Dispatch<React.SetStateAction<string>>;
  searchBase?: {
    search_type: string;
    search_field: keyof L;
  };
  setSearchBase: React.Dispatch<
    React.SetStateAction<
      | {
          search_type: string;
          search_field: keyof L;
        }
      | undefined
    >
  >;
  selectFilters: Record<string, boolean>;
  setSelectFilters: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
}

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled(
  (props: StyledFormControlLabelProps) => <FormControlLabel {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "checked",
  }
)(({ theme }) => ({
  variants: [
    {
      props: ({ checked }) => checked,

      style: {
        ".MuiFormControlLabel-label": {
          color: theme.palette.primary.main,
        },
      },
    },
  ],
}));

function FilterControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

export default function DefaultTableToolbar<T>({
  filters,
  rawSearch,
  setRawSearch,
  searchBase,
  setSearchBase,
  selectFilters,
  setSelectFilters,
}: DefaltTableToolbarProps<T>) {
  const route = useCurrentRoute(1);
  const [searchFocus, setSearchFocus] = React.useState(false);
  const searchFieldRef = React.useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filter, setFilter] = React.useState<string>("todos");
  const [filterType, setFilterType] = React.useState<string>("icontains");

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type = false
  ) => {
    if (type) {
      setFilterType((event.target as HTMLInputElement).value);
    } else {
      setFilter((event.target as HTMLInputElement).value);
      setFilterType(
        filters?.find((filter) => filter.accessor === event.target.value)
          ?.support?.[0] || "icontains"
      );
    }
  };

  const handleClickAway = () => {
    if (filter !== "todos" && filterType) {
      setSearchBase({
        search_type: filterType,
        search_field: filter as keyof T,
      });
    }
    setAnchorEl(null);
  };

  const handleResetFilter = () => {
    setFilter("todos");
    setFilterType("icontains");
    setSearchBase(undefined);
  };

  const handleSelectFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("handleSelectFilterChange called");
    console.log("event.target.value:", event.target.value);
    const selectedValue = event.target.value;
    setSelectFilters((prevFilters) => {
      console.log("prevFilters:", prevFilters);
      const newFilters: Record<string, boolean> = {};
      filters?.forEach((filter) => {
        if (filter.select) {
          filter.select.forEach((option) => {
            newFilters[option.accessor as string] =
              option.accessor === selectedValue;
          });
        }
      });
      console.log("newFilters:", newFilters);
      return newFilters;
    });
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Lista de{" "}
        {route?.title?.charAt(route?.title?.length - 1) === "s"
          ? route?.title
          : route?.title + "s"}
      </Typography>
      <Box
        sx={[
          {
            transition: "flex 0.3s ease",
          },
          searchFocus
            ? {
                flex: 3,
              }
            : {
                flex: 1,
              },
        ]}
      >
        <NuvelTextField
          placeholder={`Pesquisar por ${String(
            searchBase
              ? filters?.find(
                  (filter) => filter.accessor === searchBase.search_field
                )?.label
              : filters?.map((filter) => filter.label).join(", ")
          )}`}
          label={undefined}
          variant="outlined"
          size="small"
          onFocus={() => setSearchFocus(true)}
          onBlur={() => setSearchFocus(false)}
          sx={(theme) => ({
            backgroundColor: "#303030",

            ...theme.applyStyles("light", {
              backgroundColor: "#f5f5f5",
            }),
          })}
          InputProps={{
            endAdornment: filters ? (
              <IconButton onClick={handleFilterClick}>
                <FilterListIcon />
              </IconButton>
            ) : null,
          }}
          inputRef={searchFieldRef}
          value={rawSearch}
          onChange={(e) => setRawSearch(e.target.value)}
        />
        <Popper id={id} open={open} anchorEl={anchorEl}>
          <Paper elevation={4}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box display="flex">
                <Box p={2}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Filtros</Typography>
                    <IconButton onClick={handleResetFilter} size="small">
                      <RestartAlt color="primary" />
                    </IconButton>
                  </Box>
                  <Divider sx={{ mb: 0.5, mt: 0.5 }} />
                  <RadioGroup
                    value={filter}
                    onChange={(e) => handleFilterChange(e)}
                    name="use-radio-group"
                  >
                    <FilterControlLabel
                      value={"todos"}
                      control={<Radio size="small" />}
                      label={"Todos"}
                    />
                    {filters
                      ?.filter((filter) => filter.accessor)
                      .map((filter) => (
                        <FilterControlLabel
                          key={filter.label}
                          value={filter.accessor}
                          control={<Radio size="small" />}
                          label={filter.label}
                        />
                      ))}
                  </RadioGroup>
                  <Divider sx={{ mb: 0.5, mt: 0.5 }} />
                  {filter && filter !== "todos" && (
                    <React.Fragment>
                      <Typography>Tipo</Typography>
                      <Divider sx={{ mb: 0.5, mt: 0.5 }} />
                      <RadioGroup
                        value={filterType}
                        onChange={(e) => handleFilterChange(e, true)}
                        name="use-radio-group"
                      >
                        {filters
                          ?.filter((_filter) => _filter.accessor)
                          ?.find((_filter) => _filter.accessor === filter)
                          ?.support?.map((type) => (
                            <FilterControlLabel
                              key={type}
                              value={type}
                              control={<Radio size="small" />}
                              label={getFilterLabel(type)}
                            />
                          ))}
                      </RadioGroup>
                    </React.Fragment>
                  )}
                </Box>
                <Box pt={2} pb={2}>
                  <Divider orientation="vertical" />
                </Box>
                <Box p={2}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Opções de Filtro</Typography>
                    <IconButton
                      onClick={() => setSelectFilters({})}
                      size="small"
                    >
                      <RestartAlt color="primary" />
                    </IconButton>
                  </Box>
                  <Divider sx={{ mb: 0.5, mt: 0.5 }} />
                  {filters
                    ?.filter((filter) => filter.select)
                    .map((filter) => (
                      <Box key={filter.label}>
                        <Typography variant="subtitle2">
                          {filter.label}
                        </Typography>
                        <RadioGroup
                          value={
                            Object.keys(selectFilters).find(
                              (key) => selectFilters[key]
                            ) || ""
                          }
                          onChange={handleSelectFilterChange}
                        >
                          {filter.select?.map((option) => (
                            <FormControlLabel
                              key={option.accessor as string}
                              value={option.accessor as string}
                              control={<Radio size="small" />}
                              label={option.label}
                              checked={
                                selectFilters[option.accessor as string] ||
                                false
                              }
                            />
                          ))}
                        </RadioGroup>
                        <Divider sx={{ mb: 0.5, mt: 0.5 }} />
                      </Box>
                    ))}
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Box>
      <Box display="flex" justifyContent="flex-end" sx={{ flex: "1" }}></Box>
    </Toolbar>
  );
}
