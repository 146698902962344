import { NuvelAutoForm } from "components/nuvel/forms";
import {
  RETAGUARDA_CONTABANCARIA_FORM,
  RETAGUARDA_CONTABANCARIA_HOOKS,
} from "constants/retaguarda/financeiro/conta_bancaria";
import { ContaBancariaModel } from "data/models/financeiro";
import React from "react";

const RetContaBancariaForm: React.FC = () => {
  return (
    <NuvelAutoForm
      model={ContaBancariaModel}
      fields={RETAGUARDA_CONTABANCARIA_FORM}
      hooks={RETAGUARDA_CONTABANCARIA_HOOKS}
    />
  );
};

export default RetContaBancariaForm;
