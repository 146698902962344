import { RouteInterface } from "utils/router";
// import Home from "./views/home";
import CatalogoLayout from ".";

// const CatalogoRoutesWithLayout: RouteInterface[] = [
//   {
//     path: "/",
//     element: <Home />,
//   },
// ];

// const CatalogoRoutesWithProps = RouterGenerator(CatalogoRoutesWithLayout);

const CatalogoRoutes: RouteInterface[] = [
  {
    path: "/",
    element: <CatalogoLayout />,
  },
];

export default CatalogoRoutes;
