import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Home, ShoppingCart } from "@mui/icons-material";
import { Badge } from "@mui/material";
import { useCatalog, useCatalogActions } from "../../context";
import { useEffect } from "react";

interface BottomNavigationProps {
  value: number;
  setValue: (value: number) => void;
}

export default function SimpleBottomNavigation({
  value,
  setValue,
}: BottomNavigationProps) {
  const { state } = useCatalog();
  const hasItems = state.cartItemsCount > 0;
  const { setActiveWindow } = useCatalogActions();

  useEffect(() => {
    if (value === 0) {
      setActiveWindow("catalog");
    }
    if (value === 1) {
      setActiveWindow("cart");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (state.activeWindow === "catalog" && value !== 0) {
      setValue(0);
    }
    if (state.activeWindow === "cart" && value !== 1) {
      setValue(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeWindow]);

  return (
    <Box sx={{ width: "100%" }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Início" icon={<Home />} />
        {/* <BottomNavigationAction label="Pedidos" icon={<Bookmark />} /> */}
        <BottomNavigationAction
          label="Carrinho"
          icon={
            <Badge
              badgeContent={state.cartItemsCount}
              color="primary"
              sx={{
                "& .MuiBadge-badge": {
                  right: -3,
                  top: 3,
                },
              }}
            >
              <ShoppingCart
                sx={{
                  color: hasItems ? "primary.main" : "inherit",
                }}
              />
            </Badge>
          }
        />
      </BottomNavigation>
    </Box>
  );
}
