import { NuvelDefaultList } from "components/nuvel/lists";
import {
  RETAGUARDA_UNIDADE_FILTERS,
  RETAGUARDA_UNIDADE_LIST,
  RETAGUARDA_UNIDADE_ORDERING,
} from "constants/retaguarda/cadastro/unidades";
import { UnidadeModel } from "data/models";
import React from "react";

const RetUnidadesList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={UnidadeModel}
      columns={RETAGUARDA_UNIDADE_LIST}
      filters={RETAGUARDA_UNIDADE_FILTERS}
      ordering={RETAGUARDA_UNIDADE_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetUnidadesList;
