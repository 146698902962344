import { Box, Paper, Typography } from "@mui/material";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelGrid from "components/nuvel/Grid";
import NuvelModal from "components/nuvel/Modal";
import NuvelSelect from "components/nuvel/Select";
import NuvelTextField from "components/nuvel/TextField";
import { ContaBancariaModel } from "data/models";
import { useAppContext } from "hooks";
import React, { useState } from "react";
import { handleChange } from "utils/functions";

interface ModalProps {
  modal: {
    id: number;
    open: boolean;
  };
  id: number;
  handleModal: (id: number) => void;
  refreshListFn: () => void;
}

const defaultState = {
  conta_bancaria_entrada: undefined,
  valor: 0,
  saldo_disponivel_entrada: 0,
  saldo_novo: 0,
  operacao: 1,
};

interface InformationBoxProps {
  color: "success" | "error";
  value: string;
  label: string;
}

const InformationBox = (props: InformationBoxProps) => (
  <Paper
    elevation={0}
    sx={{
      padding: 2,
      backgroundColor: (theme) => theme.palette[props.color].dark,
    }}
  >
    <Box>
      <Box>
        <Typography color="white">{props.label}</Typography>
      </Box>
      <Box>
        <Typography color="white">{props.value}</Typography>
      </Box>
    </Box>
  </Paper>
);

const ContaBancariaAjusteModal: React.FC<ModalProps> = ({
  modal,
  id,
  handleModal,
  refreshListFn,
}) => {
  const { showSnack } = useAppContext();
  const [state, setState] = useState<{
    conta_bancaria_entrada: undefined | number | string;
    valor: number;
    saldo_disponivel_entrada: number;
    saldo_novo: number;
    operacao: number;
  }>(defaultState);

  const resetState = () => {
    setState(defaultState);
  };

  const handleSave = () => {
    ContaBancariaModel.action("post", "ajuste_saldo", {
      conta_bancaria_entrada: state.conta_bancaria_entrada,
      valor: state.operacao === 2 ? state.valor * -1 : state.valor,
    })
      .then(() => {
        showSnack("Operação realizada com sucesso", 2, "success");
        handleModal(id);
        resetState();
        refreshListFn();
      })
      .catch(() => {
        handleModal(id);
        resetState();
      });
  };

  return (
    <NuvelModal
      open={id === modal.id ? modal.open : false}
      title="Ajuste de Saldo"
      btnSave={handleSave}
      btnCancel={() => {
        handleModal(id);
        resetState();
      }}
      maxWidth="sm"
      fullWidth
    >
      <NuvelGrid container spacing={1}>
        <NuvelGrid size={{ xs: 12 }}>
          <NuvelSelect
            label="Operação"
            options={[
              {
                label: "Depósito",
                value: 1,
              },
              {
                label: "Retirada",
                value: 2,
              },
            ]}
            value={state.operacao}
            name="operacao"
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <NuvelAutocomplete
            label="Destino"
            name="conta_bancaria_entrada"
            reference="conta_bancaria"
            onChange={(e, _, full) => {
              setState((_v) => ({
                ..._v,
                conta_bancaria_entrada: e.target.value,
                saldo_disponivel_entrada: full?.saldo_atual || 0,
              }));
            }}
            value={state.conta_bancaria_entrada}
            helperText={
              state.saldo_disponivel_entrada
                ? `Saldo na conta: ${state.saldo_disponivel_entrada.toBRL()}`
                : undefined
            }
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <NuvelTextField
            label="Valor"
            value={state.valor}
            name="valor"
            onChange={(e) => handleChange(e, setState)}
            money
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <Typography variant="h6" textAlign="center">
            Resultado da Operação
          </Typography>
        </NuvelGrid>
        <NuvelGrid size={{ xs: 6 }}>
          <InformationBox
            color="error"
            label="Saldo Anterior"
            value={Number(state.saldo_disponivel_entrada).toBRL()}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 6 }}>
          <InformationBox
            color="success"
            label="Atual"
            value={(
              Number(state.saldo_disponivel_entrada) +
              (state.operacao === 2
                ? Number(state.valor) * -1
                : Number(state.valor))
            ).toBRL()}
          />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelModal>
  );
};

export default ContaBancariaAjusteModal;
