import { TableColumn, TableFilter } from "components/nuvel/lists";
import { ProdutoListSerializer } from "data/interfaces/estoque/ProdutoListSerializer";

export const RETAGUARDA_PRODUTOS_LIST: TableColumn<ProdutoListSerializer>[] = [
  {
    label: "Código",
    accessor: "codigo",
    minWidth: 50,
  },
  {
    label: "Nome",
    accessor: "nome",
    minWidth: 250,
  },
];

export const RETAGUARDA_PRODUTOS_ORDERING: (keyof ProdutoListSerializer)[] = [
  "codigo",
  "nome",
];

export const RETAGUARDA_PRODUTOS_FILTERS: TableFilter<ProdutoListSerializer>[] =
  [
    {
      label: "Código",
      accessor: "codigo",
      support: ["icontains"],
    },
    {
      label: "Nome",
      accessor: "nome",
      support: ["icontains"],
    },
  ];

export const ETIQUETAS = [
  "ETIQUETA SIMPLES",
  "ETIQUETA SIMPLES 3x4",
  "ETIQUETA SIMPLES 3x4 (3 COLUNAS)",
  "ETIQUETA SIMPLES 10x3",
  "ETIQUETA SIMPLES 11x4",
  "ETIQUETA ATACADO",
  "ETIQUETA JOIA",
];
