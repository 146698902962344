/* eslint-disable @typescript-eslint/no-explicit-any */
import { Unarchive } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import LegacySimpleTable from "components/legacy/legacy_simple_list";
import NuvelAccordion from "components/nuvel/Accordion";
import NuvelCheckbox from "components/nuvel/Checkbox";
import NuvelGrid from "components/nuvel/Grid";
import NuvelModal from "components/nuvel/Modal";
import NuvelSelect from "components/nuvel/Select";
import NuvelTextField from "components/nuvel/TextField";
import { ProdutoSerializer } from "data/interfaces/estoque/ProdutoSerializer";
import { CodBarraModel, ProdutoModel } from "data/models";
import React from "react";
import { handleChange } from "utils/functions";

interface TabEstoqueProps {
  state: ProdutoSerializer;
  setState: React.Dispatch<React.SetStateAction<ProdutoSerializer>>;
}

const TabEstoque: React.FC<TabEstoqueProps> = ({ state, setState }) => {
  const { data: codbarras, mutate: refreshCodBarras } = CodBarraModel.useModel({
    produto: state.id,
  });

  const [dialog, setDialog] = React.useState({
    open: false,
    estoque: null,
    deposito: null,
  });

  const [dialogState, setDialogState] = React.useState({
    movimento: 0,
    quantidade: "0",
    transferencia: false,
    estoque_relacionado: "",
  });

  function formatNumber(value: number) {
    return value % 1 === 0 ? value.toFixed(0) : value.toFixed(2);
  }

  function getStockValue() {
    const estoques = getCodBarEstoques();
    return {
      atual: formatNumber(estoques.sumBy("quantidade")),
      saidas: formatNumber(estoques.sumBy("total_saida")),
      entradas: formatNumber(estoques.sumBy("total_entrada")),
    };
  }

  function getCodBarEstoques() {
    let estoques: any = [];
    if (!state.id) {
      return [];
    }
    codbarras?.rows.forEach((a: any) => {
      estoques = estoques.concat(
        a.estoques.map((b: any) => ({ ...b, codbarra: a.cod_barra })) || []
      );
    });
    return estoques;
  }

  const codBarEstoques = getCodBarEstoques();

  const EstoqueCard = ({ title, value }: any) => (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          padding: theme.spacing(1),
        })}
      ></CardHeader>
      <CardContent>
        <Typography variant="body1" component="p">
          {title}
        </Typography>
        <Typography color="textSecondary">{value}</Typography>
      </CardContent>
    </Card>
  );

  return (
    <NuvelGrid container spacing={1}>
      <NuvelGrid xs={12} md={4}>
        <NuvelTextField
          decimal
          label="Estoque Mínimo"
          onChange={(e) => handleChange(e, setState)}
          name="estoque_minimo"
          value={state.estoque_minimo}
        />
      </NuvelGrid>
      <NuvelGrid
        xs={12}
        md={3}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NuvelCheckbox
          checked={state.perecivel}
          name="perecivel"
          onChange={(e) => handleChange(e, setState)}
          label="Produto perecível"
        />
      </NuvelGrid>
      {state.perecivel && (
        <NuvelGrid xs={12} md={3}>
          <NuvelTextField
            label="Dias de validade"
            onChange={(e) => handleChange(e, setState)}
            name="perecivel_dias"
            value={state.perecivel_dias}
            type="number"
          />
        </NuvelGrid>
      )}
      <NuvelGrid xs={12} md={12}>
        <NuvelAccordion title="Estoque Geral" startExpanded>
          <NuvelGrid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <NuvelGrid xs={12} md={3}>
              <EstoqueCard
                title="Estoque Atual"
                value={getStockValue().atual}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={3}>
              <EstoqueCard title="Entradas" value={getStockValue().entradas} />
            </NuvelGrid>
            <NuvelGrid xs={12} md={3}>
              <EstoqueCard title="Saídas" value={getStockValue().saidas} />
            </NuvelGrid>
            <NuvelGrid xs={12} md={3}>
              <EstoqueCard
                title="Estoque Inicial"
                value={Number(0).toDecimal(0)}
              />
            </NuvelGrid>
          </NuvelGrid>
        </NuvelAccordion>
      </NuvelGrid>
      <NuvelGrid xs={12} md={12}>
        <NuvelAccordion title="Depósitos">
          <LegacySimpleTable
            style={{
              width: "100%",
            }}
            columns={[
              { accessor: "deposito", label: "Depósito" },
              { accessor: "codbarra", label: "Cod. Barra" },
              { accessor: "quantidade", label: "Quantidade" },
              {
                accessor: "actions",
                label: "",
                style: { display: "flex", justifyContent: "flex-end" },
              },
            ]}
            rows={
              !state.id
                ? []
                : codBarEstoques.map((est: any) => ({
                    ...est,
                    actions: (
                      <>
                        <IconButton
                          sx={(theme) => ({
                            background: theme.palette.primary.main,
                            borderRadius: theme.spacing(2),
                            color: theme.palette.background.paper,

                            "&:hover": {
                              background: theme.palette.primary.dark,
                            },
                          })}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDialog((_v) => ({
                              ..._v,
                              open: true,
                              estoque: est.id,
                              deposito: est.deposito,
                            }));

                            setDialogState((_v) => ({
                              ..._v,
                            }));
                          }}
                        >
                          <Unarchive />
                        </IconButton>
                      </>
                    ),
                  }))
            }
            onRowClick={() => null}
          />
        </NuvelAccordion>
      </NuvelGrid>
      <NuvelModal
        open={dialog.open}
        title="Ajuste estoque"
        btnCancel={() => {
          setDialog((_v) => ({
            ..._v,
            open: false,
            estoque: null,
          }));
          setDialogState((_v) => ({
            ..._v,
            movimento: 0,
            quantidade: "0",
            estoque_relacionado: "",
          }));
        }}
        btnSave={() => {
          ProdutoModel.action("post", "ajustar_estoque", {
            ...dialogState,
            quantidade: Number(dialogState.quantidade),
            estoque: dialog.estoque,
            estoque_relacionado: dialogState.estoque_relacionado,
          }).then(() => {
            setDialog((_v) => ({
              ..._v,
              open: false,
              estoque: null,
            }));
            setDialogState((_v) => ({
              ..._v,
              movimento: 0,
              quantidade: "0",
              estoque_relacionado: "",
            }));
            refreshCodBarras();
          });
        }}
      >
        <NuvelGrid container spacing={2}>
          <NuvelGrid xs={12} md={12}>
            <Typography
              variant="body1"
              style={{ fontWeight: "bold" }}
              color="primary"
            >
              Ajustando o depósito: {dialog.deposito}
            </Typography>
          </NuvelGrid>
          <NuvelGrid xs={12} md={12}>
            <NuvelSelect
              label="Movimento"
              name="movimento"
              onChange={(e) => handleChange(e, setDialogState)}
              value={dialogState.movimento}
              options={[
                { label: "Ajuste", value: 0 },
                { label: "Entrada", value: 1 },
                { label: "Saída", value: 2 },
                { label: "Transferencia", value: 3 },
              ]}
            />
          </NuvelGrid>
          <NuvelGrid xs={12} md={12}>
            <NuvelTextField
              label="Quantidade"
              name="quantidade"
              type="number"
              onChange={(e) => handleChange(e, setDialogState)}
              value={dialogState.quantidade}
            />
          </NuvelGrid>
          {dialogState.movimento === 3 ? (
            <NuvelGrid xs={12} md={12}>
              <NuvelSelect
                label="Estoque Destino"
                onChange={(e) => handleChange(e, setDialogState)}
                name="estoque_relacionado"
                value={state.status}
                options={
                  codBarEstoques.map((a: any) => ({
                    label: a.codbarra + " - " + a.deposito,
                    value: a.id,
                  })) || []
                }
              />
            </NuvelGrid>
          ) : (
            ""
          )}
        </NuvelGrid>
      </NuvelModal>
    </NuvelGrid>
  );
};

export default TabEstoque;
