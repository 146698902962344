import { Button } from "@mui/material";
import NuvelGrid from "components/nuvel/Grid";
import NuvelSelect from "components/nuvel/Select";
import {
  EtiquetaAtacado,
  EtiquetaJoia,
  EtiquetaSimples,
} from "components/retaguarda/reports";
import { ETIQUETAS } from "constants/retaguarda/cadastro/produtos";
import { ProdutoSerializer } from "data/interfaces/estoque/ProdutoSerializer";
import React from "react";
import { handleChange } from "utils/functions";
import { imprimirDoc } from "utils/functions/print";

interface TabEtiquetasProps {
  state: ProdutoSerializer;
  setState: React.Dispatch<React.SetStateAction<ProdutoSerializer>>;
}

const TabEtiquetas: React.FC<TabEtiquetasProps> = ({ state }) => {
  const [localstate, setLocalState] = React.useState({
    formatoSelecionado: "ETIQUETA SIMPLES",
    codBarrasSelecionado: null,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function imprimirEtiquetas(e: any) {
    e.preventDefault();
    switch (localstate.formatoSelecionado) {
      case "ETIQUETA SIMPLES":
        imprimirDoc(
          <EtiquetaSimples
            prods={[
              {
                cod_barra: String(localstate.codBarrasSelecionado),
                preco_venda: state.preco_venda,
                produto_nome: state.nome,
              },
            ]}
          />
        );
        break;
      case "ETIQUETA SIMPLES 3x4":
        imprimirDoc(
          <EtiquetaSimples
            prods={[
              {
                cod_barra: String(localstate.codBarrasSelecionado),
                preco_venda: state.preco_venda,
                produto_nome: state.nome,
              },
            ]}
            altura={125}
            largura={95}
            wrap="wrap"
            maxHeight={80}
            cod_barras_size={45}
            cod_barras_height={90}
          />
        );
        break;
      case "ETIQUETA SIMPLES 10x3":
        imprimirDoc(
          <EtiquetaSimples
            prods={[
              {
                cod_barra: String(localstate.codBarrasSelecionado),
                preco_venda: state.preco_venda,
                produto_nome: state.nome,
              },
            ]}
            altura={94}
            largura={350}
            horizontal
          />
        );
        break;
      case "ETIQUETA SIMPLES 11x4":
        imprimirDoc(
          <EtiquetaSimples
            prods={[
              {
                cod_barra: String(localstate.codBarrasSelecionado),
                preco_venda: state.preco_venda,
                produto_nome: state.nome,
              },
            ]}
            altura={125}
            largura={385}
            horizontal
          />
        );
        break;
      case "ETIQUETA ATACADO":
        imprimirDoc(
          <EtiquetaAtacado
            prods={[
              {
                cod_barra: String(localstate.codBarrasSelecionado),
                preco_venda: state.preco_venda,
                produto_nome: state.nome,
              },
            ]}
          />
        );
        break;
      case "ETIQUETA JOIA":
        imprimirDoc(
          <EtiquetaJoia
            prods={[
              {
                cod_barra: String(localstate.codBarrasSelecionado),
                preco_venda: state.preco_venda,
                produto_nome: state.nome,
              },
            ]}
          />
        );
        break;
      default:
        break;
    }
  }

  return (
    <NuvelGrid container spacing={1}>
      <NuvelGrid xs={12} md={5}>
        <NuvelSelect
          label="Formato de Etiqueta"
          name="formatoSelecionado"
          value={localstate.formatoSelecionado}
          onChange={(e) => handleChange(e, setLocalState)}
          options={ETIQUETAS.map((i) => ({
            label: i,
            value: i,
          }))}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={4}>
        <NuvelSelect
          label="Código de barras"
          name="codBarrasSelecionado"
          value={localstate.codBarrasSelecionado}
          onChange={(e) => handleChange(e, setLocalState)}
          options={state.codbarras.map((c) => ({
            label: c.cod_barra,
            value: c.cod_barra,
          }))}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={3}>
        <Button
          onClick={imprimirEtiquetas}
          fullWidth
          style={{ height: "100%" }}
          variant="contained"
        >
          {" "}
          Imprimir Etiquetas{" "}
        </Button>
      </NuvelGrid>
    </NuvelGrid>
  );
};

export default TabEtiquetas;
