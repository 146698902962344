/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  BaseSelectProps,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
} from "@mui/material";

export interface SelectOption {
  value: string | number;
  label: string;
  [key: string]: unknown;
}

interface NuvelSelectProps extends BaseSelectProps {
  variant?: "filled" | "outlined" | "standard";
  onChange: (event: SelectChangeEvent<any>) => void;
  label: string;
  size?: "small" | "medium";
  options: SelectOption[];
  name?: string;
  disabled?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  sx?: SxProps;
  style?: React.CSSProperties;
}

const NuvelSelect: React.FC<NuvelSelectProps> = (props) => {
  const {
    variant = "filled",
    onChange,
    label,
    size = "medium",
    options,
    value,
    name,
    disabled,
    multiple,
    fullWidth = true,
    ...rest
  } = props;

  return (
    <FormControl size={size} variant={variant} fullWidth={fullWidth}>
      <InputLabel htmlFor="simple-select">{label}</InputLabel>
      <Select
        label={label}
        multiple={multiple}
        disabled={disabled}
        value={multiple ? value || ([] as Array<string | number>) : value}
        onChange={onChange}
        inputProps={{ name: name }}
        {...rest}
      >
        {options?.map((option, index) =>
          option ? (
            <MenuItem {...option} value={option.value} key={index}>
              {option.label}
            </MenuItem>
          ) : null
        )}
      </Select>
    </FormControl>
  );
};

export default NuvelSelect;
