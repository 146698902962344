import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import PaperWithLoad from "components/nuvel/PaperWithLoad";
import React from "react";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "descricao",
    numeric: false,
    disablePadding: false,
    label: "Descriçao",
  },
  {
    id: "quantidade",
    numeric: true,
    disablePadding: false,
    label: "Quantidade",
  },
  { id: "valor_total", numeric: true, disablePadding: false, label: "Total" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span
                  style={{
                    border: 0,
                    clip: "rect(0 0 0 0)",
                    height: 1,
                    margin: -1,
                    overflow: "hidden",
                    padding: 0,
                    position: "absolute",
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  rows,
  loading,
  quantidade,
  setQuantidade,
}) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("quantidade");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Card component={PaperWithLoad} loading={!rows}>
      <CardHeader subheader="Produtos mais vendidos" />
      <CardContent>
        <div
          style={{
            width: "100%",
          }}
        >
          <TableContainer>
            {rows ? (
              <Table
                sx={{
                  width: "100%",
                }}
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="left">{row.descricao}</TableCell>
                          <TableCell align="right">
                            {row.quantidade.toDecimal(2)}
                          </TableCell>
                          <TableCell align="right">
                            {row.valor_total.toBRL()}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            ) : null}
          </TableContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: 10,
            }}
          >
            <Button
              onClick={() => setQuantidade(quantidade + 5)}
              disabled={loading}
            >
              Mostrar tudo
              {/* Carregar Mais... */}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
