import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelGrid from "components/nuvel/Grid";
import NuvelTextField from "components/nuvel/TextField";
import { ProdutoSerializer } from "data/interfaces/estoque/ProdutoSerializer";
import React from "react";
import { handleChange } from "utils/functions";

// import { Container } from './styles';

interface TabImpostoProps {
  state: ProdutoSerializer;
  setState: React.Dispatch<React.SetStateAction<ProdutoSerializer>>;
}

const TabImposto: React.FC<TabImpostoProps> = ({ state, setState }) => {
  return (
    <NuvelGrid container spacing={2}>
      <NuvelGrid xs={12} md={4}>
        <NuvelTextField
          label="NCM"
          onChange={(e) => handleChange(e, setState)}
          name="ncm"
          value={state.ncm}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={4}>
        <NuvelTextField
          label="CEST"
          onChange={(e) => handleChange(e, setState)}
          name="cest"
          value={state.cest}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={4}>
        <NuvelTextField
          label="Cód. Benefício Fiscal"
          onChange={(e) => handleChange(e, setState)}
          name="codigo_beneficiario"
          value={state.codigo_beneficiario}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={5}>
        <NuvelAutocomplete
          label="Imposto"
          name="imposto"
          value={state.imposto}
          onChange={(e) => handleChange(e, setState)}
          reference="imposto"
        />
      </NuvelGrid>
    </NuvelGrid>
  );
};

export default TabImposto;
