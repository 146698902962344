import IframeWithToken from "components/nuvel/IframeWithToken";
import React from "react";

const RetNotaSaidaForm: React.FC = () => {
  const baseUrl =
    import.meta.env.MODE === "development"
      ? "http://localhost:3000/"
      : "https://legacy.nuvel.com.br/";

  return (
    <IframeWithToken
      token={localStorage.getItem("token") || ""}
      iframeUrlForToken={`${baseUrl}/set-token`}
      iframeUrlRaw={`/nota-de-saida`}
    />
  );
};

export default RetNotaSaidaForm;
