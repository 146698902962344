import OldAutocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";

export function AutocompleteRender({ ...props }) {
  return (
    <>
      <OldAutocomplete
        {...props}
        id={`combo-box-${props.id}`}
        closeIcon={<CloseIcon onClick={props.onDelete} />}
        options={props.options || []}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={props.label}
            name={props.name}
            variant={props.variant ? props.variant : "outlined"}
            onChange={() => {}} // eslint-disable-line
          />
        )}
      />
    </>
  );
}
