import { Paper, useTheme } from "@mui/material";

export interface CardProps {
  title: string;
  value: string;
  noIndicator?: boolean;
}

export default function CardContasAReceber(props: CardProps) {
  const { title, value, noIndicator } = props;
  const { palette } = useTheme();

  return (
    <Paper
      elevation={3}
      style={{
        borderTop: `10px solid ${palette.primary.main}`,
        padding: "4px 8px",
      }}
    >
      <span
        style={{
          color: palette.primary.main,
          fontWeight: 400,
        }}
      >
        {title}
      </span>
      <div style={{ display: "flex" }}>
        {noIndicator ? null : (
          <span
            style={{
              color: palette.primary.main,
              fontWeight: 900,
              flexGrow: 1,
            }}
          >
            R$
          </span>
        )}
        <span
          style={{
            fontWeight: 500,
          }}
        >
          {value.toBRL().replace("R$", "")}
        </span>
      </div>
    </Paper>
  );
}
