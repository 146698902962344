import { TableColumn, TableFilter } from "components/nuvel/lists";
import { PromocaoSerializer } from "data/interfaces/estoque/PromocaoSerializer";

export const RETAGUARDA_PROMOCAO_LIST: TableColumn<PromocaoSerializer>[] = [
  {
    label: "Descrição",
    accessor: "descricao",
    minWidth: 300,
  },
];

export const RETAGUARDA_PROMOCAO_ORDERING: (keyof PromocaoSerializer)[] = [
  "descricao",
];

export const RETAGUARDA_PROMOCAO_FILTERS: TableFilter<PromocaoSerializer>[] = [
  {
    label: "Descrição",
    accessor: "descricao",
    support: ["icontains"],
  },
];
