import { NuvelAutoForm } from "components/nuvel/forms";
import { RETAGUARDA_DEPOSITO_FORM } from "constants/retaguarda/estoque/deposito";
import { DepositoModel } from "data/models";
import React from "react";

const RetDepositoForm: React.FC = () => {
  return (
    <NuvelAutoForm fields={RETAGUARDA_DEPOSITO_FORM} model={DepositoModel} />
  );
};

export default RetDepositoForm;
