import { useMemo } from "react";
import { createBrowserRouter } from "react-router-dom";
import RoutesObject from "utils/router/main";
import CatalogoRoutes from "layouts/catalogo/router";

export const useGenerateRoutes = () => {
  const router = useMemo(() => {
    const hostname = window.location.hostname;
    const isCatalogoSubdomain = hostname.startsWith("catalogo.");
    const isDev = import.meta.env.DEV;
    const forceSubdomain = import.meta.env.VITE_FORCE_SUBDOMAIN;

    if (isCatalogoSubdomain || (isDev && forceSubdomain === "catalogo")) {
      return createBrowserRouter(CatalogoRoutes);
    }

    return createBrowserRouter(RoutesObject);
  }, []);

  return router;
};
