import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_IMPOSTO_FILTERS,
  RETAGUARDA_IMPOSTO_LIST,
  RETAGUARDA_IMPOSTO_ORDERING,
} from "constants/retaguarda/fiscal/imposto";
import { ImpostoModel } from "data/models/financeiro";
import React from "react";

const RetImpostosList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={ImpostoModel}
      columns={RETAGUARDA_IMPOSTO_LIST}
      filters={RETAGUARDA_IMPOSTO_FILTERS}
      ordering={RETAGUARDA_IMPOSTO_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetImpostosList;
