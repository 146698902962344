import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_GRUPODEPERMISSOES_FILTERS,
  RETAGUARDA_GRUPODEPERMISSOES_LIST,
  RETAGUARDA_GRUPODEPERMISSOES_ORDERING,
} from "constants/retaguarda/configuracoes/grupo_de_permissoes";

import { AcessoModel } from "data/models";
import React from "react";

const RetGrupoDePermissoesList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={AcessoModel}
      columns={RETAGUARDA_GRUPODEPERMISSOES_LIST}
      filters={RETAGUARDA_GRUPODEPERMISSOES_FILTERS}
      ordering={RETAGUARDA_GRUPODEPERMISSOES_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetGrupoDePermissoesList;
