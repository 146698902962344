import { NuvelDefaultList } from "components/nuvel/lists";
import {
  RETAGUARDA_METADEVENDA_FILTERS,
  RETAGUARDA_METADEVENDA_LIST,
  RETAGUARDA_METADEVENDA_ORDERING,
} from "constants/retaguarda/financeiro/meta_de_venda";
import { MetaDeVendaModel } from "data/models/financeiro";
import React from "react";

const RetMetasDeVendaList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={MetaDeVendaModel}
      columns={RETAGUARDA_METADEVENDA_LIST}
      filters={RETAGUARDA_METADEVENDA_FILTERS}
      ordering={RETAGUARDA_METADEVENDA_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetMetasDeVendaList;
