import { NuvelAutoForm } from "components/nuvel/forms";
import { RETAGUARDA_DOWNLOADXML_FORM } from "constants/retaguarda/fiscal/download_xml";
import { DownloadXMLModel } from "data/models";
import React from "react";

const RetDownloadXMLForm: React.FC = () => {
  return (
    <NuvelAutoForm
      fields={RETAGUARDA_DOWNLOADXML_FORM}
      model={DownloadXMLModel}
    />
  );
};

export default RetDownloadXMLForm;
