import React from "react";
import { TotalCard } from "../index.jsx";
import NuvelGrid from "components/nuvel/Grid";

// import { Container } from './styles';

function CardList({ totalPeriodo }) {
  return (
    <>
      <NuvelGrid xs={12} sm={6} lg={2}>
        <TotalCard
          title="Total Vendido"
          valor={totalPeriodo?.total_vendido}
          porcentagem={totalPeriodo?.pct_vendas}
          desc={"Total de vendas no período"}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} sm={6} lg={2}>
        <TotalCard
          title="Ticket Médio"
          valor={totalPeriodo?.tck_medio}
          porcentagem={totalPeriodo?.pct_tck_medio}
          desc={"Valor total de vendas dividido pelo número de vendas"}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} sm={6} lg={2}>
        <TotalCard
          title="Ticket Médio Produto"
          valor={totalPeriodo?.tck_medio_produto}
          porcentagem={totalPeriodo?.pct_tck_medio_produto}
          desc={
            "Valor de vendas considerando o total de produtos, dividido pelo número de vendas (Não considerando taxas e descontos."
          }
        />
      </NuvelGrid>
      <NuvelGrid xs={12} sm={6} lg={2}>
        <TotalCard
          title="Itens por Venda"
          valor={totalPeriodo?.q_itens}
          porcentagem={totalPeriodo?.pct_q_itens}
          quantidade={true}
          desc={"Média de itens vendidos em uma venda"}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} sm={6} lg={2}>
        <TotalCard
          title="Descontos"
          valor={totalPeriodo?.total_descontos}
          porcentagem={totalPeriodo?.pct_descontos}
          desc={"Total de descontos no período"}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} sm={6} lg={2}>
        <TotalCard
          title="Pedidos"
          valor={totalPeriodo?.pedidos}
          quantidade={true}
          desc={"Contagem total de vendas no período"}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} sm={6} lg={4}>
        <TotalCard
          title="Custo total"
          valor={totalPeriodo?.custo_total}
          desc={"Total de despesas no período"}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} sm={6} lg={4}>
        <TotalCard
          title="Total de Custo /  Faturamento "
          valor={totalPeriodo?.custo_dividido_faturamento}
          percent={true}
          desc={"Custo da Mercadoria Vendida (Custo/Faturamento)"}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} sm={6} lg={4}>
        <TotalCard
          title="Lucratividade"
          valor={totalPeriodo?.lucratividade}
          percent={true}
          desc={"Valor faturado (100%) - (Custo/faturamento)"}
        />
      </NuvelGrid>
    </>
  );
}

export default CardList;
