import { TableColumn, TableFilter } from "components/nuvel/lists";
import { AjusteDeEstoqueSerializer } from "data/interfaces/estoque/AjusteDeEstoqueSerializer";

export const RETAGUARDA_AJUSTEDEESTOQUE_LIST: TableColumn<AjusteDeEstoqueSerializer>[] =
  [
    {
      label: "Descrição",
      accessor: "descricao",
      minWidth: 300,
    },
  ];

export const RETAGUARDA_AJUSTEDEESTOQUE_ORDERING: (keyof AjusteDeEstoqueSerializer)[] =
  ["descricao"];

export const RETAGUARDA_AJUSTEDEESTOQUE_FILTERS: TableFilter<AjusteDeEstoqueSerializer>[] =
  [
    {
      label: "Descrição",
      accessor: "descricao",
      support: ["icontains"],
    },
  ];
