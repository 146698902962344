import React from "react";
import { useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DrawerHeader, useDrawerState, createLinks } from "./sharedFunctions";
import { RouteInterface } from "utils/router";
import { Divider, List } from "@mui/material";
import useWindowSize from "hooks/useWindowSize";

const MobileDrawer: React.FC<{
  open: boolean;
  handleDrawerClose: () => void;
  routes: RouteInterface[];
}> = ({ open, handleDrawerClose, routes }) => {
  const theme = useTheme();
  const { state, changeState, anchorEls, setAnchor } = useDrawerState(
    routes,
    open
  );
  const [width] = useWindowSize();

  return (
    <MuiDrawer
      variant="temporary"
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
      PaperProps={{
        sx: {
          width: width / 1.5,
        },
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List disablePadding>
        {createLinks(
          routes,
          state,
          changeState,
          open,
          false,
          false,
          anchorEls,
          setAnchor
        )}
      </List>
    </MuiDrawer>
  );
};

export default MobileDrawer;
