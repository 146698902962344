import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Typography, useTheme } from "@mui/material";
import { generateRandomColor } from "utils/functions/generateRandomColor";
import PaperWithLoad from "components/nuvel/PaperWithLoad";
import NuvelGrid from "components/nuvel/Grid";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      titleFont: {
        size: 15,
      },
      bodyFont: {
        size: 15,
      },
    },
    legend: {
      display: false,
      position: "top",
    },
    labels: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  elements: {
    point: {
      hoverRadius: 10,
      radius: 10,
    },
  },
  scales: {
    x: {
      display: false, // Hide x-axis labels
    },
    y: {
      display: true, // Show y-axis labels
      beginAtZero: true, // Optionally, begin the axis at zero
      maxTicksLimit: 5, // Optionally, limit the number of ticks displayed
      callback: function (value) {
        return `${value.toBRL()}`;
      },
    },
  },
};

export default function VendasPorLoja({ ...props }) {
  const { data } = props;
  const theme = useTheme();

  const temp_data = data?.data;

  const chartData = {
    labels: [],
    datasets: [
      {
        label: "Vendas",
        data: [],
        borderColor: theme.palette.primary.main, // Set a specific color for the line
        backgroundColor: [], // Background color for points
        pointBorderColor: [], // Border color for points
        pointBackgroundColor: [], // Background color for points
      },
    ],
  };

  if (temp_data?.length !== 0) {
    temp_data?.forEach((entry) => {
      const label = Object.keys(entry)[1];
      chartData.labels.push(label);
      chartData.datasets[0].data.push(entry[label]);

      const pointBorderColor = generateRandomColor(theme.palette.primary.main);

      chartData.datasets[0].backgroundColor.push(pointBorderColor);
      chartData.datasets[0].pointBackgroundColor.push(pointBorderColor);
      chartData.datasets[0].pointBorderColor.push(theme.palette.primary.main);
    });
  }

  return (
    <PaperWithLoad loading={!data} style={{ width: "100%" }}>
      <NuvelGrid container>
        <NuvelGrid
          style={{
            width: "100%",
            height: "3em",
            textAlign: "center",
          }}
          xs={12}
        >
          <Typography variant="h5" component="h2">
            Vendas por loja
          </Typography>
        </NuvelGrid>
        <NuvelGrid style={{ width: "100%", height: "21em" }} xs={12}>
          <Line options={{ ...options }} data={chartData ? chartData : null} />
        </NuvelGrid>
      </NuvelGrid>
    </PaperWithLoad>
  );
}
