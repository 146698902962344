import { NuvelAutoForm } from "components/nuvel/forms";
import { RETAGUARDA_CERTIFICADO_FORM } from "constants/retaguarda/fiscal/certificado";
import { CertificadoModel } from "data/models";
import React from "react";

const RetCertificadosForm: React.FC = () => {
  return (
    <NuvelAutoForm
      fields={RETAGUARDA_CERTIFICADO_FORM}
      model={CertificadoModel}
    />
  );
};

export default RetCertificadosForm;
