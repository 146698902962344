import { Product, useCatalogActions } from "../../context";
import { useTheme } from "@mui/material";
import { useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Box,
  Skeleton,
  CardMedia,
  Typography,
} from "@mui/material";
import PlaceholderImage from "../PlaceholderImage";

const ProductCard: React.FC<{
  product: Product;
  setSelectedProduct: (product: Product) => void;
}> = ({ product, setSelectedProduct }) => {
  const theme = useTheme();
  const [imageLoading, setImageLoading] = useState(true);
  const { getProductQuantity } = useCatalogActions();
  const productQuantity = getProductQuantity(product.id);
  return (
    <Card sx={{ position: "relative" }}>
      <CardActionArea onClick={() => setSelectedProduct(product)}>
        {productQuantity >= 1 && (
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              backgroundColor: theme.palette.primary.main,
              color: "white",
              borderRadius: 180,
              height: 30,
              width: 30,
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {productQuantity}
          </Box>
        )}
        <Box display="flex" flexDirection="row" height="100%">
          <Box width={theme.spacing(10)} height={theme.spacing(11.5)}>
            {imageLoading && (
              <Skeleton
                variant="rectangular"
                width={theme.spacing(10)}
                height={theme.spacing(11.5)}
                animation="wave"
                sx={{ position: "absolute" }}
              />
            )}
            {product.image ? (
              <CardMedia
                component="img"
                height={theme.spacing(11.5)}
                image={product.image}
                onLoad={() => setImageLoading(false)}
                sx={{ display: imageLoading ? "none" : "block" }}
              />
            ) : (
              <PlaceholderImage
                text={product?.name}
                width={theme.spacing(10)}
                height={theme.spacing(11.5)}
                setImageLoading={setImageLoading}
              />
            )}
          </Box>
          <CardContent>
            <Typography variant="h6">{product.name}</Typography>
            <Typography variant="body2">{product.price.toBRL()}</Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default ProductCard;
