import React from "react";

import Controller, { Permission } from "./components/controller";
import Fields from "./components/fields";
import Table from "./components/table";
import NuvelGrid from "components/nuvel/Grid";
import { useNavigate, useParams } from "react-router-dom";
import { AcessoModel } from "data/models";
import { NuvelRawForm } from "components/nuvel/forms";

const RetGrupoDePermissoesForm: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const buildPermissionsString = (permissions: Permission[]) => {
    const permissionsString: string[] = [];

    const traversePermissions = (nodes: Permission[]) => {
      nodes.forEach((node: Permission) => {
        if (node.value && node.value.length > 0) {
          permissionsString.push(
            `${node.acesso}_${
              typeof node.value === "string" ? node.value : node.value.join("")
            }`
          );
        }
        if (node.children && node.children.length > 0) {
          traversePermissions(node.children);
        }
      });
    };

    traversePermissions(permissions);
    return permissionsString.join(";");
  };

  async function handleSubmit(
    stopLoading: () => void,
    name: string,
    permissions: Permission[]
  ) {
    const permissionsString = buildPermissionsString(permissions);

    if (id === "novo") {
      const isSaved = await AcessoModel.save({
        nome: name,
        acessos: permissionsString,
      });
      stopLoading();
      if (isSaved) return navigate(-1);
    } else {
      const isSaved = await AcessoModel.save({
        id: Number(id),
        nome: name,
        acessos: permissionsString,
      });
      stopLoading();
      if (isSaved) return navigate(-1);
    }
  }

  return (
    <Controller>
      {({
        perms,
        permissions,
        setPermissions,
        name,
        setName,
        tempPermissions,
        setTempPermissions,
      }) => (
        <NuvelRawForm
          onSubmit={(stopLoading) =>
            handleSubmit(stopLoading, name, permissions)
          }
        >
          <NuvelGrid container spacing={1}>
            <Fields
              name={name}
              setName={setName}
              perms={perms}
              permissions={permissions}
              setPermissions={setPermissions}
              tempPermissions={tempPermissions}
              setTempPermissions={setTempPermissions}
            />
            <NuvelGrid xs={12}>
              <Table data={permissions} setPermissions={setPermissions} />
            </NuvelGrid>
          </NuvelGrid>
        </NuvelRawForm>
      )}
    </Controller>
  );
};

export default RetGrupoDePermissoesForm;
