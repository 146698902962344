// import { LojaModel } from "data/models";
import React from "react";
import { NuvelDefaultList } from "components/nuvel/lists";

import { LojaModel } from "data/models";
import {
  RETAGUARDA_LOJA_FILTERS,
  RETAGUARDA_LOJA_LIST,
  RETAGUARDA_LOJA_ORDERING,
} from "constants/retaguarda/cadastro/loja";

const RetLojaList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={LojaModel}
      columns={RETAGUARDA_LOJA_LIST}
      filters={RETAGUARDA_LOJA_FILTERS}
      ordering={RETAGUARDA_LOJA_ORDERING}
      actions={{
        update: true,
        delete: true,
        create: true,
      }}
    />
  );
};

export default RetLojaList;
