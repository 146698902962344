import { DepartamentoSerializer } from "data/interfaces/estoque/DepartamentoSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "estoque/departamento";

class DepartamentoModel extends MainModule<
  DepartamentoSerializer,
  DepartamentoSerializer
> {}

export default new DepartamentoModel(model_url);
