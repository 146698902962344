/* eslint-disable @typescript-eslint/no-unused-vars */
interface String {
  toBRL(min?: number, max?: number): string;
  toDecimal(min?: number): string;
  in(obj?: string[]): boolean;
}

String.prototype.toBRL = function (this: string, min = 2, max = 2): string {
  const formatter = new Intl.NumberFormat([], {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
  return formatter.format(Number(this));
};

String.prototype.in = function (this: string, obj: string[]): boolean {
  return !!~obj.indexOf(this);
};

String.prototype.toDecimal = function (this: string, min = 2): string {
  const formatter = new Intl.NumberFormat([], {
    style: "decimal",
    currency: "BRL",
    minimumFractionDigits: min,
    maximumFractionDigits: 3,
  });
  return formatter.format(Number(this));
};
