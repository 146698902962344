import { CircularProgress, Fade } from "@mui/material";
import { styled } from "@mui/system";

interface LoadingCoverProps {
  enabled: boolean;
}

const CircularDiv = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(0,0,0,0.3)",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  zIndex: 999,
  borderRadius: theme.spacing(1.5),
}));

export default function LoadingCover({ enabled }: LoadingCoverProps) {
  return (
    <Fade in={enabled}>
      <CircularDiv>
        <CircularProgress color="primary" />
      </CircularDiv>
    </Fade>
  );
}
