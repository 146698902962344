import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_ETIQUETAS_FILTERS,
  RETAGUARDA_ETIQUETAS_LIST,
  RETAGUARDA_ETIQUETAS_ORDERING,
} from "constants/retaguarda/cadastro/etiquetas";
import { EtiquetasModel } from "data/models";
import React from "react";

const RetEtiquetasList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={EtiquetasModel}
      columns={RETAGUARDA_ETIQUETAS_LIST}
      filters={RETAGUARDA_ETIQUETAS_FILTERS}
      ordering={RETAGUARDA_ETIQUETAS_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetEtiquetasList;
