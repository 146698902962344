import { TableColumn, TableFilter } from "components/nuvel/lists";
import { FormaSerializer } from "data/interfaces/financeiro/FormaSerializer";

export const formasIds = [
  { label: "Dinheiro", value: 1 },
  { label: "Cheque", value: 2 },
  { label: "Cartão de Crédito", value: 3 },
  { label: "Cartão de Débito", value: 4 },
  { label: "Crédito Loja", value: 5 },
  { label: "Vale Alimentação", value: 10 },
  { label: "Vale Refeição", value: 11 },
  { label: "Vale Presente", value: 12 },
  { label: "Vale Combustível", value: 13 },
  { label: "Boleto Bancário", value: 15 },
  { label: "Depósito Bancário", value: 16 },
  { label: "Pagamento Instantâneo (PIX)", value: 17 },
  { label: "Transferência bancária, Carteira Digital", value: 18 },
  { label: "Programa de fidelidade, Cashback, Crédito Virtual", value: 19 },
  { label: "Outras", value: 99 },
];

export const RETAGUARDA_FORMASDEPAGAMENTO_LIST: TableColumn<FormaSerializer>[] =
  [
    {
      label: "Forma",
      accessor: (row) =>
        formasIds.find((f) => f.value === row.finalizadora)?.label,
      minWidth: 300,
    },
    {
      label: "Descrição",
      accessor: "forma",
      minWidth: 300,
    },
  ];

export const RETAGUARDA_FORMASDEPAGAMENTO_ORDERING: (keyof FormaSerializer)[] =
  ["forma"];

export const RETAGUARDA_FORMASDEPAGAMENTO_FILTERS: TableFilter<FormaSerializer>[] =
  [
    {
      label: "Descrição",
      accessor: "forma",
      support: ["icontains"],
    },
  ];
