import pdf from "@react-pdf/renderer";
import dayjs from "dayjs";
import React from "react";
import { textToBase64Barcode } from "utils/functions/textToBase64Barcode";
import CourierPrimeBold from "../fonts/CourierPrime-Bold.ttf";
import CourierPrime from "../fonts/CourierPrime-Regular.ttf";

const { Page, Text, View, Document, StyleSheet, Font, Image } = pdf;

const largura = 310;
const altura = 140;

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: "-18px",
    fontFamily: "Courier Prime",
    backgroundColor: "#fff",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  body: {
    width: "100%",
    paddingLeft: "10px",
  },
  titulo: {
    fontSize: 14,
    fontWeight: "bold",
    width: "100%",
    textAlign: "left",
    display: "flex",
  },
  shortTitle: {
    fontSize: 10,
    fontWeight: "bold",
    width: "100%",
    textAlign: "left",
    display: "flex",
  },
  preco: {
    fontSize: 18,
    width: "100%",
    textAlign: "left",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
  },
  cifrao_varejo: {
    fontSize: 10,
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
    paddingRight: 3,
    paddingTop: 4,
  },
  barras: {
    textAlign: "center",
    width: "80%",
  },
  group: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  left: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },
  right: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  data: {
    display: "flex",
    flexDirection: "column",
    fontSize: 6,
  },
  rightTop: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#000",
    padding: "5px 10px",
  },
  varejo_titulo: {
    fontSize: 8,
    width: "100%",
  },
  atacado_titulo: {
    color: "#c5e612",
    fontWeight: "bold",
    fontSize: 8,
  },
  preco_atacado: {
    fontSize: 18,
    color: "#c5e612",
    display: "flex",
    fontWeight: "bold",
    flexDirection: "row",
  },
  group_interior_right: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  preco_atacado_total: {
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "#c5e612",
  },
  barras_interno: {
    fontSize: 6,
    textAlign: "left",
    display: "flex",
    color: "#c5e612",
    fontWeight: "bold",
  },
  subtitulo_atacado: {
    fontSize: 8,
    textAlign: "right",
    display: "flex",
    color: "#c5e612",
    fontWeight: "bold",
  },
  leve_pague: {
    fontSize: 8,
    textAlign: "center",
  },
  cifrao_atacado: {
    fontSize: 8,
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
    paddingRight: 3,
    paddingTop: 4,
  },
});

Font.register({
  family: "Courier Prime",
  fonts: [
    {
      src: CourierPrime,
    },
    {
      src: CourierPrimeBold,
      fontWeight: "bold",
    },
  ],
});

interface Prods {
  produto_nome: string;
  cod_barra: string;
  preco_venda: number;
}
interface Etiqueta {
  prods: Prods[];
}

const EtiquetaAtacado: React.FC<Etiqueta> = ({ prods }) => {
  return (
    <Document>
      {prods?.map((pro: Prods, k: number) => {
        return (
          <Page size={[largura, altura]} style={styles.page} key={k}>
            <View style={styles.body}>
              <View
                style={
                  pro.produto_nome.length < 25
                    ? styles.titulo
                    : styles.shortTitle
                }
              >
                <Text>{pro.produto_nome}</Text>
              </View>
              <View style={styles.group}>
                <View style={styles.left}>
                  <View style={styles.varejo_titulo}>
                    <Text>Varejo</Text>
                  </View>
                  <View style={styles.preco}>
                    <View style={styles.cifrao_varejo}>
                      <Text>R$</Text>
                    </View>
                    <Text>
                      {parseFloat(String(pro.preco_venda))
                        .toBRL(2)
                        .replace(".", ",")}
                    </Text>
                  </View>
                  <View style={styles.barras}>
                    <Image src={textToBase64Barcode(pro.cod_barra)} />
                  </View>
                  <View style={styles.data}>
                    <Text>{dayjs().format("DD/MM/YYYY")}</Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default EtiquetaAtacado;
