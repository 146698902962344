import React, { useCallback } from "react";
import { Button } from "@mui/material";
import { useAppContext } from "hooks";
import { Permission } from "./controller";
import NuvelTextField from "components/nuvel/TextField";
import NuvelSelect from "components/nuvel/Select";
import NuvelGrid from "components/nuvel/Grid";

interface PermissaoFieldsProps {
  name: string;
  setName: (name: string) => void;
  perms: Permission[];
  permissions: Permission[];
  setPermissions: (permissions: Permission[]) => void;
  tempPermissions: Permission[];
  setTempPermissions: (tempPermissions: Permission[]) => void;
}

const PermissaoFields: React.FC<PermissaoFieldsProps> = ({
  name,
  setName,
  perms,
  permissions,
  setPermissions,
  tempPermissions,
  setTempPermissions,
}) => {
  const {
    dialog: { showConfirm },
  } = useAppContext();

  const handlePermissions = useCallback(
    (perm_id: string, index: number) => {
      const perm = findById(perms, perm_id);
      if (!perm) return;

      const newPermissions = tempPermissions.slice(0, index);
      newPermissions.push(perm);
      setTempPermissions(newPermissions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [perms, tempPermissions, setTempPermissions]
  );

  const findById = useCallback(
    (nodes: Permission[], id: string): Permission | null => {
      for (const node of nodes) {
        if (node.id === id) {
          return node;
        }
        if (node.children.length > 0) {
          const found = findById(node.children, id);
          if (found) {
            return found;
          }
        }
      }
      return null;
    },
    []
  );

  const addPermissions = (
    newPerms: Permission[],
    tempPerms: Permission[],
    level = 0
  ) => {
    if (level >= tempPerms.length) return;

    const currentTempPerm = tempPerms[level];
    let existingPerm = newPerms.find((perm) => perm.id === currentTempPerm.id);

    if (!existingPerm) {
      existingPerm = {
        ...currentTempPerm,
        value: level === 0 ? ["R"] : ["R", "W", "D"],
        children: [],
      };
      newPerms.push(existingPerm);
    }

    if (
      level === tempPerms.length - 1 &&
      currentTempPerm.children &&
      currentTempPerm.children.length > 0
    ) {
      currentTempPerm.children.forEach((child) => {
        if (!existingPerm!.children.find((perm) => perm.id === child.id)) {
          existingPerm!.children.push({
            ...child,
            value: ["R", "W", "D"],
            children: [],
          });
        }
      });
    }

    addPermissions(existingPerm!.children, tempPerms, level + 1);
  };

  const checkIfPermissionExists = (perm: Permission, nodes: Permission[]) => {
    const foundOriginal = perm ? findById(perms, perm.id) : null;
    const foundNode = perm ? findById(nodes, perm.id) : null;

    if (!foundOriginal || !foundNode) return false;
    if (foundOriginal.children.length > 0) {
      if (foundNode.children.length === foundOriginal.children.length)
        return true;
    }
    if (foundOriginal.children.length === 0) {
      return true;
    }
    return false;
  };

  const handleAddPermissions = () => {
    if (tempPermissions[tempPermissions.length - 1]?.children.length > 0) {
      showConfirm(
        "Deseja adicionar essa permissão e todos seus filhos?",
        "Adicionar Permissão",
        (create) => (create ? handleAddPermissionsAfterConfirm() : null)
      );
    } else {
      handleAddPermissionsAfterConfirm();
    }
  };

  const handleAddPermissionsAfterConfirm = () => {
    const newPermissions = [...permissions];
    addPermissions(newPermissions, tempPermissions);
    setPermissions(newPermissions);
    setTempPermissions([]);
  };

  const renderSelects = (nodes: Permission[], level = 0): React.ReactNode => {
    if (!nodes || nodes.length === 0) return null;

    const childrenLength = tempPermissions[level]?.children.length;
    const renderAddButton = level === 0 ? false : childrenLength === 0;

    const referenceAddAllIndex = tempPermissions.length - 1;

    const isAddAvailable = level === referenceAddAllIndex;

    const AddButton = () => (
      <NuvelGrid xs={3}>
        <Button
          onClick={handleAddPermissions}
          style={{ width: "100%", height: "100%" }}
          variant="contained"
          color="primary"
        >
          Adicionar
        </Button>
      </NuvelGrid>
    );

    return (
      <>
        <NuvelGrid xs={isAddAvailable ? 9 : 12} key={level}>
          <NuvelSelect
            label={
              level
                ? `Permissões de ${tempPermissions[level - 1]?.nome}`
                : "Permissões"
            }
            value={tempPermissions[level]?.id || ""}
            onChange={(e) => handlePermissions(e.target.value as string, level)}
            options={nodes.map((perm: Permission) => ({
              value: perm.id,
              label: perm.nome,
              disabled: checkIfPermissionExists(perm, permissions),
            }))}
          />
        </NuvelGrid>
        {isAddAvailable && !renderAddButton ? <AddButton /> : null}
        {tempPermissions[level] &&
          renderSelects(tempPermissions[level].children, level + 1)}
        {renderAddButton ? <AddButton /> : null}
      </>
    );
  };

  return (
    <>
      <NuvelGrid xs={12}>
        <NuvelTextField
          name="name"
          variant="filled"
          onChange={(e) => setName(e.target.value)}
          color="primary"
          label="Nome do Grupo"
          value={name}
          fullWidth
        />
      </NuvelGrid>
      {renderSelects(perms)}
    </>
  );
};

export default PermissaoFields;
