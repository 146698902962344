import { CertificadoSerializer } from "data/interfaces/core/CertificadoSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "certificado";

class CertificadoModel extends MainModule<
  CertificadoSerializer,
  CertificadoSerializer
> {}

export default new CertificadoModel(model_url);
