import React from "react";
import pdf from "@react-pdf/renderer";
import CourierPrime from "../fonts/CourierPrime-Regular.ttf";
import CourierPrimeBold from "../fonts/CourierPrime-Bold.ttf";
import { textToBase64Barcode } from "utils/functions/textToBase64Barcode";

const { Page, Text, View, Document, StyleSheet, Font, Image } = pdf;

const largura = 60; // 30mm para cada seção
const resto_largura = 40; // 30mm para cada seção
const altura = 12; // Altura corrigida para 12mm

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Courier Prime",
    backgroundColor: "#FFF",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  etiqueta: {
    width: largura + resto_largura + "mm",
    height: altura + "mm",
    // borderWidth: 1, // Adicionando uma borda para melhor visualização
    // borderColor: '#000', // Cor da borda
    display: "flex",
    flexDirection: "row", // Disposição das seções lado a lado
    justifyContent: "space-between", // Espaçamento igual entre as seções
    alignItems: "center", // Alinhar as seções verticalmente
  },
  secao: {
    width: "30mm", // Largura de cada seção
    height: "100%", // Altura igual à altura da etiqueta
    paddingHorizontal: "2mm", // 3mm de padding horizontal
    display: "flex",
    flexDirection: "column", // Colocar os elementos em coluna dentro da seção
    justifyContent: "space-between", // Espaçamento igual entre os elementos
    marginLeft: "2mm",
  },
  titulo: {
    fontSize: "6pt", // Tamanho da fonte em pontos
    textAlign: "center",
    marginBottom: "2pt", // Margem inferior de 1mm
  },
  preco: {
    fontSize: "8pt", // Tamanho da fonte em pontos
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "2pt",
  },
  barras: {
    width: "100%", // Código de barras ocupando toda a largura da seção
    textAlign: "center",
    fontWeight: "bold",
  },
});

// Register font
Font.register({
  family: "Courier Prime",
  fonts: [
    {
      src: CourierPrime,
    },
    {
      src: CourierPrimeBold,
      fontWeight: "bold",
    },
  ],
});

interface Prods {
  produto_nome: string;
  cod_barra: string;
  preco_venda: number;
}
interface Etiqueta {
  prods: Prods[];
}

const EtiquetaJoia: React.FC<Etiqueta> = ({ prods }) => {
  return (
    <Document>
      {prods?.map((pro: Prods, k: number) => {
        return (
          <Page
            size={[largura + resto_largura + "mm", altura + "mm"]}
            style={styles.page}
            key={k}
          >
            <View style={styles.etiqueta}>
              <View
                style={{ ...styles.secao, width: `${resto_largura}mm` }}
              ></View>
              <View style={styles.secao}>
                <View style={styles.titulo}>
                  <Text>{pro.produto_nome}</Text>
                </View>
                <View style={styles.barras}>
                  <View style={styles.barras}>
                    <Image
                      src={textToBase64Barcode(pro.cod_barra, 25)}
                      style={{ width: "100%", height: "9mm" }}
                    />
                  </View>
                </View>
                <View style={styles.preco}>
                  <Text>{pro.preco_venda.toBRL()}</Text>
                </View>
              </View>
              <View style={styles.secao}>
                <View style={styles.titulo}>
                  <Text>{pro.produto_nome}</Text>
                </View>
                <View style={styles.barras}>
                  <View style={styles.barras}>
                    <Image
                      src={textToBase64Barcode(pro.cod_barra, 25)}
                      style={{ width: "100%", height: "8mm" }}
                    />
                  </View>
                </View>
                <View style={styles.preco}>
                  <Text>{pro.preco_venda.toBRL()}</Text>
                </View>
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default EtiquetaJoia;
