/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import pdf from "@react-pdf/renderer";
import { textToBase64Barcode } from "utils/functions/textToBase64Barcode";
import BarlowCondensed from "../fonts/BarlowCondensed-Regular.ttf";
import BarlowCondensedBold from "../fonts/BarlowCondensed-ExtraBold.ttf";

const { Page, Text, View, Document, StyleSheet, Font, Image } = pdf;

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Courier Prime",
    backgroundColor: "#FFF",
    width: "100%",
    display: "flex",
  },
  titulo: {
    paddingTop: "-4px",
    paddingHorizontal: 12,
    width: "100%",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  preco: {
    fontSize: 12,
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
  },
  barras: {
    paddingHorizontal: "25%",
    textAlign: "center",
    width: "100%",
    fontWeight: "bold",
  },
});

// Register font
Font.register({
  family: "Barlow Condensed",
  fonts: [
    {
      src: BarlowCondensed,
    },
    {
      src: BarlowCondensedBold,
      fontWeight: "bold",
    },
  ],
});

interface Prods {
  produto_nome: string;
  preco_venda: number;
  cod_barra: string;
}
interface Etiqueta {
  prods: Prods[];
  largura?: number;
  altura?: number;
  horizontal?: boolean;
  wrap?: string;
  maxHeight?: number;
  cod_barras_size?: number;
  cod_barras_height?: number;
  colunas?: number;
  margem?: number;
  price_on_bottom?: boolean;
  nome_font_multiplier?: number;
  preco_font_multiplier?: number;
  custom_barcode_style?: any;
}

const EtiquetaSimples: React.FC<Etiqueta> = ({
  prods,
  largura = 310,
  altura = 140,
  horizontal = false,
  wrap = "nowrap",
  maxHeight = 40,
  cod_barras_size = 10,
  cod_barras_height = undefined,
  colunas = 1,
  margem = 0,
  price_on_bottom = false,
  nome_font_multiplier = 1,
  preco_font_multiplier = 1,
  custom_barcode_style,
}) => {
  const etiquetaLargura = (largura - margem * (colunas - 1)) / colunas;

  const PriceComponentVertical = ({ pro }: { pro: Prods }) => (
    <View>
      {!price_on_bottom && (
        <View style={styles.preco}>
          <Text style={{ fontSize: 12 * preco_font_multiplier }}>
            {pro.preco_venda.toBRL()}
          </Text>
        </View>
      )}
      <View style={styles.barras}>
        <Image
          src={textToBase64Barcode(
            pro.cod_barra,
            cod_barras_size,
            cod_barras_height
          )}
          style={custom_barcode_style}
        />
      </View>
      {price_on_bottom && (
        <View style={styles.preco}>
          <Text style={{ fontSize: 12 * preco_font_multiplier }}>
            {pro.preco_venda.toBRL()}
          </Text>
        </View>
      )}
    </View>
  );

  const PriceComponentHorizontal = ({ pro }: { pro: Prods }) => (
    <View style={{ width: largura, display: "flex", flexDirection: "row" }}>
      <View style={{ flex: 1 }}>
        <View
          style={{
            ...styles.barras,
            maxHeight: (altura / 100) * 60,
            textAlign: "center",
            justifyContent: "center",
            alignContent: "center",
            paddingHorizontal: 0,
          }}
        >
          <Image
            style={{ width: (largura - 10) / 2 }}
            src={textToBase64Barcode(
              pro.cod_barra,
              cod_barras_size,
              cod_barras_height ? cod_barras_height : (altura / 100) * 90
            )}
          />
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <View
          style={{
            ...styles.preco,
            width: largura / 2,
            flex: 1,
            fontSize: 38 * preco_font_multiplier,
            letterSpacing: 0,
            paddingTop: "10%",
          }}
        >
          <Text>R${pro.preco_venda.toDecimal(2)}</Text>
        </View>
      </View>
    </View>
  );

  return (
    <Document>
      {prods?.map((pro: Prods, k: number) => (
        <Page
          size={{ width: largura, height: altura }}
          style={{ ...styles.page, width: largura }}
          key={k}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {Array.from({ length: colunas }).map((_, colIndex) => (
              <View
                key={colIndex}
                style={{
                  width: etiquetaLargura,
                  marginRight: colIndex < colunas - 1 ? margem : 0,
                }}
              >
                <View
                  style={
                    {
                      ...styles.titulo,
                      width: etiquetaLargura,
                      flexWrap: wrap,
                      maxHeight: maxHeight,
                      fontSize: 12 * nome_font_multiplier,
                    } as any
                  }
                >
                  <Text>{pro.produto_nome}</Text>
                </View>
                {horizontal ? (
                  <PriceComponentHorizontal pro={pro} />
                ) : (
                  <PriceComponentVertical pro={pro} />
                )}
              </View>
            ))}
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default EtiquetaSimples;
