import { Box } from "@mui/material";
import { EtiquetaEditor } from "components/retaguarda/etiqueta";

const RetEtiquetasForm = () => {
  return (
    <Box>
      <EtiquetaEditor />
    </Box>
  );
};

export default RetEtiquetasForm;
