/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlanoDeContasModel } from "data/models";
import React from "react";
import { findTreeItem, renderTree } from "./utils";
import NuvelTextField from "../TextField";
import { IconButton, InputAdornment } from "@mui/material";
import { Close } from "@mui/icons-material";
import NuvelModal from "../Modal";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";

export default function PlanoDeContaRecebimentoSelect(props: any) {
  const [open_, setOpen_] = React.useState<boolean>(false);
  const [list, setList] = React.useState<any>(null);
  const [select, setSelect] = React.useState<any>(null);

  const { open, id, mainId, handleOpen, value, name, onChange, disabled } =
    props;
  const { data: listPlanodeContas } = PlanoDeContasModel.useModel({
    tipo: 0,
  });

  // Função para evitar renderização extra ao tirar o mouse do <Close>
  const handleMouseDownRemove = (event: any) => {
    if (disabled) return;
    event.preventDefault();
    event.stopPropagation();
  };

  // Função para Remover o valor selecionado
  const handleClickRemove = (event: any) => {
    if (disabled) return;
    event.preventDefault();
    event.stopPropagation();
    setSelect(null);
  };

  // Função para Abrir Modal
  const handleOpenSelect = (event: any) => {
    if (disabled) return;
    event.preventDefault();
    event.stopPropagation();
    if (handleOpen) handleOpen(mainId);
    setOpen_(true);
  };

  // Função para Fechar Modal
  const handleCloseSelect = (event: any) => {
    if (disabled) return;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (handleOpen) return handleOpen();
    setOpen_(false);
  };

  // Função quando Seleciona
  const handleSelect = (event: any, id: any) => {
    if (disabled) return;
    event.preventDefault();
    event.stopPropagation();
    const selectElement = findTreeItem(listPlanodeContas as any, id);
    setSelect(selectElement);
    if (handleOpen) return handleOpen();
    setOpen_(false);
  };

  // Renderização da Lista
  React.useEffect(() => {
    if (listPlanodeContas) {
      setList(renderTree(listPlanodeContas, handleSelect));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPlanodeContas]);

  // Alterar
  React.useEffect(() => {
    if (value && listPlanodeContas) {
      const selectElement = findTreeItem(listPlanodeContas as any, value);
      setSelect(selectElement);
    }
  }, [value, listPlanodeContas]);

  // OnChange
  React.useEffect(() => {
    onChange({
      target: {
        name: name,
        value: select?.id || null,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select]);

  return (
    <>
      <NuvelTextField
        label={`Plano de Contas`}
        fullWidth
        variant="filled"
        value={select?.descricao || ""}
        onClick={handleOpenSelect}
        inputProps={{ readOnly: true }}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <>
              {select ? (
                <InputAdornment position="end">
                  <IconButton
                    disabled={disabled}
                    onClick={handleClickRemove}
                    onMouseDown={handleMouseDownRemove}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : null}
            </>
          ),
        }}
      />
      <NuvelModal
        open={handleOpen ? (id === mainId ? open : false) : open_}
        title={`Seleção de Plano de Contas - Recebimento`}
        btnSave={() => handleCloseSelect(null)}
        btnCancel={() => handleCloseSelect(null)}
        noButtons
        fullWidth
      >
        <SimpleTreeView>{list}</SimpleTreeView>
      </NuvelModal>
    </>
  );
}
