import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_ORCAMENTO_FILTERS,
  RETAGUARDA_ORCAMENTO_LIST,
  RETAGUARDA_ORCAMENTO_ORDERING,
} from "constants/retaguarda/financeiro/orcamento";
import { OrcamentoModel } from "data/models/financeiro";
import React from "react";

const RetOrcamentosList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={OrcamentoModel}
      columns={RETAGUARDA_ORCAMENTO_LIST}
      filters={RETAGUARDA_ORCAMENTO_FILTERS}
      ordering={RETAGUARDA_ORCAMENTO_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetOrcamentosList;
