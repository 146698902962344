import { EtiquetaEmLoteSerializer } from "data/interfaces/estoque/EtiquetaEmLoteSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "estoque/etiqueta_em_lote";

class EtiquetaEmLoteModel extends MainModule<
  EtiquetaEmLoteSerializer,
  EtiquetaEmLoteSerializer
> {}

export default new EtiquetaEmLoteModel(model_url);
