import { Box } from "@mui/material";
import React, { useEffect } from "react";

interface PlaceholderImageProps {
  text?: string;
  width: number | string;
  height: number | string;
  setImageLoading?: (loading: boolean) => void;
}

const PlaceholderImage: React.FC<PlaceholderImageProps> = ({
  text = "?",
  width,
  height,
  setImageLoading,
}) => {
  useEffect(() => {
    if (setImageLoading) setImageLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      width={width}
      height={height}
      bgcolor="grey.300"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        color: "grey.500",
        fontSize: "2rem",
        fontWeight: "bold",
      }}
    >
      {text.charAt(0).toUpperCase()}
    </Box>
  );
};

export default PlaceholderImage;
