import { RouterGenerator } from "utils/router";
import { redirect } from "react-router-dom";

import AuthRoutes from "layouts/auth/router";
import AuthLayout from "layouts/auth";

import AdminRoutes from "layouts/admin/router";
import AdminLayout from "layouts/admin";

import RetaguardaRoutes from "layouts/retaguarda/router";
import RetaguardaLayout from "layouts/retaguarda";

import SupportRoutes from "layouts/support/router";
import SupportLayout from "layouts/support";

const AuthRoutesWithProps = RouterGenerator(AuthRoutes);
const AdminRoutesWithProps = RouterGenerator(AdminRoutes);
const RetaguardaRoutesWithProps = RouterGenerator(RetaguardaRoutes);
const SupportRoutesWithProps = RouterGenerator(SupportRoutes);

const RoutesObject = [
  {
    path: "*",
    loader: () => redirect("/auth"),
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: AuthRoutesWithProps,
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    children: AdminRoutesWithProps,
  },
  {
    path: "/retaguarda",
    element: <RetaguardaLayout />,
    children: RetaguardaRoutesWithProps,
  },
  {
    path: "/support",
    element: <SupportLayout />,
    children: SupportRoutesWithProps,
  },
];

export default RoutesObject;
