/* eslint-disable @typescript-eslint/no-explicit-any */
import CryptoJS from "crypto-js";

const SECRET_KEY = "AHHAHAHAHAHAHAHAHHADIVERSAOOOOOO"; // eu duvido alguéma divinhar isso

export const encryptPermissions = (permissions: any) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(permissions),
    SECRET_KEY
  ).toString();
};

export const decryptPermissions = (cipherText: any) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};
