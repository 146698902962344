import { ParceiroDeNegocioSerializer } from "data/interfaces/core/ParceiroDeNegocioSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "pdn";

class ParceiroModel extends MainModule<
  ParceiroDeNegocioSerializer,
  ParceiroDeNegocioSerializer
> {}

export default new ParceiroModel(model_url);
