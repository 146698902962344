import { memo, useCallback } from "react";
import { useFormContext } from "./context";
import NuvelTextField from "components/nuvel/TextField";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import { AutoFormProps } from "./NuvelAutoForm"; // Ajuste o caminho conforme necessário
import { InternalModel } from "data/main";
import NuvelCheckbox from "components/nuvel/Checkbox";
import NuvelDatePicker from "components/nuvel/DatePicker";
import { Dayjs } from "dayjs";
import NuvelSelect from "components/nuvel/Select";
import NuvelInputFile from "components/nuvel/InputFile";
import { Autocomplete, TextField } from "@mui/material";

interface MuiAutocompleteOptions {
  label: string;
  value: number;
}

const FieldComponent = <T extends InternalModel>({
  field,
}: {
  field: AutoFormProps<T>;
}) => {
  const { state, dispatch } = useFormContext();

  const handleChange = useCallback(
    (event: {
      target: { name: string; value: string | number | boolean | undefined };
    }) => {
      dispatch({
        type: "UPDATE_FIELD",
        name: field.name as string,
        value: event.target.value,
      });
    },
    [dispatch, field.name]
  );

  if (
    field.type === "muiAutocomplete" &&
    field.label &&
    field.name &&
    field.list_name
  ) {
    return (
      <Autocomplete
        value={
          (state[field.list_name] || [])?.find(
            (option: MuiAutocompleteOptions) =>
              option.value === state[field.name as string]
          ) || null // Mapeia o número para o objeto correto da lista ou `null` se não for encontrado
        }
        onChange={(_, value: MuiAutocompleteOptions | null) => {
          dispatch({
            type: "UPDATE_FIELD",
            name: field.name as string,
            value: value ? value.value : null, // Define o valor como o número do campo "value"
          });
        }}
        disabled={
          typeof field.disabled === "function"
            ? field.disabled(state as T)
            : field.disabled || false
        }
        renderInput={(params) => (
          <TextField variant="filled" {...params} label={field.label} />
        )}
        getOptionLabel={(option: MuiAutocompleteOptions) => option.label}
        isOptionEqualToValue={(option, value) => {
          return option.value === Number(value);
        }}
        options={state[field.list_name] || []}
        loading={!state[field.list_name]} // Garante que `options` é sempre um array
        {...(field.props ? field.props : {})}
      />
    );
  }

  if (
    field.type === "autocomplete" &&
    field.reference &&
    field.label &&
    field.name
  ) {
    return (
      <NuvelAutocomplete
        reference={field.reference}
        label={field.label}
        value={state[field.name as string] as string | number | undefined}
        onChange={(_, value) =>
          dispatch({ type: "UPDATE_FIELD", name: field.name as string, value })
        }
        name={field.name as string}
        disabled={
          typeof field.disabled === "function"
            ? field.disabled(state as T)
            : field.disabled || false
        }
        {...(field.props ? field.props : {})}
      />
    );
  }

  if (field.type === "date" && field.label && field.name) {
    return (
      <NuvelDatePicker
        value={state[field.name as string] as string | Dayjs | undefined}
        onChange={handleChange}
        label={field.label}
        disabled={
          typeof field.disabled === "function"
            ? field.disabled(state as T)
            : field.disabled || false
        }
        name={field.name as string}
        {...(field.props ? field.props : {})}
      />
    );
  }

  if (field.type === "file" && field.label && field.name) {
    return (
      <NuvelInputFile
        name={field.name as string}
        onChange={(objeto: { content: string; name: string }) => {
          dispatch({
            type: "UPDATE_FIELD",
            name: field.name as string,
            value: objeto.content,
          });
          dispatch({
            type: "UPDATE_FIELD",
            name: `${String(field.name)}_nome`,
            value: objeto.name,
          });
        }}
        {...(field.props ? field.props : {})}
      />
    );
  }

  if (field.type === "select" && field.options && field.label && field.name) {
    return (
      <NuvelSelect
        label={field.label}
        value={state[field.name as string] as string | number | undefined}
        name={field.name as string}
        options={
          typeof field.options === "function"
            ? field.options(state as T)
            : field.options
        }
        disabled={
          typeof field.disabled === "function"
            ? field.disabled(state as T)
            : field.disabled || false
        }
        onChange={handleChange}
        {...(field.props ? field.props : {})}
      />
    );
  }

  if (field.type === "checkbox" && field.name && field.label) {
    return (
      <NuvelCheckbox
        checked={state[field.name as string] as boolean}
        onChange={handleChange}
        label={field.label}
        disabled={
          typeof field.disabled === "function"
            ? field.disabled(state as T)
            : field.disabled || false
        }
        {...(field.props ? field.props : {})}
      />
    );
  }

  if (
    field.type &&
    ["text", "money", "perc", "decimal", "number"].includes(field.type)
  ) {
    return (
      <NuvelTextField
        value={state[field.name as string] as string | number}
        onChange={handleChange}
        label={field.label}
        money={field.type === "money"}
        perc={field.type === "perc"}
        decimal={field.type === "decimal"}
        disabled={
          typeof field.disabled === "function"
            ? field.disabled(state as T)
            : field.disabled || false
        }
        type={field.type === "number" ? "number" : "text"}
        {...(field.props ? field.props : {})}
      />
    );
  }

  return <p>{`${field.label}/${field.type} not implemented`}</p>;
};

export default memo(FieldComponent) as <T extends InternalModel>(props: {
  field: AutoFormProps<T>;
}) => JSX.Element;
