import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useCatalog } from "../../context";

interface OrderSummaryProps {
  deliveryType?: "pickup" | "delivery";
}

const DELIVERY_FEE = 5; // Taxa de entrega em reais

export const OrderSummary: React.FC<OrderSummaryProps> = ({ deliveryType }) => {
  const { state } = useCatalog();

  const subtotal = state.cartTotal;
  const deliveryFee = deliveryType === "delivery" ? DELIVERY_FEE : 0;
  const total = subtotal + deliveryFee;

  return (
    <Box mt={4} bgcolor="background.paper" p={2} borderRadius={1}>
      <Typography variant="h6" mb={2}>
        Resumo do pedido
      </Typography>

      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography variant="body1" color="text.secondary">
            Subtotal
          </Typography>
          <Typography variant="body1">{subtotal.toBRL()}</Typography>
        </Box>

        {deliveryType === "delivery" && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Typography variant="body1" color="text.secondary">
              Taxa de entrega
            </Typography>
            <Typography variant="body1">{DELIVERY_FEE.toBRL()}</Typography>
          </Box>
        )}

        <Divider sx={{ my: 2 }} />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Total</Typography>
          <Typography variant="h6" color="primary">
            {total.toBRL()}
          </Typography>
        </Box>

        {state.cart.length > 0 && (
          <Box mt={2}>
            <Typography variant="body2" color="text.secondary">
              Itens no carrinho:
            </Typography>
            {state.cart.map((item) => (
              <Box
                key={item.id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={1}
              >
                <Typography variant="body2">
                  {item.quantity}x {item.name}
                </Typography>
                <Typography variant="body2">
                  {(item.price * item.quantity).toBRL()}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
