import { MetaDeVendaSerializer } from "data/interfaces/financeiro/MetaDeVendaSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "meta-venda";

class MetaDeVenda extends MainModule<
  MetaDeVendaSerializer,
  MetaDeVendaSerializer
> {}

export default new MetaDeVenda(model_url);
