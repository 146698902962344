import JsBarcode from "jsbarcode";

export function textToBase64Barcode(text: string, fontSize = 12, height = 40) {
  const canvas = document.createElement("canvas");
  canvas.id = "tempbarcanva";

  JsBarcode(canvas, text, {
    width: 6,
    height: height,
    fontSize: fontSize,
    fontOptions: "bold",
    margin: 5,
    background: "#ffffff",
    lineColor: "#000000",
    textMargin: 1,
    textPosition: "bottom",
    displayValue: true,
    valid: (valid) => valid,
    flat: false,
  });

  const scale = 5;
  const scaledCanvas = document.createElement("canvas");
  const ctx = scaledCanvas.getContext("2d");

  if (ctx) {
    scaledCanvas.width = canvas.width * scale;
    scaledCanvas.height = canvas.height * scale;

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";

    ctx.scale(scale, scale);
    ctx.drawImage(canvas, 0, 0);
  }

  const ret = scaledCanvas.toDataURL("image/png", 1.0);
  return ret;
}
