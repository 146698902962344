import { TableColumn, TableFilter } from "components/nuvel/lists";
import { OrcamentoSerializer } from "data/interfaces/financeiro/OrcamentoSerializer";

export const RETAGUARDA_ORCAMENTO_LIST: TableColumn<OrcamentoSerializer>[] = [
  {
    label: "Orçamento",
    accessor: (row) =>
      row.cliente_obj
        ? `${row.descricao} - ${row.cliente_obj.razao_social_nome} - ${row.cliente_obj.cpf_cnpj}`
        : `${row.descricao} - Sem cliente selecionado`,
    minWidth: 300,
  },
  {
    label: "Descrição",
    accessor: "descricao",
  },
];

export const RETAGUARDA_ORCAMENTO_ORDERING: (keyof OrcamentoSerializer)[] = [
  "codigo",
  "descricao",
];

export const RETAGUARDA_ORCAMENTO_FILTERS: TableFilter<OrcamentoSerializer>[] =
  [
    {
      label: "Código",
      accessor: "codigo",
      support: ["icontains"],
    },
    {
      label: "Descrição",
      accessor: "descricao",
      support: ["icontains"],
    },
  ];
