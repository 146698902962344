import Close from "@mui/icons-material/Close";
import { Button, Dialog, DialogProps, IconButton, styled } from "@mui/material";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import { ReactNode } from "react";

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(1),
}));

interface CustomDialogTitleProps extends DialogTitleProps {
  onClose?: () => void;
}

const DialogTitle = (props: CustomDialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <MuiDialogTitle
      sx={(theme) => ({
        margin: 0,
        padding: theme.spacing(2),
      })}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

interface NuvelModalProps extends Omit<DialogProps, "onClose" | "title"> {
  children: ReactNode;
  title: ReactNode;
  open: boolean;
  btnCancel?: () => void;
  btnSave?: () => void;
  btnSaveDisabled?: boolean;
  noButtons?: boolean;
  saveText?: string;
  saveOnly?: boolean;
  minWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  fullWidth?: boolean;
  noPadding?: boolean;
}

export default function NuvelModal(props: NuvelModalProps) {
  const {
    children,
    open,
    title,
    btnCancel,
    btnSave,
    btnSaveDisabled,
    noButtons,
    saveText,
    saveOnly,
    noPadding,
    minWidth,
    fullWidth,
    ...rest
  } = props;

  return (
    <Dialog
      open={open}
      {...rest}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={minWidth}
      fullWidth={fullWidth}
    >
      <DialogTitle onClose={btnCancel}>{title}</DialogTitle>
      <DialogContent dividers sx={{ padding: noPadding ? 0 : undefined }}>
        {children}
      </DialogContent>
      {!noButtons ? (
        <DialogActions style={{ justifyContent: "space-between" }}>
          {!saveOnly && (
            <Button variant="contained" onClick={btnCancel} color="primary">
              Cancelar
            </Button>
          )}
          <Button
            variant="contained"
            disabled={btnSaveDisabled}
            onClick={btnSave}
            color="primary"
          >
            {saveText ? saveText : "Salvar"}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
