import { ThemeOptions, darken, getContrastRatio, lighten } from "@mui/material";

const primaryColor = "rgba(1, 138, 218, 1)";
const secondaryColor = "rgba(100, 181, 246, 1)";
const tertiaryColor = "rgba(255, 183, 77, 1)";

export const generateTheme = (mode: string): ThemeOptions => ({
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
  palette:
    mode === "dark"
      ? {
          mode: "dark",
          common: {
            black: "#000",
            white: "#fff",
          },
          background: {
            paper: "#424242",
            default: "#1b1b1b",
          },
          primary: {
            light: lighten(primaryColor, 0.1),
            main: primaryColor,
            dark: darken(primaryColor, 0.1),
            contrastText:
              getContrastRatio(primaryColor, "#000") >= 3 ? "#000" : "#fff",
          },
          secondary: {
            light: lighten(secondaryColor, 0.1),
            main: secondaryColor,
            dark: darken(secondaryColor, 0.1),
            contrastText:
              getContrastRatio(secondaryColor, "#000") >= 3 ? "#000" : "#fff",
          },
          tertiary: {
            light: lighten(tertiaryColor, 0.1),
            main: tertiaryColor,
            dark: darken(tertiaryColor, 0.1),
            contrastText:
              getContrastRatio(tertiaryColor, "#000") >= 3 ? "#000" : "#fff",
          },
          error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff",
          },
          text: {
            primary: "rgba(255, 255, 255, 0.87)",
            secondary: "rgba(255, 255, 255, 0.54)",
            disabled: "rgba(255, 255, 255, 0.38)",
          },
        }
      : {
          mode: "light",
          common: {
            black: "#000",
            white: "#fff",
          },
          background: {
            paper: "#fff",
            default: "#fafafa",
          },
          primary: {
            light: lighten(primaryColor, 0.1),
            main: primaryColor,
            dark: darken(primaryColor, 0.1),
            contrastText:
              getContrastRatio(primaryColor, "#000") >= 3 ? "#000" : "#fff",
          },
          secondary: {
            light: lighten(secondaryColor, 0.1),
            main: secondaryColor,
            dark: darken(secondaryColor, 0.1),
            contrastText:
              getContrastRatio(secondaryColor, "#000") >= 3 ? "#000" : "#fff",
          },
          tertiary: {
            light: lighten(tertiaryColor, 0.1),
            main: tertiaryColor,
            dark: darken(tertiaryColor, 0.1),
            contrastText:
              getContrastRatio(tertiaryColor, "#000") >= 3 ? "#000" : "#fff",
          },
          error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff",
          },
          text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
          },
        },
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          color: "#000",
        },
      },
    },
  },
  body: {
    image: mode === "dark" ? "black" : "white",
    color: mode === "dark" ? "#1b1b1b" : "#fff",
    textColor: mode === "dark" ? "#fff" : "#000",
  },
});
