import { AutoFormProps } from "components/nuvel/forms/automatic/NuvelAutoForm";
import { TableColumn, TableFilter } from "components/nuvel/lists";
import { TagSerializer } from "data/interfaces/core/TagSerializer";

export const RETAGUARDA_TAG_FORM: AutoFormProps<TagSerializer>[] = [
  {
    grid: { xs: 12 },
    type: "grid",
    children: [
      {
        grid: { xs: 12 },
        type: "text",
        label: "Título",
        name: "titulo",
      },
    ],
  },
];

export const RETAGUARDA_TAG_LIST: TableColumn<TagSerializer>[] = [
  {
    label: "Título",
    accessor: "titulo",
    minWidth: 50,
  },
  {
    label: "Data",
    accessor: "data",
    minWidth: 250,
  },
];

export const RETAGUARDA_TAG_ORDERING: (keyof TagSerializer)[] = [
  "titulo",
  "data",
];

export const RETAGUARDA_TAG_FILTERS: TableFilter<TagSerializer>[] = [
  {
    label: "Título",
    accessor: "titulo",
    support: ["icontains"],
  },
  {
    label: "Data",
    accessor: "data",
    support: ["icontains"],
  },
];
