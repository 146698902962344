import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_PROMOCAO_FILTERS,
  RETAGUARDA_PROMOCAO_LIST,
  RETAGUARDA_PROMOCAO_ORDERING,
} from "constants/retaguarda/estoque/promocao";
import { PromocaoModel } from "data/models";
import React from "react";

const RetPromocaoList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={PromocaoModel}
      columns={RETAGUARDA_PROMOCAO_LIST}
      filters={RETAGUARDA_PROMOCAO_FILTERS}
      ordering={RETAGUARDA_PROMOCAO_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetPromocaoList;
