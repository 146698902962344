/* eslint-disable @typescript-eslint/no-explicit-any */
import NuvelAutocomplete from "components/nuvel/Autocomplete";
// import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelGrid from "components/nuvel/Grid";
import NuvelSelect from "components/nuvel/Select";
import DefaultRelatorios from "components/retaguarda/relatorios";
import dayjs from "dayjs";
import React from "react";
import { handleChange } from "utils/functions";

interface ParceirosState {
  relatorio: number;
  data_inicio: dayjs.Dayjs;
  data_fim: dayjs.Dayjs;
  lojas: number[];
}

const relatorios = () => [
  {
    url: "parceiro/clientes",
    title: "Relatório de Clientes",
    columns: [
      { label: "Nome", accessor: "nome", flex: 2 },
      { label: "CPF", accessor: "cpf", flex: 1 },
      {
        label: "Data de Inclusão",
        accessor: (row: any) => dayjs(row.data_inclusao).format("DD/MM/YYYY"),
        flex: 1,
      },
      {
        label: "Sexo",
        accessor: (row: any) => {
          switch (Number(row.sexo)) {
            case 1:
              return "Masculino";
            case 2:
              return "Feminino";
            case 3:
              return "Outro";
            case 4:
              return "Indefinido";
            default:
              return "Não informado";
          }
        },
        flex: 1,
      },
      {
        label: "Aniversário",
        accessor: (row: any) => dayjs(row.data_nascimento).format("DD/MM/YYYY"),
        flex: 1,
      },
    ],
    filtros: {},
  },
  {
    url: "parceiro/pontuacoes_vendas",
    title: "Relatório de Pontuação de Clientes/Lojitas",
    columns: [
      { label: "Nome", accessor: "nome", flex: 2 },
      { label: "CPF", accessor: "cpf", flex: 1 },
      {
        label: "Aniversário",
        accessor: (row: any) => dayjs(row.data_nascimento).format("DD/MM/YYYY"),
        flex: 1,
      },
      { label: "Pontuação", accessor: "pontuacao_em_vendas", flex: 1 },
      {
        label: "É lojista",
        accessor: (row: any) => (row.lojista ? "Sim" : "Não"),
        flex: 1,
      },
    ],
    filtros: {},
  },
  {
    url: "parceiro/fornecedores",
    title: "Relatório de Fornecedores",
    columns: [
      { label: "Razão Social", accessor: "razao_social", flex: 2 },
      { label: "Nome Fantasia", accessor: "nome_fantasia", flex: 2 },
      { label: "CNPJ/CPF", accessor: "cnpj_cpf", flex: 1 },
      {
        label: "Data de Inclusão",
        accessor: (row: any) => dayjs(row.data_inclusao).format("DD/MM/YYYY"),
        flex: 1,
      },
      {
        label: "Inscrição Municipal",
        accessor: "inscricao_municipal",
        flex: 1,
      },
      { label: "Inscrição Estadual", accessor: "inscricao_estadual", flex: 1 },
    ],
    filtros: {},
  },
];

const RetReportParceirosForm: React.FC = () => {
  const [state, setState] = React.useState<ParceirosState>({
    relatorio: 0,
    data_inicio: dayjs().startOf("day"),
    data_fim: dayjs().startOf("day"),
    lojas: [],
  });

  return (
    <DefaultRelatorios
      title="Parceiros de Negócio"
      relatorio={state.relatorio}
      relatorios={relatorios()}
      data_fim={state.data_fim}
      data_inicio={state.data_inicio}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={12}>
          <NuvelSelect
            label="Relatório"
            name="relatorio"
            onChange={(e) => handleChange(e, setState)}
            value={state.relatorio}
            options={relatorios().map((rel, index) => ({
              value: index,
              label: rel.title,
            }))}
          />
        </NuvelGrid>
        <NuvelGrid xs={12}>
          <NuvelAutocomplete
            multiple
            label="Lojas"
            name="lojas"
            reference="loja"
            value={state.lojas}
            onChange={(_, v) => setState({ ...state, lojas: v as number[] })}
          />
        </NuvelGrid>
        {/* <NuvelGrid xs={6}>
          <NuvelDatePicker
            label="Data Início"
            onChange={(e) => handleChange(e, setState)}
            name="data_inicio"
            value={state.data_inicio}
          />
        </NuvelGrid>
        <NuvelGrid xs={6}>
          <NuvelDatePicker
            label="Data Fim"
            onChange={(e) => handleChange(e, setState)}
            name="data_fim"
            value={state.data_fim}
          />
        </NuvelGrid> */}
      </NuvelGrid>
    </DefaultRelatorios>
  );
};

export default RetReportParceirosForm;
