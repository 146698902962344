import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Not_Found } from "assets/img";
import useWindowSize from "hooks/useWindowSize";

const PageNotFound: React.FC = () => {
  const [width] = useWindowSize();
  const isMobile = width < 1050;

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    (<Container sx={{ height: "100%" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        textAlign="center"
      >
        <Box sx={[width < 1300 ? {
          mb: {
            mb: -1.5
          }
        } : {
          mb: {
            mb: -3
          }
        }]}>
          <Typography variant="h1" component="h1">
            404
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Página Não Encontrada
          </Typography>
          <Typography variant="body1" gutterBottom>
            Desculpe, a página que você está procurando não existe.
          </Typography>
        </Box>
        <img
          src={Not_Found}
          alt="Robô"
          style={{
            width: isMobile ? "100%" : `calc(${width}px / 2.5)`,
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={handleGoBack}
        >
          Voltar para a Página Anterior
        </Button>
      </Box>
    </Container>)
  );
};

export default PageNotFound;