import { styled } from "@mui/material";
import { Black_Bg, White_Bg } from "assets/img";
import GlobalDialog from "components/nuvel/GlobalDialog";
import { useDialog } from "hooks/useDialog";
import React from "react";
import { closeMessage } from "utils/functions/dialog";
import ErrorBoundary from "./error/boundarie";

const BackgroundSolid = styled("div")(({ theme }) => ({
  background:
    "linear-gradient(343deg, rgba(2,0,36,1) 0%, rgba(0,41,65,1) 15%, rgba(1,138,218,1) 100%)",
  color: theme.palette.text.primary,
  minHeight: "100vh",
  height: "100%",
  width: "100%",
}));

const BackgroundPattern = styled("div")(({ theme }) => ({
  backgroundImage: `url(${
    White_Bg
  })`,

  color: theme.palette.text.primary,
  minHeight: "100vh",
  height: "100%",
  width: "100%",

  ...theme.applyStyles("dark", {
    backgroundImage: `url(${Black_Bg})`
  })
}));

const LayoutDefault: React.FC<{
  children: React.ReactNode;
  solid_background?: boolean;
}> = ({ children, solid_background }) => {
  const { data: dialog } = useDialog();

  if (solid_background) {
    return (
      <BackgroundSolid>
        <ErrorBoundary>{children}</ErrorBoundary>
        <GlobalDialog
          message={dialog?.message}
          title={dialog?.title}
          open={dialog?.open}
          handleClose={closeMessage}
          setConfirm={dialog?.func}
          type={dialog?.type}
          inputProps={dialog?.inputProps}
        />
      </BackgroundSolid>
    );
  }
  return (
    <BackgroundPattern>
      <ErrorBoundary>{children}</ErrorBoundary>
      <GlobalDialog
        message={dialog?.message}
        title={dialog?.title}
        open={dialog?.open}
        handleClose={closeMessage}
        setConfirm={dialog?.func}
        type={dialog?.type}
        inputProps={dialog?.inputProps}
      />
    </BackgroundPattern>
  );
};

export default LayoutDefault;
