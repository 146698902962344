import { AutoFormProps } from "components/nuvel/forms/automatic/NuvelAutoForm";
import { TableColumn, TableFilter } from "components/nuvel/lists";
import { UnidadeSerializer } from "data/interfaces/estoque/UnidadeSerializer";

export const RETAGUARDA_UNIDADE_FORM: AutoFormProps<UnidadeSerializer>[] = [
  {
    grid: { xs: 12 },
    type: "grid",
    children: [
      {
        grid: { xs: 12 },
        type: "text",
        label: "Nome",
        name: "nome",
      },
    ],
  },
];

export const RETAGUARDA_UNIDADE_LIST: TableColumn<UnidadeSerializer>[] = [
  {
    label: "Título",
    accessor: "nome",
    minWidth: 50,
  },
];

export const RETAGUARDA_UNIDADE_ORDERING: (keyof UnidadeSerializer)[] = [
  "nome",
];

export const RETAGUARDA_UNIDADE_FILTERS: TableFilter<UnidadeSerializer>[] = [
  {
    label: "Nome",
    accessor: "nome",
    support: ["icontains"],
  },
];
