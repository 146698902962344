import { AutoFormProps } from "components/nuvel/forms/automatic/NuvelAutoForm";
import { TableColumn, TableFilter } from "components/nuvel/lists";
import { DepositoSerializer } from "data/interfaces/estoque/DepositoSerializer";
import { DepositoSerializerList } from "data/interfaces/estoque/DepositoSerializerList";

export const RETAGUARDA_DEPOSITO_FORM: AutoFormProps<DepositoSerializer>[] = [
  {
    type: "grid",
    grid: { xs: 12 },
    children: [
      {
        grid: { xs: 12, md: 12 },
        label: "Deposito",
        name: "nome",
        type: "text",
      },
      {
        grid: { xs: 12, md: 12 },
        label: "Loja",
        name: "loja",
        type: "autocomplete",
        reference: "loja",
      },
      {
        grid: { xs: 12, md: 12 },
        label: "Adicionar todos os produtos",
        name: "should_add_all_prods",
        type: "checkbox",
      },
    ],
  },
];

export const RETAGUARDA_DEPOSITO_LIST: TableColumn<DepositoSerializerList>[] = [
  {
    label: "Deposito",
    accessor: "nome",
    minWidth: 300,
  },
  {
    label: "Loja",
    accessor: "loja",
    minWidth: 300,
  },
];

export const RETAGUARDA_DEPOSITO_ORDERING: (keyof DepositoSerializerList)[] = [
  "nome",
  "loja",
];

export const RETAGUARDA_DEPOSITO_FILTERS: TableFilter<DepositoSerializerList>[] =
  [
    {
      label: "Nome",
      accessor: "nome",
      support: ["icontains"],
    },
    {
      label: "Loja",
      accessor: "loja",
      support: ["icontains"],
    },
  ];
