/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useReducer, ReactNode } from "react";

export interface FormState {
  [key: string]: any;
}

export type FormAction = {
  type: "UPDATE_FIELD" | "UPDATE_FORM";
  name: string;
  value: any;
};

const formReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case "UPDATE_FIELD":
      return { ...state, [action.name]: action.value };
    case "UPDATE_FORM":
      return { ...state, ...action.value };
    default:
      return state;
  }
};

const FormContext = createContext<{
  state: FormState;
  dispatch: React.Dispatch<FormAction>;
}>({
  state: {},
  dispatch: () => null,
});

export const FormProvider = ({
  children,
  initialState,
}: {
  children: ReactNode;
  initialState: FormState;
}) => {
  const [state, dispatch] = useReducer(formReducer, initialState);
  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useFormContext = () => useContext(FormContext);
