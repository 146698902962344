import React from "react";

export default function useDebounce<T>(defaultValue: T, timer = 200): T | null {
  const [debounce, setDebounce] = React.useState<T | null>(null);
  const [_timeout, _setTimeout] = React.useState<number | null>(null);

  React.useEffect(() => {
    const later = () => {
      setDebounce(defaultValue);
    };
    if (_timeout) {
      clearTimeout(_timeout);
    }
    const timeId = setTimeout(later, timer);
    _setTimeout(timeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return debounce;
}
