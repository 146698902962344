import React from "react";
import { styled } from "@mui/material/styles";

const drawerWidth = 280;

interface MainProps {
  open?: boolean;
}

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<MainProps>(({ theme }) => ({
  overflow: "auto",
  flexGrow: 1,
  padding: theme.spacing(3),

  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  marginLeft: `calc(${theme.spacing(7)} + 1px)`,
  height: "100vh",

  variants: [
    {
      props: ({ open }) => open,

      style: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `calc(${drawerWidth}px + 1px)`,
      },
    },
  ],
}));

const DesktopContent: React.FC<{
  open: boolean;
  children: React.ReactNode;
}> = ({ open, children }) => {
  return (
    <Main open={open}>
      {/* <Box
        sx={[
          {
            transition: "all 0.3s ease",
            height: "10%",
          },
        ]}
      >
        <RouteSearch />
      </Box>
      <Box>{children}</Box> */}
      {children}
    </Main>
  );
};

export default DesktopContent;
