/* eslint-disable @typescript-eslint/no-explicit-any */
import { Delete } from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InputField from "components/legacy/inputField";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import { NuvelDefaultForm } from "components/nuvel/forms";
import NuvelGrid from "components/nuvel/Grid";
import NuvelTextField from "components/nuvel/TextField";
import { AjusteDeEstoqueSerializer } from "data/interfaces/estoque/AjusteDeEstoqueSerializer";
import { AjusteDeEstoqueModel } from "data/models";
import { useState } from "react";
import { handleChange } from "utils/functions";

interface AjusteDeEstoqueSerializerExtendido extends AjusteDeEstoqueSerializer {
  produto_selecionado: any;
}
function Form_AjusteDeEstoque() {
  const [state, setState] = useState<AjusteDeEstoqueSerializerExtendido>({
    id: undefined,
    descricao: "",
    data: new Date(),
    loja: null,
    deposito: null,
    produto_selecionado: null,
    produtos: [],
  });

  return (
    <NuvelDefaultForm
      state={state}
      setState={setState}
      model={AjusteDeEstoqueModel}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={12} md={12}>
          <NuvelTextField
            label="Descrição"
            onChange={(e) => handleChange(e, setState)}
            name="descricao"
            value={state.descricao}
          />
        </NuvelGrid>
        <NuvelGrid xs={6}>
          <NuvelAutocomplete
            label="Loja"
            name="loja"
            value={Number(state.loja)}
            onChange={(e) => handleChange(e, setState)}
            reference="loja"
          />
        </NuvelGrid>
        <NuvelGrid xs={6}>
          <NuvelAutocomplete
            label="Depósito"
            name="deposito"
            value={Number(state.deposito)}
            onChange={(e) => handleChange(e, setState)}
            filter={{
              loja: state.loja,
            }}
            disabled={!state.loja}
            reference="deposito"
          />
        </NuvelGrid>
        <NuvelGrid xs={12}>
          <NuvelAutocomplete
            label="Produto"
            name="produto_selecionado"
            value={Number(state.produto_selecionado)}
            onChange={(_e, _v, r) => {
              setState((prev: any) => ({
                ...prev,
                produtos: [
                  ...prev.produtos,
                  {
                    codbarras: r,
                    qnt_input: 0,
                    qnt: 0,
                    qnt_anterior: Number(
                      r?.estoques?.find(
                        (est: any) => est.deposito_id === state.deposito
                      ).quantidade || 0
                    ),
                  },
                ],
              }));
            }}
            filter={{
              estoques__deposito: state.deposito,
            }}
            disabled={!state.deposito}
            reference="cod_barra"
            clearAfterSelect
          />
        </NuvelGrid>
        <NuvelGrid xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Produto</TableCell>
                  <TableCell>Qnt Atual</TableCell>
                  <TableCell>Qnt Nova</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {state.produtos?.map((produto, index) => (
                  <TableRow key={index}>
                    <TableCell>{produto.codbarras.produto_nome}</TableCell>
                    <TableCell>{produto.qnt_anterior}</TableCell>
                    <TableCell>
                      <InputField
                        type="number"
                        value={produto.qnt_input}
                        onChange={(e: any) => {
                          const value = Number(e.target.value);
                          setState((prev: any) => ({
                            ...prev,
                            produtos: prev.produtos.map((p: any, i: any) => {
                              if (i === index) {
                                return {
                                  ...p,
                                  qnt_input: value || "",
                                  qnt: value - produto.qnt_anterior,
                                };
                              }
                              return p;
                            }),
                          }));
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            produtos: prev.produtos.filter(
                              (_p, i) => i !== index
                            ),
                          }));
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </NuvelGrid>
      </NuvelGrid>
    </NuvelDefaultForm>
  );
}

export default Form_AjusteDeEstoque;
