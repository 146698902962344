import axios, { AxiosInstance } from "axios";

export const API_URL = import.meta.env.VITE_API_URL;

export default function api(): AxiosInstance {
  const api_token = localStorage.getItem("token");
  const axiosInstance = axios.create({
    baseURL: API_URL + "/api/",
    headers: api_token
      ? {
          authorization: `Token ${api_token}`,
        }
      : {},
  });

  return axiosInstance;
}
