import { TableColumn, TableFilter } from "components/nuvel/lists";
import { DepartamentoSerializer } from "data/interfaces/estoque/DepartamentoSerializer";

export const RETAGUARDA_DEPARTAMENTO_LIST: TableColumn<DepartamentoSerializer>[] =
  [
    {
      label: "Nome",
      accessor: "nome",
    },
  ];

export const RETAGUARDA_DEPARTAMENTO_ORDERING: (keyof DepartamentoSerializer)[] =
  ["nome"];

export const RETAGUARDA_DEPARTAMENTO_FILTERS: TableFilter<DepartamentoSerializer>[] =
  [
    {
      label: "Nome",
      accessor: "nome",
      support: ["icontains"],
    },
  ];
