import { ProdutoListSerializer } from "data/interfaces/estoque/ProdutoListSerializer";
import { ProdutoSerializer } from "data/interfaces/estoque/ProdutoSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "estoque/produto";

class ProdutoModel extends MainModule<
  ProdutoSerializer,
  ProdutoListSerializer
> {}

export default new ProdutoModel(model_url);
