import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_FORMASDEPAGAMENTO_FILTERS,
  RETAGUARDA_FORMASDEPAGAMENTO_LIST,
  RETAGUARDA_FORMASDEPAGAMENTO_ORDERING,
} from "constants/retaguarda/financeiro/formas_de_pagamento";
import { FormasDePagamentoModel } from "data/models/financeiro";
import React from "react";

const RetFormasDePagamentoList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={FormasDePagamentoModel}
      columns={RETAGUARDA_FORMASDEPAGAMENTO_LIST}
      filters={RETAGUARDA_FORMASDEPAGAMENTO_FILTERS}
      ordering={RETAGUARDA_FORMASDEPAGAMENTO_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetFormasDePagamentoList;
