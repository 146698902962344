import { NuvelAutoForm } from "components/nuvel/forms";
import { RETAGUARDA_UNIDADE_FORM } from "constants/retaguarda/cadastro/unidades";
import { UnidadeModel } from "data/models";
import React from "react";

const RetUnidadesForm: React.FC = () => {
  return (
    <NuvelAutoForm model={UnidadeModel} fields={RETAGUARDA_UNIDADE_FORM} />
  );
};

export default RetUnidadesForm;
