/* eslint-disable @typescript-eslint/no-explicit-any */
import NuvelAccordion from "components/nuvel/Accordion";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelCheckbox from "components/nuvel/Checkbox";
import NuvelGrid from "components/nuvel/Grid";
import NuvelTextField from "components/nuvel/TextField";
import { handleChange } from "utils/functions";
import { SliderPicker } from "react-color";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function FieldsDepartamento({
  state,
  setState,
  allowAddChildren,
  handleOpen,
}: any) {
  const handleChangeChildren = (_e: any, selectedValue: any) => {
    setState((v: any) => ({ ...v, filhos: [...v.filhos, selectedValue] }));
  };

  return (
    <NuvelGrid container spacing={2}>
      <NuvelGrid xs={12} md={2}>
        <NuvelCheckbox
          checked={state.ativo}
          name="ativo"
          onChange={(e) => handleChange(e, setState)}
          label="Ativo"
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={10}>
        <NuvelTextField
          label="Nome"
          onChange={(e) => handleChange(e, setState)}
          name="nome"
          value={state.nome}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={12}>
        <NuvelTextField
          label="Prioridade"
          onChange={(e) => handleChange(e, setState)}
          name="prioridade"
          value={state.prioridade}
          type="number"
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={12}>
        <NuvelTextField
          label="Coluna"
          onChange={(e) => handleChange(e, setState)}
          name="coluna"
          value={state.coluna}
          type="number"
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={12}>
        {allowAddChildren && (
          <NuvelAccordion title="Sub-departamentos" startExpanded>
            <NuvelGrid container spacing={1}>
              <NuvelGrid xs={12} md={10}>
                <NuvelAutocomplete
                  label="Subdepartamentos"
                  name="filhos"
                  value={state.filhos}
                  onChange={(e, _, selectedValue) =>
                    handleChangeChildren(e, selectedValue)
                  }
                  reference="departamento"
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={2}>
                <Button
                  variant="contained"
                  sx={{ width: "100%", height: "100%" }}
                  onClick={handleOpen}
                >
                  Adicionar Novo
                </Button>
              </NuvelGrid>
              <NuvelGrid xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Subdepartamento</TableCell>
                        <TableCell width={50}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.filhos.map((item: any, index: number) => (
                        <TableRow key={item.id}>
                          <TableCell>{item.nome}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() => {
                                setState((v: any) => ({
                                  ...v,
                                  filhos: v.filhos.filter(
                                    (_: any, i: number) => i !== index
                                  ),
                                }));
                              }}
                              variant="contained"
                              color="error"
                            >
                              Remover
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </NuvelGrid>
            </NuvelGrid>
          </NuvelAccordion>
        )}
        <NuvelAccordion title="Grupo Tributário (Opcional)">
          <NuvelGrid container spacing={1}>
            <NuvelGrid xs={12} md={4}>
              <NuvelTextField
                label="NCM"
                onChange={(e) => handleChange(e, setState)}
                name="ncm"
                value={state.ncm}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={4}>
              <NuvelTextField
                label="CEST"
                onChange={(e) => handleChange(e, setState)}
                name="cest"
                value={state.cest}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={8}>
              <NuvelAutocomplete
                label="Imposto"
                name="imposto"
                value={Number(state.imposto)}
                onChange={(e) => handleChange(e, setState)}
                reference="imposto"
              />
            </NuvelGrid>
          </NuvelGrid>
        </NuvelAccordion>
        <NuvelAccordion title="Cores(Opcional)">
          <NuvelGrid xs={12} md={12}>
            <SliderPicker
              color={state.cor}
              onChange={(color) =>
                setState((v: any) => ({ ...v, cor: color.hex }))
              }
            />
          </NuvelGrid>
        </NuvelAccordion>
      </NuvelGrid>
    </NuvelGrid>
  );
}

export default FieldsDepartamento;
