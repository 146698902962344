/* eslint-disable @typescript-eslint/no-explicit-any */
import { NuvelDefaultForm } from "components/nuvel/forms";
import { DepartamentoModel } from "data/models";
import React from "react";
import FieldsDepartamento from "./fields";
import ModalDepartamentos from "./modal";

const RetDepartamentoForm: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<any>({
    id: "",
    nome: "",
    ativo: true,
    pai: null,
    filhos: [],
    cfop: "",
    ncm: "",
    cest: "",
    aliquota: "",
    cor: "",
    prioridade: 0,
    coluna: 1,
  });

  return (
    <NuvelDefaultForm
      setState={setState}
      state={state}
      model={DepartamentoModel}
    >
      <FieldsDepartamento
        handleOpen={() => {
          setOpen(!open);
        }}
        state={state}
        setState={setState}
        allowAddChildren
      />
      <ModalDepartamentos
        open={open}
        handleOpen={() => {
          setOpen(!open);
        }}
        setState={setState}
      />
    </NuvelDefaultForm>
  );
};

export default RetDepartamentoForm;
