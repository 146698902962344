import { Box, TextField, Button, CircularProgress } from "@mui/material";
import { CartState } from "layouts/catalogo/views/cart";
import React, { useState } from "react";

interface DeliveryFormProps {
  cartState: CartState;
  setCartState: (state: CartState) => void;
  onNext: () => void;
  onBack: () => void;
}

const DeliveryForm: React.FC<DeliveryFormProps> = ({
  cartState,
  setCartState,
  onNext,
  onBack,
}) => {
  const [loading, setLoading] = useState(false);
  const [cep, setCep] = useState(cartState.address?.cep || "");

  const handleCepSearch = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();

      if (!data.erro) {
        setCartState({
          ...cartState,
          address: {
            ...cartState.address,
            cep,
            street: data.logradouro,
            neighborhood: data.bairro,
            city: data.localidade,
            state: data.uf,
          },
        });
      }
    } catch (error) {
      console.error("Erro ao buscar CEP:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box display="flex" gap={2} mb={2}>
        <TextField
          label="CEP"
          value={cep}
          onChange={(e) => setCep(e.target.value)}
          disabled={loading}
        />
        <Button
          variant="contained"
          onClick={handleCepSearch}
          disabled={loading || cep.length !== 8}
        >
          {loading ? <CircularProgress size={24} /> : "Buscar CEP"}
        </Button>
      </Box>

      <TextField
        fullWidth
        label="Rua"
        value={cartState.address?.street || ""}
        onChange={(e) =>
          setCartState({
            ...cartState,
            address: { ...cartState.address, street: e.target.value },
          })
        }
        margin="normal"
      />

      <Box display="flex" gap={2}>
        <TextField
          label="Número"
          value={cartState.address?.number || ""}
          onChange={(e) =>
            setCartState({
              ...cartState,
              address: { ...cartState.address, number: e.target.value },
            })
          }
          margin="normal"
        />
        <TextField
          fullWidth
          label="Complemento"
          value={cartState.address?.complement || ""}
          onChange={(e) =>
            setCartState({
              ...cartState,
              address: { ...cartState.address, complement: e.target.value },
            })
          }
          margin="normal"
        />
      </Box>

      <Box display="flex" gap={2} mt={4}>
        <Button variant="outlined" onClick={onBack}>
          Voltar
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          disabled={!cartState.address?.street || !cartState.address?.number}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
};

export default DeliveryForm;
