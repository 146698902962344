import NuvelGrid from "components/nuvel/Grid";
import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelModal from "components/nuvel/Modal";
import React, { useEffect, useState } from "react";
import { TransacaoSerializer } from "data/interfaces/financeiro/TransacaoSerializer";
import { TransacaoModel } from "data/models";
import { TransacaoPrestacaoSerializer } from "data/interfaces/financeiro/TransacaoPrestacaoSerializer";
import { useAppContext } from "hooks";

interface IModalAlterarData {
  id: number;
  modal: {
    id: number;
    open: boolean;
    transacao: TransacaoSerializer | undefined;
    prestacao: TransacaoPrestacaoSerializer | undefined;
  };
  handleModal: (id: number, transacao?: TransacaoSerializer) => void;
  mutate: () => void;
}

const ModalAlterarData: React.FC<IModalAlterarData> = ({
  id,
  modal,
  handleModal,
  mutate,
}) => {
  const { showSnack } = useAppContext();

  const [newData, setNewData] = useState<string>();

  const handleSave = () => {
    TransacaoModel.action(
      "post",
      "alterar-vencimento",
      {
        nova_data: newData,
      },
      modal.prestacao?.id
    ).then(() => {
      showSnack("Data alterada com sucesso", 3, "success");
      mutate();
      handleModal(id);
    });
  };

  useEffect(() => {
    if (id === modal.id && modal.open) {
      setNewData(String(modal.prestacao?.vencimento));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.id, modal.open]);

  return (
    <NuvelModal
      title="Alterar Data"
      open={id === modal.id && modal.open}
      btnCancel={() => {
        setNewData("");
        handleModal(id);
      }}
      btnSave={() => handleSave()}
    >
      <NuvelGrid size={{ xs: 12 }}>
        <NuvelDatePicker
          name="data"
          label="Data"
          onChange={(e) => setNewData(e.target.value)}
          value={newData}
        />
      </NuvelGrid>
    </NuvelModal>
  );
};

export default ModalAlterarData;
