export const getFilterLabel = (label: string) => {
  if (label === "icontains") {
    return "Contém";
  }
  if (label === "istartswith") {
    return "Começa com";
  }
  if (label === "exact") {
    return "Igual";
  }
  return label;
};
