import { TableColumn, TableFilter } from "components/nuvel/lists";
import { AcessoSerializer } from "data/interfaces/core/AcessoSerializer";

export const RETAGUARDA_GRUPODEPERMISSOES_LIST: TableColumn<AcessoSerializer>[] =
  [
    {
      label: "Descrição",
      accessor: "nome",
      minWidth: 300,
    },
  ];

export const RETAGUARDA_GRUPODEPERMISSOES_ORDERING: (keyof AcessoSerializer)[] =
  ["nome"];

export const RETAGUARDA_GRUPODEPERMISSOES_FILTERS: TableFilter<AcessoSerializer>[] =
  [
    {
      label: "Descrição",
      accessor: "nome",
      support: ["icontains"],
    },
  ];
