/* eslint-disable @typescript-eslint/no-explicit-any */
import { Delete } from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import { NuvelDefaultForm } from "components/nuvel/forms";
import NuvelGrid from "components/nuvel/Grid";
import NuvelTextField from "components/nuvel/TextField";
import AuthController from "data/controller/auth";
import { OrcamentoModel, ProdutoModel, ServicoModel } from "data/models";
import React from "react";
import { handleChange } from "utils/functions";

const RetOrcamentosForm: React.FC = () => {
  const { data: user } = AuthController.useCurrentUser();

  const [state, setState] = React.useState<any>({
    descricao: "",
    obs: "",
    cliente: null,
    items: [],
    licenca: user?.licenca.id,
    servico: null,
    produto: null,
  });

  const [quantidade, setQuantidade] = React.useState<any>(1);

  const getItem = (id: any, type: any) => {
    if (type === 1) {
      ServicoModel.get(id)
        .then((res) => {
          if (
            state.items.find(
              (p: any) => p.servico === res.id && p.servico !== null
            )
          )
            return;

          const items = [
            ...state.items,
            {
              quantidade: quantidade,
              nome: res.nome,
              valor_unitario: parseFloat(res.preco_venda),
              desconto: 0,
              valor_total: parseFloat(res.preco_venda) * quantidade,
              servico: res.id,
            },
          ];
          setState({ ...state, items });
        })
        .catch((err) => console.error(err));
    } else {
      ProdutoModel.get(id)
        .then((res) => {
          if (
            state.items.find(
              (p: any) => p.produto === res.id && p.produto !== null
            )
          )
            return;

          const items = [
            ...state.items,
            {
              quantidade: quantidade,
              nome: res.nome,
              valor_unitario: parseFloat(res.preco_venda),
              desconto: 0,
              valor_total: parseFloat(res.preco_venda) * quantidade,
              produto: res.id,
            },
          ];
          setState({ ...state, items });
        })
        .catch((err) => console.error(err));
    }
  };

  const removeItem = (index: any) => () => {
    const items = state.items.filter((_p: any, i: any) => i !== index);
    setState({ ...state, items });
  };

  return (
    <NuvelDefaultForm model={OrcamentoModel} state={state} setState={setState}>
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={12} md={12}>
          <NuvelTextField
            label="Orçamento"
            onChange={(e) => handleChange(e, setState)}
            name="descricao"
            value={state.descricao}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={3}>
          <NuvelTextField
            label="Quantidade"
            onChange={(e) => {
              setQuantidade(e.target.value);
            }}
            name="quantidade"
            value={quantidade}
            type="number"
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={3}>
          <NuvelAutocomplete
            label="Serviço"
            name="servico"
            value={state.servico}
            onChange={(e) => {
              if (e.target.value) {
                getItem(e.target.value, 1);
                setState((_v: any) => ({ ..._v, servico: null }));
              }
            }}
            reference="servico"
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={3}>
          <NuvelAutocomplete
            label="Produto"
            name="produto"
            value={Number(state.produto)}
            onChange={(e) => {
              if (e.target.value) {
                getItem(e.target.value, 2);
              }
            }}
            reference="produto"
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={3}>
          <NuvelAutocomplete
            label="Cliente"
            name="cliente"
            value={state.cliente}
            onChange={(e) => handleChange(e, setState)}
            reference="cliente"
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={12}>
          <TableContainer>
            <Table
              style={{ width: "100%" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    Produto/Serviço
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>Quantidade</TableCell>
                  <TableCell style={{ width: "20%" }} align="center">
                    Valor
                  </TableCell>
                  <TableCell style={{ width: "20%" }} align="center">
                    Total
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    Tipo
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    Ação
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.items.map((p: any, i: any) => (
                  <TableRow key={i}>
                    <TableCell>{p.nome}</TableCell>
                    <TableCell>{p.quantidade}</TableCell>
                    <TableCell align="center">
                      {p.valor_unitario.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {(p.valor_unitario * p.quantidade).toLocaleString(
                        "pt-BR",
                        { style: "currency", currency: "BRL" }
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {p.servico !== null ? "Serviço" : "Produto"}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={removeItem(i)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="center" colSpan={3}></TableCell>
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="center">
                    {state.items
                      .reduce(
                        (acc: any, p: any) =>
                          acc + p.valor_unitario * p.quantidade - p.desconto,
                        0
                      )
                      .toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </NuvelGrid>
        <NuvelGrid xs={12} md={12}>
          <NuvelTextField
            label="Obs"
            onChange={(e) => handleChange(e, setState)}
            name="obs"
            value={state.obs}
          />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelDefaultForm>
  );
};

export default RetOrcamentosForm;
