export const TIPO_TRANSACAO = {
  CONTAS_A_PAGAR: {
    transacao__tipo: 1,
  },
  CONTAS_A_RECEBER: {
    transacao__tipo: 2,
  },
  VALE_CLIENTE: {
    transacao__tipo: 3,
  },
  VALE_FUNCIONARIO: {
    transacao__tipo: 4,
  },
};
