import NuvelGrid from "components/nuvel/Grid";
import React from "react";

interface DefaultSpacingProps {
  children: React.ReactNode;
}

const DefaultSpacing: React.FC<DefaultSpacingProps> = ({ children }) => {
  return (
    <NuvelGrid container>
      <NuvelGrid size={{ xs: 0, sm: 2.5 }}></NuvelGrid>
      <NuvelGrid size={{ xs: 12, sm: 7 }}>{children}</NuvelGrid>
      <NuvelGrid size={{ xs: 0, sm: 2.5 }}></NuvelGrid>
    </NuvelGrid>
  );
};

export default DefaultSpacing;
