import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelGrid from "components/nuvel/Grid";
import NuvelModal from "components/nuvel/Modal";
import NuvelSelect from "components/nuvel/Select";
import NuvelTextField from "components/nuvel/TextField";
import { TransacaoFaturamentoSerializer } from "data/interfaces/financeiro/TransacaoFaturamentoSerializer";
import { TransacaoPrestacaoSerializer } from "data/interfaces/financeiro/TransacaoPrestacaoSerializer";
import { TransacaoSerializer } from "data/interfaces/financeiro/TransacaoSerializer";
import { TransacaoModel } from "data/models";
import dayjs from "dayjs";
import { useAppContext } from "hooks";
import React, { useEffect, useState } from "react";

interface IModalRecebimento {
  modal: {
    open: boolean;
    id: number;
    transacao: TransacaoSerializer | undefined;
  };
  handleModal: (id: number) => void;
  id: number;
  mutate: () => void;
  list: TransacaoPrestacaoSerializer[];
  setSelectedOriginal: React.Dispatch<
    React.SetStateAction<TransacaoPrestacaoSerializer[]>
  >;
}

const tipoPagamento = [
  { label: "Conta Cofre", value: 0 },
  { label: "Conta Bancária", value: 1 },
  { label: "Cheque Terceiros", value: 2 },
];

const descricao_default = `Pagamento de Contas - ${dayjs().format(
  "DD/MM/YYYY"
)}`;

const ModalRecebimento: React.FC<IModalRecebimento> = ({
  modal,
  id,
  handleModal,
  list,
  mutate,
  setSelectedOriginal,
}) => {
  const { showSnack } = useAppContext();
  const [collection, setCollection] = useState<
    TransacaoFaturamentoSerializer[]
  >([]);
  const [selected, setSelected] = useState<number>(0);
  const [state, setState] = useState<{
    descricao: string;
    valor: number;
    faturamentos: TransacaoFaturamentoSerializer[];
  }>({
    descricao: descricao_default,
    valor: 0,
    faturamentos: [],
  });

  useEffect(() => {
    if (id === modal.id && modal.open) {
      const _v: TransacaoFaturamentoSerializer[] = list.map((p) => ({
        descricao: descricao_default,
        conta_entrada: undefined,
        desconto: undefined,
        id: undefined,
        juros: undefined,
        prestacao: p.id,
        valor: p.valor_faltante || p.valor,
        tipo_faturamento: 0,
      }));

      setCollection(_v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.open]);

  const handleSave = () => {
    TransacaoModel.action("post", "faturamento", state).then(() => {
      showSnack("Pagamento Realizado com Sucesso", 3, "success");
      mutate();
      handleCancel();
    });
  };

  const handleCancel = () => {
    handleModal(id);
    setCollection([]);
    setSelectedOriginal([]);
  };

  const isDisabled = () =>
    collection.some(
      (item) =>
        (item.tipo_faturamento === 0 || item.tipo_faturamento === 1) &&
        !item.conta_entrada
    );

  const collection_state = collection[selected];

  const handleChange = (e: {
    target: { name: string; value: number | string };
  }) => {
    setCollection((_v) => {
      const old_value = [..._v];
      old_value[selected] = {
        ...old_value[selected],
        [e.target.name]: e.target.value,
      };
      return old_value;
    });
  };

  useEffect(() => {
    if (collection.length > 0) {
      setState({
        ...state,
        faturamentos: collection,
        valor: collection.reduce(
          (acc, cur) => acc + (Number(cur.valor) || 0),
          0
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  useEffect(() => {
    if (collection.length > 0) {
      setCollection((_v) => {
        const old_value = [..._v];
        old_value[selected].descricao = state.descricao;
        return old_value;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.descricao]);

  const totalCollectionValue = list.reduce(
    (sum, item) => sum + (Number(item.valor) || 0),
    0
  );

  const totalValue = collection.reduce(
    (sum, item) => sum + (Number(item.valor) || 0),
    0
  );

  const totalFaltante = list.reduce(
    (sum, item) => sum + (Number(item.valor_faltante) || 0),
    0
  );

  return (
    <NuvelModal
      open={id === modal.id && modal.open}
      title="Recebimento"
      btnCancel={handleCancel}
      btnSave={handleSave}
      fullWidth
      maxWidth="md"
      btnSaveDisabled={isDisabled()}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid size={{ xs: 12 }}>
          <NuvelTextField
            name="descricao"
            label="Descrição"
            value={state.descricao}
            onChange={(e) => setState({ ...state, descricao: e.target.value })}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small"
            >
              <TableHead>
                <TableCell>Descrição</TableCell>
                <TableCell>Fornecedor</TableCell>
                <TableCell>Vencimento</TableCell>
                <TableCell>Total Prestação</TableCell>
                <TableCell>Total Pagamento</TableCell>
                <TableCell>Falta Receber</TableCell>
              </TableHead>
              <TableBody>
                {list?.map((row, index) => {
                  const isSelected = selected === index;
                  const hasContaBancaria = collection[index]?.conta_entrada;
                  const valor = collection[index]?.valor;
                  return (
                    <TableRow
                      selected={isSelected}
                      key={row.id}
                      onClick={() => setSelected(index)}
                      hover
                      sx={{
                        "&.MuiTableRow-root.Mui-selected": {
                          backgroundColor: hasContaBancaria
                            ? green[200]
                            : red[200],
                        },
                        "&.MuiTableRow-root:not(.Mui-selected)": {
                          backgroundColor: hasContaBancaria
                            ? green[300]
                            : red[300],
                        },
                        "&.MuiTableRow-root:hover": {
                          backgroundColor: hasContaBancaria
                            ? green[300]
                            : red[300],
                        },
                      }}
                    >
                      <TableCell>{row.descricao}</TableCell>
                      <TableCell>
                        {row.parceiro_de_negocios_nome || "Sem Fornecedor"}
                      </TableCell>
                      <TableCell>
                        {dayjs(row.vencimento).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{row.valor?.toBRL()}</TableCell>
                      <TableCell>{valor?.toBRL()}</TableCell>
                      <TableCell>{row.valor_faltante?.toBRL()}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell colSpan={3}>
                    <b>Total</b>
                  </TableCell>
                  <TableCell>{totalCollectionValue.toBRL()}</TableCell>
                  <TableCell>{totalValue.toBRL()}</TableCell>
                  <TableCell>{totalFaltante.toBRL()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </NuvelGrid>
        {collection_state && (
          <>
            <NuvelGrid size={{ xs: 4 }}>
              <NuvelSelect
                label="Tipo de Pagamento"
                options={tipoPagamento}
                value={collection_state.tipo_faturamento}
                onChange={(e) => handleChange(e)}
                name="tipo_faturamento"
              />
            </NuvelGrid>
            <NuvelGrid size={{ xs: 4 }}>
              <NuvelAutocomplete
                name="conta_entrada"
                reference="conta_bancaria"
                label="Conta Bancária"
                value={collection_state.conta_entrada}
                onChange={(e) =>
                  handleChange({
                    target: { name: e.target.name, value: e.target.value },
                  })
                }
                filter={{
                  cofre: collection_state.tipo_faturamento === 0,
                }}
                error={!collection_state.conta_entrada}
              />
            </NuvelGrid>
            <NuvelGrid size={{ xs: 4 }}>
              <NuvelTextField
                name="valor"
                label="Valor Total"
                value={collection_state.valor}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: e.target.value,
                    },
                  });
                }}
                money
                max={collection_state.valor_faltante || totalCollectionValue}
                min={0}
              />
            </NuvelGrid>
          </>
        )}
      </NuvelGrid>
    </NuvelModal>
  );
};

export default ModalRecebimento;
