/* eslint-disable @typescript-eslint/no-explicit-any */
import NuvelModal from "components/nuvel/Modal";
import React from "react";
import FieldsDepartamento from "./fields";

// import { Container } from './styles';
const def_values = {
  nome: "",
  ativo: true,
  pai: null,
  filhos: [],
  cfop: "",
  ncm: "",
  cest: "",
  aliquota: "",
  cor: "",
  prioridade: 0,
  coluna: 1,
};
const ModalDepartamentos: React.FC<{
  open: boolean;
  handleOpen: () => void;
  setState: any;
}> = ({ open, handleOpen, setState: outSetState }) => {
  const [state, setState] = React.useState<any>(def_values);

  const handleConfirm = () => {
    outSetState((o_value: any) => ({
      ...o_value,
      filhos: [...o_value.filhos, { ...state, licenca: o_value.licenca }],
    }));
    setState(def_values);
    handleOpen();
  };

  const handleCancel = () => {
    setState(def_values);
    handleOpen();
  };

  return (
    <NuvelModal
      open={open}
      title="Departamento"
      btnSave={handleConfirm}
      btnCancel={handleCancel}
    >
      <FieldsDepartamento state={state} setState={setState} />
    </NuvelModal>
  );
};

export default ModalDepartamentos;
