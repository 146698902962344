/* eslint-disable react-hooks/rules-of-hooks */
import { Typography } from "@mui/material";
import NuvelGrid from "components/nuvel/Grid";
import PaperWithLoad from "components/nuvel/PaperWithLoad";
import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function vendasPresentePassado({ ...props }) {
  const { data } = props;
  const [innerData, setInnerData] = useState([]);
  const [bars, setBars] = useState([]);

  useEffect(() => {
    if (data) {
      if (data.rows && data.bars) {
        setInnerData(data.rows);
        setBars(data.bars);
      }
    }
  }, [data]);

  const generateColor = (idx) => {
    if (idx % 2 === 0) {
      return "#8884d8";
    } else {
      return "#82ca9d";
    }
  };

  return (
    <PaperWithLoad loading={!data} style={{ width: "100%", height: "24em" }}>
      <NuvelGrid container>
        <NuvelGrid
          style={{
            width: "100%",
            height: "3em",
            textAlign: "center",
          }}
          xs={12}
        >
          <Typography variant="h5" component="h2">
            Comparativo atual x semana anterior
          </Typography>
        </NuvelGrid>
        <NuvelGrid style={{ width: "100%", height: "21em" }} xs={12}>
          <ResponsiveContainer width="100%" height="100%" label={"Teste"}>
            <ComposedChart
              width={500}
              height={400}
              data={innerData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip
                labelFormatter={(value) => value}
                formatter={(value, order, entry) => [
                  `${value.toBRL()}`,
                  entry?.payload?.payload?.labels,
                ]}
              />
              <Legend />
              {bars.map((bar, index) => (
                <Bar fill={generateColor(index)} key={index} dataKey={bar} />
              ))}
              <Line type="monotone" dataKey="total" stroke="#ff7300" />
            </ComposedChart>
          </ResponsiveContainer>
        </NuvelGrid>
      </NuvelGrid>
    </PaperWithLoad>
  );
}
