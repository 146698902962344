/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { getPeriodo } from "utils/functions/getPeriodo";

interface PeriodoProps {
  state: {
    periodo: string;
    periodoParams: {
      start_date: string;
      end_date: string;
      previus_start_date: string;
      previus_end_date: string;
    };
  };
  setState: React.Dispatch<React.SetStateAction<any>>;
}

const PeriodoSelect: React.FC<PeriodoProps> = ({ state, setState }) => {
  const handleChangePeriodo = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    const p_details = getPeriodo(value);
    setState((prevState: any) => ({
      ...prevState,
      periodo: value,
      periodoParams: {
        ...prevState.periodoParams,
        start_date: p_details.start_date,
        end_date: p_details.end_date,
        previus_start_date: p_details.previus_start_date,
        previus_end_date: p_details.previus_end_date,
      },
    }));
  };

  return (
    <FormControl fullWidth variant="filled" sx={{ minWidth: "150px" }}>
      <InputLabel htmlFor="periodo-select">Período</InputLabel>
      <Select
        value={state.periodo}
        onChange={handleChangePeriodo}
        autoWidth
        inputProps={{
          id: "periodo-select",
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
      >
        <MenuItem value={"hoje"}>Hoje</MenuItem>
        <MenuItem value={"ontem"}>Ontem</MenuItem>
        <MenuItem value={"esta_semana"}>Esta Semana</MenuItem>
        <MenuItem value={"semana_passada"}>Semana Passada</MenuItem>
        <MenuItem value={"este_mes"}>Este Mês</MenuItem>
        <MenuItem value={"mes_passado"}>Mês Passado</MenuItem>
        <MenuItem value={"este_ano"}>Este Ano</MenuItem>
        <MenuItem value={"ano_passado"}>Ano Passado</MenuItem>
      </Select>
    </FormControl>
  );
};

export default PeriodoSelect;
