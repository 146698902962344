/* eslint-disable @typescript-eslint/no-unused-vars */
interface Number {
  toBRL(min?: number, max?: number): string;
  toDecimal(min?: number): string;
  in(obj?: number[]): boolean;
}

Number.prototype.toBRL = function (this: number, min = 2, max = 2): string {
  const formatter = new Intl.NumberFormat([], {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
  return formatter.format(this);
};

Number.prototype.toDecimal = function (this: number, min = 2): string {
  const formatter = new Intl.NumberFormat([], {
    style: "decimal",
    currency: "BRL",
    minimumFractionDigits: min,
    maximumFractionDigits: 3,
  });
  return formatter.format(this);
};

Number.prototype.in = function (this: number, obj: number[]): boolean {
  return !!~obj.indexOf(this);
};
