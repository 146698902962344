/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { useParams } from "react-router-dom";
import axios from "axios";
import NuvelAccordion from "components/nuvel/Accordion";
import NuvelGrid from "components/nuvel/Grid";
import NuvelTextField from "components/nuvel/TextField";
import { handleChange } from "utils/functions";

function AccordionEndereco(props: { state: any; setState: any }) {
  const { state, setState } = props;
  const { id } = useParams();

  const numeroRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (state.cep?.length === 8 && id === "novo")
      axios
        .get(
          `https://viacep.com.br/ws/${state.cep.replaceAll(
            /[.,/-]/g,
            ""
          )}/json/`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setState((oval: any) => ({
            ...oval,
            bairro: response.data.bairro,
            complemento: response.data.complemento,
            uf: response.data.uf,
            pais: "Brasil",
            endereco: response.data.logradouro,
            cidade: response.data.localidade,
            cod_ibge: response.data.ibge,
          }));
          if (numeroRef.current) {
            numeroRef.current.focus();
          }
        });
    // eslint-disable-next-line
  }, [state.cep]);

  return (
    (<NuvelAccordion title="Endereço">
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={12} md={4}>
          <NuvelTextField
            label="CEP"
            mask="00.000-000"
            normalize={(value) => value.replaceAll(/[-.]/g, "")}
            onChange={(e) => handleChange(e, setState)}
            name="cep"
            value={state.cep}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={8}>
          <NuvelTextField
            label="Endereço"
            onChange={(e) => handleChange(e, setState)}
            name="endereco"
            value={state.endereco}
          />
        </NuvelGrid>
        <NuvelGrid xs={6} md={2}>
          <NuvelTextField
            label="Número"
            inputRef={numeroRef}
            onChange={(e) => handleChange(e, setState)}
            name="numero"
            value={state.numero}
          />
        </NuvelGrid>
        <NuvelGrid xs={6} md={2}>
          <NuvelTextField
            label="UF"
            onChange={(e) => handleChange(e, setState)}
            name="uf"
            value={state.uf}
            normalize={(str) => str.toUpperCase()}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={8}>
          <NuvelTextField
            label="Complemento"
            onChange={(e) => handleChange(e, setState)}
            name="complemento"
            value={state.complemento}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={3}>
          <NuvelTextField
            label="Bairro"
            onChange={(e) => handleChange(e, setState)}
            name="bairro"
            value={state.bairro}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={3}>
          <NuvelTextField
            label="Cidade"
            onChange={(e) => handleChange(e, setState)}
            name="cidade"
            value={state.cidade}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={3}>
          <NuvelTextField
            label="País"
            onChange={(e) => handleChange(e, setState)}
            name="pais"
            value={state.pais}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={3}>
          <NuvelTextField
            label="Cód. IBGE"
            onChange={(e) => handleChange(e, setState)}
            name="cod_ibge"
            value={state.cod_ibge}
          />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelAccordion>)
  );
}

export default AccordionEndereco;
