import { RouteInterface } from "utils/router";

import RetPlanoDeContasForm from "../views/financeiro/plano_de_contas/form";
import RetContaBancariaList from "../views/financeiro/conta_bancaria/list";
import RetContaBancariaForm from "../views/financeiro/conta_bancaria/form";
import RetContasAPagarForm from "../views/financeiro/contas_a_pagar/form";
import RetContasAReceberForm from "../views/financeiro/contas_a_receber/form";
import RetServicosList from "../views/financeiro/servicos/list";
import RetServicosForm from "../views/financeiro/servicos/form";
import RetOrcamentosList from "../views/financeiro/orcamentos/list";
import RetOrcamentosForm from "../views/financeiro/orcamentos/form";
import RetMetasDeVendaList from "../views/financeiro/metas_de_venda/list";
import RetMetasDeVendaForm from "../views/financeiro/metas_de_venda/form";
import RetFormasDePagamentoList from "../views/financeiro/formas_de_pagamento/list";
import RetFormasDePagamentoForm from "../views/financeiro/formas_de_pagamento/form";
import RetValeClienteForm from "../views/financeiro/vale_cliente/form";
import RetValeFuncionarioForm from "../views/financeiro/vale_funcionario/form";

const financeiroRoutes: RouteInterface[] = [
  {
    title: "Plano de Contas",
    path: "/retaguarda/financeiro/plano_de_contas",
    element: <RetPlanoDeContasForm />,
    perm: "FINANCEIRO_PLANO_DE_CONTAS",
  },
  {
    title: "Conta Bancária",
    path: "/retaguarda/financeiro/conta_bancaria",
    element: <RetContaBancariaList />,
    form: <RetContaBancariaForm />,
    perm: "FINANCEIRO_CONTA_BANCARIA",
  },
  {
    title: "Contas a Pagar",
    path: "/retaguarda/financeiro/contas_a_pagar",
    element: <RetContasAPagarForm />,
    perm: "FINANCEIRO_CONTAS_A_PAGAR",
  },
  {
    title: "Contas a Receber",
    path: "/retaguarda/financeiro/contas_a_receber",
    element: <RetContasAReceberForm />,
    perm: "FINANCEIRO_CONTAS_A_RECEBER",
  },
  {
    title: "Vale Cliente",
    path: "/retaguarda/financeiro/vale_cliente",
    element: <RetValeClienteForm />,
    perm: "FINANCEIRO_VALE_CLIENTE",
  },
  {
    title: "Vale Funcionário",
    path: "/retaguarda/financeiro/vale_funcionario",
    element: <RetValeFuncionarioForm />,
    perm: "FINANCEIRO_VALE_FUNCIONARIO",
  },
  {
    title: "Serviços",
    path: "/retaguarda/financeiro/servicos",
    element: <RetServicosList />,
    form: <RetServicosForm />,
    perm: "FINANCEIRO_SERVICOS",
  },
  {
    title: "Orçamentos",
    path: "/retaguarda/financeiro/orcamentos",
    element: <RetOrcamentosList />,
    form: <RetOrcamentosForm />,
    perm: "FINANCEIRO_ORCAMENTOS",
  },
  {
    title: "Metas de Venda",
    path: "/retaguarda/financeiro/metas_de_venda",
    element: <RetMetasDeVendaList />,
    form: <RetMetasDeVendaForm />,
    perm: "FINANCEIRO_METAS_DE_VENDA",
  },
  {
    title: "Formas de Pagamento",
    path: "/retaguarda/financeiro/formas_de_pagamento",
    element: <RetFormasDePagamentoList />,
    form: <RetFormasDePagamentoForm />,
    perm: "FINANCEIRO_FORMAS",
  },
];

export default financeiroRoutes;
