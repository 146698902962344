/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useRef, useEffect } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import Draggable from "react-draggable";
import { ElementoBase, Produto } from "./types";
import { textToBase64Barcode } from "../../../utils/functions/textToBase64Barcode";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import FitScreenIcon from "@mui/icons-material/FitScreen";

interface Props {
  elemento: ElementoBase;
  selecionado: boolean;
  onClick: () => void;
  onDragEnd: (x: number, y: number) => void;
  onResize: (width: number, height: number) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  produtoExemplo?: Produto;
  gridSize?: number;
}

const ElementoEtiqueta: React.FC<Props> = ({
  elemento,
  selecionado,
  onClick,
  onDragEnd,
  onResize,
  containerRef,
  produtoExemplo,
  gridSize = 10,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const resizing = useRef(false);
  const startPos = useRef({ x: 0, y: 0 });
  const startSize = useRef({ width: 0, height: 0 });

  const snapToGrid = (value: number): number => {
    return Math.round(value / gridSize) * gridSize;
  };

  const centralizarElemento = () => {
    if (containerRef.current && elementRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
      const elementWidth = elementRef.current.offsetWidth;
      const elementHeight = elementRef.current.offsetHeight;

      const x = (containerWidth - elementWidth) / 2;
      const y = (containerHeight - elementHeight) / 2;

      onDragEnd(x, y);
    }
  };

  const ajustarTamanhoAoConteudo = () => {
    if (contentRef.current) {
      const { width, height } = contentRef.current.getBoundingClientRect();
      onResize(width + 8, height + 8); // +8 para padding
    }
  };

  useEffect(() => {
    if (contentRef.current && elemento.ajustarAoConteudo) {
      ajustarTamanhoAoConteudo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produtoExemplo, elemento.ajustarAoConteudo]);

  const renderControles = () => {
    if (!selecionado) return null;

    return (
      <Box
        sx={{
          position: "absolute",
          top: -30,
          right: 0,
          display: "flex",
          gap: 0.5,
          backgroundColor: "white",
          padding: "2px",
          borderRadius: "4px",
          boxShadow: "0 0 5px rgba(0,0,0,0.2)",
        }}
      >
        <Tooltip title="Centralizar">
          <IconButton size="small" onClick={centralizarElemento}>
            <CenterFocusStrongIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Ajustar ao conteúdo">
          <IconButton size="small" onClick={ajustarTamanhoAoConteudo}>
            <FitScreenIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const handleDrag = (_e: any, _data: { x: number; y: number }) => {
    // Atualização em tempo real da posição (opcional)
    // onDragEnd(data.x, data.y);
  };

  const handleDragStop = (_e: any, data: { x: number; y: number }) => {
    onDragEnd(data.x, data.y);
  };

  const handleMouseDown = (e: React.MouseEvent, direction: string) => {
    e.stopPropagation();
    resizing.current = true;
    startPos.current = { x: e.clientX, y: e.clientY };
    startSize.current = {
      width: elementRef.current?.offsetWidth || 0,
      height: elementRef.current?.offsetHeight || 0,
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!resizing.current) return;

      const deltaX = e.clientX - startPos.current.x;
      const deltaY = e.clientY - startPos.current.y;

      let newWidth = startSize.current.width;
      let newHeight = startSize.current.height;

      if (direction.includes("e")) newWidth += deltaX;
      if (direction.includes("w")) newWidth -= deltaX;
      if (direction.includes("s")) newHeight += deltaY;
      if (direction.includes("n")) newHeight -= deltaY;

      onResize(Math.max(20, newWidth), Math.max(20, newHeight));
    };

    const handleMouseUp = () => {
      resizing.current = false;
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const renderResizeHandles = () => {
    const handles = ["n", "e", "s", "w", "ne", "se", "sw", "nw"];
    return handles.map((direction) => (
      <Box
        key={direction}
        sx={{
          position: "absolute",
          width: "8px",
          height: "8px",
          backgroundColor: "#1976d2",
          borderRadius: "50%",
          ...(direction.includes("n") && { top: "-4px" }),
          ...(direction.includes("s") && { bottom: "-4px" }),
          ...(direction.includes("e") && { right: "-4px" }),
          ...(direction.includes("w") && { left: "-4px" }),
          ...(direction === "n" && { left: "calc(50% - 4px)" }),
          ...(direction === "s" && { left: "calc(50% - 4px)" }),
          ...(direction === "e" && { top: "calc(50% - 4px)" }),
          ...(direction === "w" && { top: "calc(50% - 4px)" }),
          cursor:
            direction.length === 2
              ? `${direction}-resize`
              : `${direction}${
                  direction === "n" || direction === "s" ? "s" : "e"
                }-resize`,
          zIndex: 2,
          "&:hover": {
            transform: "scale(1.2)",
          },
        }}
        onMouseDown={(e) => handleMouseDown(e, direction)}
      />
    ));
  };

  const renderConteudo = () => {
    if (!produtoExemplo) {
      switch (elemento.tipo) {
        case "texto":
          return <div style={elemento.estilo}>Texto Exemplo</div>;
        case "codigoBarras":
          return (
            <div
              style={{
                background: "#ccc",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Código de Barras
            </div>
          );
        case "preco":
          return <div style={elemento.estilo}>R$ 99,99</div>;
        default:
          return null;
      }
    }

    switch (elemento.tipo) {
      case "texto":
        return <div style={elemento.estilo}>{produtoExemplo.produto_nome}</div>;
      case "codigoBarras":
        return (
          <img
            src={textToBase64Barcode(produtoExemplo.cod_barra)}
            alt="Código de Barras"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
              display: "block",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            onClick={(e) => e.stopPropagation()}
            draggable={false}
          />
        );
      case "preco":
        return (
          <div style={elemento.estilo}>
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(produtoExemplo.preco_venda)}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Draggable
      bounds="parent"
      handle=".drag-handle"
      position={{
        x: snapToGrid(elemento.posicao.x),
        y: snapToGrid(elemento.posicao.y),
      }}
      grid={[gridSize, gridSize]}
      onDrag={handleDrag}
      onStop={handleDragStop}
    >
      <Box
        ref={elementRef}
        className="drag-handle"
        sx={{
          position: "absolute",
          width: snapToGrid(elemento.tamanho?.width || 100),
          height: snapToGrid(elemento.tamanho?.height || 30),
          cursor: "move",
          padding: "4px",
          border: selecionado ? "2px solid #1976d2" : "1px dashed #ccc",
          backgroundColor: "white",
          userSelect: "none",
          transition: "border-color 0.2s",
          "&:hover": {
            borderColor: "#1976d2",
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        <Box ref={contentRef}>{renderConteudo()}</Box>
        {renderControles()}
        {selecionado && renderResizeHandles()}
      </Box>
    </Draggable>
  );
};

export default ElementoEtiqueta;
