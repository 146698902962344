import { LayoutDefault } from "components";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import AdminRoutes from "./router";

import MobileAppBar from "components/layout/MobileAppBar";
import DesktopDrawer from "components/layout/DesktopDrawer";
import MobileDrawer from "components/layout/MobileDrawer";
import DesktopContent from "components/layout/DesktopContent";
import MobileContent from "components/layout/MobileContent";
import { CssBaseline } from "@mui/material";
import useWindowSize from "hooks/useWindowSize";
import DefaultLoader from "components/layout/DefaultLoader";

const AdminLayout: React.FC = () => {
  const [width] = useWindowSize();
  const isMobile = width < 1050;

  const [open, setOpen] = React.useState(isMobile ? false : true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <LayoutDefault>
      <Suspense>
        <CssBaseline />
        {isMobile ? <MobileAppBar handleDrawerOpen={handleDrawerOpen} /> : null}
        {isMobile ? (
          <MobileDrawer
            open={open}
            handleDrawerClose={handleDrawerClose}
            routes={AdminRoutes}
          />
        ) : (
          <DesktopDrawer
            open={open}
            handleDrawer={handleDrawer}
            routes={AdminRoutes}
          />
        )}
        {isMobile ? (
          <MobileContent>
            <Suspense fallback={<DefaultLoader />}>
              <Outlet />
            </Suspense>
          </MobileContent>
        ) : (
          <DesktopContent open={open}>
            <Suspense fallback={<DefaultLoader />}>
              <Outlet />
            </Suspense>
          </DesktopContent>
        )}
      </Suspense>
    </LayoutDefault>
  );
};

export default AdminLayout;
