/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
interface Array<T> {
  sumBy(fn: (val: T) => number | string): number;
}

Array.prototype.sumBy = function (
  this: any[],
  fn: (val: any) => number | string
): number {
  return this.map(typeof fn === "function" ? fn : (val) => val[fn]).reduce(
    (acc, val) => Number(acc) + Number(val),
    0
  );
};
