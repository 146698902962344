import useCurrentRoute from "hooks/useCurrentRoute";
import React from "react";
import { useNavigate } from "react-router-dom";
import BaseForm from "./shared/form";

interface NuvelRawFormProps {
  children: React.ReactNode;
  onSubmit: (stopLoading: () => void) => void;
  buttonsProps?: {
    save_label?: string;
    cancel_label?: string;
  };
  title?: string | (() => React.ReactNode);
}

const NuvelRawForm = ({
  children,
  onSubmit,
  buttonsProps,
  title,
}: NuvelRawFormProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const route = useCurrentRoute(1);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (onSubmit) {
      return onSubmit(() => setLoading(false));
    }
  };

  return (
    <BaseForm
      handleSubmit={handleSubmit}
      loading={loading}
      route={route}
      navigate={navigate}
      buttonsProps={buttonsProps}
      title={title}
    >
      {children}
    </BaseForm>
  );
};

export default NuvelRawForm;
