import { Typography } from "@mui/material";
import NuvelCheckbox from "components/nuvel/Checkbox";
import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelGrid from "components/nuvel/Grid";
import NuvelTextField from "components/nuvel/TextField";
import AccordionContato from "components/retaguarda/accordions/contato";
import AccordionEndereco from "components/retaguarda/accordions/endereco";
import { ParceiroDeNegocioSerializer } from "data/interfaces/core/ParceiroDeNegocioSerializer";
import React from "react";
import { handleChange } from "utils/functions";

interface TabPrincipalProps {
  state: ParceiroDeNegocioSerializer;
  setState: React.Dispatch<React.SetStateAction<ParceiroDeNegocioSerializer>>;
}

const TabPrincipal: React.FC<TabPrincipalProps> = ({ state, setState }) => {
  const isCnpj = state.cpf_cnpj?.length > 11;
  return (
    <NuvelGrid container spacing={2}>
      <NuvelGrid xs={12} md={5}>
        <NuvelTextField
          label={isCnpj ? "CNPJ" : "CPF"}
          mask={isCnpj ? "00.000.000/0000-00" : "000.000.000-000"}
          normalize={(value) =>
            value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
          }
          onChange={(e) => handleChange(e, setState)}
          name="cpf_cnpj"
          value={state.cpf_cnpj}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={3}>
        <NuvelTextField
          label="IE / RG"
          onChange={(e) => handleChange(e, setState)}
          name="rg_ie"
          type="number"
          value={state.rg_ie}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={2}>
        <NuvelTextField
          label="IM"
          onChange={(e) => handleChange(e, setState)}
          name="im"
          value={state.im}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={2}>
        <NuvelDatePicker
          label={isCnpj ? "Dt. Abertura" : "Dt. Nascimento"}
          onChange={(e) => handleChange(e, setState)}
          name="data_nascimento"
          value={state.data_nascimento}
        />
      </NuvelGrid>
      <NuvelGrid xs={12}>
        <NuvelTextField
          label={isCnpj ? "Razão Social" : "Nome"}
          onChange={(e) => handleChange(e, setState)}
          name="razao_social_nome"
          value={state.razao_social_nome}
        />
      </NuvelGrid>
      <NuvelGrid xs={12}>
        <NuvelTextField
          label={isCnpj ? "Nome Fantasia" : "Apelido"}
          onChange={(e) => handleChange(e, setState)}
          name="nome_fantasia"
          value={state.nome_fantasia}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={12}>
        <NuvelGrid container>
          <NuvelGrid
            xs={12}
            md={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">Tipo de Parceiro:</Typography>
          </NuvelGrid>
          <NuvelGrid xs={12} md={10}>
            <NuvelCheckbox
              checked={state.cliente}
              name="cliente"
              onChange={(e) => handleChange(e, setState)}
              label="Cliente"
            />
            <NuvelCheckbox
              checked={state.func}
              name="func"
              onChange={(e) => handleChange(e, setState)}
              label="Funcionário"
            />
            <NuvelCheckbox
              checked={state.fornecedor}
              name="fornecedor"
              onChange={(e) => handleChange(e, setState)}
              label="Fornecedor"
            />
            <NuvelCheckbox
              checked={state.transpor}
              name="transpor"
              onChange={(e) => handleChange(e, setState)}
              label="Transportador"
            />
            <NuvelCheckbox
              checked={state.lojista}
              name="lojista"
              onChange={(e) => handleChange(e, setState)}
              label="Lojista"
            />
          </NuvelGrid>
        </NuvelGrid>
      </NuvelGrid>
      <NuvelGrid xs={12} md={12}>
        <AccordionEndereco state={state} setState={setState} />
        <AccordionContato
          contatos={state.contatos}
          setContatos={(contatos) => setState({ ...state, contatos })}
          isMultiple
        />
      </NuvelGrid>
    </NuvelGrid>
  );
};

export default TabPrincipal;
