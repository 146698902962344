import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { RouteInterface } from "utils/router";
import AuthRoutes from "layouts/auth/router";
import RetaguardaRoutes from "layouts/retaguarda/router";
import AdminRoutes from "layouts/admin/router";
import SupportRoutes from "layouts/support/router";

const matchPathWithParams = (path: string, pathname: string): boolean => {
  const pathSegments = path.split("/");
  const pathnameSegments = pathname.split("/");

  if (
    pathSegments.length !== pathnameSegments.length &&
    pathSegments.length + 1 !== pathnameSegments.length
  ) {
    return false;
  }

  return pathSegments.every((segment, index) => {
    return segment.startsWith(":") || segment === pathnameSegments[index];
  });
};

const findRouteDetail = (
  routes: RouteInterface[],
  pathname: string
): RouteInterface | null => {
  for (const route of routes) {
    // Check if the pathname matches the route path directly
    if (route.path === pathname) {
      return route;
    }

    // Check if the pathname matches the form path (with a dynamic ID at the end)
    if (
      Object.keys(route).includes("form") &&
      matchPathWithParams(`${route.path}/:id`, pathname)
    ) {
      return route;
    }

    // Recursively check child routes
    if (route.children) {
      const childRoute = findRouteDetail(
        route.children as RouteInterface[],
        pathname
      );
      if (childRoute) {
        return childRoute;
      }
    }
  }
  return null;
};

// eslint-disable-next-line no-constant-binary-expression
const useCurrentRoute = (layout = 1 || 2 || 3) => {
  const location = useLocation();
  let routes: RouteInterface[] = AuthRoutes;

  if (layout === 1) {
    routes = RetaguardaRoutes;
  } else if (layout === 2) {
    routes = AdminRoutes;
  } else {
    routes = SupportRoutes;
  }

  const currentRoute = useMemo(() => {
    return findRouteDetail(routes, location.pathname);
  }, [location.pathname, routes]);

  return currentRoute;
};

export default useCurrentRoute;
