import { ContaBancariaSerializer } from "data/interfaces/financeiro/ContaBancariaSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "contabancaria";

class ContaBancariaModel extends MainModule<
  ContaBancariaSerializer,
  ContaBancariaSerializer
> {}

export default new ContaBancariaModel(model_url);
