import { Chip } from "@mui/material";
import { TableColumn } from "components/nuvel/lists";
import { CaixaPDVSerializer } from "data/interfaces/pdv/CaixaPDVSerializer";
import dayjs from "dayjs";

export const RETAGUARDA_CAIXA_LIST: TableColumn<CaixaPDVSerializer>[] = [
  {
    label: "ID",
    accessor: (row) => `#${row.id}`,
    minWidth: 300,
  },
  {
    label: "Data de Abertura",
    accessor: (row) =>
      row.data_abertura &&
      dayjs(row.data_abertura).format("DD/MM/YYYY HH:mm:ss"),
    minWidth: 300,
  },
  {
    label: "Data de Fechamento",
    accessor: (row) =>
      row.data_fechamento &&
      dayjs(row.data_fechamento).format("DD/MM/YYYY HH:mm:ss"),
    minWidth: 300,
  },
  {
    label: "Status",
    accessor: (row) =>
      row.status ? (
        <Chip
          style={{ backgroundColor: "#74c778", color: "white" }}
          label="Fechado"
          color="success"
        />
      ) : (
        <Chip
          style={{ backgroundColor: "#ffd33e", color: "white" }}
          label="Aberto"
          color="warning"
        />
      ),
    minWidth: 300,
  },
  {
    label: "Operador",
    accessor: "operador",
    minWidth: 300,
  },
];

export const RETAGUARDA_CAIXA_ORDERING: (keyof CaixaPDVSerializer)[] = ["id"];
