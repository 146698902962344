import { NuvelAutoForm } from "components/nuvel/forms";
import { RETAGUARDA_METADEVENDA_FORM } from "constants/retaguarda/financeiro/meta_de_venda";
import { MetaDeVendaModel } from "data/models/financeiro";
import React from "react";

const RetMetasDeVendasForm: React.FC = () => {
  return (
    <NuvelAutoForm
      model={MetaDeVendaModel}
      fields={RETAGUARDA_METADEVENDA_FORM}
    />
  );
};

export default RetMetasDeVendasForm;
