import { TransacaoPrestacaoSerializer } from "data/interfaces/financeiro/TransacaoPrestacaoSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "transacoes_prestacao";

class TransacaoPrestacao extends MainModule<
  TransacaoPrestacaoSerializer,
  TransacaoPrestacaoSerializer
> {}

export default new TransacaoPrestacao(model_url);
