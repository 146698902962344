/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import AUTOCOMPLETE_MODELS, {
  ValidReference,
} from "constants/nuvel/Autocomplete";
import useDebounce from "hooks/useDebounce";
import { Chip } from "@mui/material";

interface OptionType {
  label: string;
  value: number | string;
}

interface NuvelAutocompleteProps {
  name: string;
  reference: ValidReference;
  label: string;
  value: undefined | string | number | (string | number)[];
  onChange: (
    event: { target: { name: string; value: string | number } },
    value: string | number | (string | number)[] | undefined,
    filtered_value?: any,
    reason?: string,
    details?: any
  ) => void;
  multiple?: boolean;
  disabled?: boolean;
  filter?: any;
  clearAfterSelect?: boolean;
  helperText?: string;
  error?: boolean;
}

const NuvelAutocomplete: React.FC<NuvelAutocompleteProps> = React.memo(
  ({
    name,
    reference,
    label,
    value,
    onChange,
    multiple = false,
    filter,
    clearAfterSelect = false,
    helperText,
    error,
    ...props
  }: NuvelAutocompleteProps) => {
    const [options, setOptions] = React.useState<OptionType[] | undefined>();
    const [inputValue, setInputValue] = React.useState<string>("");

    const debouncedInputValue = useDebounce(inputValue, 250);

    const referenced_model = React.useMemo(() => {
      return AUTOCOMPLETE_MODELS.find((model) => model.reference === reference);
    }, [reference]);

    if (!referenced_model) {
      throw new Error(
        `Modelo de autocomplete não encontrado para a referência ${reference}`
      );
    }

    const { data, isValidating } = referenced_model.model.useModel({
      search: debouncedInputValue,
      ...(referenced_model.filters || {}),
      ...(filter || {}),
    });

    React.useEffect(() => {
      if (data && referenced_model && !isValidating) {
        setOptions(
          data.rows
            .filter((item: any) => item[referenced_model.label_by] !== "")
            .map((item: any) => ({
              label: item[referenced_model.label_by] as string,
              value: item[referenced_model.value_by] as number | string,
            }))
        );
      }
    }, [data, referenced_model, isValidating]);

    const selectedOptions = React.useMemo(() => {
      if (multiple) {
        return (
          options?.filter((option) =>
            (value as (string | number)[]).includes(option.value)
          ) || []
        );
      } else {
        return options?.find((option) => option.value === value) || null;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleInputChange = React.useCallback(
      (_event: React.SyntheticEvent, newInputValue: string) => {
        setInputValue(newInputValue);
      },
      []
    );

    const handleChange = React.useCallback(
      (
        _event: React.SyntheticEvent,
        newValue: OptionType | OptionType[] | null,
        fullValue: any,
        _reason: string,
        _details?: any
      ) => {
        if (multiple) {
          onChange(
            {
              target: {
                name,
                value: (newValue as OptionType)?.value as string,
              },
            },
            (newValue as OptionType[]).map((option) => option.value),
            fullValue,
            _reason,
            _details
          );
        } else {
          onChange(
            {
              target: {
                name,
                value: (newValue as OptionType)?.value as string,
              },
            },
            (newValue as OptionType)?.value || undefined,
            fullValue,
            _reason,
            _details
          );
        }
        if (clearAfterSelect) {
          setInputValue("");
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onChange, multiple]
    );

    return (
      <Autocomplete<OptionType, boolean, false, false>
        {...props}
        multiple={multiple}
        loading={options === undefined}
        disablePortal
        id={`combo-box-${name}`}
        options={options || []}
        value={multiple ? selectedOptions : selectedOptions || null} // Ajuste aqui
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={(e, v, r, d) =>
          handleChange(
            e,
            v,
            multiple
              ? v
              : data?.rows.find(
                  (item: any) =>
                    item[referenced_model.value_by] === (v as OptionType)?.value
                ),
            r,
            d
          )
        }
        fullWidth
        renderInput={(params) => (
          <TextField
            variant="filled"
            {...params}
            label={label}
            helperText={helperText}
            error={error}
          />
        )}
        inputMode="search"
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              color="primary"
              label={option.label}
              {...getTagProps({ index })}
            />
          ))
        }
      />
    );
  }
);

export default NuvelAutocomplete;
