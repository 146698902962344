import {
  ButtonBase,
  Divider,
  Fab,
  FabProps,
  Icon,
  Typography,
} from "@mui/material";
import { alpha, Box } from "@mui/system";
import PaperWithLoad from "components/nuvel/PaperWithLoad";
import {
  TransacaoFilters,
  defaultFiltros,
  legends,
} from "constants/retaguarda/financeiro/contas_a_pagar";
import React, {
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
  useState,
} from "react";

import Filtros from "./filtros";
import ContasAPagarList from "./list";
import { TIPO_TRANSACAO } from "constants/retaguarda/financeiro/transacoes";
import ModalCreate from "./modals/create";
import { TransacaoSerializer } from "data/interfaces/financeiro/TransacaoSerializer";
import { TransacaoPrestacaoSerializer } from "data/interfaces/financeiro/TransacaoPrestacaoSerializer";
import { motion } from "framer-motion";
import ModalPagamento from "./modals/pagamento";
import { TransacaoFaturamentoSerializer } from "data/interfaces/financeiro/TransacaoFaturamentoSerializer";
import ModalEstorno from "./modals/estorno";
import ModalAlterarData from "./modals/alterar_data";
import { TransacaoPrestacaoModel } from "data/models";

const AnimatedFab = motion(
  Fab as ForwardRefExoticComponent<FabProps & RefAttributes<HTMLButtonElement>>
);

const ContasAPagarForm: React.FC = () => {
  const [selected, setSelected] = useState<TransacaoPrestacaoSerializer[]>([]);
  const [primeiraSelecao, setPrimeiraSelecao] = useState(true);
  const [estornos, setEstornos] = useState<TransacaoFaturamentoSerializer[]>(
    []
  );
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    if (selected.length > 0 && primeiraSelecao) {
      setPrimeiraSelecao(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const [filters, setFilters] = useState<TransacaoFilters>(defaultFiltros);
  const [modal, setModal] = useState<{
    open: boolean;
    id: number;
    transacao: TransacaoSerializer | undefined;
    prestacao: TransacaoPrestacaoSerializer | undefined;
  }>({
    open: false,
    id: 0,
    transacao: undefined,
    prestacao: undefined,
  });

  const { data, isValidating, mutate } = TransacaoPrestacaoModel.useModel({
    ...TIPO_TRANSACAO.CONTAS_A_PAGAR,
    ...filters,
    data_inicial: filters.data_inicial?.toISOString(),
    data_final: filters.data_final?.toISOString(),
    status__in:
      filters.statusList.length > 0
        ? filters.statusList.join(",")
        : filters.status === -1
        ? undefined
        : filters.status,
    status: undefined,
    statusList: undefined,
    page: page,
  });

  const handleModal = (
    id: number,
    transacao?: TransacaoSerializer,
    prestacao?: TransacaoPrestacaoSerializer
  ) => {
    setModal((_v) => ({
      ..._v,
      open: !_v.open,
      id,
      transacao: transacao,
      prestacao: prestacao,
    }));
  };

  const onClickLegend = (status: number) => {
    let newStatusList = [...filters.statusList];
    if (newStatusList?.includes(status)) {
      newStatusList = newStatusList.filter(
        (item) => Number(item) !== Number(status)
      );
    } else {
      newStatusList.push(status);
    }
    setFilters((_v) => ({ ..._v, statusList: newStatusList }));
  };

  const handleChangePage = (_event: unknown, value: number) => {
    setPage(value);
  };

  const rows_counts = data?.totalCount || 0;
  const total_pages = Math.ceil(rows_counts / 10);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <PaperWithLoad
        loading={!data || isValidating}
        sx={{
          width: "100%",
          borderRadius: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box p={2}>
          <Filtros state={filters} setState={setFilters} />
        </Box>
        <Divider variant="middle" />
        <ContasAPagarList
          data={data}
          handleModal={handleModal}
          selected={selected}
          setSelected={setSelected}
          setEstornos={setEstornos}
          mutate={mutate}
          page={page}
          total_pages={total_pages}
          handleChangePage={handleChangePage}
        />
        <Divider variant="middle" />
        <Box p={1.5} display="flex" justifyContent="left">
          {legends.map((opt, index) => {
            const isSelected = filters.statusList.includes(opt.status);
            return (
              <ButtonBase
                key={index}
                sx={{
                  display: "flex",
                  justifyItems: "center",
                  alignItems: "center",
                  mr: 2,
                  border: `1px solid ${opt.color}`,
                  p: 0.5,
                  pr: 1,
                  pl: 1,
                  borderRadius: 10,
                  backgroundColor: isSelected ? alpha(opt.color, 0.2) : "none",
                  "&:hover": {
                    backgroundColor: alpha(opt.color, isSelected ? 0.1 : 0.2),
                  },
                }}
                onClick={() => onClickLegend(opt.status)}
              >
                <Icon style={{ marginRight: 6, color: opt.color }}>
                  {opt.icon}
                </Icon>
                <Typography style={{ color: opt.color }} variant="body1">
                  <b>{opt.text}</b>
                </Typography>
              </ButtonBase>
            );
          })}
        </Box>
        <AnimatedFab
          color="primary"
          aria-label={selected.length > 0 ? "recebimento" : "add"}
          onClick={() => handleModal(selected.length === 0 ? 1 : 2)}
          sx={{
            position: "absolute",
            bottom: 40,
            right: 40,
            width: 70,
            height: 70,
            zIndex: 1000,
          }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          animate={{
            rotate: selected.length > 0 ? 45 : 0,
            backgroundColor: selected.length > 0 ? "#4caf50" : "#1976d2",
          }}
          transition={{ duration: 0.3 }}
        >
          <Icon fontSize="large">add</Icon>
        </AnimatedFab>

        {selected.length > 0 && (
          <motion.div
            initial={
              primeiraSelecao ? { opacity: 0, y: 20 } : { opacity: 1, y: 0 }
            }
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
            style={{
              position: "absolute",
              bottom: 120,
              right: 30,
              zIndex: 1000,
            }}
          >
            <Typography variant="body1" color="success">
              + Pagamento
            </Typography>
          </motion.div>
        )}
      </PaperWithLoad>
      {modal.id === 1 && (
        <ModalCreate
          id={1}
          modal={modal}
          handleModal={handleModal}
          mutate={mutate}
        />
      )}
      {modal.id === 2 && (
        <ModalPagamento
          id={2}
          modal={modal}
          handleModal={handleModal}
          mutate={mutate}
          list={selected}
          setSelectedOriginal={setSelected}
        />
      )}
      {modal.id === 3 && (
        <ModalEstorno
          id={3}
          modal={modal}
          handleModal={handleModal}
          estornos={estornos}
          mutate={mutate}
        />
      )}
      {modal.id === 4 && (
        <ModalAlterarData
          id={4}
          modal={modal}
          handleModal={handleModal}
          mutate={mutate}
        />
      )}
    </Box>
  );
};

export default ContasAPagarForm;
