import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
} from "@mui/material";

interface CheckboxProps extends Omit<MuiCheckboxProps, "onChange"> {
  label?: string;
  onChange?: (event: { target: { name: string; value: boolean } }) => void;
}

export default function NuvelCheckbox(props: CheckboxProps) {
  return (
    <FormControlLabel
      style={{
        ...props.style,
      }}
      control={
        <MuiCheckbox
          {...props}
          onChange={(e) => {
            if (!props.onChange) {
              throw new Error(
                "Não foi informado o onChange para o CheckBox " + e.target.name
              );
            } else {
              props.onChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked,
                },
              });
            }
          }}
        />
      }
      label={props.label}
    />
  );
}
