import { NuvelAutoForm } from "components/nuvel/forms";
import { RETAGUARDA_IMPOSTO_FORM } from "constants/retaguarda/fiscal/imposto";
import { ImpostoModel } from "data/models";
import React from "react";

const RetImpostosForm: React.FC = () => {
  return (
    <NuvelAutoForm fields={RETAGUARDA_IMPOSTO_FORM} model={ImpostoModel} />
  );
};

export default RetImpostosForm;
