import { Box, Button } from "@mui/material";
import React from "react";
import { NavigateFunction } from "react-router-dom";

interface FormsButtonsProps {
  navigate: NavigateFunction;
  buttonsProps?: {
    save_label?: string;
    cancel_label?: string;
  };
}

const FormsButtons: React.FC<FormsButtonsProps> = ({
  navigate,
  buttonsProps,
}) => {
  return (
    <Box
      pt={2}
      sx={{ height: "52.5px" }}
      display="flex"
      justifyContent="space-between"
    >
      <Button
        variant="contained"
        onClick={() => {
          if (window.history.length > 1) {
            navigate(-1);
          } else {
            const newUrl = window.location.pathname
              .split("/")
              .slice(0, -1)
              .join("/");
            navigate(newUrl);
          }
        }}
      >
        {buttonsProps && buttonsProps.cancel_label
          ? buttonsProps.cancel_label
          : "Voltar"}
      </Button>
      <Button variant="contained" type="submit">
        {buttonsProps && buttonsProps.save_label
          ? buttonsProps.save_label
          : "Salvar"}
      </Button>
    </Box>
  );
};

export default FormsButtons;
