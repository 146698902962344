import { PromocaoSerializer } from "data/interfaces/estoque/PromocaoSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "estoque/promocao";

class PromocaoModel extends MainModule<
  PromocaoSerializer,
  PromocaoSerializer
> {}

export default new PromocaoModel(model_url);
