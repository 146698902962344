/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputAdornment, InputBase } from "@mui/material";
import IntlCurrencyInput from "react-intl-currency-input";

export default function InputField(props: any) {
  const inputProps: any = props.perc
    ? {
        startAdornment: <InputAdornment position="start"> % </InputAdornment>,
      }
    : {
        startAdornment: <InputAdornment position="start"> R$ </InputAdornment>,
      };

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "decimal",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  if (props.money || props.perc) {
    return (
      <InputBase
        {...props}
        value={Number(props.value)}
        inputComponent={IntlCurrencyInput}
        inputProps={{ ...inputProps, currency: "BRL", config: currencyConfig }}
        onChange={(_e: any, v: any) =>
          props.onChange({
            target: {
              name: props.name,
              value: v,
            },
          })
        }
        fullWidth
        id={props.name}
      />
    );
  } else {
    return <InputBase {...props} fullWidth id={props.name} />;
  }
}
