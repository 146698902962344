import API from "data/api";
import { AppConstants } from "provider";
import { NavigateFunction } from "react-router-dom";
import useSWR from "swr";

interface UserRequest {
  token: string;
  created: boolean;
  ifood: boolean;
  pagina_inicial: string | null;
  bookmarks: string[];
  permissoes: string;
  layout: number;
  is_superuser: boolean;
}

class AuthController {
  private appContext: AppConstants;
  private navigation: NavigateFunction;
  private setLoading: (value: boolean) => void;

  constructor(
    context: AppConstants,
    navigation: NavigateFunction,
    setLoading: (value: boolean) => void
  ) {
    this.appContext = context;
    this.navigation = navigation;
    this.setLoading = setLoading;
  }

  async login(licence: string, password: string, user: string) {
    const { showSnack, dialog } = this.appContext;

    if (!licence || !password || !user) {
      this.setLoading(false);
      showSnack("Preencha todos os campos.", 5, "error");
      return;
    }

    try {
      const response = await API().post(`obtain-auth-token/`, {
        licenca: licence,
        password,
        username: user,
      });

      if (response.status !== 200) {
        showSnack("Erro ao realizar login.", 5, "error");
        return false;
      }

      const data = response.data as UserRequest;

      showSnack("Login realizado com sucesso!", 5, "success");

      this.appContext.configuration.setPermissions(data.permissoes);
      localStorage.setItem("token", `${data.token}`);
      localStorage.setItem("ifood", `${data.ifood}`);
      localStorage.setItem("licenca", licence);

      this.setLoading(false);

      if (data.is_superuser && licence === "nuvel") {
        return dialog.showLayoutDialog((layout) => {
          if (layout === 1) {
            return this.navigation("/retaguarda/home");
          }
          if (layout === 2) {
            return this.navigation("/support/home");
          }
          if (layout === 3) {
            return this.navigation("/admin/home");
          }
        });
      }

      if (data.layout === 2) {
        return this.navigation("/support/");
      }
      if (data.layout === 3) {
        return this.navigation("/admin/");
      }

      if (data.pagina_inicial) {
        this.navigation(data.pagina_inicial);
      } else {
        if (
          data.permissoes &&
          data.permissoes.includes("DASHBOARD_VENDAS_VISAO_GERAL")
        ) {
          return this.navigation("/retaguarda/dashboard/geral");
        }
        if (!data.permissoes && user !== "suporte") {
          return this.navigation("/retaguarda/dashboard/geral");
        }
        if (user === "suporte") {
          return this.navigation("/retaguarda/home");
        }
        return this.navigation("/retaguarda/home");
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      this.setLoading(false);
      showSnack("Erro ao realizar login.", 5, "error");
    }
  }

  static useCurrentUser() {
    const fetcher = (url: string) =>
      API()
        .get(url)
        .then((res) => res.data);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSWR("user/current/", fetcher);
  }
  static useCurrentFranquia() {
    const fetcher = (url: string) =>
      API()
        .get(url)
        .then((res) => res.data?.licenca?.franquia);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSWR("user/current/", fetcher);
  }
  static useUserIsSupport() {
    const fetcher = (url: string) =>
      API()
        .get(url)
        .then((res) => res.data?.usuario === "suporte");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSWR("user/current/", fetcher);
  }
}

export default AuthController;
