/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { fromEvent } from "file-selector";
import { Button } from "@mui/material";

const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function openFileDialog(accept: any, multiple: any, callback: any) {
  // this function must be called from  a user
  // activation event (ie an onclick event)

  // Create an input element
  const inputElement = document.createElement("input");
  // Set its type to file
  inputElement.type = "file";
  // Set accept to the file types you want the user to select.
  // Include both the file extension and the mime type
  inputElement.accept = accept;
  // Accept multiple files
  inputElement.multiple = multiple;
  // set onchange event to call callback when user has selected file
  inputElement.addEventListener("change", (evt) => {
    fromEvent(evt).then(async (files) => {
      files.forEach((file: any) => {
        (async () => {
          callback({
            name: file.name,
            content: await toBase64(file),
          });
        })();
      });
    });
  });
  // dispatch a click event to open the file dialog
  inputElement.dispatchEvent(new MouseEvent("click"));
}

interface NuvelInputFileProps {
  name: string;
  onChange: any;
  accept?: string[] | string;
}

const NuvelInputFile: React.FC<NuvelInputFileProps> = ({
  onChange,
  accept = "*",
}) => {
  return (
    <label htmlFor="contained-button-file">
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => {
          openFileDialog(accept, false, (a: any) => {
            onChange(a);
          });
        }}
        sx={{ height: "100%" }}
      >
        Anexar
      </Button>
    </label>
  );
};

export default NuvelInputFile;
