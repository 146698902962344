import React from "react";
import { motion, useAnimation } from "framer-motion";
import { Logo_Light } from "assets/img";

interface LoadingAnimationProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ setLoading }) => {
  const controls = useAnimation();

  const startSecondAnimation = async () => {
    setTimeout(
      async () => {
        await controls.start({
          opacity: 0,
          transition: { duration: import.meta.env.DEV ? 0.2 : 0.3 },
        });

        setLoading(false);
      },
      import.meta.env.DEV ? 0 : 500
    );
  };

  return (
    <motion.div
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0, opacity: 0 }}
      transition={{
        duration: import.meta.env.DEV ? 0.5 : 0.7,
        ease: "easeInOut",
        onComplete: startSecondAnimation,
      }}
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999,
      }}
    >
      <motion.img
        src={Logo_Light}
        alt="Logo da Empresa"
        style={{ width: 150 }}
        animate={controls}
      />
    </motion.div>
  );
};

export default LoadingAnimation;
