/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

import Modal from "components/nuvel/Modal";
import { FechamentoCegoModel } from "data/models/pdv";
import { FormasDePagamentoModel } from "data/models/financeiro";
import NuvelGrid from "components/nuvel/Grid";
import NuvelTextField from "components/nuvel/TextField";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NuvelSelect from "components/nuvel/Select";
import { Delete } from "@mui/icons-material";

interface FechamentoCegoProps {
  modal: any;
  handleModal: any;
  data: any;
  setData: any;
}

const FechamentoCego: React.FC<FechamentoCegoProps> = ({
  modal,
  handleModal,
  data,
  setData,
}) => {
  const [state, setState] = useState<any>({});
  const [fechamentos_cegos_formas, setFechamentosCegosFormas] = useState<any[]>(
    []
  );
  const [formasPdv, setFormasPdv] = useState<any[]>([]);

  const loadFormas = async () => {
    const formas = await FormasDePagamentoModel.get(undefined, {
      page: 1,
      page_size: 9999,
    });
    setFormasPdv(formas.rows);
  };

  const deletePayment = (index: number) => {
    const newFechamentosCegosFormas = [...fechamentos_cegos_formas];
    newFechamentosCegosFormas.splice(index, 1);
    setFechamentosCegosFormas(newFechamentosCegosFormas);
  };

  useEffect(() => {
    if (data.caixa) {
      setState({
        caixa: data.caixa.id,
        id: data.fechamento_cego.id,
        troco_inicial: data.fechamento_cego.troco_inicial
          ? data.fechamento_cego.troco_inicial
          : 0,
        troco_final: data.fechamento_cego.troco_final
          ? data.fechamento_cego.troco_final
          : 0,
        desconto_total: data.fechamento_cego.desconto_total
          ? data.fechamento_cego.desconto_total
          : 0,
        sangria: data.fechamento_cego.sangria
          ? data.fechamento_cego.sangria
          : 0,
        suprimento: data.fechamento_cego.suprimento
          ? data.fechamento_cego.suprimento
          : 0,
        status: data.fechamento_cego.status ? data.fechamento_cego.status : 0,
        fechamentos_cegos_formas:
          data.fechamento_cego.fechamentos_cegos_formas.length > 0
            ? data.fechamento_cego.fechamentos_cegos_formas
            : [],
      });
      setFechamentosCegosFormas(
        data.fechamento_cego.fechamentos_cegos_formas.length > 0
          ? data.fechamento_cego.fechamentos_cegos_formas
          : []
      );
      loadFormas();
    }
  }, [data]);

  const handleChangeIdx = (
    e: { target: { name: any; value: any } },
    index: number
  ) => {
    const { name, value } = e.target;
    const newFechamentosCegosFormas = [...fechamentos_cegos_formas];

    newFechamentosCegosFormas[index][name] = value;

    const finalizadora = formasPdv.find(
      (item) =>
        item.id === fechamentos_cegos_formas[index].caixa_pagamento_forma
    );
    newFechamentosCegosFormas[index].dinheiro =
      finalizadora?.finalizadora === 1;
    newFechamentosCegosFormas[index].forma_nome = finalizadora?.forma;

    setFechamentosCegosFormas(newFechamentosCegosFormas);
  };

  const addNewForma = () => {
    setFechamentosCegosFormas([
      ...fechamentos_cegos_formas,
      {
        caixa_pagamento_forma: 0,
        total: 0,
        troco: 0,
        forma_nome: "",
      },
    ]);
  };

  return (
    <Modal
      minWidth="lg"
      fullWidth
      title={"Fechamento Cego"}
      open={modal.id === 1 ? modal.open : false}
      btnCancel={() => {
        handleModal(1);
      }}
      btnSave={async () => {
        setState({
          ...state,
          fechamentos_cegos_formas,
          status: 1,
        });

        setData({
          ...data,
          fechamento_cego: {
            ...state,
            fechamentos_cegos_formas,
            status: 1,
          },
        });

        await FechamentoCegoModel.save({
          ...state,
          fechamentos_cegos_formas,
          status: 1,
        });

        handleModal(2, true);
      }}
    >
      <NuvelGrid container spacing={1}>
        <NuvelGrid xs={12} md={6}>
          <NuvelTextField
            label="Troco Inicial"
            value={state.troco_inicial}
            onChange={(e) =>
              setState({ ...state, troco_inicial: e.target.value })
            }
            money
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={6}>
          <NuvelTextField
            label="Troco Final"
            value={state.troco_final}
            onChange={(e) =>
              setState({ ...state, troco_final: e.target.value })
            }
            money
            color="secondary"
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={4}>
          <NuvelTextField
            label="Sangria"
            value={state.sangria}
            onChange={(e) => setState({ ...state, sangria: e.target.value })}
            money
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={4}>
          <NuvelTextField
            label="Suprimento"
            value={state.suprimento}
            onChange={(e) => setState({ ...state, suprimento: e.target.value })}
            money
          />
        </NuvelGrid>
        {formasPdv.length > 0 && (
          <NuvelGrid xs={12} md={4}>
            <Button
              disabled={fechamentos_cegos_formas.length === formasPdv.length}
              onClick={addNewForma}
              color="primary"
              variant="contained"
              style={{ height: "100%" }}
            >
              Add Pag.
            </Button>
          </NuvelGrid>
        )}
        <NuvelGrid xs={12}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Forma</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Troco (Só dinheiro)</TableCell>
                  <TableCell>Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fechamentos_cegos_formas.map((_item, index) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={`row_group_${index}`}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        key={`cell_group1_${index}`}
                      >
                        <NuvelSelect
                          style={{ width: "100%" }}
                          label="Pagamento"
                          name="caixa_pagamento_forma"
                          value={
                            fechamentos_cegos_formas[index]
                              .caixa_pagamento_forma
                          }
                          onChange={(e) => handleChangeIdx(e, index)}
                          options={
                            formasPdv.length > 0
                              ? formasPdv.map((item) => {
                                  return {
                                    label: item.forma,
                                    value: item.id,
                                  };
                                })
                              : []
                          }
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        key={`cell_group2_${index}`}
                      >
                        <NuvelTextField
                          label="Total"
                          onChange={(e) => handleChangeIdx(e, index)}
                          name="total"
                          money
                          value={fechamentos_cegos_formas[index].total}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        key={`cell_group3_${index}`}
                      >
                        {fechamentos_cegos_formas[index].dinheiro ? (
                          <NuvelTextField
                            label="Troco"
                            onChange={(e) => handleChangeIdx(e, index)}
                            name="troco"
                            money
                            value={fechamentos_cegos_formas[index].troco}
                            fullWidth
                          />
                        ) : null}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        key={`cell_group2_${index}`}
                      >
                        <IconButton
                          color={"error"}
                          aria-label="check"
                          size="small"
                          onClick={() => deletePayment(index)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <NuvelGrid xs={12} md={4}>
                      <NuvelTextField
                        label="Total"
                        name="total"
                        money
                        value={fechamentos_cegos_formas.reduce(
                          (acc, item) => acc + (item.total - item.troco),
                          0
                        )}
                      />
                    </NuvelGrid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </NuvelGrid>
      </NuvelGrid>
    </Modal>
  );
};

export default FechamentoCego;
