import { AutocompleteRender } from "./AutoCompleteRender.jsx";
import Dialog from "./dialog.jsx";
import { Button, Grid, Select, TextField } from "@mui/material";
import AutoRelation from "./index.jsx";
import { Add } from "@mui/icons-material";
import NuvelCheckbox from "components/nuvel/Checkbox";

export function ManyToManyRender(
  props,
  _dialog,
  setDialog,
  handleDialogClose,
  handleDialogAdd,
  handleChangeDialog,
  loading,
  classes,
  customM2MSelector
) {
  const options = props.options
    ?.filter((ep) => !props.source.find((op) => ep.id === op.id))
    .filter((ep) => ep.id !== props.mainID);

  const allFieldsFilled = props.fields.every((field) => {
    if (field.isID) return true;
    if (field.required || field.required === undefined) {
      if (field.type === "boolean") {
        return _dialog.fields[field.name] !== undefined;
      }
      return _dialog.fields[field.name];
    }
    return true;
  });

  return (
    <>
      <Grid item xs={12} sm={10} md={10} xg={11}>
        {customM2MSelector ? (
          customM2MSelector(handleDialogAdd)
        ) : (
          <AutocompleteRender
            id={3}
            disabled={props.disableAutoComplete}
            name={props.modelName}
            label={props.modelTitle}
            variant="filled"
            options={options}
            getOptionLabel={(options) => options[props.optionLabel]}
            onChange={(e, value) => {
              if (value) {
                handleDialogAdd("", value);
              } else {
                props.onChange(null);
              }
            }}
          />
        )}
      </Grid>

      <Grid
        item
        xs={12}
        sm={2}
        md={2}
        xg={1}
        className={classes.fullWidth}
        style={{ textAlign: "center" }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => setDialog((v) => ({ ...v, dialogOpen: true }))}
          startIcon={<Add />}
        >
          Novo
        </Button>
        <Dialog
          open={_dialog.dialogOpen}
          title={props.modelTitle}
          handleClose={handleDialogClose}
          handleAdd={handleDialogAdd}
          disabledAdd={loading || !allFieldsFilled}
          disabledClose={loading}
        >
          <Grid container spacing={1}>
            {props.fields
              ? props.fields.map((field, key) => {
                  const fieldValue = _dialog.fields
                    ? _dialog.fields[field.name] || field.default
                    : field.default;
                  if (field.isID) return null;

                  let forceDisabled = false;

                  if (field.depends_on) {
                    const dependsOn = _dialog.fields[field.depends_on];
                    if (field.depends_on_behavior === "hide") {
                      if (!dependsOn) {
                        return null;
                      }
                    }
                    if (!dependsOn) {
                      forceDisabled = true;
                    }
                  }

                  if (field.type === "select") {
                    return (
                      <Grid item xs={12} key={key}>
                        <Select
                          value={fieldValue}
                          onChange={(e) => handleChangeDialog(e)}
                          fullWidth
                          name={field.name}
                          label={field.label}
                          options={
                            field.selectProps.options?.map((op) => ({
                              value: op[field.selectProps.value],
                              label: op[field.selectProps.label],
                            })) || []
                          }
                          disabled={forceDisabled}
                        />
                      </Grid>
                    );
                  }
                  if (field.type === "autocomplete") {
                    return (
                      <Grid item xs={12} key={key}>
                        <AutoRelation
                          disabled={forceDisabled}
                          value={fieldValue}
                          model={field.model}
                          optionLabel={field.acprops.option}
                          onChange={(e) => {
                            handleChangeDialog(e);
                          }}
                          modelName={field.name}
                          modelTitle={field.label}
                          relation={
                            field.acprops.multiple
                              ? "ManyToOneMuliple"
                              : "ManyToOne"
                          }
                          fields={field.fields}
                          returnValue={field.acprops.vk}
                        />
                      </Grid>
                    );
                  }
                  if (field.type === "check") {
                    return (
                      <Grid item xs={12} key={key}>
                        <NuvelCheckbox
                          disabled={forceDisabled}
                          checked={fieldValue}
                          name={field.name}
                          onChange={(e) => handleChangeDialog(e)}
                          label={field.label}
                        />
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={12} key={key}>
                      <TextField
                        onChange={(e) => handleChangeDialog(e)}
                        value={fieldValue}
                        fullWidth
                        name={field.name}
                        mask={field.mask || null}
                        color="primary"
                        variant="filled"
                        label={field.label}
                        inputProps={field.inputProps}
                        type={field.type}
                        style={{
                          opacity: field.hidden ? 0 : 1,
                        }}
                      />
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </Dialog>
      </Grid>
    </>
  );
}
