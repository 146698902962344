import { TransacaoFaturamentoSerializer } from "data/interfaces/financeiro/TransacaoFaturamentoSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "transacoes_faturamento";

class TransacaoFaturamento extends MainModule<
  TransacaoFaturamentoSerializer,
  TransacaoFaturamentoSerializer
> {}

export default new TransacaoFaturamento(model_url);
