/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  calculateSangriaSuprimento,
  sortByName,
  validateField,
  validatePagamentoIgual,
} from "../utils";
import Modal from "components/nuvel/Modal";
import NuvelGrid from "components/nuvel/Grid";
import NuvelTextField from "components/nuvel/TextField";

interface FechamentoComparacaoDialogProps {
  modal: any;
  handleModal: (id: number) => void;
  data: any;
}
const FechamentoComparacaoDialog = ({
  modal,
  handleModal,
  data,
}: FechamentoComparacaoDialogProps) => {
  const [state, setState] = useState<any>({});

  useEffect(() => {
    if (data.caixa) {
      setState({
        caixa: data.caixa,
        fechamento_cego: data.fechamento_cego,
      });
    }
  }, [data]);

  return (
    <Modal
      minWidth="lg"
      fullWidth
      title={"Comparativo"}
      open={modal.id === 2 ? modal.open : false}
      saveText={"Fechar"}
      btnCancel={() => {
        handleModal(2);
      }}
      btnSave={async () => {
        handleModal(2);
      }}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid
          style={{
            boxSizing: "border-box",
            padding: "10px",
          }}
          xs={12}
          sm={6}
        >
          <NuvelGrid container spacing={2}>
            <NuvelGrid xs={12} sm={12}>
              Fechamento do Caixa
            </NuvelGrid>
            <NuvelGrid xs={12} sm={12}>
              <NuvelTextField
                error={validateField(
                  state.fechamento_cego?.troco_inicial,
                  state.caixa?.troco_inicial
                )}
                label="Troco Inicial"
                readOnly
                decimal={true}
                value={state.caixa?.troco_inicial}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={12}>
              <NuvelTextField
                error={validateField(
                  state.fechamento_cego?.troco_final,
                  state.caixa?.troco_final
                )}
                label="Troco Final"
                readOnly
                decimal={true}
                value={state.caixa?.troco_final}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={12}>
              <NuvelTextField
                error={validateField(
                  state.fechamento_cego?.sangria,
                  calculateSangriaSuprimento(state.caixa?.operacoes, 1)
                )}
                label="Sangria"
                readOnly
                decimal={true}
                value={calculateSangriaSuprimento(state.caixa?.operacoes, 1)}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={12}>
              <NuvelTextField
                error={validateField(
                  state.fechamento_cego?.suprimento,
                  calculateSangriaSuprimento(state.caixa?.operacoes, 2)
                )}
                label="Suprimentos"
                readOnly
                decimal={true}
                value={calculateSangriaSuprimento(state.caixa?.operacoes, 2)}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={`row_group`}
                    >
                      <TableCell>Forma de Pagamento</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Troco</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortByName(state.caixa?.pagamentos).map((item, index) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={`row_group_${index}`}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            key={`cell_group1_${index}`}
                          >
                            <NuvelTextField
                              style={{ width: "100%" }}
                              label="Total"
                              readOnly
                              name="total"
                              value={item.forma_nome}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            key={`cell_group2_${index}`}
                          >
                            <NuvelTextField
                              style={{ width: "100%" }}
                              label="Total"
                              readOnly
                              name="total"
                              decimal={true}
                              value={item.total}
                              error={validatePagamentoIgual(
                                item,
                                state?.fechamento_cego?.fechamentos_cegos_formas
                              )}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            key={`cell_group3_${index}`}
                          >
                            {item.id === 1 ? (
                              <NuvelTextField
                                style={{ width: "100%" }}
                                label="Troco"
                                readOnly
                                name="troco"
                                decimal={true}
                                value={item.troco}
                              />
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <NuvelTextField
                          style={{ width: "100%" }}
                          label="Total"
                          name="total"
                          decimal={true}
                          readOnly
                          value={state.caixa?.pagamentos.reduce(
                            (acc: any, item: any) =>
                              acc + (item.total - item.troco),
                            0
                          )}
                          error={validateField(
                            state.caixa?.pagamentos.reduce(
                              (acc: any, item: any) =>
                                acc + (item.total - item.troco),
                              0
                            ),
                            state.fechamento_cego?.fechamentos_cegos_formas.reduce(
                              (acc: any, item: any) =>
                                acc + (item.total - item.troco),
                              0
                            )
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </NuvelGrid>
          </NuvelGrid>
        </NuvelGrid>
        <NuvelGrid
          style={{
            boxSizing: "border-box",
            padding: "10px",
          }}
          xs={12}
          sm={6}
        >
          <NuvelGrid container spacing={2}>
            <NuvelGrid xs={12} sm={12}>
              Fechamento Cego
            </NuvelGrid>
            <NuvelGrid xs={12} sm={12}>
              <NuvelTextField
                error={validateField(
                  state.fechamento_cego?.troco_inicial,
                  state.caixa?.troco_inicial
                )}
                label="Troco Inicial"
                readOnly
                decimal={true}
                value={state.fechamento_cego?.troco_inicial}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={12}>
              <NuvelTextField
                error={validateField(
                  state.fechamento_cego?.troco_final,
                  state.caixa?.troco_final
                )}
                label="Troco Final"
                readOnly
                decimal={true}
                value={state.fechamento_cego?.troco_final}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={12}>
              <NuvelTextField
                error={validateField(
                  state.fechamento_cego?.sangria,
                  calculateSangriaSuprimento(state.caixa?.operacoes, 1)
                )}
                label="Sangria"
                readOnly
                decimal={true}
                value={state.fechamento_cego?.sangria}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={12}>
              <NuvelTextField
                error={validateField(
                  state.fechamento_cego?.suprimento,
                  calculateSangriaSuprimento(state.caixa?.operacoes, 2)
                )}
                label="Suprimentos"
                readOnly
                decimal={true}
                value={state.fechamento_cego?.suprimento}
              />
            </NuvelGrid>
            <NuvelGrid xs={12} sm={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={`row_group`}
                    >
                      <TableCell>Forma de Pagamento</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Troco</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortByName(
                      state.fechamento_cego?.fechamentos_cegos_formas
                    ).map((item, index) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={`row_group_${index}`}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            key={`cell_group1_${index}`}
                          >
                            <NuvelTextField
                              style={{ width: "100%" }}
                              label="Total"
                              readOnly
                              name="total"
                              value={item.forma_nome}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            key={`cell_group2_${index}`}
                          >
                            <NuvelTextField
                              style={{ width: "100%" }}
                              label="Total"
                              readOnly
                              name="total"
                              decimal={true}
                              value={item.total}
                              error={validatePagamentoIgual(
                                item,
                                state?.caixa?.pagamentos
                              )}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            key={`cell_group3_${index}`}
                          >
                            {item.finalizadora === 1 ? (
                              <NuvelTextField
                                style={{ width: "100%" }}
                                label="Troco"
                                readOnly
                                name="troco"
                                decimal={true}
                                value={item.troco}
                              />
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <NuvelTextField
                          style={{ width: "100%" }}
                          label="Total"
                          name="total"
                          decimal={true}
                          readOnly
                          value={state.fechamento_cego?.fechamentos_cegos_formas.reduce(
                            (acc: any, item: any) =>
                              acc + (item.total - item.troco),
                            0
                          )}
                          error={validateField(
                            state.caixa?.pagamentos.reduce(
                              (acc: any, item: any) =>
                                acc + (item.total - item.troco),
                              0
                            ),
                            state.fechamento_cego?.fechamentos_cegos_formas.reduce(
                              (acc: any, item: any) =>
                                acc + (item.total - item.troco),
                              0
                            )
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </NuvelGrid>
          </NuvelGrid>
        </NuvelGrid>
      </NuvelGrid>
    </Modal>
  );
};

export default FechamentoComparacaoDialog;
