import React from "react";
import { Box } from "@mui/material";

interface Props {
  width: number;
  height: number;
  spacing: number; // em pixels
}

const EtiquetaGrid: React.FC<Props> = ({ width, height, spacing }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
      }}
    >
      {/* Linhas verticais */}
      {Array.from({ length: Math.floor(width / spacing) }).map((_, i) => (
        <Box
          key={`v-${i}`}
          sx={{
            position: "absolute",
            left: i * spacing,
            top: 0,
            width: "1px",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.1)",
            "&::after": {
              content: '"•"',
              position: "absolute",
              top: -10,
              left: -3,
              color: "rgba(0,0,0,0.3)",
            },
          }}
        />
      ))}

      {/* Linhas horizontais */}
      {Array.from({ length: Math.floor(height / spacing) }).map((_, i) => (
        <Box
          key={`h-${i}`}
          sx={{
            position: "absolute",
            top: i * spacing,
            left: 0,
            width: "100%",
            height: "1px",
            backgroundColor: "rgba(0,0,0,0.1)",
            "&::after": {
              content: '"•"',
              position: "absolute",
              left: -10,
              top: -3,
              color: "rgba(0,0,0,0.3)",
            },
          }}
        />
      ))}

      {/* Linha central vertical */}
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: 0,
          width: "1px",
          height: "100%",
          backgroundColor: "rgba(255,0,0,0.2)",
          pointerEvents: "none",
        }}
      />

      {/* Linha central horizontal */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: 0,
          width: "100%",
          height: "1px",
          backgroundColor: "rgba(255,0,0,0.2)",
          pointerEvents: "none",
        }}
      />
    </Box>
  );
};

export default EtiquetaGrid;
