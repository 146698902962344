import { PlanoDeContaSerializer } from "data/interfaces/financeiro/PlanoDeContaSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "planodeconta";

class PlanoDeContasModel extends MainModule<
  PlanoDeContaSerializer,
  PlanoDeContaSerializer
> {}

export default new PlanoDeContasModel(model_url);
