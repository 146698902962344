import { AutoFormProps } from "components/nuvel/forms/automatic/NuvelAutoForm";
import { TableColumn, TableFilter } from "components/nuvel/lists";
import { TipoInsumoSerializer } from "data/interfaces/estoque/TipoInsumoSerializer";

export const RETAGUARDA_TIPOINSUMO_FORM: AutoFormProps<TipoInsumoSerializer>[] =
  [
    {
      type: "grid",
      grid: { xs: 12 },
      children: [
        {
          grid: { xs: 12, md: 6 },
          label: "Código",
          name: "codigo",
          type: "text",
          disabled: true,
        },
        {
          grid: { xs: 12, md: 6 },
          label: "Descrição",
          name: "descricao",
          type: "text",
        },
      ],
    },
  ];

export const RETAGUARDA_TIPOINSUMO_LIST: TableColumn<TipoInsumoSerializer>[] = [
  {
    label: "Descrição",
    accessor: "descricao",
    minWidth: 300,
  },
];

export const RETAGUARDA_TIPOINSUMO_ORDERING: (keyof TipoInsumoSerializer)[] = [
  "descricao",
];

export const RETAGUARDA_TIPOINSUMO_FILTERS: TableFilter<TipoInsumoSerializer>[] =
  [
    {
      label: "Descrição",
      accessor: "descricao",
      support: ["icontains"],
    },
  ];
