import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NuvelGrid from "components/nuvel/Grid";
import NuvelModal from "components/nuvel/Modal";
import { TransacaoFaturamentoSerializer } from "data/interfaces/financeiro/TransacaoFaturamentoSerializer";
import { TransacaoSerializer } from "data/interfaces/financeiro/TransacaoSerializer";
import { TransacaoModel } from "data/models";
import { useAppContext } from "hooks";
import React from "react";

interface IModalEstorno {
  id: number;
  modal: {
    id: number;
    open: boolean;
  };
  handleModal: (id: number, data?: TransacaoSerializer) => void;
  estornos: TransacaoFaturamentoSerializer[];
  mutate: () => void;
}

const ModalEstorno: React.FC<IModalEstorno> = ({
  id,
  modal,
  handleModal,
  estornos,
  mutate,
}) => {
  const { showSnack } = useAppContext();
  const getTipoFaturamento = (tipo: number) => {
    switch (tipo) {
      case 0:
        return "Conta Cofre";
      case 1:
        return "Conta Bancária";
      case 3:
        return "Cheque Terceiros";
      default:
        return "";
    }
  };

  const handleSave = () => {
    TransacaoModel.action("post", "estorno-faturamento", {
      faturamento_ids: estornos.map((e) => e.id),
    }).then(() => {
      mutate();
      showSnack("Faturamento(s) estornado(s) com sucesso", 3, "success");
      handleModal(id);
    });
  };

  return (
    <NuvelModal
      title="Confirmar estorno"
      open={id === modal.id && modal.open}
      btnCancel={() => handleModal(id)}
      btnSave={() => handleSave()}
      saveText="Estornar"
      noPadding
      fullWidth
      maxWidth="md"
    >
      <NuvelGrid container>
        <NuvelGrid size={{ xs: 12 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Tipo Faturamento</TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {estornos.map((estorno) => (
                  <TableRow key={estorno.id}>
                    <TableCell>{estorno.descricao}</TableCell>
                    <TableCell>
                      {getTipoFaturamento(estorno.tipo_faturamento || 0)}
                    </TableCell>
                    <TableCell>{estorno.valor?.toBRL()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </NuvelGrid>
      </NuvelGrid>
    </NuvelModal>
  );
};

export default ModalEstorno;
