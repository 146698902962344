import { TableColumn, TableFilter } from "components/nuvel/lists";
import { EtiquetasSerializer } from "data/interfaces/core/EtiquetasSerializer";

export const RETAGUARDA_ETIQUETAS_LIST: TableColumn<EtiquetasSerializer>[] = [
  {
    label: "Nome",
    accessor: "nome",
  },
  {
    label: "Descrição",
    accessor: "descricao",
  },
];

export const RETAGUARDA_ETIQUETAS_ORDERING: (keyof EtiquetasSerializer)[] = [
  "nome",
  "descricao",
];

export const RETAGUARDA_ETIQUETAS_FILTERS: TableFilter<EtiquetasSerializer>[] =
  [
    {
      label: "Nome",
      accessor: "nome",
      support: ["icontains"],
    },
    {
      label: "Descrição",
      accessor: "descricao",
      support: ["icontains"],
    },
  ];
