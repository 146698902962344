import { TableColumn, TableFilter } from "components/nuvel/lists";
import { ParceiroDeNegocioSerializer } from "data/interfaces/core/ParceiroDeNegocioSerializer";

export const RETAGUARDA_PARCEIRO_LIST: TableColumn<ParceiroDeNegocioSerializer>[] =
  [
    {
      label: "Código",
      accessor: "codigo",
      minWidth: 50,
    },
    {
      label: "Razão Social / Nome",
      accessor: "razao_social_nome",
      minWidth: 250,
    },
    {
      label: "Data de Cadastro",
      accessor: "data_cadastro",
      minWidth: 250,
      type: "date",
    },
  ];

export const RETAGUARDA_PARCEIRO_ORDERING: (keyof ParceiroDeNegocioSerializer)[] =
  ["codigo", "razao_social_nome"];

export const RETAGUARDA_PARCEIRO_FILTERS: TableFilter<ParceiroDeNegocioSerializer>[] =
  [
    {
      label: "Código",
      accessor: "codigo",
      support: ["icontains"],
    },
    {
      label: "Razão Social / Nome",
      accessor: "razao_social_nome",
      support: ["icontains"],
    },
    {
      label: "Tipo de Parceiro",
      select: [
        {
          label: "Cliente",
          accessor: "cliente",
        },
        {
          label: "Fornecedor",
          accessor: "fornecedor",
        },
        {
          label: "Funcionário",
          accessor: "func",
        },
        {
          label: "Transportador",
          accessor: "transpor",
        },
        {
          label: "Lojista",
          accessor: "lojista",
        },
      ],
    },
  ];
