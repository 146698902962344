/* eslint-disable @typescript-eslint/no-explicit-any */
import useSWR, { mutate, SWRResponse } from "swr";
import api from "./api";
import { ResponseType } from "axios";
import { showMessage } from "utils/functions/dialog";

interface DjangoError {
  [key: string]: string | string[];
}

interface DialogError {
  title: string;
  messages: string[];
}

interface AxiosError<T = any> {
  message: string;
  name: string;
  stack?: string;
  config: any;
  code?: string;
  status?: number;
  response?: {
    data: T;
    status: number;
    statusText: string;
  };
}

const convertDjangoErrorToDialogError = (
  error: DjangoError,
  status: number,
  statusText: string
): DialogError => {
  const dialogError: DialogError = {
    title: `Error ${status}: ${statusText}`,
    messages: [],
  };

  for (const key in error) {
    if (key !== "detail" && error[key]) {
      if (typeof error[key] === "string") {
        dialogError.messages.push(`${key}: ${error[key]}`);
      } else {
        if (typeof error[key] === "object") {
          dialogError.messages.push(
            `${key}: ${(error[key] as string[]).join(",")}`
          );
        }
      }
    }
  }

  return dialogError;
};

const handleAxiosError = (error: AxiosError<DjangoError>): DialogError => {
  if (error.response) {
    const { data, status, statusText } = error.response;
    return convertDjangoErrorToDialogError(data, status, statusText);
  } else {
    return {
      title: "Erro desconhecido",
      messages:
        "Ocorreu um erro desconhecido. \n Por favor entre em contato com o suporte.".split(
          "\n"
        ),
    };
  }
};

export interface InternalModel {
  id?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface NuvelDefaultListType<_T, L> {
  rows: L[];
  page: number;
  totalCount: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface MainInterface<T extends InternalModel, _L = T> {
  namespace: string;
  response: any;
  save: (body: T) => Promise<boolean>;
  save_with_response: (body: T) => Promise<boolean>;
  get: (id?: number, params?: object) => Promise<T>;
  action: (
    type:
      | "post"
      | "get"
      | "patch"
      | { method: "POST" | "GET" | "PATCH"; responseType?: ResponseType },
    route: string,
    data: any,
    id?: string,
    params?: object
  ) => Promise<any>;
  delete: (id: number) => Promise<void>;
  useModel: (queryParams?: object) => SWRResponse;
  refreshModel: (forceurl?: string, queryParams?: object) => void;
}

export default class Main<T extends InternalModel, L = T>
  implements MainInterface<T, L>
{
  namespace = "";
  response: any;
  interface: T;
  interface_list: L;

  constructor(namespace: string) {
    this.namespace = namespace;
    this.response = null;
    this.interface = {} as T;
    this.interface_list = {} as L;
  }

  save = async (body: T) => {
    try {
      this.response = body.id
        ? await api().patch(`/${this.namespace}/${body.id}/`, body)
        : await api().post(`/${this.namespace}/`, body);
    } catch (e: any) {
      const { messages } = handleAxiosError(e as AxiosError<DjangoError>);
      showMessage(messages.join("\n"), "Erro ao salvar");
      return false;
    }

    return this.response.status === 201 || this.response.status === 200;
  };

  save_with_response = async (body: T) => {
    try {
      this.response = body.id
        ? await api().patch(`/${this.namespace}/${body.id}/`, body)
        : await api().post(`/${this.namespace}/`, body);
    } catch (e: any) {
      const { messages } = handleAxiosError(e as AxiosError<DjangoError>);
      showMessage(messages.join("\n"), "Erro ao salvar");
      return false;
    }

    return this.response;
  };

  get = async (id?: number, params: object = {}) => {
    const produtos = await api().get(
      id ? `/${this.namespace}/${id}/` : `/${this.namespace}/`,
      { params }
    );
    return produtos ? produtos.data : null;
  };

  action = async (
    type:
      | "post"
      | "get"
      | "patch"
      | { method: "POST" | "GET" | "PATCH"; responseType?: ResponseType },
    route: string,
    data: any,
    id: string | number = "",
    params?: object
  ) => {
    route = route ? `${route}/` : "";
    switch (type) {
      case "patch":
        return await api().patch(
          id
            ? `/${this.namespace}/${id}/${route}`
            : `/${this.namespace}/${route}`,
          data
        );
      case "post":
        return await api().post(
          id
            ? `/${this.namespace}/${id}/${route}`
            : `/${this.namespace}/${route}`,
          data
        );
      case "get":
        return await api().get(
          id
            ? `/${this.namespace}/${id}/${route}`
            : `/${this.namespace}/${route}`,
          { params }
        );
      default:
        return await api().request({
          method: type.method,
          responseType: type.responseType,
          url: id
            ? `/${this.namespace}/${id}/${route}`
            : `/${this.namespace}/${route}`,
          data,
          params,
        });
    }
  };

  delete = async (id: number) => {
    await api().delete(`/${this.namespace}/${id}/`);
  };

  useModel(queryParams: object = {}) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSWR<NuvelDefaultListType<T, L>, any>(
      `${this.namespace}${JSON.stringify(queryParams)}`,
      async () => {
        const res = await api().get(`/${this.namespace}/`, {
          params: queryParams,
        });
        return res.data;
      }
    );
  }

  refreshModel(forceurl?: string, queryParams: object = {}) {
    return mutate(
      `${forceurl}${JSON.stringify(queryParams)}` ||
        `${this.namespace}${JSON.stringify(queryParams)}`,
      false
    );
  }
}
