import React from "react";

import { styled } from "@mui/material";

import {
  TreeItem,
  treeItemClasses,
  TreeItemProps,
} from "@mui/x-tree-view/TreeItem";

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  "&:hover > $content": {
    backgroundColor: theme.palette.action.hover,
  },
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
}));

const NuvelTreeItem: React.FC<TreeItemProps> = (props) => {
  return (
    <CustomTreeItem
      {...props}
      itemId={String(props.itemId)}
      label={props.label}
      id={String(props.itemId)}
    />
  );
};

export default NuvelTreeItem;
