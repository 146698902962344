import { RouteInterface } from "utils/router";

import RetLojaList from "../views/cadastro/loja/list";
import RetLojaForm from "../views/cadastro/loja/form";
import RetUserList from "../views/cadastro/usuario/list";
import RetUserForm from "../views/cadastro/usuario/form";
import RetAgendaForm from "../views/cadastro/agenda/form";
import RetAgendaList from "../views/cadastro/agenda/list";
import RetParceiroList from "../views/cadastro/parceiro_de_negocios/list";
import RetParceiroForm from "../views/cadastro/parceiro_de_negocios/form";
import RetProdutosList from "../views/cadastro/produtos/list";
import RetProdutosForm from "../views/cadastro/produtos/form";
import RetDepartamentoList from "../views/cadastro/departamento/list";
import RetDepartamentoForm from "../views/cadastro/departamento/form";
import RetPDVList from "../views/cadastro/pdv/list";
import RetPDVForm from "../views/cadastro/pdv/form";
import RetTagList from "../views/cadastro/tag/list";
import RetTagForm from "../views/cadastro/tag/form";
import RetEtiquetasList from "../views/cadastro/etiquetas/list";
import RetEtiquetasForm from "../views/cadastro/etiquetas/form";

const cadastroRoutes: RouteInterface[] = [
  {
    title: "Usuário",
    path: "/retaguarda/cadastro/usuario",
    element: <RetUserList />,
    form: <RetUserForm />,
    perm: "CADASTRO_USUARIO",
  },
  {
    title: "Loja",
    path: "/retaguarda/cadastro/loja",
    element: <RetLojaList />,
    form: <RetLojaForm />,
    perm: "CADASTRO_LOJA",
  },
  {
    title: "Agenda",
    path: "/retaguarda/cadastro/agenda",
    element: <RetAgendaList />,
    form: <RetAgendaForm />,
    perm: "CADASTRO_AGENDA",
  },
  {
    title: "Parceiro de Negócios",
    path: "/retaguarda/cadastro/parceiro",
    element: <RetParceiroList />,
    form: <RetParceiroForm />,
    perm: "CADASTRO_PARCEIRO",
  },
  {
    title: "Produtos",
    path: "/retaguarda/cadastro/produtos",
    element: <RetProdutosList />,
    form: <RetProdutosForm />,
    perm: "CADASTRO_PRODUTOS",
  },
  {
    title: "Departamento",
    path: "/retaguarda/cadastro/departamento",
    element: <RetDepartamentoList />,
    form: <RetDepartamentoForm />,
    perm: "CADASTRO_DEPARTAMENTO",
  },
  {
    title: "PDV",
    path: "/retaguarda/cadastro/pdv",
    element: <RetPDVList />,
    form: <RetPDVForm />,
    perm: "CADASTRO_PDV",
  },
  {
    title: "Tag",
    path: "/retaguarda/cadastro/tag",
    element: <RetTagList />,
    form: <RetTagForm />,
    perm: "CADASTRO_TAG",
  },
  {
    title: "Etiquetas (BETA)",
    path: "/retaguarda/cadastro/etiquetas",
    element: <RetEtiquetasList />,
    form: <RetEtiquetasForm />,
    perm: "CADASTRO_ETIQUETAS",
  },
];

export default cadastroRoutes;
