import { AutoFormProps } from "components/nuvel/forms/automatic/NuvelAutoForm";
import { TableColumn, TableFilter } from "components/nuvel/lists";
import { CertificadoSerializer } from "data/interfaces/core/CertificadoSerializer";

interface CertificadoSerializerForm extends CertificadoSerializer {
  certificado_nome: string;
}

export const RETAGUARDA_CERTIFICADO_FORM: AutoFormProps<CertificadoSerializerForm>[] =
  [
    {
      type: "grid",
      grid: { xs: 12 },
      children: [
        {
          grid: { xs: 12, md: 8 },
          label: "Arquivo Certificado",
          type: "text",
          name: "certificado_nome",
          props: {
            readOnly: true,
          },
        },
        {
          grid: { xs: 12, md: 4 },
          label: "Anexar",
          type: "file",
          name: "certificado",
          props: {
            accept: [
              ".p12",
              ".pfx",
              ".p7b",
              ".spc",
              ".p7r",
              ".p7c",
              ".p7m",
              ".p7s",
              ".cer",
              ".crt",
              ".der",
            ],
          },
        },
        {
          grid: { xs: 12, md: 8 },
          label: "Loja",
          type: "autocomplete",
          name: "loja",
          reference: "loja",
        },
        {
          grid: { xs: 12, md: 4 },
          label: "Senha",
          type: "text",
          name: "senha",
        },
      ],
    },
  ];

export const RETAGUARDA_CERTIFICADO_LIST: TableColumn<CertificadoSerializer>[] =
  [
    {
      label: "Loja",
      accessor: "loja_nome",
      minWidth: 300,
    },
    {
      label: "Certificado",
      accessor: (row) =>
        String(row.certificado)?.split(".?")[0].split("/").pop(),
      minWidth: 300,
    },
  ];

export const RETAGUARDA_CERTIFICADO_ORDERING: (keyof CertificadoSerializer)[] =
  ["loja_nome"];

export const RETAGUARDA_CERTIFICADO_FILTERS: TableFilter<CertificadoSerializer>[] =
  [
    {
      label: "Loja",
      accessor: "loja_nome",
      support: ["icontains"],
    },
  ];
