import { BalancaFormatter } from "./balancaFormatter";
import { ToledoFormatter } from "./toledoFormatter";

export const createBalancaFormatter = (type: "TOLEDO"): BalancaFormatter => {
  switch (type) {
    case "TOLEDO":
      return new ToledoFormatter();
    default:
      throw new Error(`Formato de balança não suportado: ${type}`);
  }
};
