import React, { useEffect, useRef, useState } from "react";

interface IframeWithTokenProps {
  token: string;
  iframeUrlForToken: string;
  iframeUrlRaw: string;
}

const IframeWithToken: React.FC<IframeWithTokenProps> = ({
  token,
  iframeUrlForToken,
  iframeUrlRaw,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    const sendTokenToIframe = () => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        // Envia o token para o iframe usando postMessage
        iframeRef.current.contentWindow.postMessage(
          {
            token: token,
            url: iframeUrlRaw,
          },
          "*"
        );
      }
    };

    // Escuta mensagens de confirmação do iframe de que o token foi setado
    const receiveMessage = (event: MessageEvent) => {
      // Verifique a origem para garantir que a mensagem seja confiável
      if (
        event.origin === "https://legacy.nuvel.com.br" ||
        event.origin === "http://localhost:3000"
      ) {
        if (event.data.tokenSet) {
          setIframeLoaded(true);
        }
      }
    };

    // Adiciona o listener para receber a mensagem do iframe
    window.addEventListener("message", receiveMessage);

    // Envia o token quando o iframe for carregado inicialmente
    if (iframeRef.current) {
      iframeRef.current.onload = sendTokenToIframe;
    }

    return () => {
      // Remove o listener quando o componente for desmontado
      window.removeEventListener("message", receiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <iframe
      ref={iframeRef}
      src={iframeUrlForToken}
      width={iframeLoaded ? "100%" : "1%"}
      height={iframeLoaded ? "100%" : "1%"}
      style={{
        border: "none",
      }}
    />
  );
};

export default IframeWithToken;
