import React, { useState } from "react";
import FechamentoCego from "./modals/cego";
import FechamentoComparacao from "./modals/comparacao";
import { NuvelDefaultList } from "components/nuvel/lists";
import {
  RETAGUARDA_CAIXA_LIST,
  RETAGUARDA_CAIXA_ORDERING,
} from "constants/retaguarda/pdv/caixa";
import { CaixaModel } from "data/models/pdv";
import { IconButton } from "@mui/material";
import { Check, DoneAll, Print } from "@mui/icons-material";
import { printCupom } from "./utils";
import { CaixaPDVSerializer } from "data/interfaces/pdv/CaixaPDVSerializer";

const RetPDVCaixaList: React.FC = () => {
  const [modal, setModal] = useState({ id: 0, open: false });
  const [data, setData] = useState({});

  const handleModal = (id: number, change = false) => {
    if (change) {
      setModal({ ...modal, id });
    } else {
      setModal({ id, open: !modal.open });
    }
  };

  const RowActions = (row: CaixaPDVSerializer) => {
    const item = row;
    return item.data_fechamento && item.fechamentos_cegos.length > 0 ? (
      <>
        {item.fechamentos_cegos[0].status === 0 ? (
          <IconButton
            color={"success"}
            aria-label="check"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setData({
                caixa: item,
                fechamento_cego: item.fechamentos_cegos[0],
              });
              handleModal(1);
            }}
            sx={theme => ({
              border: `1px solid ${theme.palette.success.main}`
            })}
          >
            <Check fontSize="inherit" />
          </IconButton>
        ) : (
          <>
            <IconButton
              color={"success"}
              aria-label="check"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setData({
                  caixa: item,
                  fechamento_cego: item.fechamentos_cegos[0],
                });
                handleModal(2);
              }}
              sx={theme => ({
                border: `1px solid ${theme.palette.success.main}`
              })}
            >
              <DoneAll style={{ color: "#74c778" }} fontSize="inherit" />
            </IconButton>
            <IconButton
              color={"success"}
              aria-label="check"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                printCupom(item);
              }}
              sx={theme => ({
                border: `1px solid ${theme.palette.success.main}`
              })}
            >
              <Print style={{ color: "#74c778" }} fontSize="inherit" />
            </IconButton>
          </>
        )}
      </>
    ) : (
      <></>
    );
  };

  return (
    <React.Fragment>
      <NuvelDefaultList
        model={CaixaModel}
        columns={RETAGUARDA_CAIXA_LIST}
        ordering={RETAGUARDA_CAIXA_ORDERING}
        actions={{
          custom: RowActions,
        }}
      />
      <FechamentoCego
        modal={modal}
        handleModal={handleModal}
        data={data}
        setData={setData}
      />
      <FechamentoComparacao
        modal={modal}
        handleModal={handleModal}
        data={data}
      />
    </React.Fragment>
  );
};

export default RetPDVCaixaList;