import { TableColumn, TableFilter } from "components/nuvel/lists";
import { GradeSerializer } from "data/interfaces/estoque/GradeSerializer";

export const RETAGUARDA_GRADE_LIST: TableColumn<GradeSerializer>[] = [
  {
    label: "Descrição",
    accessor: "descricao",
    minWidth: 300,
  },
];

export const RETAGUARDA_GRADE_ORDERING: (keyof GradeSerializer)[] = [
  "descricao",
];

export const RETAGUARDA_GRADE_FILTERS: TableFilter<GradeSerializer>[] = [
  {
    label: "Descrição",
    accessor: "descricao",
    support: ["icontains"],
  },
];
