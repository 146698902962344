import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  FormControlLabel,
} from "@mui/material";
import React from "react";

interface SwitchProps extends Omit<MuiSwitchProps, "onChange"> {
  label: string | React.ReactElement;
  onChange?: (event: { target: { name: string; value: boolean } }) => void;
}

export default function NuvelSwitch(props: SwitchProps) {
  return (
    <FormControlLabel
      style={{
        ...props.style,
      }}
      control={
        <MuiSwitch
          {...props}
          onChange={(e) => {
            if (!props.onChange) {
              throw new Error(
                "Não foi informado o onChange para o Switch " + e.target.name
              );
            } else {
              props.onChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked,
                },
              });
            }
          }}
        />
      }
      label={props.label}
    />
  );
}
