import { Product } from "../../context";
import ProductModal from "./ProductModal";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Skeleton,
  CardMedia,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { Add, Delete, Remove } from "@mui/icons-material";
import PlaceholderImage from "../PlaceholderImage";

const ProductModalMobile: React.FC<{
  product: Product;
  open: boolean;
  setOpen: (open: boolean) => void;
  setSelectedProduct: (product: Product | null) => void;
}> = (props) => {
  return (
    <ProductModal {...props}>
      {({
        imageLoading,
        setImageLoading,
        quantity,
        setQuantity,
        handleClose,
        handleAddToCart,
        handleRemoveFromCart,
        productQuantity,
        disableQuantity,
      }) => (
        <Dialog open={props.open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>Adicionar produto</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" height="100%">
              <Box width="100%" height="20vh">
                {imageLoading && (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="20vh"
                    animation="wave"
                    sx={{ position: "absolute" }}
                  />
                )}
                {props.product?.image ? (
                  <CardMedia
                    component="img"
                    height="20vh"
                    image={props.product.image}
                    onLoad={() => setImageLoading(false)}
                    sx={{
                      display: imageLoading ? "none" : "block",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <PlaceholderImage
                    text={props.product?.name}
                    width="100%"
                    height="20vh"
                    setImageLoading={setImageLoading}
                  />
                )}
              </Box>
              <Box display="flex" flexDirection="column" mt={1} flexGrow={1}>
                <Box flexGrow={1}>
                  <Typography variant="h6" component="h2">
                    {props.product?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {props.product?.description}
                  </Typography>
                  <Typography variant="h6" component="h2">
                    {props.product?.price.toBRL()}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={2}
                >
                  {productQuantity >= 1 && quantity === 1 ? (
                    <IconButton
                      onClick={handleRemoveFromCart}
                      sx={{
                        border: (theme) =>
                          `1px solid ${theme.palette.error.main}`,
                        color: (theme) => theme.palette.error.main,
                      }}
                    >
                      <Delete />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => setQuantity(Math.max(1, quantity - 1))}
                      sx={{
                        border: (theme) =>
                          `1px solid ${
                            disableQuantity ? "gray" : theme.palette.error.main
                          }`,
                        color: (theme) =>
                          disableQuantity ? "gray" : theme.palette.error.main,
                      }}
                      disabled={disableQuantity}
                    >
                      <Remove />
                    </IconButton>
                  )}
                  <Typography variant="h6" component="h2">
                    {quantity}
                  </Typography>
                  <IconButton
                    onClick={() => setQuantity(quantity + 1)}
                    sx={{
                      border: (theme) =>
                        `1px solid ${theme.palette.success.main}`,
                      color: (theme) => theme.palette.success.main,
                    }}
                  >
                    <Add />
                  </IconButton>
                  <Button
                    onClick={handleAddToCart}
                    variant="contained"
                    color="primary"
                  >
                    Confirmar
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </ProductModal>
  );
};

export default ProductModalMobile;
