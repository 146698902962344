import { AutoFormProps } from "components/nuvel/forms/automatic/NuvelAutoForm";
import { TableColumn, TableFilter } from "components/nuvel/lists";
import { SelectOption } from "components/nuvel/Select";
import {
  CSOSN,
  CST_IPI,
  CST_LIST,
  CST_PIS_COFINS_LIST,
  MODALIDADE_BC_ICMS_ST,
  MOTIVO_DESONERACAO,
  UF_LIST,
} from "constants/shared/impostos";
import { ImpostoSerializer } from "data/interfaces/financeiro/ImpostoSerializer";

export const RETAGUARDA_IMPOSTO_FORM: AutoFormProps<ImpostoSerializer>[] = [
  {
    grid: { xs: 12 },
    type: "grid",
    children: [
      {
        grid: { xs: 12 },
        name: "nome",
        label: "Descrição",
        type: "text",
      },
      {
        grid: { xs: 12, md: 4 },
        name: "aliq_def",
        label: "Aliquota Padrão",
        type: "perc",
      },
      {
        grid: { xs: 12, md: 4 },
        name: "aliq_des_def",
        label: "Aliq. Desoneração Padrão",
        type: "perc",
      },
      {
        grid: { xs: 12, md: 4 },
        name: "p_mva_def",
        label: "Perc. MVA Padrão",
        type: "perc",
      },
      {
        grid: { xs: 12, md: 4 },
        name: "aliq_fcp_def",
        label: "Aliq. FCP Padrão",
        type: "perc",
      },
      {
        grid: { xs: 12, md: 4 },
        name: "cfop",
        label: "CFOP",
        type: "text",
      },
      {
        grid: { xs: 12 },
        type: "grid",
      },
      {
        grid: { xs: 12, md: 12 },
        name: "csosn",
        label: "CSOSN",
        type: "select",
        options: CSOSN,
      },
      {
        grid: { xs: 12, md: 12 },
        name: "cst",
        label: "CST",
        type: "select",
        options: CST_LIST,
      },
      {
        validation: (state) =>
          ["20", "30", "40", "70", "90"].includes(state.cst),
        grid: { xs: 12 },
        name: "motivo_desoneracao",
        label: "Motivo Desoneração",
        type: "select",
        options: (state) =>
          MOTIVO_DESONERACAO[Number(state.cst)] as SelectOption[],
      },
      {
        validation: (state) =>
          ["201", "202", "203"].includes(state.csosn) ||
          ["30", "10", "60"].includes(state.csosn),
        grid: { xs: 12 },
        name: "modalidade_bc_st",
        label: "Modalidade de determinação da BC do ICMS ST",
        type: "select",
        options: MODALIDADE_BC_ICMS_ST,
      },
      {
        grid: { xs: 12 },
        type: "accordion",
        familly: [
          {
            label: "IPI",
            children: [
              {
                grid: { xs: 12, md: 4 },
                name: "cst_ipi",
                label: "CST IPI",
                type: "select",
                options: CST_IPI,
              },
              {
                grid: { xs: 12, md: 4 },
                name: "aliq_ipi_def",
                label: "Aliquota IPI",
                type: "perc",
              },
              {
                grid: { xs: 12, md: 4 },
                name: "cst_ipi_enq",
                label: "CST Enquadramento",
                type: "decimal",
              },
            ],
          },
          {
            label: "PIS / COFINS",
            children: [
              {
                grid: { xs: 12, md: 4 },
                name: "incinde_pis_cofins",
                label: "Incide PIS/COFINS",
                type: "checkbox",
                defaultValue: false,
              },
              {
                validation: (state) => state.incinde_pis_cofins,
                grid: { xs: 12, md: 4 },
                name: "cst_pis",
                label: "Aliq PIS",
                type: "select",
                options: CST_PIS_COFINS_LIST,
              },
              {
                validation: (state) => state.incinde_pis_cofins,
                grid: { xs: 12, md: 4 },
                name: "cst_cofins",
                label: "Aliq COFINS",
                type: "select",
                options: CST_PIS_COFINS_LIST,
              },
            ],
          },
          {
            label: "Combustível",
            children: [
              {
                grid: { xs: 12, md: 4 },
                name: "aliq_def",
                label: "Código ANP",
                type: "text",
              },
              {
                grid: { xs: 12, md: 4 },
                name: "comb_desc_anp",
                label: "Descrição ANP",
                type: "text",
              },
              {
                grid: { xs: 12, md: 4 },
                name: "comb_uf_cons",
                label: "UF Consumo",
                type: "select",
                options: UF_LIST.map((uf) => ({ value: uf, label: uf })),
              },
              {
                grid: { xs: 12, md: 4 },
                name: "comb_p_glp",
                label: "Gás Devirado do Petrólio",
                type: "perc",
              },
              {
                grid: { xs: 12, md: 4 },
                name: "comb_p_gn_n",
                label: "Gás Natural Nacional",
                type: "perc",
              },
              {
                grid: { xs: 12, md: 4 },
                name: "comb_p_gn_i",
                label: "Gás Natural Importado",
                type: "perc",
              },
              {
                grid: { xs: 12, md: 4 },
                name: "comb_v_part",
                label: "Valor de Partida",
                type: "decimal",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const RETAGUARDA_IMPOSTO_LIST: TableColumn<ImpostoSerializer>[] = [
  {
    label: "Nome",
    accessor: "nome",
  },
  {
    label: "Aliquota",
    accessor: (row) => `${Number(row.aliq_def).toDecimal(2)}%`,
  },
  {
    label: "Aliq. Desonerada",
    accessor: (row) => `${Number(row.aliq_des_def).toDecimal(2)}%`,
  },
  {
    label: "CFOP Venda",
    accessor: "cfop",
  },
];

export const RETAGUARDA_IMPOSTO_ORDERING: (keyof ImpostoSerializer)[] = [
  "nome",
];

export const RETAGUARDA_IMPOSTO_FILTERS: TableFilter<ImpostoSerializer>[] = [
  {
    label: "Nome",
    accessor: "nome",
    support: ["icontains"],
  },
];
