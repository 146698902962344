import React, { ReactElement, JSXElementConstructor } from "react";
import { NonIndexRouteObject } from "react-router-dom";

export interface RouteInterface extends Omit<NonIndexRouteObject, "path"> {
  path: string;
  element?:
    | ReactElement<unknown, string | JSXElementConstructor<unknown>>
    | undefined;
  form?:
    | ReactElement<unknown, string | JSXElementConstructor<unknown>>
    | undefined;
  perm?: string;
  icon?: ReactElement;
  children?: RouteInterface[];
  state?: string;
  title?: string;
  norender?: boolean;
  disabled?: boolean;
}

const renderRoute = (route: RouteInterface, props?: object): RouteInterface => {
  if (!route.element) return route;
  return {
    ...route,
    element: React.cloneElement(route.element, { ...props }),
  };
};

export const addPropsToRoutes = (
  routes: RouteInterface[],
  props?: object
): RouteInterface[] => {
  const newRoutes: RouteInterface[] = [];

  routes.forEach((route) => {
    const newRoute = { ...route };

    newRoutes.push(renderRoute(newRoute, props));

    if (route.form) {
      newRoutes.push({
        path: `${route.path}/:id`,
        element: React.cloneElement(route.form, { ...props }),
        perm: route.perm,
      });
    }

    if (route.children) {
      newRoute.children = addPropsToRoutes(route.children, props);
    }
  });

  return newRoutes;
};

export const RouterGenerator = (
  routes: RouteInterface[],
  props?: object
): RouteInterface[] => addPropsToRoutes(routes, props);
