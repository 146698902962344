import { Product, useCatalogActions } from "../../context";
import React, { useState } from "react";

const ProductModal: React.FC<{
  product: Product;
  open: boolean;
  setOpen: (open: boolean) => void;
  setSelectedProduct: (product: Product | null) => void;
  children: (props: {
    imageLoading: boolean;
    setImageLoading: (loading: boolean) => void;
    quantity: number;
    setQuantity: (quantity: number) => void;
    handleClose: () => void;
    handleAddToCart: () => void;
    handleRemoveFromCart: () => void;
    productQuantity: number;
    disableQuantity: boolean;
  }) => React.ReactNode;
}> = ({ product, setOpen, setSelectedProduct, children }) => {
  const { addToCart, getProductQuantity, removeFromCart } = useCatalogActions();
  const productQuantity = getProductQuantity(product.id);
  const [quantity, setQuantity] = useState(productQuantity || 1);
  const [imageLoading, setImageLoading] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setSelectedProduct(null);
    }, 100);
    setImageLoading(true);
    setQuantity(1);
  };

  const handleAddToCart = () => {
    if (product) {
      addToCart({ ...product, quantity });
      handleClose();
    }
  };

  const handleRemoveFromCart = () => {
    if (product) {
      removeFromCart(product.id);
      handleClose();
    }
  };

  const disableQuantity = quantity === 1;

  return children({
    imageLoading,
    setImageLoading,
    quantity,
    setQuantity,
    handleClose,
    handleAddToCart,
    handleRemoveFromCart,
    productQuantity,
    disableQuantity,
  });
};

export default ProductModal;
