import React from "react";

import { Document, Font } from "@react-pdf/renderer";
import OpenSans from "../fonts/CourierPrime-Regular.ttf";
import OpenSansBold from "../fonts/CourierPrime-Bold.ttf";

// Register font
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: OpenSans,
    },
    {
      src: OpenSansBold,
      fontWeight: "bold",
    },
  ],
});

function defaultImpressoes({ children }) {
  return <Document>{children}</Document>;
}

export default defaultImpressoes;
