/* eslint-disable no-unused-vars */
import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { Logo_Black } from "assets/img";

import DefaultImpressao from "./impressao";
import dayjs from "dayjs";

function formatbytype(data, accessor, type) {
  const final_data = typeof data === "object" ? data[accessor] : data;
  switch (type) {
    case "date":
      return dayjs(final_data).format("DD/MM/YYYY");
    case "datetime":
      return dayjs(final_data).format("DD/MM/YYYY HH:mm");
    case "time":
      return dayjs(final_data).format("HH:mm");
    case "percent":
      return `${final_data.toDecimal(2)}%`;
    case "money":
      return final_data?.toBRL();
    default:
      return final_data;
  }
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Open Sans",
    backgroundColor: "#FFF",
    padding: 16,
  },
  flex: {
    display: "flex",
  },
  logo: {
    width: 120,
    height: 80,
  },
  logo_wrapper: {
    justifyContent: "center",
    alignContent: "left",
    alignItems: "left",
    width: 170,
  },
  header_wrapper: {
    marginBottom: 10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    flex: 1,
  },
  title_wrapper: {
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    justifyContent: "center",
    width: "100%",
  },
  text_max: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 2.5,
  },
  text_med: {
    fontSize: 15,
    textAlign: "center",
    marginBottom: 2.5,
  },
  text_bold: {
    fontWeight: "bold",
  },
  divider: {
    height: 2,
    backgroundColor: "#000",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: 15,
    textAlign: "left",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    // width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    fontSize: 11,
    // fontWeight: 'bold',
  },
});

function Columns(props) {
  return (
    <React.Fragment>
      <View style={[styles.row, styles.divider]} fixed={props.fixed} />
      <View
        style={{ flexDirection: "row", alignContent: "left" }}
        fixed={props.fixed}
      >
        {props.columns?.map((col, key) => (
          <View key={key} style={{ flex: col.flex }}>
            <Text style={styles.tableCell}>{col.label}</Text>
          </View>
        ))}
      </View>
      <View style={[styles.row, styles.divider]} fixed={props.fixed} />
    </React.Fragment>
  );
}

function Row(props) {
  return (
    <View
      key={props.key}
      style={{ flexDirection: "row", alignContent: "left" }}
    >
      {props.columns?.map((col, key) => (
        <View key={key} style={{ flex: col.flex }}>
          <Text style={styles.tableCell}>
            {typeof col.accessor == "string"
              ? formatbytype(props.item, col.accessor, col.type)
              : col.accessor(props.item)}
          </Text>
        </View>
      ))}
    </View>
  );
}

function ComposeRow(props) {
  return (
    <React.Fragment>
      {props.child && props.item[props.child_key] && (
        <Columns columns={props.columns} />
      )}
      <Row columns={props.columns} item={props.item} />
      {props.child && props.item[props.child_key] && (
        <View style={{ marginLeft: 25 }}>
          <View style={[styles.row, styles.divider]} />
          <View style={{ flexDirection: "row", alignContent: "left" }}>
            {props.child.map((col, key) => (
              <View key={key} style={{ flex: col.flex }}>
                <Text style={styles.tableCell}>{col.label}</Text>
              </View>
            ))}
          </View>
          <View style={[styles.row, styles.divider]} />
          {props.item[props.child_key].map((item, key) => (
            <React.Fragment key={key}>
              <Row columns={props.child} item={item} key={key} />
            </React.Fragment>
          ))}
        </View>
      )}
    </React.Fragment>
  );
}

function ImpMaster(props) {
  const totais = props.totais ? props.totais.split(",") : [];

  return (
    <DefaultImpressao>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={[styles.row, styles.header_wrapper]} fixed>
            <View style={[styles.column, styles.logo_wrapper]}>
              <Image style={styles.logo} src={Logo_Black} />
            </View>
            <View style={[styles.column, styles.title_wrapper]}>
              <Text style={[styles.text, styles.text_bold]}>{props.title}</Text>
              <View style={[styles.row, styles.title_wrapper]}>
                <Text style={[styles.text_med, styles.text_bold]}>
                  Período:{" "}
                </Text>
                <Text style={styles.text_med}>
                  {dayjs(props.inicio).format("DD/MM/YYYY")} até{" "}
                  {dayjs(props.fim).format("DD/MM/YYYY")}
                </Text>
              </View>
              <View style={[styles.row, styles.title_wrapper]}>
                <Text style={[styles.text_med, styles.text_bold]}>
                  Emissão:{" "}
                </Text>
                <Text style={styles.text_med}>
                  {dayjs().format("DD/MM/YYYY HH:mm:ss")}
                </Text>
              </View>
              <Text
                style={[styles.text_med, styles.text_bold]}
                render={({ pageNumber, totalPages }) =>
                  `Página ${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </View>
          </View>
          {!props.child && <Columns columns={props.columns} fixed />}
          {props.data.map((item, key) => (
            <ComposeRow
              key={key}
              columns={props.columns}
              item={item}
              child={props.child}
              child_key={props.child_key}
            />
          ))}
          <View style={[styles.row, styles.divider]} />
          <View style={{ flexDirection: "row", alignContent: "left" }}>
            <View style={{ flex: 1 }}>
              <Text style={styles.tableCell}>Total</Text>
            </View>
            {props.columns
              ?.filter((col, index) => index != 0)
              ?.map((col, index) => (
                <View key={index} style={{ flex: col.flex }}>
                  <Text style={styles.tableCell}>
                    {
                      //Se o accessor for uma string, então ele simplesmente soma todos os registros daquela coluna
                      //Mas caso seja um campo calculado, ou seja, o accessor for uma função, ele vai processar todos os dados
                      //e somar, caso o index daquela função esteja no totais, ex = 'quantidade,1'
                      //Na expressão anterior, significa que ele vai somar o campo quantidade, e o campo de index 1 (Já que ele n tem accessor como string)
                      typeof col.accessor == "string"
                        ? totais.indexOf(col.accessor) !== -1
                          ? formatbytype(
                              props.data.sumBy(col.accessor),
                              col.accessor,
                              col.type
                            )
                          : ""
                        : totais === String(index)
                        ? props.data
                            .map((data) => ({ total: col.accessor(data) }))
                            .sumBy("total")
                        : ""
                    }
                  </Text>
                </View>
              ))}
          </View>
        </View>
      </Page>
    </DefaultImpressao>
  );
}

export default ImpMaster;
