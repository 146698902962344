import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_GRADE_FILTERS,
  RETAGUARDA_GRADE_LIST,
  RETAGUARDA_GRADE_ORDERING,
} from "constants/retaguarda/estoque/grade";
import { GradeModel } from "data/models";
import React from "react";

const RetGradeList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={GradeModel}
      columns={RETAGUARDA_GRADE_LIST}
      filters={RETAGUARDA_GRADE_FILTERS}
      ordering={RETAGUARDA_GRADE_ORDERING}
      actions={{
        update: true,
        delete: true,
        create: true,
      }}
    />
  );
};

export default RetGradeList;
