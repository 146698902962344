import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_DEPOSITO_FILTERS,
  RETAGUARDA_DEPOSITO_LIST,
  RETAGUARDA_DEPOSITO_ORDERING,
} from "constants/retaguarda/estoque/deposito";
import { DepositoModel } from "data/models";
import React from "react";

const RetDepositoList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={DepositoModel}
      columns={RETAGUARDA_DEPOSITO_LIST}
      filters={RETAGUARDA_DEPOSITO_FILTERS}
      ordering={RETAGUARDA_DEPOSITO_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetDepositoList;
