import React, { useState, useEffect } from "react";
import { Grid, List, ListItemButton, Divider } from "@mui/material";
import { codbarra, contato, departamento } from "./dispositions.jsx";
import { ManyToManyRender } from "./ManyToManyRender.jsx";
import { ManyToOneRender } from "./ManyToOneRender.jsx";

import { makeStyles } from "@mui/styles";

const DispositionComponents = {
  codbarra: codbarra,
  contato: contato,
  departamento: departamento,
};

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: "100%",
  },
  text: {
    marginTop: 8,
  },
  mtoAutocomplete: {
    width: "calc(100% - 35px)",
    zIndex: 1,
  },
  mtoCfgButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 35,
    minWidth: 0,
    zIndex: 0,
  },
  mtoAutocompleteVo: {
    width: "100%",
  },
  mtoAddPage: {
    padding: 8,
  },
  loadingCp: {
    width: "100%",
    textAlign: "center",
  },
}));

function AutoRelation(props) {
  const classes = useStyles();

  const isManyToOne = ["ManyToOne", "ManyToOneMultiple"].includes(
    props.relation
  );

  const isMultiple = props.relation === "ManyToOneMultiple";

  const [form, setForm] = useState({
    edit: false,
    current: 0,
    actualValue: "",
  });

  const [acValue, setAcValue] = useState(
    props.relation === "ManyToOneMultiple" ? [] : null
  );
  const [isLoading, setIsLoading] = useState(false);

  const { data: dados, mutate: refreshDados } = props.model?.useModel() || {
    data: undefined,
    mutate: undefined,
  };

  //--------------------------------Dialog-------------------------------------

  const [_dialog, setDialog] = useState({
    dialogOpen: false,
    pageIndex: 0,
    fields: {},
  });

  const handleDialogAdd = async (v, manualSource) => {
    const finalSource = manualSource || _dialog.fields;
    if (props.createCheck) {
      setIsLoading(true);
      if (!(await props.createCheck(finalSource))) {
        setIsLoading(false);
        return;
      }
    }
    if (_dialog.pageIndex === 1 && isManyToOne) {
      //Dialog Add on Many To One
      (async () => {
        setDialog((v) => ({
          ...v,
          pageIndex: 2,
        }));
        await props.model.save(finalSource);
        await refreshDados();
        setDialog((v) => ({
          ...v,
          pageIndex: 0,
        }));
      })();
    } else {
      let source = props.source;
      if (form.edit) {
        source.splice(form.current, 1);
      }
      source.push(finalSource);
      handleDialogClose();
      props.onChange(source);
      setIsLoading(false);
    }
  };

  const handleChangeDialog = (e) => {
    if (!e) return;
    const { value, name } = e.target;
    setDialog((v) => ({
      ...v,
      fields: { ...v.fields, [name]: value },
    }));
  };

  const handleDialogClose = () => {
    //Rotinas de veirifação ao fechar o Dialog
    if (isManyToOne) {
      setDialog((v) => ({
        ...v,
        pageIndex: 0,
        dialogOpen: _dialog.pageIndex === 1 ? _dialog.dialogOpen : false,
      }));
      setForm({ ...form, edit: false });
      return;
    }
    setDialog({ dialogOpen: false, fields: {} });
    setForm({
      edit: false,
      current: 0,
    });
    reloadFieldToDefaultValues();
  };

  function handleOpenCfg() {
    //Abre o DIALOG
    setDialog((v) => ({
      ...v,
      dialogOpen: true,
    }));
  }

  function handleItemClick(key) {
    //Clica em um item da lista
    const current = props.source[key];
    setDialog((v) => ({
      ...v,
      fields: { ...current },
      dialogOpen: true,
    }));
    setForm({
      edit: true,
      current: key,
    });
  }

  function handleItemDeleteClick(e, key) {
    //Remove um item da lista
    e.stopPropagation();
    let source = props.source;
    source.splice(key, 1);
    props.onChange(source);
  }

  //--------------------------------Dialog-------------------------------------

  useEffect(() => {
    //Quanto o valor atual mudar, ele vai pesquisar na source
    //o objeto com o [returnValue] correspondente a esse e trocar no value
    //CASO NAO ESTEJA FUNCIONANDO CERTIFICA DO SOURCE EXISTIR ANTES DO value PROCURAR
    if (props.relation === "ManyToMany") {
      const current = props.source.find((s) => {
        return s[props.returnValue] === props.value;
      });
      if (current) setAcValue(current);
      return;
    }

    if (props.relation === "ManyToOneMultiple" && props.returnValue) {
      const current = props.value.map((val) =>
        dados?.rows?.find((s) => {
          return s[props.returnValue] === val;
        })
      );
      if (current) setAcValue(current);
      return;
    }

    if (props.relation === "ManyToOneMultiple") {
      setAcValue(props.value);
      return;
    }

    const current = dados?.rows?.find((s) => {
      return s[props.returnValue] === props.value;
    });
    setAcValue(current || null);

    //Colocar o source aí embaixo vai acarretar em muitos renders
    //eslint-disable-next-line
  }, [props.value, dados]);

  function reloadFieldToDefaultValues(checkExistence = false) {
    //Recarrega os valores padrão dos campos
    let fields = _dialog.fields;
    props.fields.forEach((field) => {
      if (!field.isID) {
        if (checkExistence && fields[field.name] !== undefined) return;
        fields = { ...fields, [field.name]: field.default };
      }
    });
    setDialog((v) => ({ ...v, fields: { ...fields } }));
  }

  useEffect(() => {
    //Renderiza todos os campos no objeto Dialog dentro da propriedade FIELDS
    if (props.fields && !form.edit && _dialog.fields) {
      reloadFieldToDefaultValues(true);
    }
    //eslint-disable-next-line
  }, [props.fields]);

  function renderFieldByDisposition(data, fields, disposition, k) {
    //Renderiza a lista baseada na disposição dela, definida pela variável DispositionComponents
    const Disposition = DispositionComponents[disposition];
    if (Disposition) {
      return (
        <Disposition
          data={data}
          handleItemDeleteClick={handleItemDeleteClick}
          keyIndex={k}
          key={k}
        />
      );
    }
    return (
      <>
        Nenhuma disposição de renderização para lista foi feita, favor definir.
      </>
    );
  }

  const loading =
    isLoading || (props.relation === "ManyToMany" ? false : !dados?.rows);

  function mainRenderType() {
    switch (props.relation) {
      case "ManyToMany":
        return ManyToManyRender(
          props,
          _dialog,
          setDialog,
          handleDialogClose,
          handleDialogAdd,
          handleChangeDialog,
          loading,
          classes,
          props.customM2MSelector
        );
      case "ManyToOneMultiple":
      case "ManyToOne":
        return ManyToOneRender(
          props,
          acValue,
          isMultiple,
          dados,
          loading,
          handleOpenCfg,
          handleDialogAdd,
          handleDialogClose,
          handleChangeDialog,
          classes,
          setDialog,
          _dialog,
          setForm,
          form,
          refreshDados
        );
      default:
        break;
    }
  }

  return (
    <Grid
      spacing={1}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      {mainRenderType()}
      {props.relation === "ManyToMany" && props.source.length > 0 ? (
        <Grid item xs={12}>
          <List component="nav" aria-labelledby={`lista-${props.modelName}`}>
            {props.source
              ? props.source.map((c, k) => (
                  <div key={k}>
                    <ListItemButton onClick={() => handleItemClick(k)}>
                      {
                        //Renderiza a lista conforme o tipo dela.
                        renderFieldByDisposition(
                          c,
                          props.fields,
                          props.modelName,
                          k
                        )
                      }
                    </ListItemButton>
                    <Divider />
                  </div>
                ))
              : null}
          </List>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default AutoRelation;
