import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { RouteInterface } from "utils/router";

interface FormsTitleProps {
  route: RouteInterface | null;
  title?: string | (() => React.ReactNode);
}

const FormsTitle: React.FC<FormsTitleProps> = ({ route, title }) => {
  return (
    <Box p={2} sx={{ height: 65 }}>
      <Typography
        sx={{ flex: "1" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title ? (
          typeof title === "string" ? (
            title
          ) : (
            title()
          )
        ) : (
          <>
            Cadastro de{" "}
            {route?.title?.charAt(route?.title?.length - 1) === "s"
              ? route?.title
              : route?.title + "s"}
          </>
        )}
      </Typography>
      <Divider sx={{ pt: 1 }} />
    </Box>
  );
};

export default FormsTitle;
