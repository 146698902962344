/* eslint-disable @typescript-eslint/no-explicit-any */
import { InternalModel, MainInterface } from "data/main";
import {
  AcessoModel,
  CargoModel,
  CodBarraModel,
  ContaBancariaModel,
  DepartamentoModel,
  DepositoModel,
  EtiquetaEmLoteModel,
  FormasDePagamentoModel,
  GradeModel,
  ImpostoModel,
  LojaModel,
  ParceiroModel,
  ProdutoModel,
  ServicoModel,
  TagModel,
  TipoInsumoModel,
} from "data/models";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VALID_REFERENCES = {
  loja: "LojaModel",
  tags: "TagModel",
  fornecedor: "ParceiroModel",
  cliente: "ParceiroModel",
  funcionario: "ParceiroModel",
  deposito: "DepositoModel",
  produto: "ProdutoModel",
  produto_codigo: "ProdutoModel",
  departamento: "DepartamentoModel",
  servico: "ServicoModel",
  forma_pagamento: "FormasDePagamentoModel",
  conta_bancaria: "ContaBancariaModel",
  cod_barra: "CodBarraModel",
  imposto: "ImpostoModel",
  acesso: "AcessoModel",
  cargo: "CargoModel",
  insumo: "TipoInsumoModel",
  grades: "GradeModel",
  etiqueta_em_lote: "EtiquetaEmLoteModel",
} as const;

export type ValidReference = keyof typeof VALID_REFERENCES;

interface AutocompleteModel<T extends InternalModel> {
  model: MainInterface<T, any>;
  label_by: keyof T;
  value_by: keyof T;
  reference: ValidReference;
  filters?: Record<string, any>;
}

const AUTOCOMPLETE_MODELS: AutocompleteModel<any>[] = [
  {
    model: LojaModel,
    label_by: "razao_social",
    value_by: "id",
    reference: "loja",
  },
  {
    model: TagModel,
    label_by: "titulo",
    value_by: "id",
    reference: "tags",
  },
  {
    model: ParceiroModel,
    label_by: "razao_social_nome",
    value_by: "id",
    reference: "fornecedor",
    filters: {
      fornecedor_ativo: true,
      fornecedor: true,
    },
  },
  {
    model: ParceiroModel,
    label_by: "razao_social_nome",
    value_by: "id",
    reference: "cliente",
    filters: {
      cliente_ativo: true,
      cliente: true,
    },
  },
  {
    model: ParceiroModel,
    label_by: "razao_social_nome",
    value_by: "id",
    reference: "funcionario",
    filters: {
      func: true,
      funcionario_ativo: true,
    },
  },
  {
    model: DepositoModel,
    label_by: "nome",
    value_by: "id",
    reference: "deposito",
  },
  {
    model: ProdutoModel,
    label_by: "nome",
    value_by: "id",
    reference: "produto",
  },
  {
    model: ProdutoModel,
    label_by: "nome",
    value_by: "codigo",
    reference: "produto_codigo",
  },
  {
    model: DepartamentoModel,
    label_by: "nome",
    value_by: "id",
    reference: "departamento",
  },
  {
    model: ServicoModel,
    label_by: "nome",
    value_by: "id",
    reference: "servico",
  },
  {
    model: FormasDePagamentoModel,
    label_by: "forma",
    value_by: "id",
    reference: "forma_pagamento",
  },
  {
    model: ContaBancariaModel,
    label_by: "descricao",
    value_by: "id",
    reference: "conta_bancaria",
    filters: {
      tipo__in: "1, 2",
    },
  },
  {
    model: CodBarraModel,
    label_by: "produto_nome",
    value_by: "id",
    reference: "cod_barra",
  },
  {
    model: EtiquetaEmLoteModel,
    label_by: "produto_nome",
    value_by: "id",
    reference: "etiqueta_em_lote",
  },
  {
    model: ImpostoModel,
    label_by: "nome",
    value_by: "id",
    reference: "imposto",
  },
  {
    model: AcessoModel,
    label_by: "nome",
    value_by: "id",
    reference: "acesso",
  },
  {
    model: CargoModel,
    label_by: "nome",
    value_by: "id",
    reference: "cargo",
  },
  {
    model: TipoInsumoModel,
    label_by: "descricao",
    value_by: "id",
    reference: "insumo",
  },
  {
    model: GradeModel,
    label_by: "descricao",
    value_by: "id",
    reference: "grades",
  },
];

export default AUTOCOMPLETE_MODELS;
