/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import AuthController from "data/controller/auth";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const FilialSelection = (props: any) => {
  const [aval_lojas, setAval_lojas] = React.useState<any>([]);

  const { data: current_user } = AuthController.useCurrentUser();

  React.useEffect(() => {
    if (current_user?.lojas_licenca?.length !== 0) {
      setAval_lojas(() => current_user?.lojas_licenca);
    }
  }, [current_user]);

  const handleCheck = (e: any, id: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.target.checked) {
      props.setter((v: any) => ({
        ...v,
        lojas: v.lojas.filter((lo: any) => lo !== id),
      }));
    } else {
      props.setter((v: any) => ({ ...v, lojas: [...(v.lojas || []), id] }));
    }
  };

  return (
    <TableContainer>
      <Table style={{ width: "100%" }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "20%" }}>CNPJ</TableCell>
            <TableCell style={{ width: "70%" }}>FILIAL</TableCell>
            <TableCell style={{ width: "10%" }} align="center">
              VINCULADO
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {aval_lojas?.length !== 0 ? (
            aval_lojas?.map((loja: any, chave: any) => {
              return (
                <TableRow
                  hover
                  // onClick={(e) => handleCheck(e, loja.id)}
                  key={chave}
                  tabIndex={-1}
                  role="checkbox"
                  selected={
                    props.getter.lojas?.find((lo: any) => lo === loja.id) ||
                    false
                  }
                  aria-checked={
                    props.getter.lojas?.find((lo: any) => lo === loja.id) ||
                    false
                  }
                >
                  <TableCell style={{ padding: "2px 16px" }} align="left">
                    {loja.cnpj}
                  </TableCell>
                  <TableCell
                    style={{ padding: "2px 16px" }}
                    component="th"
                    scope="row"
                    align="left"
                  >
                    {loja.razao_social}
                  </TableCell>
                  <TableCell style={{ padding: "2px 16px" }} align="center">
                    <Checkbox
                      style={{ padding: 0 }}
                      checked={
                        props.getter.lojas?.find((lo: any) => lo === loja.id) ||
                        false
                      }
                      onChange={(e) => handleCheck(e, loja.id)}
                      inputProps={{ "aria-label": "Marcar Loja" }}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell>Sem Filial Cadastrada</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FilialSelection;
