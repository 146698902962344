import React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

interface LayoutMobileAppBarProps {
  handleDrawerOpen: () => void;
}

const LayoutMobileAppBar: React.FC<LayoutMobileAppBarProps> = ({
  handleDrawerOpen,
}) => {
  return (
    <MuiAppBar position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div"></Typography>
      </Toolbar>
    </MuiAppBar>
  );
};

export default LayoutMobileAppBar;
