import { EtiquetasSerializer } from "data/interfaces/core/EtiquetasSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "etiqueta_template";

class EtiquetasModel extends MainModule<
  EtiquetasSerializer,
  EtiquetasSerializer
> {}

export default new EtiquetasModel(model_url);
