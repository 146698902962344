import { FileCopyOutlined, Folder } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone, Accept } from "react-dropzone";

interface DropzoneProps {
  setData: (data: {
    filename: string;
    base64: string | ArrayBuffer | null;
  }) => void;
  setExtension?: (extension: string) => void;
  accept?: Accept;
  multiple?: boolean;
}

interface StyleObject {
  [key: string]: string | number;
}

const baseStyle: StyleObject = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px 20px 20px 20px",
  borderWidth: 1,
  borderRadius: 6,
  borderColor: "#DEE0EA",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#242424",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle: StyleObject = {
  borderColor: "#2196f3",
};

const acceptStyle: StyleObject = {
  borderColor: "#00e676",
};

const rejectStyle: StyleObject = {
  borderColor: "#ff1744",
};

const Dropzone: React.FC<DropzoneProps> = ({
  setData,
  setExtension,
  accept,
  multiple,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach(async (file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = async () => {
          const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
          const base64 = reader.result;
          setData({ filename: file.name, base64: base64 });
          if (setExtension) setExtension(extension);
        };
        reader.readAsDataURL(file);
      });
    },
    [setData, setExtension]
  );

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: accept
      ? accept
      : {
          "image/jpeg": [".jpg", ".jpeg"],
          "image/png": [".png"],
        },
    multiple: multiple || false,
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <ListItem key={file.path}>
      <ListItemIcon>
        <Folder />
      </ListItemIcon>
      <ListItemText primary={file.path} secondary={`${file.size} bytes`} />
    </ListItem>
  ));

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragReject && <p>Arquivo Inválido</p>}
        {isDragAccept && <p>Solte o arquivo aqui...</p>}
        {!isDragActive && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <FileCopyOutlined color="primary" sx={{ fontSize: 42 }} />
            <Typography variant="body1" color="primary.main">
              Arraste seu arquivo
            </Typography>
            <Typography variant="body1" color="primary.main">
              ou
            </Typography>
            <Typography variant="body1" fontWeight="bold" color="primary.main">
              Selecione do seu dispositivo
            </Typography>
          </Box>
        )}
      </div>
      {acceptedFileItems.length !== 0 ? (
        <aside>
          <List>{acceptedFileItems}</List>
        </aside>
      ) : null}
    </section>
  );
};

export default Dropzone;
