import React from "react";
import { Typography, IconButton } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  depContainer: {
    display: "contents",
  },
  depDeleteButton: {
    position: "absolute",
    right: 16,
  },
  depIcon: {
    fontSize: 30,
  },
}));

export default function Departamento(props) {
  const classes = useStyles();
  const { data, handleItemDeleteClick, keyIndex } = props;
  return (
    <div className={classes.depContainer} key={keyIndex}>
      <Typography variant="h5">{data.nome}</Typography>
      <IconButton
        className={classes.depDeleteButton}
        aria-label="deletar"
        onClick={(e) => handleItemDeleteClick(e, keyIndex)}
      >
        <DeleteIcon className={classes.depIcon} />
      </IconButton>
    </div>
  );
}
