/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { NuvelPaginationComponent } from "components/nuvel/Pagination";
import dayjs from "dayjs";
import React from "react";

function Row(props: any) {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow
        hover
        key={props.index}
        className={
          props.onRowClick
            ? {
                transition: "0.1s",
                cursor: "pointer",
              }
            : {
                transition: "0.1s",
              }
        }
        onClick={() =>
          props.onRowClick
            ? props.onRowClick(props.row, props.index)
            : undefined
        }
        {...(props.rowProps ? props.rowProps(props.row) : {})}
      >
        {props.child && (
          <TableCell
            sx={{
              padding: 1,
            }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              sx={(theme) => ({
                color: theme.palette.primary.main,
              })}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
        {props?.columns?.map((col: any, key1: any) => {
          return (
            <TableCell
              key={key1}
              sx={{
                padding: 1,
              }}
              style={{ ...props.cellStyle, ...col.style }}
              width={col.width}
              align={col.align}
            >
              {typeof col.accessor === "function"
                ? col.accessor(props.row, props.index)
                : props.formatbytype(
                    props.row,
                    col.accessor,
                    col.type,
                    props.index
                  )}
            </TableCell>
          );
        })}
      </TableRow>
      {props.child && (
        <TableRow
          sx={{
            transition: "0.1s",
          }}
        >
          <TableCell
            sx={{
              padding: 1,
            }}
            style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 70 }}
            colSpan={props.columns.length + 1}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <LegacySimpleTable
                columns={props.child}
                rows={props.row[props.child_key]}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

function LegacySimpleTable(props: any) {
  function formatbytype(data: any, accessor: any, type: any) {
    const final_data = typeof data === "object" ? data[accessor] : data;

    switch (type) {
      case "date":
        return dayjs(final_data).format("DD/MM/YYYY");
      case "datetime":
        return dayjs(final_data).format("DD/MM/YYYY HH:mm");
      case "time":
        return dayjs(final_data).format("HH:mm");
      case "percent":
        return `${final_data.toDecimal(2)}%`;
      case "money":
        return final_data?.toBRL();
      default:
        return final_data;
    }
  }

  return (
    <Paper
      style={{
        overflowX: "auto",
        marginRight: "auto",
        marginLeft: "auto",
        boxShadow: "none",
        width: "100%",
      }}
    >
      <Table className={props.className} style={props.style}>
        <TableHead>
          <TableRow>
            {props.child && (
              <TableCell
                sx={{
                  padding: 1,
                  fontWeight: "bold",
                }}
              />
            )}
            {props.columns?.map((col: any, key: any) => {
              if (col.renderHeader) return col.renderHeader(col);
              return (
                <TableCell
                  key={key}
                  sx={{
                    padding: 1,
                    fontWeight: "bold",
                  }}
                  style={{ ...props.headerStyle, ...col.hstyle }}
                  align={col.align}
                >
                  {col.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {(props.rows || []).map((row: any, key: any) => (
            <Row
              key={key}
              columns={props.columns}
              row={row}
              onRowClick={props.onRowClick}
              cellStyle={props.cellStyle}
              rowProps={props.rowProps}
              formatbytype={formatbytype}
              child={props.child}
              child_key={props.child_key}
              index={key}
            />
          ))}
          {props?.totals && (
            <TableRow
              hover
              sx={
                props.onRowClick
                  ? {
                      transition: "0.1s",
                      cursor: "pointer",
                    }
                  : {
                      transition: "0.1s",
                    }
              }
            >
              {props.child && (
                <TableCell
                  sx={{
                    padding: 1,
                    fontWeight: "bold",
                  }}
                />
              )}
              {props?.columns?.map((col: any, key1: any) => {
                if (!props.totals[col.accessor])
                  return <TableCell key={key1} />;
                return (
                  <TableCell
                    key={key1}
                    sx={{
                      padding: 1,
                    }}
                    style={{ ...props.cellStyle, ...col.style }}
                    width={col.width}
                    align={col.align}
                  >
                    {formatbytype(
                      props.totals[col.accessor],
                      col.accessor,
                      col.type
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          )}
        </TableBody>
      </Table>
      {props.setPage && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <NuvelPaginationComponent
            count={props.totalCount}
            page={props.page}
            setPage={props.setPage}
            pageSize={props.pageSize || 10}
          />
        </div>
      )}
    </Paper>
  );
}

export default LegacySimpleTable;
