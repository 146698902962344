import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_CONTABANCARIA_FILTERS,
  RETAGUARDA_CONTABANCARIA_LIST,
  RETAGUARDA_CONTABANCARIA_ORDERING,
} from "constants/retaguarda/financeiro/conta_bancaria";
import { ContaBancariaModel } from "data/models/financeiro";
import React, { useState } from "react";

import ContaBancariaAjusteModal from "./modals/ajuste";
import ContaBancariaTransferenciaModal from "./modals/transferencia";

const RetContaBancariaList: React.FC = () => {
  const [refreshListFn, setRefreshListFn] = useState<() => void>(() => {});
  const [modal, setModal] = useState({
    id: 0,
    open: false,
  });

  const handleModal = (id: number) => {
    if (id === modal.id) {
      setModal((_v) => ({ ..._v, open: !_v.open }));
    } else {
      setModal({
        id: id,
        open: true,
      });
    }
  };

  return (
    <>
      <NuvelDefaultList
        model={ContaBancariaModel}
        columns={RETAGUARDA_CONTABANCARIA_LIST}
        filters={RETAGUARDA_CONTABANCARIA_FILTERS}
        ordering={RETAGUARDA_CONTABANCARIA_ORDERING}
        actions={{
          create: true,
          update: true,
          delete: true,
        }}
        extraAddOptions={[
          {
            function: () => {
              handleModal(1);
            },
            label: "Transferência entre contas",
          },
          {
            function: () => {
              handleModal(2);
            },
            label: "Ajustar Saldo",
          },
        ]}
        captureMutate={setRefreshListFn}
      />
      <ContaBancariaTransferenciaModal
        id={1}
        modal={modal}
        handleModal={handleModal}
        refreshListFn={refreshListFn}
      />
      <ContaBancariaAjusteModal
        id={2}
        modal={modal}
        handleModal={handleModal}
        refreshListFn={refreshListFn}
      />
    </>
  );
};

export default RetContaBancariaList;
