import React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer, { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import {
  DrawerHeader,
  useDrawerState,
  createLinks,
  openedMixin,
  closedMixin,
} from "./sharedFunctions";
import { RouteInterface } from "utils/router";
import { Box, List } from "@mui/material";
import { Menu, Start } from "@mui/icons-material";
import DesktopUser from "./DesktopUser";

const drawerWidth = 280;

interface DesktopDrawerProps extends MuiDrawerProps {
  open?: boolean;
  hovered?: boolean;
}

const HoverDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "hovered",
})<DesktopDrawerProps>(({
  theme
}) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  variants: [{
    props: (
      {
        open
      }
    ) => open,

    style: {
          ...openedMixin(theme),
          "& .MuiDrawer-paper": {
            ...openedMixin(theme),
          },
        }
  }, {
    props: (
      {
        open
      }
    ) => !open,

    style: {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": {
            ...closedMixin(theme),
          },
        }
  }]
}));

const DesktopDrawer: React.FC<{
  open: boolean;
  handleDrawer: () => void;
  routes: RouteInterface[];
}> = ({ open, handleDrawer, routes }) => {
  const { state, changeState, anchorEls, setAnchor } = useDrawerState(
    routes,
    open
  );

  return (
    <HoverDrawer
      PaperProps={{
        elevation: 3,
      }}
      variant="permanent"
      anchor="left"
      open={open}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <DrawerHeader>
          <IconButton sx={{ mr: 0.5 }} color="primary" onClick={handleDrawer}>
            {open ? <Start sx={{ transform: "rotate(180deg)" }} /> : <Menu />}
          </IconButton>
        </DrawerHeader>
        <List disablePadding sx={{ flexGrow: 1 }}>
          {createLinks(
            routes,
            state,
            changeState,
            open,
            false,
            false,
            anchorEls,
            setAnchor
          )}
        </List>
        <Box sx={{ mt: "auto", p: 1, pb: 0 }}>
          <DesktopUser open={open} />
        </Box>
      </Box>
    </HoverDrawer>
  );
};

export default DesktopDrawer;