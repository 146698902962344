import NuvelGrid from "components/nuvel/Grid";
import NuvelSelect from "components/nuvel/Select";
import NuvelSwitch from "components/nuvel/Switch";
import NuvelTextField from "components/nuvel/TextField";
import { ParceiroDeNegocioSerializer } from "data/interfaces/core/ParceiroDeNegocioSerializer";
import React from "react";
import { handleChange } from "utils/functions";

interface TabClienteProps {
  state: ParceiroDeNegocioSerializer;
  setState: React.Dispatch<React.SetStateAction<ParceiroDeNegocioSerializer>>;
}
const TabCliente: React.FC<TabClienteProps> = ({ state, setState }) => {
  return (
    <NuvelGrid container spacing={1}>
      <NuvelGrid
        xs={12}
        md={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <NuvelSwitch
          checked={state.cliente_ativo}
          name="cliente_ativo"
          onChange={(e) => handleChange(e, setState)}
          label="Ativo"
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={5}>
        <NuvelTextField
          label="Filiação"
          onChange={(e) => handleChange(e, setState)}
          name="cliente_filiacao"
          value={state.cliente_filiacao}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={5}>
        <NuvelTextField
          label="Conjuguê"
          onChange={(e) => handleChange(e, setState)}
          name="cliente_conjugue"
          value={state.cliente_conjugue}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={6}>
        <NuvelSelect
          label="Sexo"
          name="cliente_sexo"
          value={state.cliente_sexo}
          onChange={(e) => handleChange(e, setState)}
          options={[
            {
              label: "Masculino",
              value: 1,
            },
            {
              label: "Feminino",
              value: 2,
            },
            {
              label: "Não Binário",
              value: 3,
            },
            {
              label: "Outro",
              value: 4,
            },
          ]}
        />
      </NuvelGrid>
      <NuvelGrid xs={12} md={6}>
        <NuvelSelect
          label="Estado Civil"
          name="cliente_estadocivil"
          value={state.cliente_estadocivil}
          onChange={(e) => handleChange(e, setState)}
          options={[
            {
              label: "Solteiro(a)",
              value: 1,
            },
            {
              label: "Casado(a)",
              value: 2,
            },
            {
              label: "Divorciado(a)",
              value: 3,
            },
            {
              label: "Viúvo(a)",
              value: 4,
            },
            {
              label: "Separado(a) judicialmente",
              value: 5,
            },
          ]}
        />
      </NuvelGrid>
    </NuvelGrid>
  );
};

export default TabCliente;
