/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppBar, Box, Tabs as MuiTabs, Tab, Typography } from "@mui/material";
import { InternalModel, MainInterface } from "data/main";
import { useAppContext } from "hooks";
import useCurrentRoute from "hooks/useCurrentRoute";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BaseForm from "../shared/form";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1.5, pl: 0, pr: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

interface NuvelDefaultFormProps<T extends InternalModel, L> {
  model: MainInterface<T, L>;
  state: T;
  setState: React.Dispatch<React.SetStateAction<T>>;
  onLoad?: (data: T) => void;
  tabs: {
    title: string;
    children: React.ReactNode;
    disabled?: boolean;
  }[];
}

const NuvelDefaultForm = <T extends InternalModel, L extends InternalModel>({
  model,
  setState,
  state,
  onLoad,
  tabs,
}: NuvelDefaultFormProps<T, L>) => {
  const {
    dialog: { showMessage },
    showSnack,
  } = useAppContext();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const formRef = React.useRef<HTMLFormElement>(null);

  const { id } = useParams<{ id: string }>();
  const route = useCurrentRoute(1);

  useEffect(() => {
    if (id && id !== "novo") {
      setLoading(true);
      model
        .get(Number(id))
        .then((data: T) => {
          setState(data);
          if (onLoad) {
            onLoad(data);
          }
          setLoading(false);
        })
        .catch(() => {
          showMessage(
            "Verifique sua conexão de internet, caso persista, favor consultar um administrador.",
            "Houve um erro ao processar solicitação."
          );
          navigate(-1);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formRef.current && !formRef.current.checkValidity()) {
      Array.from(formRef.current.elements).forEach((element) => {
        if (
          element instanceof HTMLInputElement ||
          element instanceof HTMLSelectElement ||
          element instanceof HTMLTextAreaElement
        ) {
          if (!element.validity.valid) {
            showSnack(
              `${element.validationMessage}: ${element.name}`,
              4,
              "error"
            );
          }
        }
      });

      // Exibe as mensagens de erro padrão nos campos
      formRef.current.reportValidity();
      return;
    }

    if (tabIndex !== getTabsLength()) {
      return handleTabIndex(null, true);
    }
    console.log("Formulário válido!");

    setLoading(true);
    model
      .save(id !== "novo" ? { ...state, id: Number(id) } : state)
      .then((response) => {
        if (response) {
          showSnack("Salvo com sucesso!", 2, "success");
          navigate(-1);
        } else {
          showSnack("Erro ao salvar!", 2, "error");
          setLoading(false);
        }
      });
  };

  const getNextIndexAvaliable = () => {
    let nextIndex = 0;
    Object.keys(tabs).forEach((_, index) => {
      const tab_content = tabs[index];
      if (tab_content?.disabled) {
        return;
      }
      if (index > tabIndex && nextIndex === 0) {
        nextIndex = index;
      }
    });
    return nextIndex;
  };

  const getTabsLength = () => {
    let tabLength = 0;
    Object.keys(tabs).forEach((_, index) => {
      const tab_content = tabs[index];
      if (tab_content?.disabled) {
        return;
      }
      if (tabLength < index) {
        tabLength = index;
      }
    });
    return tabLength;
  };

  const handleTabIndex = (val: any, isSubmit = false) => {
    if (isSubmit) {
      setTabIndex(getNextIndexAvaliable());
    } else {
      setTabIndex(val);
    }
  };

  return (
    <BaseForm
      handleSubmit={handleSubmit}
      loading={loading}
      route={route}
      navigate={navigate}
      formRef={formRef}
      buttonsProps={{
        save_label: tabIndex === getTabsLength() ? "SALVAR" : "Próximo",
      }}
    >
      <AppBar position="static">
        <MuiTabs
          value={tabIndex}
          onChange={(_, val) => handleTabIndex(val)}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {tabs.map((tabContent, index) => (
            <Tab
              label={tabContent.title}
              disabled={tabContent.disabled}
              {...a11yProps(index)}
            />
          ))}
        </MuiTabs>
      </AppBar>
      {tabs.map((tabContent, index) => (
        <TabPanel value={tabIndex} index={index}>
          {tabContent.children}
        </TabPanel>
      ))}
    </BaseForm>
  );
};

export default NuvelDefaultForm;
