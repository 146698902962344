import { Grid2Props } from "@mui/material";
import { Grid2 } from "@mui/material";
import React from "react";

interface DeprecatedSizes extends Grid2Props {
  /** @deprecated xs is no longer supported please use in size*/
  xs?: number;

  /** @deprecated sm is no longer supported please use in size*/
  sm?: number;

  /** @deprecated md is no longer supported please use in size*/
  md?: number;

  /** @deprecated lg is no longer supported please use in size*/
  lg?: number;

  /** @deprecated xl is no longer supported please use in size*/
  xl?: number;
}

const NuvelGrid: React.FC<DeprecatedSizes> = (props) => {
  const size = props.size
    ? props.size
    : {
        xs: props.xs || undefined,
        sm: props.sm || undefined,
        md: props.md || undefined,
        lg: props.lg || undefined,
        xl: props.xl || undefined,
      };

  return (
    <Grid2 size={size} {...props}>
      {props.children}
    </Grid2>
  );
};

export default NuvelGrid;
