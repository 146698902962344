import { DownloadXMLSerializer } from "data/interfaces/core/DownloadXMLSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "download_xml";

class DownloadXMLModel extends MainModule<
  DownloadXMLSerializer,
  DownloadXMLSerializer
> {}

export default new DownloadXMLModel(model_url);
