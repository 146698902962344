interface Date {
  isLeapYear(): boolean;
  getDaysInMonth(): number;
  addMonths(value: number): Date;
  format(format: string): string;
}

Date.prototype.isLeapYear = function (this: Date): boolean {
  return (
    (this.getFullYear() % 4 === 0 && this.getFullYear() % 100 !== 0) ||
    this.getFullYear() % 400 === 0
  );
};

Date.prototype.getDaysInMonth = function (this: Date): number {
  return [
    31,
    this.isLeapYear() ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ][this.getMonth()];
};

Date.prototype.addMonths = function (this: Date, value: number): Date {
  const n = this.getDate();
  this.setDate(1);
  this.setMonth(this.getMonth() + value);
  this.setDate(Math.min(n, this.getDaysInMonth()));
  return this;
};

Date.prototype.format = function (this: Date, format: string): string {
  const year = this.getFullYear();
  const month = this.getMonth() + 1;
  const day = this.getDate();
  const hours = this.getHours();
  const minutes = this.getMinutes();
  const seconds = this.getSeconds();

  const pad = (value: number): string =>
    value < 10 ? `0${value}` : `${value}`;

  return format
    .replace("YYYY", year.toString())
    .replace("MM", pad(month))
    .replace("DD", pad(day))
    .replace("HH", pad(hours))
    .replace("mm", pad(minutes))
    .replace("ss", pad(seconds));
};
