import { Delete, Edit, Event, Undo } from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { blue, green, red, yellow } from "@mui/material/colors";
import CardContasAReceber from "components/legacy/card";
import NuvelCheckbox from "components/nuvel/Checkbox";
import { TransacaoFaturamentoSerializer } from "data/interfaces/financeiro/TransacaoFaturamentoSerializer";
import { TransacaoPrestacaoSerializer } from "data/interfaces/financeiro/TransacaoPrestacaoSerializer";
import { TransacaoSerializer } from "data/interfaces/financeiro/TransacaoSerializer";
import { TransacaoModel } from "data/models";
import dayjs from "dayjs";
import React from "react";
import { showConfirm } from "utils/functions/dialog";

const TableCellStyled = styled(TableCell)(() => ({
  fontWeight: "bold",
}));

interface ContaAReceberListProps {
  data:
    | {
        page: number;
        rows: TransacaoPrestacaoSerializer[];
        totalCount: number;
      }
    | undefined;
  handleModal: (
    id: number,
    transacao?: TransacaoSerializer,
    prestacao?: TransacaoPrestacaoSerializer
  ) => void;
  selected: TransacaoPrestacaoSerializer[];
  setSelected: React.Dispatch<
    React.SetStateAction<TransacaoPrestacaoSerializer[]>
  >;
  setEstornos: React.Dispatch<
    React.SetStateAction<TransacaoFaturamentoSerializer[]>
  >;
  mutate: () => void;
  page: number;
  total_pages: number;
  handleChangePage: (event: unknown, newPage: number) => void;
}

const statusOptions = [
  { label: "A Vencer", value: 0, color: blue[500] },
  { label: "Pago", value: 1, color: green[500] },
  { label: "Parcialmente Pago", value: 2, color: yellow[900] },
  { label: "Vencido", value: 3, color: red[500] },
];

const getStatusLabel = (status: number) => {
  return statusOptions.find((option) => option.value === status)?.label;
};

const getStatusColor = (status: number) => {
  return statusOptions.find((option) => option.value === status)?.color;
};

const ContaAReceberList: React.FC<ContaAReceberListProps> = ({
  data,
  handleModal,
  selected,
  setSelected,
  setEstornos,
  mutate,
  page,
  total_pages,
  handleChangePage,
}) => {
  const onSelectAllClick = (event: {
    target: { name: string; value: boolean };
  }) => {
    if (event.target.value) {
      setSelected(data?.rows || []);
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (row: TransacaoPrestacaoSerializer) => {
    if (selected.includes(row)) {
      setSelected(selected.filter((a) => a.id !== row.id));
    } else {
      setSelected([...selected, row]);
    }
  };

  const handleDelete = (id: number | undefined) => {
    TransacaoModel.delete(id || 0).then(() => {
      mutate();
    });
  };

  return (
    <>
      <TableContainer sx={{ flexGrow: 1, position: "relative" }}>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
          <TableHead>
            <TableCell padding="checkbox" align="center">
              <NuvelCheckbox
                style={{ width: "100%", margin: 0 }}
                indeterminate={
                  selected.length > 0 &&
                  selected.length < (data?.rows?.length || 0)
                }
                checked={
                  selected.length > 0 &&
                  selected.length === (data?.rows?.length || 0)
                }
                onChange={onSelectAllClick}
              />
            </TableCell>
            <TableCellStyled sx={{ width: "5%" }} align="center">
              Status
            </TableCellStyled>
            <TableCellStyled>Descrição</TableCellStyled>
            <TableCellStyled>Fornecedor</TableCellStyled>
            <TableCellStyled sx={{ width: "10%" }} align="center">
              Vencimento
            </TableCellStyled>
            <TableCellStyled sx={{ width: "10%" }} align="center">
              Total
            </TableCellStyled>
            <TableCellStyled sx={{ width: "15%" }} align="center">
              Ações
            </TableCellStyled>
          </TableHead>
          <TableBody>
            {data?.rows.map((row) => {
              const parcela = {
                isPending: row.status === 0,
                isPaid: row.status === 1,
                isPartial: row.status === 2,
                isLate: row.status === 3,
                transacao: {
                  isPending: row.transacao?.status === 0,
                  isPaid: row.transacao?.status === 1,
                  isPartial: row.transacao?.status === 2,
                  isLate: row.transacao?.status === 3,
                },
              };

              const allowSelect = !(
                parcela.isPending ||
                parcela.isPartial ||
                parcela.isLate
              );

              const allowEdit = allowSelect;
              const allowAlterDate = allowSelect && parcela.isPaid;
              const allowEstorno = !(parcela.isPaid || parcela.isPartial);

              return (
                <TableRow
                  key={row.id}
                  hover
                  onClick={() => !allowSelect && handleSelect(row)}
                >
                  <TableCell padding="checkbox" align="center">
                    <NuvelCheckbox
                      style={{ width: "100%", margin: 0 }}
                      checked={selected.includes(row)}
                      onChange={() => handleSelect(row)}
                      disabled={allowSelect}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={getStatusLabel(row.status || 0)}
                      sx={{
                        backgroundColor: getStatusColor(row.status || 0),
                        color: "white",
                      }}
                    />
                  </TableCell>
                  <TableCell>{row.descricao}</TableCell>
                  <TableCell>
                    {row.parceiro_de_negocios_nome || "Sem Fornecedor"}
                  </TableCell>
                  <TableCell align="center">
                    {dayjs(row.vencimento).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="center">{row.valor?.toBRL()}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Editar Transação">
                      <IconButton
                        color="info"
                        disabled={allowEdit}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleModal(1, row.transacao);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Alterar Data">
                      <IconButton
                        color="warning"
                        disabled={allowAlterDate}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleModal(4, undefined, row);
                        }}
                      >
                        <Event />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Estornar">
                      <IconButton
                        color="error"
                        disabled={allowEstorno}
                        onClick={() => {
                          setEstornos(row.faturamentos || []);
                          handleModal(3);
                        }}
                      >
                        <Undo />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar">
                      <IconButton
                        color="error"
                        disabled={!parcela.transacao.isPending}
                        onClick={(e) => {
                          e.stopPropagation();
                          showConfirm(
                            "Você irá deletar todas as prestações associadas à essa Transação",
                            "Atenção!",
                            (valid: boolean) => {
                              if (valid) {
                                handleDelete(row.transacao?.id);
                              }
                            }
                          );
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ position: "relative" }}>
        <div style={{ opacity: 0, marginTop: 8 }}>
          <CardContasAReceber title="Selecionadas" value={""} />
        </div>
        <div style={{ opacity: 1 }}>
          <Box
            sx={{
              position: "absolute",
              right: "50%",
              transform: "translateX(50%) translateY(50%)",
              bottom: "50%",
            }}
          >
            <Pagination
              page={page}
              onChange={handleChangePage}
              count={total_pages}
              variant="outlined"
              color="primary"
            />
          </Box>
          <Box display="flex" position="absolute" bottom={4} left={12}>
            <Box mr={1} style={{ width: 150 }}>
              <CardContasAReceber
                title="Pagas"
                value={
                  data?.rows
                    ?.filter((a) => a.status === 1)
                    .reduce((sum, a) => sum + (Number(a.valor) || 0), 0)
                    .toFixed(2) || "0"
                }
              />
            </Box>
            <Box mr={1} style={{ width: 150 }}>
              <CardContasAReceber
                title="A Pagar"
                value={
                  data?.rows
                    ?.filter((a) => a.status !== 1)
                    .reduce((sum, a) => sum + (Number(a.valor) || 0), 0)
                    .toFixed(2) || "0"
                }
              />
            </Box>
            <Box mr={1} style={{ width: 150 }}>
              <CardContasAReceber
                title="Selecionadas"
                value={selected
                  .reduce((sum, a) => sum + (Number(a.valor) || 0), 0)
                  .toFixed(2)}
              />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default ContaAReceberList;
