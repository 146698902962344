import { Add, Visibility } from "@mui/icons-material";
import {
  Fab,
  Menu,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { ContaBancariaSerializer } from "data/interfaces/financeiro/ContaBancariaSerializer";
import React from "react";

interface ListValeClienteProps {
  handleModal: (id: number, conta_id?: number) => void;
  page: number;
  total_pages: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  modal: {
    open: boolean;
    id: number;
    conta_id: number | undefined;
  };
  data?: {
    page: number;
    rows: ContaBancariaSerializer[];
    totalCount: number;
  };
}

const ListValeCliente: React.FC<ListValeClienteProps> = ({
  data,
  handleModal,
  page,
  total_pages,
  handleChangePage,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleClickList = (id: number) => {
    handleModal(3, id);
  };

  return (
    <React.Fragment>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>CPF / CNPJ</TableCell>
              <TableCell sx={{ width: "80%" }}>Razão Social</TableCell>
              <TableCell>Saldo</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.rows.map((row, index) => (
              <TableRow
                hover
                onClick={() => handleClickList(row.id as number)}
                key={index}
              >
                <TableCell>{row.parceiro_cpf_cnpj}</TableCell>
                <TableCell>{row.parceiro_nome}</TableCell>
                <TableCell>{row.saldo_atual?.toBRL()}</TableCell>
                <TableCell>
                  <Tooltip title="Transações feitas">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickList(row.id as number);
                      }}
                      color="primary"
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          minHeight: 56,
          position: "absolute",
          right: "50%",
          transform: "translateX(50%)",
          bottom: 0,
        }}
      >
        <Pagination
          page={page}
          onChange={handleChangePage}
          count={total_pages}
          variant="outlined"
          color="primary"
        />
      </Box>
      <Fab
        color="primary"
        onClick={handleClickOptions}
        sx={{
          position: "fixed",
          bottom: 32,
          right: 32,
          width: 72,
          height: 72,
        }}
      >
        <Add sx={{ fontSize: 72 / 1.5 }} />
      </Fab>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseOptions}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => handleModal(1)}>Adicionar Crédito</MenuItem>
        <MenuItem onClick={() => handleModal(2)}>Adicionar Débito</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ListValeCliente;
