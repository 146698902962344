import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Fab,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import NuvelGrid from "components/nuvel/Grid";
import NuvelModal from "components/nuvel/Modal";
import { NuvelPaginationComponent } from "components/nuvel/Pagination";
import NuvelTextField from "components/nuvel/TextField";
import { AgendaModel } from "data/models";
import useQuery from "hooks/useQuery";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleChange } from "utils/functions";
import { showConfirm } from "utils/functions/dialog";

/* eslint-disable @typescript-eslint/no-explicit-any */
const defaultState = {
  title: "",
  description: "",
};

function List_Agendas() {
  const [add, setAdd] = useState<any>(false);
  const [edit, setEdit] = useState<any>(0);
  const [state, setState] = useState<any>(defaultState);

  const navigate = useNavigate();

  const query = useQuery();
  const currentPage = query.get("page") || 1;

  const { data: listData, mutate: refreshList } = AgendaModel.useModel({
    page: currentPage,
    page_size: 10,
  });

  const changePage = (page: any) => {
    navigate(`?page=${page}`);
  };

  const handleEdit = (item: any) => {
    AgendaModel.get(item.id).then((data) => {
      setState(data);
      setAdd(true);
      setEdit(item.id);
    });
  };

  const handleClose = () => {
    setAdd(false);
    setEdit(0);
    setState(defaultState);
  };

  const RowActions = (inner_props: any) => (
    <>
      <IconButton
        aria-label="edit"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          handleEdit(inner_props.item);
        }}
      >
        <Edit fontSize="inherit" />
      </IconButton>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          deleteModel(inner_props.item);
        }}
      >
        <Delete fontSize="inherit" />
      </IconButton>
    </>
  );

  const deleteModel = (item: any) => {
    showConfirm(
      `Tem certeza que deseja deletar ${item.title}?`,
      "Mensagem de Cofirmação",
      () => {
        AgendaModel.delete(item.id).then(() => {
          refreshList();
        });
      }
    );
  };

  const columns = [
    {
      Header: "Título",
      accessor: "title",
      width: 200,
    },
    {
      Header: "Descrição",
      accessor: "description",
    },
  ];

  return (
    <div>
      <Paper>
        <Table
          style={{ width: "100%" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  style={{ fontWeight: "bold", width: column.width }}
                >
                  {column.Header}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {listData?.rows.map((row: any, i) => (
              <TableRow
                key={i}
                hover
                onClick={() =>
                  navigate(window.location.pathname + "/" + row.id)
                }
              >
                {columns.map((column, j) => (
                  <TableCell key={j}>{row[column.accessor]}</TableCell>
                ))}
                <TableCell align="right">
                  <RowActions item={row} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box display="flex" justifyContent="center" p={1}>
          <NuvelPaginationComponent
            setPage={changePage}
            page={currentPage as number}
            count={listData?.totalCount || 0}
            pageSize={10}
          />
        </Box>
      </Paper>
      <Tooltip
        PopperProps={{ disablePortal: true }}
        onClick={() => setAdd(true)}
        title={"Adicionar Agenda"}
      >
        <Fab
          color="primary"
          style={{
            position: "fixed",
            bottom: 32,
            right: 32,
            width: 70,
            height: 70,
            zIndex: 1000,
          }}
        >
          <Add style={{ fontSize: 40 }} />
        </Fab>
      </Tooltip>
      <NuvelModal
        title={edit ? "Editar Agenda" : "Adicionar Agendas"}
        open={add}
        btnCancel={() => handleClose()}
        btnSave={() => {
          AgendaModel.save(state).then(() => {
            handleClose();
            refreshList();
          });
        }}
      >
        <NuvelGrid container spacing={2}>
          <NuvelGrid xs={12}>
            <NuvelTextField
              label="Título"
              name="title"
              onChange={(e) => handleChange(e, setState)}
              value={state.title}
            />
          </NuvelGrid>
          <NuvelGrid xs={12}>
            <NuvelTextField
              label="Descrição"
              onChange={(e) => handleChange(e, setState)}
              name="description"
              value={state.description}
            />
          </NuvelGrid>
        </NuvelGrid>
      </NuvelModal>
    </div>
  );
}

export default List_Agendas;
