import { AjusteDeEstoqueSerializer } from "data/interfaces/estoque/AjusteDeEstoqueSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "estoque/ajuste_de_estoque";

class AjusteDeEstoqueModel extends MainModule<
  AjusteDeEstoqueSerializer,
  AjusteDeEstoqueSerializer
> {}

export default new AjusteDeEstoqueModel(model_url);
