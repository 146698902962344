/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-async-promise-executor */
import { pdf } from "@react-pdf/renderer";
import printj from "print-js";

const printDoc = async (doc: any) => {
  const blob = await pdf(doc).toBlob();

  return new Promise<void>(async (resolve) => {
    const pdfUrl = URL.createObjectURL(blob);
    printj({
      printable: pdfUrl,
      onPrintDialogClose: async () => {
        URL.revokeObjectURL(pdfUrl);
        resolve();
      },
    });
  });
};

export { printDoc };
