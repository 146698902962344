import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Typography, useTheme } from "@mui/material";
import NuvelGrid from "components/nuvel/Grid";
import PaperWithLoad from "components/nuvel/PaperWithLoad";
import dayjs from "dayjs";

export default function VendasPeriodo({ ...props }) {
  const { data, isDayInterval } = props;
  const [innerData, setInnerData] = React.useState([]);

  const theme = useTheme();
  const currentTheme = localStorage.getItem("theme");
  const color =
    currentTheme === "light"
      ? theme.palette.primary.main
      : theme.palette.primary.main;
  const textC =
    currentTheme === "light"
      ? theme.palette.common.black
      : theme.palette.common.white;

  React.useEffect(() => {
    if (data) {
      if (data?.length !== 0) {
        const _temp = [];
        data.forEach((element) => {
          if (element.v_total > 0) {
            let date = ((element) => {
              if (!isDayInterval)
                return dayjs(element.data).format("DD/MM/YYYY");
              return dayjs(element.data).format("HH:mm");
            })(element);
            _temp.push({ date: date, y: element.v_total });
          }
        });
        setInnerData(_temp);
      }
    }
  }, [data]);

  const CustomizedAxisTickX = ({ ...props }) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill={textC}>
          {payload.value.includes("/")
            ? `${payload.value}`
            : `${payload.value}H`}
        </text>
      </g>
    );
  };

  const CustomizedAxisTickY = ({ ...props }) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill={textC}
        >{`R$${payload.value}`}</text>
      </g>
    );
  };

  return (
    <>
      <PaperWithLoad loading={!data} style={{ width: "100%", height: "24em" }}>
        <NuvelGrid container>
          <NuvelGrid
            style={{
              width: "100%",
              height: "3em",
              textAlign: "center",
            }}
            xs={12}
          >
            <Typography variant="h5" component="h2">
              Vendas Por Período
            </Typography>
          </NuvelGrid>
          <NuvelGrid style={{ width: "100%", height: "21em" }} xs={12}>
            {innerData.length > 0 && (
              <ResponsiveContainer width="100%" height="100%" label={"Teste"}>
                <BarChart data={innerData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    height={30}
                    tick={<CustomizedAxisTickX />}
                  />
                  <YAxis height={60} tick={<CustomizedAxisTickY />} />
                  <Tooltip
                    labelFormatter={(value) => value}
                    formatter={(value, order, entry) => [
                      `${value.toBRL()}`,
                      entry?.payload?.date,
                    ]}
                  />
                  <Bar dataKey="y" fill={color} />
                </BarChart>
              </ResponsiveContainer>
            )}
          </NuvelGrid>
        </NuvelGrid>
      </PaperWithLoad>
    </>
  );
}
