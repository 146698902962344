import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_DOWNLOADXML_FILTERS,
  RETAGUARDA_DOWNLOADXML_LIST,
  RETAGUARDA_DOWNLOADXML_ORDERING,
} from "constants/retaguarda/fiscal/download_xml";
import { DownloadXMLModel } from "data/models";
import React from "react";

const RetDownloadXMLList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={DownloadXMLModel}
      columns={RETAGUARDA_DOWNLOADXML_LIST}
      filters={RETAGUARDA_DOWNLOADXML_FILTERS}
      ordering={RETAGUARDA_DOWNLOADXML_ORDERING}
      actions={{
        create: true,
      }}
    />
  );
};

export default RetDownloadXMLList;
