/* eslint-disable @typescript-eslint/no-explicit-any */
import { CollectionsBookmark, Folder } from "@mui/icons-material";
import { Button, Typography, useTheme } from "@mui/material";
import NuvelTreeItem from "../TreeItem";

// eslint-disable-next-line react-refresh/only-export-components
export const findTreeItem = (arr: any[], id: any) => {
  let obj: any;
  arr.some((item: any) => {
    if (Number(item.id) === Number(id)) {
      obj = item;
      return true;
    }
    if (item.filhos.length >= 1) {
      obj = findTreeItem(item.filhos, id);
      if (obj) return true;
    }
    return false;
  });
  return obj;
};

export function StyledTreeItem(props: any) {
  const { handleSelect, isPai, name, itemId, key, ...other } = props;
  const theme = useTheme();
  return (
    <NuvelTreeItem
      key={key}
      itemId={itemId}
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0.2, 0),
          }}
        >
          {isPai ? (
            <Folder color="inherit" sx={{ mr: 1 }} />
          ) : (
            <CollectionsBookmark color="primary" sx={{ mr: 1 }} />
          )}
          <Typography
            variant="body2"
            sx={{
              fontWeight: "inherit",
              flexGrow: 1,
            }}
          >
            {name}
          </Typography>
          <Button
            onClick={(e) => handleSelect(e, other.nodeId)}
            size="small"
            variant="contained"
            color="primary"
          >
            Selecionar
          </Button>
        </div>
      }
      {...other}
    />
  );
}

const getNodeNumber = (node: any, index: any, OldNumber: any) => {
  let NewNumber;
  if (OldNumber) {
    NewNumber = `${OldNumber}.${
      node.id === "1" || node.id === "4" ? index + 1 : "0" + (index + 1)
    }`;
  } else {
    NewNumber = `${
      node.id === 1 || node.id === 2 ? index + 1 : "0" + (index + 1)
    }`;
  }
  return NewNumber;
};

// eslint-disable-next-line react-refresh/only-export-components
export const renderTree = (
  mainNode: any,
  handleSelect: any,
  OldNumber?: any
) => {
  const generateProps = (node: any, idx: number) => {
    const NewNumber = getNodeNumber(node, idx, OldNumber);

    const name = `${NewNumber} - ${node.descricao}`;

    const treeItemProps = {
      key: node.id,
      handleSelect: (e: any) => handleSelect(e, node.id),
      isPai: node.filhos?.length > 0,
      name: name,
      itemId: String(node.id),
    };

    return treeItemProps;
  };

  const mapNode = Array.isArray(mainNode) ? mainNode : [mainNode];
  return mapNode.map((nodes, index) => {
    const innerProps = generateProps(nodes, index);
    return (
      <StyledTreeItem {...innerProps}>
        {Array.isArray(nodes.filhos)
          ? nodes.filhos.map((node: any) =>
              renderTree(node, handleSelect, OldNumber)
            )
          : null}
      </StyledTreeItem>
    );
  });
};

// export function renderTree(
//   mainNode: any,
//   handleSelect: any,
//   index?: any,
//   OldNumber?: any
// ) {
//   if (Array.isArray(mainNode)) {
//     return mainNode.map((nodes, idx) => {
//       const NewNumber = getNodeNumber(nodes, idx, OldNumber);

//       const name = `${NewNumber} - ${nodes.descricao}`;
//       return (
//         <StyledTreeItem
//           index={idx}
//           key={nodes.id}
//           itemId={String(nodes.id)}
//           name={name}
//           ativo={nodes.ativo}
//           counter={NewNumber}
//           labelText={nodes.descricao}
//           isPai={nodes.filhos?.length > 0}
//           handleSelect={handleSelect}
//         >
//           {Array.isArray(nodes.filhos)
//             ? nodes.filhos.map((node: any, index: any) =>
//                 renderTree(node, handleSelect, index, NewNumber)
//               )
//             : null}
//         </StyledTreeItem>
//       );
//     });
//   } else {
//     const NewNumber = getNodeNumber(mainNode, index, OldNumber);
//     const name = `${NewNumber} - ${mainNode.descricao}`;

//     return (
//       <StyledTreeItem
//         index={index}
//         key={mainNode.id}
//         itemId={String(mainNode.id)}
//         labelText={mainNode.descricao}
//         counter={NewNumber}
//         name={name}
//         handleSelect={handleSelect}
//         ativo={mainNode.ativo}
//         isPai={mainNode.filhos?.length > 0}
//       >
//         {Array.isArray(mainNode.filhos)
//           ? mainNode.filhos.map((node: any, index: any) =>
//               renderTree(node, handleSelect, index, NewNumber)
//             )
//           : null}
//       </StyledTreeItem>
//     );
//   }
// }