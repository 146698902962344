/* eslint-disable @typescript-eslint/no-explicit-any */
import { Add, Delete } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Fab,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import FilialSelection from "components/legacy/FilialTable";
import InputField from "components/legacy/inputField";
import LegacySimpleTable from "components/legacy/legacy_simple_list";
import NuvelCheckbox from "components/nuvel/Checkbox";
import NuvelGrid from "components/nuvel/Grid";
import NuvelModal from "components/nuvel/Modal";
import NuvelTextField from "components/nuvel/TextField";
import { ProdutoSerializer } from "data/interfaces/estoque/ProdutoSerializer";
import { UnidadeModel } from "data/models";
import React from "react";
import { handleChange } from "utils/functions";

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1.5, pl: 0, pr: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface TabEstoqueProps {
  state: ProdutoSerializer;
  setState: React.Dispatch<React.SetStateAction<ProdutoSerializer>>;
}

const TabPrecos: React.FC<TabEstoqueProps> = ({ state, setState }) => {
  const [value1, setValue] = React.useState(0);

  const handleChangeTab = (event: any, newValue: any) => {
    setValue(event?.type === "click" ? newValue : event);
  };

  const defaultState = {
    nome: "",
    descricao: "",
    qt_minima: 0,
    aplicar_automaticamente: true,
    informar_na_venda: false,
    precos: [],
    lojas: [],
    editando: -1,
  };
  const [innerState, setInnerState] = React.useState(defaultState);

  const [modal, setModal] = React.useState({
    open: false,
  });

  const handleModal = () => {
    setModal((_v) => ({ ..._v, open: !modal.open }));
  };

  const { data: listUnidade } = UnidadeModel.useModel();

  React.useEffect(() => {
    const obj: any = [];
    state.codbarras.forEach((cod) => {
      const { preco } = precoDoCodBarra(cod);
      if (!preco) {
        obj.push({
          codbarra: cod.id ? cod.id : cod.cod_barra,
          preco_venda: 0,
          preco_custo: 0,
        });
      } else {
        obj.push(preco);
      }
    });
    setInnerState((_v) => ({ ..._v, precos: obj }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.codbarras, innerState.editando, modal.open]);

  const handleChangeValue = (e: any, index: any) => {
    const { value, name } = e.target;
    const obj: any = [...innerState.precos];
    obj[index][name] = Number(value || "0");
    return setInnerState((_v) => ({ ..._v, precos: obj }));
  };

  const removePreco = (e: any, index: any) => {
    e.stopPropagation();
    let obj = [...state.precos];
    obj = obj.filter((_, i) => i !== index);
    setState((_v) => ({ ..._v, precos: obj }));
  };

  const precoDoCodBarra = (codbarra: any) => {
    const precoIndex = innerState.precos.findIndex(
      (x: any) =>
        x.codbarra === codbarra.id || x.codbarra === codbarra.cod_barra
    );
    const preco = innerState.precos[precoIndex];
    return { preco, precoIndex };
  };

  return (
    <>
      <LegacySimpleTable
        columns={[
          {
            accessor: "nome",
            label: "Nome",
          },
          {
            accessor: "qt_minima",
            label: "Qt. Mínima",
          },
          {
            accessor: "descricao",
            label: "Descrição",
          },
          {
            accessor: (_: any, index: any) => (
              <IconButton onClick={(e) => removePreco(e, index)} size="small">
                <Delete fontSize="inherit" />
              </IconButton>
            ),
            label: "",
            align: "right",
          },
        ]}
        rows={state.precos}
        onRowClick={(preco: any, index: any) => {
          setInnerState({ ...preco, editando: index });
          handleModal();
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          width: "100%",
        }}
      >
        <Fab
          color="primary"
          style={{ margin: 10 }}
          disabled={state.codbarras.length === 0}
          onClick={handleModal}
        >
          <Add
            fontSize="large"
            sx={(theme) => ({
              color: theme.palette.secondary.contrastText,
            })}
          />
        </Fab>
      </div>
      <NuvelModal
        title="Criação de Preço"
        open={modal.open}
        btnCancel={() => {
          setInnerState(defaultState);
          handleModal();
        }}
        minWidth="sm"
        fullWidth
        btnSave={() => {
          const obj: any = [...state.precos];
          if (innerState.editando >= 0) {
            obj[innerState.editando] = innerState;
          } else {
            obj.push(innerState);
          }
          setState((_v) => ({ ..._v, precos: obj }));
          setInnerState(defaultState);
          handleModal();
        }}
      >
        <NuvelGrid container spacing={1}>
          <NuvelGrid xs={9}>
            <NuvelTextField
              label="Nome"
              onChange={(e) => handleChange(e, setInnerState)}
              name="nome"
              value={innerState.nome}
            />
          </NuvelGrid>
          <NuvelGrid xs={3}>
            <NuvelTextField
              label="Qt. Mínima"
              onChange={(e) => handleChange(e, setInnerState)}
              type="number"
              name="qt_minima"
              value={innerState.qt_minima}
            />
          </NuvelGrid>
          <NuvelGrid xs={12}>
            <NuvelTextField
              label="Descrição"
              onChange={(e) => handleChange(e, setInnerState)}
              name="descricao"
              value={innerState.descricao}
            />
          </NuvelGrid>
          <NuvelGrid xs={12}>
            <NuvelCheckbox
              label={"Aplicar automaticamente"}
              onChange={(e) => handleChange(e, setInnerState)}
              name="aplicar_automaticamente"
              checked={innerState.aplicar_automaticamente}
            />
          </NuvelGrid>
          <NuvelGrid xs={12}>
            <NuvelCheckbox
              label={"Informar na venda"}
              onChange={(e) => handleChange(e, setInnerState)}
              name="informar_na_venda"
              checked={innerState.informar_na_venda}
            />
          </NuvelGrid>

          <NuvelGrid xs={12}>
            <AppBar position="static" color="transparent">
              <Tabs
                value={value1}
                onChange={handleChangeTab}
                centered
                variant="fullWidth"
                aria-label="simple tabs example"
              >
                <Tab label="Preços" {...a11yProps(0)} />
                <Tab label="Lojas" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value1} index={0}>
              <LegacySimpleTable
                columns={[
                  { accessor: "cod_barra", label: "Cod. Barras", width: "30%" },
                  {
                    accessor: (cod: any) =>
                      listUnidade?.rows?.find((x) => x.id === cod.unidade)
                        ?.nome,
                    label: "UN",
                    width: "10%",
                  },
                  {
                    accessor: (cod: any) => {
                      const { preco }: any = precoDoCodBarra(cod);
                      return `${(
                        (Number(preco?.preco_venda) * 100) /
                          Number(preco?.preco_custo) -
                          100 || 0
                      ).toDecimal(0)}%`;
                    },
                    label: "Margem",
                    align: "center",
                  },
                  {
                    accessor: (cod: any) => {
                      const { preco, precoIndex }: any = precoDoCodBarra(cod);
                      return (
                        <InputField
                          money
                          onChange={(e: any) =>
                            handleChangeValue(e, precoIndex)
                          }
                          name="preco_custo"
                          value={Number(preco?.preco_custo)}
                          style={{ textAlignLast: "center" }}
                        />
                      );
                    },
                    label: "Custo",
                    align: "center",
                  },
                  {
                    accessor: (cod: any) => {
                      const { preco, precoIndex }: any = precoDoCodBarra(cod);
                      return (
                        <InputField
                          money
                          onChange={(e: any) =>
                            handleChangeValue(e, precoIndex)
                          }
                          name="preco_venda"
                          style={{ textAlignLast: "center" }}
                          value={Number(preco?.preco_venda)}
                        />
                      );
                    },
                    label: "Preço",
                    align: "center",
                  },
                ]}
                rows={state.codbarras}
              />
            </TabPanel>
            <TabPanel value={value1} index={1}>
              <FilialSelection getter={innerState} setter={setInnerState} />
            </TabPanel>
          </NuvelGrid>
        </NuvelGrid>
      </NuvelModal>
    </>
  );
};

export default TabPrecos;
