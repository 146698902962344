import { NuvelAutoForm } from "components/nuvel/forms";
import { RETAGUARDA_TAG_FORM } from "constants/retaguarda/cadastro/tag";
import { TagModel } from "data/models";
import React from "react";

const RetTagForm: React.FC = () => {
  return <NuvelAutoForm fields={RETAGUARDA_TAG_FORM} model={TagModel} />;
};

export default RetTagForm;
