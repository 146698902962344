import React from "react";
import { Typography, IconButton } from "@mui/material";
import { Delete as DeleteIcon, AccountCircle } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  contatoIcon: {
    fontSize: 70,
  },
  contatoContainer: {
    paddingLeft: 8,
  },
  contatoDeleteButton: {
    position: "absolute",
    right: 8,
  },
}));

export default function Contato(props) {
  const classes = useStyles();
  const { data, handleItemDeleteClick, keyIndex } = props;
  return (
    <>
      <AccountCircle className={classes.contatoIcon} />
      <div className={classes.contatoContainer}>
        <Typography variant="h5">{data.nome}</Typography>
        <Typography variant="subtitle1" style={{ lineHeight: "1.4" }}>
          Email : {data.email} <br />
          Telefone: {data.telefone}
        </Typography>
      </div>
      <IconButton
        className={classes.contatoDeleteButton}
        aria-label="delete"
        onClick={(e) => handleItemDeleteClick(e, keyIndex)}
      >
        <DeleteIcon className={classes.contatoIcon} style={{ fontSize: 45 }} />
      </IconButton>
    </>
  );
}
