import { TableColumn, TableFilter } from "components/nuvel/lists";
import { PDVSerializer } from "data/interfaces/pdv/PDVSerializer";

export const RETAGUARDA_PDV_LIST: TableColumn<PDVSerializer>[] = [
  {
    label: "Descrição",
    accessor: "descricao",
    minWidth: 50,
  },
  {
    label: "Loja",
    accessor: "loja_descricao",
    minWidth: 250,
  },
  {
    label: "Ultima Comunicação",
    accessor: "ultima_comunicacao",
    minWidth: 300,
    type: "datetime",
  },
  {
    label: "PDV Fiscal",
    accessor: (row) => (row.emite_nfce ? "Sim" : "Não"),
    minWidth: 300,
  },
];

export const RETAGUARDA_PDV_ORDERING: (keyof PDVSerializer)[] = ["descricao"];

export const RETAGUARDA_PDV_FILTERS: TableFilter<PDVSerializer>[] = [
  {
    label: "Descrição",
    accessor: "descricao",
    support: ["icontains"],
  },
];
