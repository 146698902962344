import React, { useEffect } from "react";
import { ThemeProvider, createTheme, responsiveFontSizes, useMediaQuery } from "@mui/material";
import { generateTheme } from "./theme";

interface ThemeConstructorProps {
  theme: string;
  setTheme: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode;
}

function ThemeConstructor({
  theme,
  setTheme,
  children,
}: ThemeConstructorProps) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    if (prefersDarkMode && localStorage.getItem("token") === "") {
      setTheme("dark");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const current_theme = React.useMemo(
    () => responsiveFontSizes(createTheme(generateTheme(theme))),
    [theme]
  );

  return <ThemeProvider theme={current_theme}>{children}</ThemeProvider>;
}

export default ThemeConstructor;
