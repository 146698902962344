/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { Button, DialogActions, Typography } from "@mui/material";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AgendamentoModel } from "data/models";
import NuvelGrid from "components/nuvel/Grid";
import NuvelTextField from "components/nuvel/TextField";
import NuvelAutocomplete from "components/nuvel/Autocomplete";

const CustomEditor = (props: any) => {
  const { scheduler } = props;
  const event = scheduler.edited;
  const event_state = scheduler.state;

  const [error, setError] = useState<any>("");
  const [state, setState] = useState<any>({
    ...(event ? event : {}),
    title: event?.title || "",
    description: event?.description || "",
    parceiro_funcionario: event?.parceiro_funcionario_id || null,
    service: event?.service || null,
    parceiro_cliente: event?.parceiro_cliente || null,
    start: dayjs(event_state?.start?.value) || dayjs(new Date()),
    end: dayjs(event_state?.end?.value) || dayjs(new Date()),
  });

  const handleChange = (value: any, name: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleSubmit = async () => {
    // Your own validation
    if (state.title.length < 3) {
      return setError("Minimo de 3 letras");
    }

    try {
      scheduler.loading(true);

      const added_or_updated_agent = await AgendamentoModel.save_with_response(
        state
      ).then((res) => {
        const { data } = res;
        return {
          event_id: data.id,
          parceiro_funcionario_id: data.parceiro_funcionario,
          ...data,
        };
      });

      scheduler.onConfirm(
        {
          ...added_or_updated_agent,
          start: new Date(state.start),
          end: new Date(state.end),
        },
        event ? "edit" : "create"
      );

      scheduler.close();
    } finally {
      scheduler.loading(false);
    }
  };
  return (
    <div>
      <div style={{ padding: "1rem" }}>
        <NuvelGrid container spacing={2}>
          <NuvelGrid xs={12}>
            <Typography variant="h4">
              {event ? "Editar evento" : "Criar evento"}
            </Typography>
          </NuvelGrid>
          <NuvelGrid xs={12}>
            <NuvelTextField
              label="Título"
              value={state.title}
              onChange={(e) => handleChange(e.target.value, "title")}
              error={!!error}
              helperText={error}
              fullWidth
            />
          </NuvelGrid>
          <NuvelGrid xs={12}>
            <NuvelTextField
              label="Descrição"
              value={state.description}
              onChange={(e) => handleChange(e.target.value, "description")}
              fullWidth
            />
          </NuvelGrid>
          <NuvelGrid xs={6}>
            <DateTimePicker
              label="Início"
              value={state.start}
              onChange={(new_value) => handleChange(new_value, "start")}
              slotProps={{ textField: { variant: "filled", fullWidth: true } }}
              ampm={false}
            />
          </NuvelGrid>
          <NuvelGrid xs={6}>
            <DateTimePicker
              label="Fim"
              value={state.end}
              onChange={(new_value) => handleChange(new_value, "end")}
              slotProps={{ textField: { variant: "filled", fullWidth: true } }}
              ampm={false}
            />
          </NuvelGrid>
          <NuvelGrid xs={6}>
            <NuvelAutocomplete
              label="Funcionário"
              name="parceiro_funcionario"
              value={Number(state.parceiro_funcionario)}
              onChange={(e) =>
                handleChange(e.target.value, "parceiro_funcionario")
              }
              reference="funcionario"
            />
          </NuvelGrid>
          <NuvelGrid xs={6}>
            <NuvelAutocomplete
              label="Cliente"
              name="parceiro_cliente"
              value={Number(state.parceiro_cliente)}
              onChange={(e) => handleChange(e.target.value, "parceiro_cliente")}
              reference="cliente"
            />
          </NuvelGrid>
          <NuvelGrid xs={12}>
            <NuvelAutocomplete
              label="Serviço"
              name="service"
              value={Number(state.service)}
              onChange={(e) => handleChange(e.target.value, "service")}
              reference="servico"
            />
          </NuvelGrid>
        </NuvelGrid>
      </div>
      <DialogActions>
        <Button onClick={scheduler.close}>Cancelar</Button>
        <Button onClick={handleSubmit}>Confirmar</Button>
      </DialogActions>
    </div>
  );
};

export default CustomEditor;
