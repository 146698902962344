/* eslint-disable @typescript-eslint/no-explicit-any */
import { Add, CollectionsBookmark, Create, Folder } from "@mui/icons-material";
import {
  Box,
  Button,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import NuvelTreeItem from "components/nuvel/TreeItem";

const ColorButton = styled(Button)(({ theme }) => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    "&:hover": {
      backgroundColor: blue[700],
    },
  },
}));

export default function StyledTreeItem(props: any) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { ...other } = props;

  const { _origin: plano_de_conta } = props;

  const isPai = plano_de_conta.filhos?.length > 0;
  const name = `${props.prefix} - ${plano_de_conta.descricao}`;

  const mainObject = {
    _origin: plano_de_conta,
    name: name,
  };

  return (
    <NuvelTreeItem
      itemId={String(plano_de_conta.id)}
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0.2, 0),
          }}
        >
          {isPai ? (
            <Folder color="inherit" sx={{ mr: 1 }} />
          ) : (
            <CollectionsBookmark color="primary" sx={{ mr: 1 }} />
          )}
          <Typography
            variant="body2"
            sx={{
              fontWeight: "inherit",
              flexGrow: 1,
            }}
          >
            {name}
          </Typography>
          <Box display={matches ? "block" : "flex"} textAlign="right">
            <ColorButton
              onClick={(e) => props.handle_edit(e, mainObject)}
              startIcon={<Create />}
              size="small"
              variant="contained"
              color="primary"
              style={{ marginRight: 8 }}
            >
              Editar
            </ColorButton>
            <Button
              onClick={(e) => props.handle_add(e, mainObject)}
              startIcon={<Add />}
              size="small"
              variant="contained"
              color="primary"
            >
              Adicionar
            </Button>
          </Box>
        </div>
      }
      {...other}
    />
  );
}