/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { AutocompleteRender } from "./AutoCompleteRender.jsx";
import SwipeableViews from "react-swipeable-views";
import Dialog from "./dialog.jsx";
import { showConfirm } from "utils/functions/dialog";

export function ManyToOneRender(
  props,
  acValue,
  isMultiple,
  dados,
  loading,
  handleOpenCfg,
  handleDialogAdd,
  handleDialogClose,
  handleChangeDialog,
  classes,
  setDialog,
  _dialog,
  setForm,
  form,
  refreshDados
) {
  return (
    <Grid item xs={12} style={{ display: "flex" }}>
      <>
        {loading || !dados?.rows ? (
          <div className={classes.loadingCp}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <AutocompleteRender
              id={4}
              name={props.modelName}
              value={acValue}
              label={props.modelTitle}
              multiple={isMultiple}
              variant="filled"
              options={dados?.rows}
              getOptionLabel={(options) => {
                return options[props.optionLabel];
              }}
              getOptionSelected={(option, value) => {
                return value?.id === option?.id;
              }}
              onChange={(e, value) => {
                if (value) {
                  if (isMultiple) {
                    props.onChange(
                      null,
                      props.returnValue
                        ? value.map((v) => v[props.returnValue])
                        : value
                    );
                    return;
                  }
                  props.onChange(
                    {
                      target: {
                        name: props.modelName,
                        value: value[props.returnValue],
                      },
                    },
                    value
                  );
                } else {
                  props.onChange(null);
                }
              }}
              className={
                props.viewOnly
                  ? classes.mtoAutocompleteVo
                  : classes.mtoAutocomplete
              }
            />
            {props.viewOnly ? null : (
              <Button
                onClick={() => {
                  if (props.modelName === "unidade") {
                    window.open("/retaguarda/estoque/unidades/novo", "_blank");
                  } else {
                    handleOpenCfg();
                  }
                }}
                disabled={loading}
                width={50}
                color="primary"
                variant="contained"
                className={classes.mtoCfgButton}
              >
                <i className="material-icons"> settings </i>
              </Button>
            )}
          </>
        )}
        <Dialog
          open={_dialog.dialogOpen}
          title={props.modelTitle}
          handleAdd={handleDialogAdd}
          handleClose={handleDialogClose}
          buttonType={_dialog.pageIndex}
          listType
        >
          <SwipeableViews index={_dialog.pageIndex}>
            {/* <div>
              <DataTable
                onlyButton
                title={props.modelTitle}
                addBtn={() => {
                  const cleanFields = props.fields
                    .filter((f) => !f.isID)
                    .map((f) => {
                      return [f.name, f.default];
                    });
                  setDialog((v) => ({
                    ...v,
                    pageIndex: 1,
                    fields: { ...Object.fromEntries(cleanFields) },
                  }));
                }}
                onRowClick={async (event, rowData) => {
                  //Entra em modo de Edição
                  setForm({ ...form, edit: true });
                  setDialog((v) => ({
                    ...v,
                    pageIndex: 2,
                  }));
                  //dispatch(props.reduxGet(rowData.id));
                  const retobj = await props.model.get(rowData.id);
                  const valoresObj = Object.entries(retobj);
                  let finalFields = {};
                  valoresObj.forEach((data) => {
                    if (props.fields.find((campo) => campo.name === data[0])) {
                      //Se o campo existia na lista
                      finalFields[data[0]] = data[1];
                    }
                  });
                  setDialog((v) => ({
                    ...v,
                    fields: { ...finalFields },
                    pageIndex: 1,
                  }));
                }}
                actions={[
                  {
                    icon: "delete",
                    tooltip: `Deletar ${props.modelTitle}`,
                    onClick: (event, rowData) => {
                      showConfirm(
                        "Tem certeza que deseja deletar " +
                          props.modelTitle +
                          ": " +
                          rowData[props.optionLabel] +
                          " ?",
                        "Mensagem de Cofirmação",
                        async () => {
                          setDialog((v) => ({
                            ...v,
                            pageIndex: 2,
                          }));
                          await props.model.delete(rowData.id);
                          await refreshDados();
                          setDialog((v) => ({
                            ...v,
                            pageIndex: 0,
                          }));
                        }
                      );
                    },
                  },
                ]}
                columns={
                  props.fields
                    ? props.fields
                        .filter((field) => !field.isID)
                        .map((field) => ({
                          title: field.label,
                          field: field.name,
                        }))
                    : []
                }
                data={dados?.rows}
              />
            </div> */}
            <div className={classes.mtoAddPage}>
              {props.fields
                ? props.fields.map((field, key) =>
                    field.isID ? null : (
                      <TextField
                        key={key}
                        className={classes.text}
                        onChange={(e) => handleChangeDialog(e)}
                        value={
                          _dialog.fields
                            ? _dialog.fields[field.name] || field.default
                            : field.default
                        }
                        fullWidth
                        name={field.name}
                        mask={field.mask || null}
                        inputProps={field.inputProps}
                        type={field.type}
                        color="primary"
                        variant="filled"
                        label={field.label}
                        style={{
                          opacity: field.hidden ? 0 : 1,
                        }}
                      />
                    )
                  )
                : null}
            </div>
            <div style={{ height: "100%" }}>
              <Grid
                style={{ height: "100%" }}
                container
                justify="center"
                alignItems="center"
              >
                <CircularProgress size={60} />
              </Grid>
            </div>
          </SwipeableViews>
        </Dialog>
      </>
    </Grid>
  );
}
