import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_PRODUTOS_FILTERS,
  RETAGUARDA_PRODUTOS_LIST,
  RETAGUARDA_PRODUTOS_ORDERING,
} from "constants/retaguarda/cadastro/produtos";
import { ProdutoModel } from "data/models";
import React from "react";

const RetProdutosList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={ProdutoModel}
      columns={RETAGUARDA_PRODUTOS_LIST}
      filters={RETAGUARDA_PRODUTOS_FILTERS}
      ordering={RETAGUARDA_PRODUTOS_ORDERING}
      actions={{
        update: true,
        delete: true,
        create: true,
      }}
    />
  );
};

export default RetProdutosList;
