import { NuvelAutoForm } from "components/nuvel/forms";
import { RETAGUARDA_TIPOINSUMO_FORM } from "constants/retaguarda/estoque/tipo_insumo";
import { TipoInsumoModel } from "data/models";
import React from "react";

const RetTipoInsumoForm: React.FC = () => {
  return (
    <NuvelAutoForm
      model={TipoInsumoModel}
      fields={RETAGUARDA_TIPOINSUMO_FORM}
    />
  );
};

export default RetTipoInsumoForm;
