import { TipoInsumoSerializer } from "data/interfaces/estoque/TipoInsumoSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "estoque/tipoinsumo";

class TipoDeInsumoModel extends MainModule<
  TipoInsumoSerializer,
  TipoInsumoSerializer
> {}

export default new TipoDeInsumoModel(model_url);
