/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface NuvelDatePickerProps {
  dateTime?: boolean;
  timePicker?: boolean;
  label?: string;
  value?: string | dayjs.Dayjs;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  format?: string;
  disabled?: boolean;
  minDate?: Date;
  noStyle?: boolean;
}

const NuvelDatePicker: React.FC<NuvelDatePickerProps> = ({
  dateTime,
  timePicker,
  label,
  value,
  onChange,
  name,
  format,
  disabled,
  minDate,
  noStyle,
  ...rest
}) => {
  if (dateTime && timePicker) {
    throw new Error(
      "You can't use both dateTime and timePicker props at the same time"
    );
  }

  const handleChange = (newValue: any) => {
    onChange({
      target: {
        name: name,
        value: format ? dayjs(newValue).format(format) : newValue,
      },
    });
  };

  if (noStyle) {
    return (
      <DatePicker
        value={value ? dayjs(value) : null}
        onChange={handleChange}
        minDate={minDate ? minDate : null}
        disabled={disabled ? disabled : false}
        slotProps={{
          textField: {
            variant: "standard",
            InputProps: {
              disableUnderline: true,
            },
            sx: {
              "& .MuiInputBase-root": {
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: 1,
                paddingLeft: 1,
                "&:before, &:after": {
                  display: "none",
                },
              },
              "& .MuiInputAdornment-root": {
                marginRight: 1,
              },
            },
          },
        }}
      />
    );
  }

  if (dateTime) {
    return (
      <DateTimePicker
        label={label}
        value={value ? dayjs(value) : null}
        onChange={handleChange}
        minDate={minDate ? minDate : null}
        disabled={disabled ? disabled : false}
        sx={{ width: "100%" }}
        slotProps={{
          textField: {
            variant: "filled",
          },
        }}
        {...rest}
      />
    );
  }

  if (timePicker) {
    return (
      <TimePicker
        label={label}
        value={value ? dayjs(value) : null}
        onChange={handleChange}
        disabled={disabled ? disabled : false}
        sx={{ width: "100%" }}
        slotProps={{
          textField: {
            variant: "filled",
          },
        }}
        {...rest}
      />
    );
  }

  return (
    <DatePicker
      label={label}
      value={value ? dayjs(value) : null}
      onChange={handleChange}
      minDate={minDate ? minDate : null}
      disabled={disabled ? disabled : false}
      sx={{ width: "100%" }}
      slotProps={{
        textField: {
          variant: "filled",
        },
      }}
      {...rest}
    />
  );
};

export default NuvelDatePicker;
