import React from "react";
import { Typography, IconButton } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  codbarraContainer: {
    display: "contents",
  },
  codbarraDeleteButton: {
    position: "absolute",
    right: 16,
  },
  codbarraIcon: {
    fontSize: 30,
  },
}));

export default function Codbarra(props) {
  const classes = useStyles();
  const { data, handleItemDeleteClick, keyIndex } = props;
  return (
    <div className={classes.codbarraContainer} key={keyIndex}>
      <Typography variant="h5">{data.cod_barra}</Typography>
      <IconButton
        className={classes.codbarraDeleteButton}
        aria-label="deletar"
        onClick={(e) => handleItemDeleteClick(e, keyIndex)}
      >
        <DeleteIcon className={classes.codbarraIcon} />
      </IconButton>
    </div>
  );
}
