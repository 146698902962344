import { AutoFormProps } from "components/nuvel/forms/automatic/NuvelAutoForm";
import { TableColumn, TableFilter } from "components/nuvel/lists";
import { ServicoSerializer } from "data/interfaces/financeiro/ServicoSerializer";

export const RETAGUARDA_SERVICOS_FORM: AutoFormProps<ServicoSerializer>[] = [
  {
    type: "grid",
    grid: { xs: 12 },
    children: [
      {
        grid: { xs: 6 },
        label: "Serviço",
        name: "nome",
        type: "text",
      },
      {
        grid: { xs: 3 },
        label: "Preço de Custo",
        name: "preco_custo",
        type: "money",
      },
      {
        grid: { xs: 3 },
        label: "Preço de Venda",
        name: "preco_venda",
        type: "money",
      },
    ],
  },
];

export const RETAGUARDA_SERVICOS_LIST: TableColumn<ServicoSerializer>[] = [
  {
    label: "Código",
    accessor: "codigo",
    minWidth: 300,
  },
  {
    label: "Nome",
    accessor: "nome",
    minWidth: 300,
  },
  {
    label: "Preço de Custo",
    accessor: "preco_custo",
    minWidth: 300,
    type: "money",
  },
  {
    label: "Preço do serviço",
    accessor: "preco_venda",
    minWidth: 300,
    type: "money",
  },
];

export const RETAGUARDA_SERVICOS_ORDERING: (keyof ServicoSerializer)[] = [
  "codigo",
  "nome",
];

export const RETAGUARDA_SERVICOS_FILTERS: TableFilter<ServicoSerializer>[] = [
  {
    label: "Código",
    accessor: "codigo",
    support: ["icontains"],
  },
  {
    label: "Nome",
    accessor: "nome",
    support: ["icontains"],
  },
];
