/* eslint-disable @typescript-eslint/no-explicit-any */
import { Add, Delete } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import BFTable from "components/legacy/bf_table";
import { NuvelDefaultForm } from "components/nuvel/forms";
import NuvelGrid from "components/nuvel/Grid";
import NuvelTextField from "components/nuvel/TextField";
import { GradeModel } from "data/models";
import { useState } from "react";
import { handleChange } from "utils/functions";

function Form_Grade() {
  const [state, setState] = useState<any>({
    codigo: "",
    descricao: "",
    tipo: null,
    itens: [],
    item: "",
  });

  const handleAddItem = (item: any) => {
    if (item.trim() === "") {
      return;
    }

    const _temp = [...state.itens];
    _temp.push({
      descricao: item,
    });
    setState((_v: any) => ({
      ..._v,
      itens: _temp,
      item: "",
    }));
  };

  const handleRemove = (index: any) => {
    const _temp = [...state.itens];
    _temp.splice(index, 1);
    setState((_v: any) => ({
      ..._v,
      itens: _temp,
    }));
  };

  return (
    <NuvelDefaultForm setState={setState} state={state} model={GradeModel}>
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={12} sm={6}>
          <NuvelTextField
            label="Código"
            name="codigo"
            type="number"
            value={state.codigo}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} sm={6}>
          <NuvelTextField
            label="Descrição"
            name="descricao"
            value={state.descricao}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} sm={8}>
          <NuvelTextField
            label="Item"
            onChange={(e) => handleChange(e, setState)}
            name="item"
            value={state.item}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAddItem(state.item)}
            fullWidth
            startIcon={<Add />}
          >
            Adicionar
          </Button>
        </NuvelGrid>
        <NuvelGrid xs={12}>
          <BFTable
            setRows={(itens: any) => setState((v: any) => ({ ...v, itens }))}
            columns={[
              {
                Header: "Descrição",
                accessor: "descricao",
                input: true,
              },
              {
                Header: "Ações",
                accessor: (_: any, __: any, krow: any) => (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemove(krow);
                    }}
                  >
                    <Delete fontSize="inherit" />
                  </IconButton>
                ),
              },
            ]}
            rows={state.itens}
            noPaper
          />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelDefaultForm>
  );
}

export default Form_Grade;
