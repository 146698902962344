import { RouteInterface } from "utils/router";

import RetGrupoDePermissoesList from "../views/configuracoes/grupo_de_permissoes/list";
import RetGrupoDePermissoesForm from "../views/configuracoes/grupo_de_permissoes/form";

const configuracoesRoutes: RouteInterface[] = [
  {
    title: "Grupo de Permissões",
    path: "/retaguarda/config/grupo_de_permissoes",
    element: <RetGrupoDePermissoesList />,
    form: <RetGrupoDePermissoesForm />,
    perm: "CONFIGURACOES_GRUPO_DE_PERMISSOES",
  },
];

export default configuracoesRoutes;
